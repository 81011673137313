import React, { ComponentType, useMemo } from 'react';

import { Id } from '@rexlabs/model-generator';
import Box from '@rexlabs/box';
import { StyleSheet, useStyles } from '@rexlabs/styling';

import CollisionAvoidanceBar from 'view/components/collision-avoidance-bar';

import { Screen } from 'components/screen';

import { RecordDetailsTabs } from './tabs';
import { RecordDetailsStreams } from './streams';
import { RecordDetailsPagination } from './pagination';
import { RecordDetailActions } from './actions';
import { useSaveCancelOverlay } from './utils/use-save-cancel-overlay';
import { useMode } from './utils/use-mode';

const defaultStyles = StyleSheet({
  content: {
    width: '940px',
    display: 'flex',
    flexDirection: 'row',

    '@media only screen and (min-width: 1720px)': {
      width: '1605px'
    }
  },

  contentFullWidth: {
    width: '100%',

    '@media only screen and (min-width: 1720px)': {
      width: '100%'
    }
  },

  main: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',

    '@media only screen and (min-width: 1720px)': {
      flexDirection: 'row'
    }
  },

  recordDetails: {
    padding: '0 25px 25px 0',
    margin: 0,
    display: 'flex',
    flex: 1,
    flexDirection: 'column'
  },

  actionsBar: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '18px',
    height: '34px'
  },

  actions: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },

  titleBlock: {
    marginBottom: '18px'
  },

  recordTabs: {},

  right: {
    width: '275px'
  },

  pagination: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    height: '34px'
  },

  paginationRight: {
    marginBottom: '18px'
  },

  rightBar: {
    width: '100%',
    background: '#fff'
  },

  streams: {
    width: '100%',
    padding: '0 25px 25px 0',

    '@media only screen and (min-width: 1720px)': {
      width: '665px'
    }
  },

  streamTabs: {},

  collisionAvoidanceBar: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0
  }
});

export interface RecordDetailsScreenContentProps {
  data: any; // TODO: properly type
  status: string;
  serviceName: string;
  id: Id;
  TitleBlock: ComponentType<any>;
  modes?: any[]; // TODO: modes config
  blocks?: any; // TODO: blocks config
  widgets?: any; // TODO: widgets config
  streams?: any; // TODO: streams config
  actions?: any[]; // TODO: actions config
  form?: any; // TODO: form passthrough props
  customTabs: any;
}

export function RecordDetailsScreenContent({
  data,
  status,
  TitleBlock,
  modes = [],
  blocks,
  widgets,
  streams,
  actions,
  form,
  customTabs,
  serviceName,
  id
}: RecordDetailsScreenContentProps) {
  const s = useStyles(defaultStyles);

  const [modeId] = useMode();
  const activeMode = useMemo(() => modes.find((mode) => mode.id === modeId), [
    modeId,
    modes
  ]);

  const visibleBlocks = blocks?.[modeId];
  const visibleWidgets = widgets?.[modeId];
  const visibleStreams = streams?.[modeId];

  const isFullWidth = activeMode?.isFullWidth;

  useSaveCancelOverlay(form);

  return (
    <>
      <Screen>
        <Box {...s('content', { contentFullWidth: isFullWidth })}>
          <main {...s('main')}>
            <Box {...s('recordDetails')}>
              <Box {...s('actionsBar')}>
                <Box {...s('actions')}>
                  <RecordDetailActions
                    actions={actions}
                    data={data}
                    modes={modes}
                  />
                </Box>
                {!visibleWidgets?.length ? (
                  <Box {...s('pagination')}>
                    <RecordDetailsPagination />
                  </Box>
                ) : (
                  <Box />
                )}
              </Box>
              <Box {...s('titleBlock')}>
                <TitleBlock data={data} status={status} />
              </Box>
              <Box {...s('recordTabs')}>
                <RecordDetailsTabs
                  tabs={visibleBlocks}
                  customTabs={customTabs}
                  data={data}
                  values={form?.values}
                />
              </Box>
            </Box>
            {!!visibleStreams?.length && (
              <Box {...s('streams')}>
                <RecordDetailsStreams streams={visibleStreams} data={data} />
              </Box>
            )}
          </main>
          {!!visibleWidgets?.length && (
            <aside {...s('right')}>
              <Box {...s('pagination', 'paginationRight')}>
                <RecordDetailsPagination />
              </Box>
              <Box {...s('rightBar')}>
                {visibleWidgets?.map?.((widget) => {
                  if (widget.widgets?.length) {
                    // TODO: implement "show more" functionality for listtings right bar
                    // Worth checking with UX if this could be removed/simplified first tho
                    return (
                      <Box
                        {...s('widgetGroup', {
                          widgetGroupGrey: widget.isGrey
                        })}
                      >
                        {widget.widgets.map((widget) => {
                          const Component = widget.Component;
                          if (Component) {
                            return (
                              <Component data={data} values={form?.values} />
                            );
                          }
                          return null;
                        })}
                      </Box>
                    );
                  }
                  const Component = widget.Component;
                  if (Component) {
                    return <Component data={data} values={form?.values} />;
                  }
                  return null;
                })}
              </Box>
            </aside>
          )}
        </Box>
      </Screen>
      <Box {...s('collisionAvoidanceBar')}>
        <CollisionAvoidanceBar
          serviceName={serviceName}
          recordId={id}
          userRecordPermissions={data?.security_user_rights || []}
        />
      </Box>
    </>
  );
}
