import React, { CSSProperties, ReactNode } from 'react';
import Box from '@rexlabs/box';
import { StyleSheet, useStyles } from '@rexlabs/styling';
import {
  CellActionMenu,
  CellActionMenuProps
} from '../components/cell-action-menu';

const defaultStyles = StyleSheet({
  container: {}
});

export interface DefaultCellProps {
  children?: ReactNode;
  value?: ReactNode;
  emptyValue?: ReactNode;
  width?: string;
  data?: Record<string, any>;
  items?: CellActionMenuProps['items'];
  'data-testid'?: string;
  style?: CSSProperties;
  fullWidth?: boolean;
  justifyContentEnd?: boolean;
}

export function DefaultCell({
  children,
  value,
  emptyValue = '—',
  width,
  items,
  data,
  'data-testid': testId,
  style = {},
  fullWidth = false,
  justifyContentEnd = false
}: DefaultCellProps) {
  const s = useStyles(defaultStyles);
  return (
    <td
      style={style}
      {...s.with(justifyContentEnd ? 'justifyContentEnd' : 'container')({
        width,
        ...style
      })}
    >
      <Box
        justifyContent={justifyContentEnd ? 'flex-end' : 'space-between'}
        alignItems='center'
      >
        <span
          data-testid={testId || 'RecordList.Cell'}
          style={{ width: fullWidth ? '100%' : width }}
        >
          {children || value || emptyValue}
        </span>
        {items?.length ? <CellActionMenu data={data} items={items} /> : null}
      </Box>
    </td>
  );
}
