import { useMemo } from 'react';

import { QueryCriteria, Selection } from '../types';

interface UseSelectionCriteria {
  queryCriteria?: QueryCriteria[];
  selection: Selection;
}

export function useSelectionCriteria({
  queryCriteria,
  selection
}: UseSelectionCriteria): QueryCriteria[] {
  return useMemo(() => {
    const { type, ids } = selection;
    return [
      ...(queryCriteria || []),
      { name: 'id', type: type === 'exclude' ? 'notin' : 'in', value: ids }
    ];
  }, [queryCriteria, selection]);
}
