/*
|-------------------------------------------------------------------------------
| Menu Switcher
|-------------------------------------------------------------------------------
|
| The following component decides which list of results should be shown currently and
| applies custom animations to allow a seemless transition between view states.
|
| Initially shown are recent results if any, followed by global search results, and finally
| scoped search results if we are currently in a scoped view state.
|
| State:
|  -
|
| Actions:
|  - handleOuterClick
|  - scrollIntoView
|  - handleMenuScroll
|  - handleOptionHover
|  - handleOptionHover
|  - handleOptionSelect
|
| Lifecycle:
|  - componentWillReceiveProps
|  - componentWillUnmount
|
| Helpers:
|  - unsubscribeFromOuterClick
|  - getHeightBasedOnResults
|  - setRef
|
*/

import { selectController } from '@rexlabs/select-input';
import { styled, StyleSheet } from '@rexlabs/styling';
import { autobind } from 'core-decorators';
import React, { PureComponent } from 'react';
import DefaultErrorComponent from '../components/default-error-component';
import { preventDefault, subscribeToOuterClick, VIEW_MODES } from '../utils';
import MenuEntities from './menu-entities';
import MenuRecents from './menu-recents';
import SearchHelpBar from 'view/components/navigation/app-search/base/components/search-help-bar';

@styled(
  StyleSheet({
    container: {
      position: 'absolute',
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      zIndex: 1000,
      maxHeight: '665px',
      minHeight: '584px',
      overflow: 'hidden',
      background: 'white',
      outline: 'none',
      borderBottomLeftRadius: '2px',
      borderBottomRightRadius: '2px',
      boxShadow: '0 6px 22px 0 rgba(82,96,147,0.26)'
    },
    longHeight: { height: '90vh', maxHeight: '90vh' },
    shortHeight: { height: '70vh', maxHeight: '70vh' },
    scrollableArea: {
      flex: 1,
      overflowX: 'hidden',
      overflowY: 'scroll',
      minHeight: '400px'
    },
    primary: {},
    secondary: {}
  })
)
@autobind
class MenuSwitcher extends PureComponent {
  constructor(...args) {
    super(...args);

    this.unsubscribeFromOuterClick = subscribeToOuterClick(
      this.handleOuterClick
    );
  }

  getIsScoped() {
    const { viewMode } = this.props;
    return viewMode !== VIEW_MODES.GLOBAL_SEARCH;
  }

  getShowLoadingInState() {
    const { showLoadingInState, select } = this.props;
    const {
      state: { isLoading }
    } = select;
    return showLoadingInState || isLoading;
  }

  componentWillReceiveProps(nextProps) {
    const {
      select: nextSelect,
      showLoadingInState: nextShowLoadingInState,
      viewMode: nextViewMode,
      ...props
    } = nextProps;

    const currentShowLoadingInState = this.getShowLoadingInState();
    const currentViewMode = this.props.viewMode;
    if (currentShowLoadingInState && !nextShowLoadingInState) {
      nextSelect.actions.setIndex(0);
    }

    if (
      !props.isHidden &&
      props.isHidden !== this.props.isHidden &&
      this.props.autoOpen
    ) {
      this.handleOpenMenu(); // This is used for mobile devices to open the search straight away
    }

    // Reset the position of the menu switcher encase the user has scrolled
    // This needs to happen before switching menu's or the second menu gets
    // stuck above the view port until the menu re-opens
    if (currentViewMode !== nextViewMode) {
      if (this.el && this.el.scrollTop && this.el.scrollTop > 0) {
        this.el.scrollTop = 0;
      }
    }
  }

  handleOpenMenu() {
    this.props.select.actions.openMenu();
  }

  componentWillUnmount() {
    this.unsubscribeFromOuterClick();
  }

  render() {
    const {
      styles: s,
      select,
      searchErrors,
      Error,
      searchTerm,
      showLoadingInState,
      viewMode,
      onBack,
      recentOptions,
      renderRecents,
      emptySearch,
      canQuickFilter,
      quickFilterTypes,
      SectionBack,
      handleBack,
      shouldShowSectionBack,
      hasScopedScroll,
      partiallyLoading
    } = this.props;
    const {
      state: { isOpen }
    } = select;

    if (!isOpen) return null;

    // The primary & secondary allow us to animate results
    // and lets us have multiple layers of results
    // e.g. Global results & scoped results
    let menuPrimary = null; // First layer of the results
    let menuSecondary = null; // Second layer of the results

    const menuProps = {
      searchTerm,
      emptySearch,
      onBack,
      onHover: this.handleOptionHover,
      onSelect: this.handleOptionSelect,
      scrollIntoView: this.scrollIntoView,
      renderOptions: this.props.renderOptions,
      renderLoadingInState: this.props.renderLoadingInState,
      showLoadingInState,
      viewMode,
      canQuickFilter,
      quickFilterTypes,
      SectionBack,
      handleBack,
      shouldShowSectionBack,
      partiallyLoading
    };

    if (searchErrors) {
      menuPrimary = Error ? <Error /> : <DefaultErrorComponent />;
    } else {
      if (!this.getIsScoped()) {
        if (
          recentOptions.length &&
          ((searchTerm && searchTerm.length < 2) || !searchTerm)
        ) {
          menuPrimary = (
            <MenuRecents
              key='recents'
              renderRecents={renderRecents}
              styledClass='primary'
              {...menuProps}
            />
          );
        } else {
          menuPrimary = (
            <MenuEntities key='global' styledClass='primary' {...menuProps} />
          );
        }
      } else {
        menuSecondary = (
          <MenuEntities
            key={`scoped-${viewMode}`}
            onBack={onBack}
            styledClass='secondary'
            {...menuProps}
          />
        );
      }
    }

    return (
      <div
        {...s('container')}
        onKeyDown={this.handleKeyDown}
        onMouseDown={preventDefault}
        tabIndex={-1} // Forces tab to jump to the first option and avoid this container
      >
        <div ref={this.setRef} {...s('scrollableArea')}>
          {menuPrimary}

          {menuSecondary}
        </div>
        <SearchHelpBar />
      </div>
    );
  }

  setRef(ref) {
    const { zenscroll } = this.props;
    this.el = ref;
    if (this.el) {
      this.el.removeEventListener('scroll', this.handleMenuScroll);
      this.Scroller = zenscroll.createScroller(this.el, 0, 0);
      this.el.addEventListener('scroll', this.handleMenuScroll);
    }
  }

  scrollIntoView(el) {
    if (this.Scroller && el) this.Scroller.intoView(el, 0);
  }

  handleOuterClick(event) {
    const {
      actions,
      state: { isOpen }
    } = this.props.select;
    // HACK: Target the Select container (parent of menu)
    if (isOpen && this.el && !this.el.parentElement.contains(event.target)) {
      actions.closeMenu();
    }
  }

  handleKeyDown(event) {
    const { keyCode } = event;
    const { select } = this.props;
    switch (keyCode) {
      case 9: // TAB
        if (select.state.isOpen) {
          // Note: Blocks the escape from closing other components. eg Modals
          event.stopPropagation();
          select.actions.closeMenu();
        }
        break;
      default:
        break;
    }
  }

  // TODO: Implement the on scroll bottom showing a custom element
  handleMenuScroll(event) {
    const el = event.target;
    const { onScrollToBottom } = this.props;
    if (!onScrollToBottom) return;
    const bottom = el.scrollTop;
    const max = el.scrollHeight - el.clientHeight;
    if (bottom === max) {
      onScrollToBottom(event);
    }
  }

  handleOptionHover(activeIndex) {
    const { select } = this.props;
    if (activeIndex !== select.state.index) {
      select.actions.setIndex(activeIndex);
    }
  }

  handleOptionSelect(option, event) {
    const {
      select: { actions }
    } = this.props;
    actions.closeMenu();
    actions.setValue(undefined);
    actions.pushSelected(option);
    preventDefault(event);
  }
}

/*
|-------------------------------------------------------------------------------
| WithSelectController HoC
|-------------------------------------------------------------------------------
|
| Provides the MenuSwitcher class with the selectController HoC and allowing us
| to still hit the MenuSwitcher when using StylesProvider. Due to the way react
| constructs components in the DOM we loose reference to the original Component
| name.
|
*/
@selectController
class WithSelectController extends PureComponent {
  render() {
    const { ...props } = this.props;
    return <MenuSwitcher {...props} />;
  }
}

export default WithSelectController;
