import Box from '@rexlabs/box';
import { withModel } from '@rexlabs/model-generator';
import { RecordListTable } from 'components/record-list-screen/table';
import React, { Dispatch, FC, SetStateAction, useMemo } from 'react';
import thirdPartyServiceXeroModel from 'features/apps/xero/data/third-party-service-xero';
import {
  InvoicePagination,
  ThirdPartyServiceXeroModel
} from 'features/apps/xero/types/third-party-service-xero';
import { compose } from 'redux';
import { Invoice } from 'features/apps/xero/types/invoice';
import { Body } from 'components/text/body';
import { ColumnConfig, Selection } from 'components/record-list-screen/types';
import { DateCell } from 'components/record-list-screen/cells/date-cell';
import { useRegion } from 'hooks/use-region';
import { formatCurrency } from 'src/utils/formatters';
import { ContactCell } from 'components/record-list-screen/cells/contact-cell';
import Spinner from 'shared/components/spinner';
import { EntityCell } from 'features/apps/xero/components/entity-cell';
import { pick } from 'lodash';
import { Pagination } from 'view/components/record-details-screen/related-table/pagination';
import ErrorList from '../error-list';
import { useDialog } from 'hooks/use-dialog';
import { StateView } from 'components/record-list-screen/state-view';

interface ExportInvoicesProps {
  thirdPartyServiceXero: ThirdPartyServiceXeroModel;
  selection: Selection;
  setSelection: Dispatch<SetStateAction<Selection>>;
  ignoredInvoices: string[];
  setIgnoredInvoices: Dispatch<SetStateAction<string[]>>;
  invoices: Invoice[];
  isLoading: boolean;
  selectedInvoices: string[];
  pagination: InvoicePagination;
  errors: string[];
  invoiceIds: string[];
}

const ExportInvoices = ({
  invoices,
  isLoading,
  selection,
  setSelection,
  ignoredInvoices,
  setIgnoredInvoices,
  selectedInvoices,
  pagination,
  errors,
  invoiceIds
}: ExportInvoicesProps) => {
  const region = useRegion();
  const editInvoice = useDialog('editInvoice');
  const currencySymbol = region.financial.currency.symbol;

  const viewInvoice = (row: Invoice) => {
    if (row?._id) {
      editInvoice.open({
        id: row._id,
        data: row
      });
    }
  };

  const columns: ColumnConfig<Invoice>[] = useMemo(
    () => [
      {
        id: 'invoice_number',
        label: 'Invoice No.',
        forced: true
      },
      {
        id: 'listing',
        label: region.isUK ? 'Listing, Project or Project Stage' : 'Listing',
        forced: true,
        selector: (row) => pick(row, ['listing', 'project', 'project_stage']),
        Cell: EntityCell as FC,
        cellProps: {
          sequence: ['listing', 'project', 'project_stage'],
          items: (row: Invoice) => {
            const rowActions = [
              {
                label: 'View Invoice',
                onClick: () => {
                  viewInvoice(row);
                }
              }
            ];

            if (ignoredInvoices.includes(row._id)) {
              rowActions.push({
                label: 'Revert Invoice',
                onClick: () => {
                  setIgnoredInvoices((prev) =>
                    prev.filter((prev) => prev !== row._id)
                  );
                }
              });
            } else {
              rowActions.push({
                label: 'Ignore Invoice',
                onClick: () => {
                  setIgnoredInvoices((prev) => [...prev, row._id]);
                  setSelection((prev) => {
                    if (prev.type === 'include') {
                      return {
                        ...prev,
                        ids: prev.ids.filter((prevId) => prevId !== row._id)
                      };
                    } else {
                      return {
                        ...prev,
                        ids: [...prev.ids, row._id]
                      };
                    }
                  });
                }
              });
            }

            return rowActions;
          }
        }
      },
      {
        id: 'system_ctime',
        label: 'Date',
        forced: true,
        Cell: DateCell
      },
      {
        id: 'amount',
        label: 'Amount',
        selector: (row) =>
          currencySymbol + formatCurrency(row.system_total_amount),
        forced: true
      },
      {
        id: 'contact',
        label: 'Person',
        selector: (row) => ({
          id: row.contact_id,
          name: row.contact_name
        }),
        forced: true,
        Cell: ContactCell
      },
      {
        id: 'invoice_type',
        label: 'Type',
        selector: (row) => row.invoice_type?.text,
        forced: true
      }
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currencySymbol, region, ignoredInvoices]
  );

  return (
    <Box>
      <Box justifyContent='space-between' alignItems='center' mb={15}>
        <Body dark semibold>
          Export Invoices to Xero
        </Body>
        <Body small light normal regular>
          {selectedInvoices.length} items selected
        </Body>
      </Box>

      {errors.length > 0 && <ErrorList errors={errors} />}

      <RecordListTable
        allIds={invoiceIds}
        items={invoices}
        columns={columns}
        visibleColumns={columns.map((c) => c.id)}
        setVisibleColumns={() => null}
        selection={selection}
        setSelection={setSelection}
        disabledSelections={ignoredInvoices}
        hasSelection={true}
        onItemClick={viewInvoice}
        setOrderBy={() => null}
        isLoading={isLoading}
        LoadingView={() => (
          <Box
            flexDirection='row'
            width={'100%'}
            height={100}
            alignItems='center'
            justifyContent='center'
            spacing={20}
          >
            <Body dark semibold>
              Searching for invoices
            </Body>
            <Spinner small dark />
          </Box>
        )}
        EmptyView={() => <StateView noPadding>No invoices found</StateView>}
        variant={'compact'}
      />

      {pagination.pagination.totalPages > 1 && (
        <Box flexDirection='row' justifyContent='flex-end' mt={5}>
          <Pagination
            pagination={pagination.pagination}
            limit={10}
            page={pagination.page}
            setPage={pagination.setPage}
            variant='arrow'
          />
        </Box>
      )}
    </Box>
  );
};

export default compose<
  React.ComponentType<Omit<ExportInvoicesProps, 'thirdPartyServiceXero'>>
>(withModel(thirdPartyServiceXeroModel))(ExportInvoices);
