import { ModelGeneratorLoaded } from '@rexlabs/model-generator';

import { Generator } from 'shared/utils/models';
import { api } from 'shared/utils/api-client';
import { isSystemEventTypeId } from 'utils/calendar';

import { ReminderTypeValue } from 'features/calendar/data/reminder-type';
import { FollowUpReminderDueDateValue } from 'features/calendar/data/follow-up-reminder-due-dates';
import { AppointConfirmationSendViaValue } from 'features/calendar/data/appointment-confirmation-send-via';
import { UserItem } from 'data/models/value-lists/account-users';

type Id = string;
type Text = string;

type AlertSendUnitId = Id;
type AlertSendUnitText = Text;
type AlertSendUnit = {
  id: AlertSendUnitId;
  text: AlertSendUnitText;
};

export type AlertTypeId = Id;
type AlertTypeText = Text;
type AlertType = {
  id: AlertTypeId;
  text: AlertTypeText;
};

export interface AppointmentTypeSelectValue {
  value: string | number;
  label: string;
  model: object;
  // TODO: Need to fix this up - more details below:
  // https://app.clubhouse.io/rexlabs/story/53904/appointment-type-in-calendar-event
  appointment_type?: AppointmentTypeData;
  data: AppointmentTypeData;
}

export interface AlertData {
  alert_send_unit: AlertSendUnit;
  alert_send_value: string | number;
  alert_type: AlertType;
  id: number;
  recipient_type: {
    id: Id;
    text: Text;
  };
}

export type AppointmentFreebusyId = Id;
type AppointmentFreebusyText = Text;
type AppointmentFreebusy = {
  id: AppointmentFreebusyId;
  text: AppointmentFreebusyText;
};

export type AppointmentDuration = 15 | 30 | 45 | 60 | 90 | 120;

export interface AppointmentTypeData {
  account_id: number;
  alert_email_body: string | null;
  alert_email_subject: string | null;
  alert_sms_body: string | null;
  color: string | null;
  confirmation_can_edit_before_send: 1 | null;
  confirmation_email_body: string | null;
  confirmation_email_subject: string | null;
  confirmation_send_via: AppointConfirmationSendViaValue;
  confirmation_sms_body: string | null;
  vendor_confirmation_can_edit_before_send: 1 | null;
  vendor_confirmation_email_body: string | null;
  vendor_confirmation_email_subject: string | null;
  vendor_confirmation_send_via: AppointConfirmationSendViaValue;
  vendor_confirmation_sms_body: string | null;
  duration: AppointmentDuration;
  etag: string;
  freebusy: AppointmentFreebusy;
  id: string;
  is_active: string;
  is_hidden: 1 | null;
  library: { id: number; _library_name: string } | null;
  name: string;
  related: {
    alerts: AlertData[];
  };
  reminder_create_automatically: 1 | null;
  reminder_custom_due_date_delay: string | null;
  reminder_custom_due_date_unit: {
    id: Id;
    text: Text;
  };
  reminder_due_date: FollowUpReminderDueDateValue;
  reminder_type: ReminderTypeValue;
  system_created_user: UserItem;
  system_ctime: number;
  system_modified_user: UserItem;
  system_modtime: number;
  system_owner_user: UserItem;
  system_record_state: string;
  category: {
    id: Id;
    text: Text;
  };
}

interface AppointmentType {
  id: string | number;
  name: string;
}

export type AdminAppointmentTypesModel = ModelGeneratorLoaded<
  AppointmentTypeData,
  any
>;

const TYPE = 'adminAppointmentTypes';
const adminAppointmentTypesModel = new Generator(TYPE).createEntityModel();

// TODO: work out how to add select to model so TS stops yelling
// eslint-disable-next-line
// @ts-ignore
adminAppointmentTypesModel.select = {
  autocomplete: (searchTerm: string) =>
    api
      .post('AdminAppointmentTypes::search', {
        criteria: [{ name: 'name', type: 'like', value: `%${searchTerm}%` }]
      })
      .then(({ data }) =>
        data.result.rows
          .filter((type: AppointmentType) => {
            // We need to filter auction and open home out here
            return !isSystemEventTypeId(type.id);
          })
          .map((type: AppointmentType) => ({
            value: type.id,
            label: type.name,
            model: adminAppointmentTypesModel,
            data: type
          }))
      )
};

export function filterSystemAppointmentTypes(types) {
  return types.filter((t) => !isSystemEventTypeId(t.id));
}

export default adminAppointmentTypesModel;
