import { withModel } from '@rexlabs/model-generator';
import { Autobind } from 'utils/decorators';
import React, { PureComponent } from 'react';
import { styled, StyleSheet } from '@rexlabs/styling';
import { withDevice } from 'view/containers/with-device';
import ClassicAppFrame from 'view/classic-bridges/app-frame';
import { getOverlaySync } from 'utils/overlays';
import { match, parseUrlToRoute, withWhereabouts } from '@rexlabs/whereabouts';
import { SLOW_ANIMATION_DURATION } from 'view/components/navigation/shell/menu/constants';
import config from 'shared/utils/config';
import { connect } from 'react-redux';
import _ from 'lodash';

import uiModel, { COMPONENTS } from 'data/models/custom/ui';
import navModel from 'data/models/custom/nav';
import Analytics from 'shared/utils/vivid-analytics';
import { COLORS } from 'src/theme';

const appStyles = StyleSheet({
  container: {
    position: 'relative',
    width: '100%',
    height: '100%',
    backgroundColor: COLORS.WARM_GREY
  },
  withNav: {
    flex: 'initial',
    animationDuration: '300ms',
    animationFillMode: 'forwards'
  },
  edgeWithNav: {
    flex: 'initial',
    transition: 'width 300ms ease-in-out'
  },
  withNavDesktopPosition: {
    // left: '58px'
  },
  withNavTabletMobile: {
    '&:after': {
      content: '""',
      width: '100%',
      height: '100%',
      position: 'fixed',
      top: 0,
      left: 0,
      zIndex: '11',
      backgroundColor: 'rgba(0,0,0,0)',
      pointerEvents: 'none',
      transition: 'background-color 300ms ease-in-out'
    }
  },
  withNavDarkenedOverlay: {
    '&:after': {
      pointerEvents: 'initial',
      backgroundColor: 'rgba(0,0,0,0.2)'
    }
  },
  appInstantAnimation: {
    animationDuration: '0ms'
  }
});

@withWhereabouts
@withModel(uiModel)
@styled(appStyles)
@Autobind
class ClassicApp extends PureComponent {
  static pluckViewpathRoute(whereabouts) {
    return whereabouts.hashQuery?.viewpath
      ? parseUrlToRoute(whereabouts.hashQuery?.viewpath)
      : undefined;
  }

  state = {
    isStepthroughOverflowing: false,
    isStepthroughMinimized: false
  };

  skipNextAnimation = false;

  componentDidMount() {
    Analytics.performance({
      task: 'Classic-FullInit',
      event: 'ClassicIframeRender'
    });
  }

  componentWillReceiveProps(nextProps) {
    const { ui, whereabouts } = this.props;
    const { disabled: currentlyDisabled } = ui;
    const {
      ui: { disabled: nextDisabled }
    } = nextProps;

    // NOTE: We are not setting this.skipNextAnimation directly to the if condition cause
    // it sets to false quickly after it becomes true 🤦‍
    if (currentlyDisabled.includes('menu') && !nextDisabled.includes('menu')) {
      this.skipNextAnimation = true;
      // Using a timeout vs onAnimationEnd as it was firing unreliably
      setTimeout(
        () => (this.skipNextAnimation = false),
        SLOW_ANIMATION_DURATION
      );
    }

    // This fixes issue where using naviation buttons to leave applet overlay would not unhide classic header
    // Make sure not to consider an additional "#" as a new path
    if (whereabouts.path.replace('#', '') !== nextProps.whereabouts.path) {
      if (ui.isOverlayActive) {
        ui.updateOverlayActive(false);
      }

      if (ui.isClassicDialogActive) {
        ui.updateClassicDialogActive(false);
      }

      if (ui.isDialogActive) {
        ui.updateDialogActive(false);
      }

      if (
        ui.disabled.includes(COMPONENTS.HEADER) ||
        ui.disabled.includes(COMPONENTS.MENU)
      ) {
        ui.showComponents([COMPONENTS.HEADER, COMPONENTS.MENU]);
      }
    }
  }

  render() {
    const { whereabouts } = this.props;
    const isStepthrough = match(whereabouts, { path: '/stepthrough' });

    return isStepthrough
      ? this.renderClassicWithStepthrough()
      : this.renderClassicOnly();
  }

  renderClassicWithStepthrough() {
    const { styles: s, ui, onClick, whereabouts } = this.props;

    const overlaySync = getOverlaySync(ui);
    const styles = s.with('container', {
      appInstantAnimation: this.skipNextAnimation
    })(this.props);
    const appFrameRoute = ClassicApp.pluckViewpathRoute(whereabouts);

    if (appFrameRoute) {
      return (
        <div {...styles} onClick={overlaySync.isBlocking ? undefined : onClick}>
          <ClassicAppFrame whereabouts={appFrameRoute} />
        </div>
      );
    }
    return null;
  }

  renderClassicOnly() {
    const { styles: s, onClick, whereabouts } = this.props;
    const styles = s.with('container', {
      appInstantAnimation: this.skipNextAnimation
    })(this.props);

    return (
      <div {...styles} onClick={onClick}>
        {/* Framed original Rex 2 App */}
        {config.ENABLE_REX_FRAME && (
          <ClassicAppFrame whereabouts={whereabouts} />
        )}
      </div>
    );
  }

  onStepthroughUpdateOverflow(isOn) {
    this.setState(() => ({ isStepthroughOverflowing: isOn }));
  }

  onStepthroughUpdateMinimized(shouldMinimize, callback) {
    this.setState(() => ({ isStepthroughMinimized: shouldMinimize }), callback);
  }
}

@withDevice
@connect((state) => ({ isSwitching: _.get(state, 'session.isSwitching') }))
@withModel(navModel)
@withModel(uiModel)
@styled(appStyles)
@Autobind
class ClassicAppSupportingNav extends PureComponent {
  render() {
    const {
      styles: s,
      nav,
      device,
      ui: { disabled }
    } = this.props;

    // TODO: clean this up once the new menu is proven stable enough
    // that we won't roll this back
    const isNewMenu = true;

    const isMenuHidden = disabled.includes('menu');
    const isEdge = window.navigator.userAgent.indexOf('Edge') > -1;

    const className = isNewMenu
      ? s({
          withNav: isNewMenu && !isEdge,
          edgewithNav: isNewMenu && isEdge,
          withNavTabletMobile:
            isNewMenu && (device.isTablet || device.isMobile),
          withNavAppFrameOnNavOpen:
            isNewMenu && !isEdge && !device.isMobile && nav.isOpen,
          edgeWithNavAppFrameOnNavOpen:
            isNewMenu && isEdge && !device.isMobile && nav.isOpen,
          withNavAppFrameOnNavClose:
            isNewMenu && !isEdge && !device.isMobile && !nav.isOpen,
          edgeWithNavAppFrameOnNavClose:
            isNewMenu && isEdge && !device.isMobile && !nav.isOpen,
          withNavDesktopPosition: isNewMenu && device.isDesktop,
          withNavDarkenedOverlay:
            isNewMenu &&
            nav.hasDarkenedOverlay &&
            (device.isTablet || device.isMobile),
          withNavHidden: isMenuHidden,
          withFullNavHidden: isMenuHidden && nav.isOpen
        }).className
      : '';

    return <ClassicApp className={className} />;
  }
}

class ClassicAppScreen extends PureComponent {
  render() {
    return <ClassicAppSupportingNav />;
  }
}

export default ClassicAppScreen;
