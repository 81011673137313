import React, { Fragment, memo, ReactNode, useState } from 'react';
import { StyleSheet, useStyles } from '@rexlabs/styling';
import { useModelActions } from '@rexlabs/model-generator';
import { COLORS, FONT, PADDINGS } from 'theme';
import appraisalsModel, {
  AppraisalData
} from 'data/models/entities/appraisals';
import dayjs from 'dayjs';
import { formatAddress, formatCurrency } from 'utils/formatters';
import _ from 'lodash';
import { useWhichWordFactory } from 'hooks/use-which-word';
import { useRegion } from 'hooks/use-region';
import { Link } from 'components/text/link';
import { PropertyPreviewPopout } from 'features/properties/popouts/property-preview-popout';
import { useDialog } from 'hooks/use-dialog';
import { ContactPreviewPopout } from 'features/contacts/popouts/contact-preview-popout';
import Box from '@rexlabs/box';
import Tag from 'view/components/tag';
import { ArrowDropdown } from 'view/components/action-menu';
import { usePipelineDropdownActionsAppraisal } from 'features/pipelines/modules/appraisals/hooks/use-pipeline-dropdown-actions-appraisal';
import { useSelector } from 'react-redux';
import { SecurityUserRights } from 'data/models/entities/contacts';
import { Body } from 'components/text/body';
import { ICONS } from 'shared/components/icon';

export const APPRAISAL_PIPELINE_ITEM_HEIGHT = 180;

const itemStyles = StyleSheet({
  container: {
    background: COLORS.WHITE,
    borderRadius: '4px',
    boxShadow: '0 1px 3px 0 rgba(0,0,0,0.3)',
    padding: PADDINGS.XS,
    height: APPRAISAL_PIPELINE_ITEM_HEIGHT,
    position: 'relative',
    '& [data-hover]': {
      opacity: 0
    },

    '&:hover [data-hover]': {
      opacity: 1
    }
  },
  headingWrapper: {
    maxWidth: '100%',
    display: 'inline-block',
    paddingRight: PADDINGS.S
  },
  heading: {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    maxWidth: '100%',
    display: 'inline-block',
    lineHeight: '16px !important'
  },
  ownersBlock: {
    whiteSpace: 'pre'
  },
  ownersLink: {
    lineHeight: '17px !important'
  },
  dropdown: {
    position: 'absolute',
    right: PADDINGS.XS,
    top: PADDINGS.XS
  },
  dropdownActive: {
    opacity: '1 !important'
  },
  body: {
    fontSize: '12px',
    fontWeight: FONT.WEIGHTS.REGULAR,
    fontStyle: 'normal',
    lineHeight: '16px',
    pointerEvents: 'none',
    maxWidth: '100%',
    overflow: 'hidden',
    display: 'flex',
    gap: '3px'
  },
  bodyLabel: {
    color: COLORS.PRIMARY.SAND_DARK
  },
  bodyValue: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    '& div': {
      display: 'inline'
    }
  },
  lock: {
    position: 'absolute',
    top: '8px',
    right: '8px',
    '& svg': {
      width: '20px',
      height: '20px'
    }
  }
});

function LabelValueText({
  label,
  value,
  hasPointerEvents
}: {
  label: string;
  value?: ReactNode;
  hasPointerEvents?: boolean;
}) {
  const s = useStyles(itemStyles);

  return (
    <div
      {...s('body')}
      style={hasPointerEvents ? { pointerEvents: 'all' } : {}}
    >
      <span {...s('bodyLabel')}>{label}:</span>
      <span {...s('bodyValue')}>
        {value === null ||
        value === '' ||
        value === undefined ||
        (Array.isArray(value) && !value.length)
          ? '–'
          : value}
      </span>
    </div>
  );
}

const interestLevelColors = {
  hot: COLORS.INTEREST_LEVEL.HOT_SELECTED,
  warm: COLORS.INTEREST_LEVEL.WARM_SELECTED,
  cold: COLORS.INTEREST_LEVEL.COLD_SELECTED
};

const archiveReasonMap = {
  won: 'Won',
  lost_to_another_agency: 'Lost to another agent',
  not_interested: 'Not interested',
  reappraised: 'Reappraised'
};

/**
 * TODO: Improve currency formatter and address abstractions
 */
const PipelineItemAppraisal = memo(function PipelineItemAppraisal({
  data
}: {
  data: AppraisalData;
}) {
  const s = useStyles(itemStyles);
  const region = useRegion();
  const actions = useModelActions(appraisalsModel);
  const symbol = region?.financial?.currency?.symbol || '$';
  const addEditAppraisal = useDialog('addEditAppraisal');

  // We use a selector here rather than a useEntityQuery as it's much more
  // performant and this is the only data point we need
  const itemStatus = useSelector(
    (state: any) => state.entities.appraisals.items[data.id].status
  );

  const ww = useWhichWordFactory();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const propertyOwners = data.related?.property?.contact_reln_property
    ?.filter?.((c) => c.reln_type?.id === 'owner')
    .map((c) => c.contact)
    .filter(Boolean);

  const dropdownActions = usePipelineDropdownActionsAppraisal({
    data,
    onDelete: () => actions.trashItem({ id: data.id })
  });

  const hasRights = (right: SecurityUserRights) =>
    data.security_user_rights?.includes(right);

  const isOverdue =
    !data.archive_date &&
    data.expected_close_date &&
    dayjs(data.expected_close_date).isBefore(dayjs().startOf('day'));

  return (
    <div
      {...s('container')}
      style={{
        opacity: ['loading', 'updating'].includes(itemStatus) ? 0.4 : 1,
        backgroundColor: isOverdue ? '#ffeeee' : 'white',
        cursor: hasRights('update') ? 'grab' : 'not-allowed'
      }}
      onClick={(e) => {
        if (e.target !== e.currentTarget || !hasRights('read')) return;
        addEditAppraisal.open({
          id: data.id,
          propertyCategoryId: data.property?.property_category?.id
        });
      }}
    >
      <Box {...s('headingWrapper')} mb={PADDINGS.XXS}>
        {hasRights('read') && data.property ? (
          <PropertyPreviewPopout id={data.property?.id} stopPropagation>
            <Link blue undecorated record bold {...s('heading')}>
              {[
                formatAddress(
                  _.pick(data.property, [
                    'adr_street_name',
                    'adr_unit_number',
                    'adr_street_number',
                    'adr_locality',
                    'property_category',
                    'adr_building'
                  ])
                ),
                data.property.adr_suburb_or_town
              ]
                .filter(Boolean)
                .join(', ')}
            </Link>
          </PropertyPreviewPopout>
        ) : (
          <Body dark bold {...s('heading')}>
            –
          </Body>
        )}
      </Box>
      <Box
        flexDirection={'row'}
        mb={'3px'}
        style={{ gap: PADDINGS.XXS, pointerEvents: 'none' as const }}
      >
        <Tag
          backgroundColor={COLORS.STATES.IDLE}
          color={COLORS.PRIMARY.GREY_DARK}
          roundedEdges
          noMargin
        >
          {data.appraisal_type.text}
        </Tag>
        {data.interest_level ? (
          <Tag
            roundedEdges
            noMargin
            backgroundColor={interestLevelColors[data.interest_level]}
            color={COLORS.PRIMARY.WHITE}
          >
            {_.startCase(data.interest_level)}
          </Tag>
        ) : null}
        {data.archive_reason ? (
          <Tag
            backgroundColor={COLORS.GREY_DARK}
            color={COLORS.WHITE}
            roundedEdges
            noMargin
          >
            {archiveReasonMap[data.archive_reason.id]}
          </Tag>
        ) : null}
      </Box>
      <Box flexDirection={'row'} {...s('ownersBlock')}>
        <LabelValueText
          label={`Owner${
            propertyOwners &&
            propertyOwners?.length === 1 &&
            !propertyOwners[0].name.includes('&')
              ? ''
              : 's'
          }`}
          hasPointerEvents
          value={
            hasRights('read')
              ? propertyOwners?.map((c, i) => (
                  <Fragment key={c.id}>
                    {i > 0 ? <span {...s('bodyValue')}>{', '}</span> : null}
                    <ContactPreviewPopout key={c.id} id={c.id} stopPropagation>
                      <Link blue record small {...s('ownersLink')}>
                        {c.name}
                      </Link>
                    </ContactPreviewPopout>
                  </Fragment>
                ))
              : null
          }
        />
      </Box>
      <LabelValueText
        label={`Agent${data.agent_1 && data.agent_2 ? 's' : ''}`}
        value={[data.agent_1?.text, data.agent_2?.text]
          .filter(Boolean)
          .join(', ')}
      />
      <LabelValueText
        label={'Price'}
        value={
          data.price_min || data.price_max
            ? [data.price_min, data.price_max]
                .filter(Boolean)
                .map((v) => `${symbol}${formatCurrency(v)}`)
                .join(' – ')
            : null
        }
      />
      {data.appraisal_type.id === 'sale' ? (
        <LabelValueText
          label={'Estimated commission'}
          value={
            parseFloat((data.comm_est_amount_net_of_tax as unknown) as string)
              ? symbol + formatCurrency(data.comm_est_amount_net_of_tax, 0)
              : '–'
          }
        />
      ) : null}
      <LabelValueText
        label={`${_.startCase(ww('valuation'))} date`}
        value={
          data.appraisal_date
            ? dayjs(data.appraisal_date).format('D MMM YYYY')
            : null
        }
      />
      <LabelValueText
        label={'Expected close date'}
        value={
          <span
            style={{
              ...(isOverdue
                ? { fontWeight: 'bold', color: COLORS.PRIMARY.RED }
                : {})
            }}
          >
            {data.expected_close_date
              ? dayjs(data.expected_close_date).format('D MMM YYYY')
              : null}
          </span>
        }
      />
      <LabelValueText
        label={'Last contacted date'}
        value={
          data.property?.system_owner_last_contacted_date
            ? dayjs(data.property?.system_owner_last_contacted_date).format(
                'D MMM YYYY'
              )
            : null
        }
      />
      {dropdownActions.length ? (
        <div
          {...s('dropdown', {
            dropdownActive: isDropdownOpen
          })}
          data-hover
        >
          <ArrowDropdown
            tiny
            items={dropdownActions}
            onChange={(isOpen) => setIsDropdownOpen(isOpen)}
          />
        </div>
      ) : null}
      {!hasRights('read') ? (
        <div {...s('lock')}>
          {/** @ts-ignore **/}
          <ICONS.APP_SEARCH.LOCK />
        </div>
      ) : null}
    </div>
  );
});

export { PipelineItemAppraisal };
