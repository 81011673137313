import React, { useCallback } from 'react';
import { Link as WhereLink, Whereabouts } from '@rexlabs/whereabouts';
import { getUrlWithGivenViewpath, isViewpathActiveUrl } from 'utils/routing';

interface LinkProps {
  target: string;
  onClick: (e: any) => void;
  active: () => void;
  setRef: () => void;
  whereabouts: Whereabouts;
}

function getOnClick({
  e,
  path,
  linkProps
}: {
  e: MouseEvent;
  path?: string;
  linkProps: LinkProps;
}) {
  if (path && (e.metaKey || e.shiftKey || e.ctrlKey)) {
    return window.open(path, '', 'noopener,noreferrer');
  }

  return linkProps.onClick(e);
}

/**
 * This wrapper function wraps the whereabouts Link component to provide some additional functionality.
 * Rather than override links when ever we want the user to navigate to a new tab, we have added this
 * to the wrapper component. If the user uses a modifer key, we will open in new tab/window, and exit
 * the onlick handler.
 *
 * Also, if we want to perform additional tasks on click, we can do so by passing in a onClick callback
 * function to the Link component. It will call it before continuing with the redirect.
 */
function Link(
  props: React.ComponentProps<typeof WhereLink> & {
    children: (linkProps: LinkProps) => React.ReactNode;
  }
) {
  const { path, children } = props;

  const getPath = useCallback(() => {
    if (isViewpathActiveUrl()) {
      return getUrlWithGivenViewpath(path);
    } else {
      return path;
    }
  }, [path]);

  const getTarget = useCallback(
    (linkProps: LinkProps) => {
      if (isViewpathActiveUrl() && path) {
        return path;
      } else {
        return linkProps.target;
      }
    },
    [path]
  );

  return (
    <WhereLink {...props} path={getPath()}>
      {(linkProps: LinkProps) => {
        return children({
          ...linkProps,
          target: getTarget(linkProps),
          onClick: (e: MouseEvent) => getOnClick({ e, linkProps, path })
        });
      }}
    </WhereLink>
  );
}

export default Link;
