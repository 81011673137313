import { useCallback } from 'react';
import { Deferred } from 'jquery';

import { api } from 'shared/utils/api-client';

import { useDialog } from 'hooks/use-dialog';

import {
  AsyncResultNotification,
  DownloadFileProps,
  MassActionProgressRecordProps,
  MassActionResultListProps,
  MassActionResultsRecordProps
} from 'utils/dialogs/mapping-classic-interfaces';
import { QueryCriteria, Selection } from 'components/record-list-screen/types';

interface StandardNotifProps {
  value: number;
  label: string;
  linkText?: string;
  linkAction?: () => void;
}

export function formatStandardNotification({
  value,
  label,
  linkText,
  linkAction
}: StandardNotifProps): AsyncResultNotification[] {
  return [
    { text: value || 0, click: null, class: 'mass-result value' },
    { text: label, click: null, class: 'mass-result label semi-bold' },
    {
      text: linkText,
      click: linkAction,
      class: 'mass-result link clickable semi-bold'
    }
  ];
}

interface GetTotalCountForBulkActions {
  selection: Selection;
  selectionCriteria: QueryCriteria[];
}

export function useGetTotalForBulkActions({ service_name }) {
  return useCallback(
    ({
      selection: { ids, type },
      selectionCriteria
    }: GetTotalCountForBulkActions) => {
      return type === 'include'
        ? Promise.resolve(ids.length)
        : api
            .post(`${service_name}::search`, {
              criteria: selectionCriteria,
              ids_only: true
            })
            .then(({ data }: any) => {
              // the export dialog will take either a list of ids or a total count
              return data?.result?.total;
            });
    },
    [service_name]
  );
}

interface GetViewstateIdForBulkActions {
  selectionCriteria: QueryCriteria[];
}

export function useGetViewstateIdForBulkActions({ service_name }) {
  return useCallback(
    ({ selectionCriteria }: GetViewstateIdForBulkActions) => {
      return api
        .post(`${service_name}::search`, {
          criteria: selectionCriteria,
          create_viewstate: true,
          limit: 0
        })
        .then(({ data }: any) => {
          return {
            viewstate_id: data?.result?.viewstate_id,
            criteria: data?.result?.criteria,
            total: data?.result?.total
          };
        });
    },
    [service_name]
  );
}

export function useFileDownloadDialog(
  data: DownloadFileProps['data'] = 'export'
) {
  const fileDownloadDialog = useDialog('downloadFile');

  return useCallback(({ file }) => fileDownloadDialog.open({ file, data }), [
    fileDownloadDialog,
    data
  ]);
}

export function useViewMassActionResult() {
  const massActionResultDialog = useDialog('massActionResultsRecord');
  return useCallback(
    (options: MassActionResultsRecordProps) => {
      return massActionResultDialog.open(options);
    },
    [massActionResultDialog]
  );
}

export function useViewMassActionResultList() {
  const massActionResultListDialog = useDialog('massActionResultList');

  const openMassActionResultListDialog = useCallback(
    ({ records, options, callback }: MassActionResultListProps) => {
      return massActionResultListDialog.open({ records, options, callback });
    },
    [massActionResultListDialog]
  );

  return openMassActionResultListDialog;
}

export function useMassActionProgressDialog() {
  const massActionProgressDialog = useDialog('massActionProgressRecord');

  const openProgressDialog = useCallback(
    ({ token }: Omit<MassActionProgressRecordProps, 'deferred'>) => {
      const deferred = Deferred();
      massActionProgressDialog?.open({ token, deferred });
      return deferred;
    },
    [massActionProgressDialog]
  );

  return openProgressDialog;
}
