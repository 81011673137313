/* eslint-disable max-lines */
import React, { useState, useMemo, useEffect, useCallback } from 'react';
import { get } from 'lodash';

import Box from '@rexlabs/box';
import { PLACEMENTS } from '@rexlabs/tooltip';
import { useModelActions } from '@rexlabs/model-generator';
import { StyleSheet, useStyles } from '@rexlabs/styling';

import { contactPrivacy } from 'data/models/custom/contact-privacy';
import { PrivacyProfile } from 'data/models/custom/contact-privacy/contact-privacy';

import Icon, { ICONS } from 'shared/components/icon';
import Tooltip from 'view/components/tooltip';
import { openNewTab } from 'src/utils/browser';
import { useErrorDialog } from 'hooks/use-error-dialog';
import { TEXTS, COLORS, PADDINGS, TRANSITIONS } from 'theme';
import ViewMore from './widget/view-more';
import RightBarWidget from './widget/widget';

const defaultStyles = StyleSheet({
  container: {
    background: 'white',
    marginTop: PADDINGS.M,
    ...TEXTS.CONTENT.DESCRIPTIVE_SUBTEXT_1,

    // Note: This handles showing / hiding the action button that appears beside the title
    '& > div:first-child > div': {
      visibility: 'hidden',
      opacity: 0,
      transition: 'visibility 0.3s, opacity 0.3s',
      padding: 0,
      marginLeft: PADDINGS.XS
    },

    '&:hover > div:first-child > div': {
      visibility: 'visible',
      opacity: 1
    }
  },
  name: {
    color: COLORS.GREY_DARK,
    fontWeight: 600,
    fontSize: '12px'
  },
  status: {
    color: COLORS.GREY_DARK,
    fontSize: '12px',
    fontWeight: 'normal'
  },
  icon: {
    height: '18px',
    marginRight: PADDINGS.XS,
    display: 'flex',
    alignItems: 'center'
  },
  iconDisabled: {
    color: '#B3B2AC'
  },
  crossIcon: {
    color: '#B13024',
    marginRight: 10
  },
  statusMarker: {
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: PADDINGS.M,
    width: PADDINGS.M
  },
  statusMarkerDot: {
    display: 'block',
    height: '10px',
    width: '10px',
    borderRadius: '10px'
  },
  markerGreen: {
    backgroundColor: '#96BE51'
  },
  markerRed: {
    backgroundColor: '#B13024'
  },
  markerGray: {
    backgroundColor: '#B4B1A9'
  },
  consentList: {
    marginLeft: '-5px'
  },
  emptyList: {
    marginTop: PADDINGS.XS
  },
  banner: {
    borderRadius: 2
  },
  error: {
    backgroundColor: '#FDE9E8'
  },
  warning: {
    backgroundColor: '#FFF0C7'
  }
});

const STATUS_COLOUR = {
  not_requested: 'markerGray',
  declined: 'markerRed',
  given: 'markerGreen'
};

interface PrivacyWidgetProps {
  id: string;
}

const DEFAULT_PRIVACY: PrivacyProfile = {
  consents: [],
  contact_by_email: null,
  contact_by_letter: null,
  contact_by_phone: null,
  contact_by_sms: null,
  do_not_contact: null
};

const PREFERENCES = [
  {
    id: 'contact_by_email',
    name: 'email',
    icon: [ICONS.EMAIL, ICONS.DONT_EMAIL]
  },
  {
    id: 'contact_by_letter',
    name: 'letter',
    icon: [ICONS.LETTER, ICONS.DONT_MAIL]
  },
  {
    id: 'contact_by_phone',
    name: 'phone',
    icon: [ICONS.PHONE, ICONS.DONT_CALL]
  },
  {
    id: 'contact_by_sms',
    name: 'sms',
    icon: [ICONS.SMS, ICONS.DONT_SMS]
  }
];

const PrivacyWidget = ({ id: contactId }: PrivacyWidgetProps) => {
  const errorDialog = useErrorDialog();
  const s = useStyles(defaultStyles);
  const { fetchPrivacyProfile, fetchConsumerDashboardUrl } = useModelActions(
    contactPrivacy
  );

  const [showAllInstances, setShowAllInstances] = useState(false);
  const [loading, setLoading] = useState(true);
  const [visible, setVisible] = useState(true);
  const [privacyData, setPrivacyData] = useState<PrivacyProfile>(
    DEFAULT_PRIVACY
  );

  const privacyConsents = useMemo(() => privacyData.consents || [], [
    privacyData.consents
  ]);

  const contactPreferences = useMemo(
    () =>
      PREFERENCES.map(({ id, name, icon }) => {
        const allowed = privacyData[id] && !privacyData.do_not_contact;
        return {
          name,
          isAllowed: allowed,
          tooltip: allowed
            ? `Prefers contact by ${name}`
            : `Prefers not to be contacted by ${name}`,
          icon: icon[allowed ? 0 : 1] // 0 - active icon, 1 - inactive icon
        };
      }),
    [privacyData]
  );

  const noContactPreference = useMemo(
    () => contactPreferences.every((p) => p.isAllowed === false),
    [contactPreferences]
  );

  const showViewMore = privacyConsents.length >= 5;
  const viewMoreCount = privacyConsents.length - 4;

  const shownConsents = useMemo(() => {
    return showViewMore && showAllInstances
      ? privacyConsents.slice(0, 4)
      : privacyConsents;
  }, [privacyConsents, showAllInstances, showViewMore]);

  const hasSomeConsents = shownConsents.length > 0;
  const expanded = privacyConsents.length === shownConsents.length;

  useEffect(() => {
    fetchPrivacyProfile(contactId)
      .then((response) => {
        setPrivacyData(response);
        setLoading(false);
      })
      .catch((error) => {
        errorDialog.open(error);
        setVisible(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchConsumerUrl = useCallback(() => {
    openNewTab(
      fetchConsumerDashboardUrl(contactId).then((consumerUrl) => consumerUrl)
    ).catch(errorDialog.open);
  }, [contactId, errorDialog.open, fetchConsumerDashboardUrl]);

  if (!visible) return null;

  return (
    <RightBarWidget
      heading={'Privacy'}
      iconButtonIcon={ICONS.EDIT}
      iconButtonOnClick={fetchConsumerUrl}
      isLoading={loading}
    >
      {(privacyData.do_not_contact || noContactPreference) && (
        <Box
          {...s('banner', { error: true })}
          p={10}
          mb={10}
          justifyContent='flex-start'
          alignItems='center'
        >
          <Icon {...s('crossIcon')} type={ICONS.PRIVACY.CROSS} />
          <p {...s('name')}>Marked as do not contact</p>
        </Box>
      )}

      <Box alignItems='center'>
        {contactPreferences.map(({ name, isAllowed, tooltip, icon }) => (
          <Tooltip
            key={name}
            Content={() => <p>{tooltip}</p>}
            placement={PLACEMENTS.TOP}
            inline
          >
            <Icon
              {...s('icon', {
                iconDisabled: !isAllowed
              })}
              type={icon}
            />
          </Tooltip>
        ))}
      </Box>

      <Box
        {...s({
          consentList: hasSomeConsents,
          emptyList: !hasSomeConsents
        })}
      >
        {hasSomeConsents && (
          <Box
            style={{
              overflow: 'hidden',
              transition: TRANSITIONS.REGULAR.HEIGHT,
              height: showAllInstances ? privacyConsents.length * 20 : 80
            }}
            flexDirection={'column'}
            mt={5}
          >
            {privacyConsents.map((consent) => {
              const id = get(consent, 'consent_type.id');
              const name = get(consent, 'consent_type.text');
              const statusId = get(consent, 'status.id');
              const statusText =
                statusId !== 'not_requested'
                  ? statusId === 'given'
                    ? 'yes'
                    : 'no'
                  : 'pending response';
              return (
                <Box key={id} alignItems='center'>
                  <span {...s('statusMarker')}>
                    <span
                      {...s('statusMarkerDot', STATUS_COLOUR[statusId])}
                    ></span>
                  </span>
                  <p {...s('name')}>
                    {name}: <span {...s('status')}>{statusText}</span>
                  </p>
                </Box>
              );
            })}
          </Box>
        )}
        {!hasSomeConsents && 'No available consent types'}
      </Box>
      {hasSomeConsents && showViewMore && (
        <ViewMore
          expanded={!expanded}
          onClick={() => setShowAllInstances((prevState) => !prevState)}
          moreCount={viewMoreCount}
        />
      )}
    </RightBarWidget>
  );
};

export default PrivacyWidget;
