import { useEffect, useMemo, useState } from 'react';

import { useRegion } from 'hooks/use-region';
import { useWhichWordFactory } from 'hooks/use-which-word';

import { makeEntity } from 'features/custom-reporting/modules/helpers';

import {
  PropertyReportQuery,
  propertyReportQueryDefaultVariables
} from './property-report.query';
import {
  ModuleConfigItem,
  OrderByDirectionType
} from 'features/custom-reporting/modules/module-config-types';
import { moduleNameMap } from 'features/custom-reporting/modules/module-name-map';

import { useCustomFields } from 'features/custom-reporting/hooks/use-async-report-data';

export const defaultColumns = [
  'property_address',
  'adr_postcode',
  'property_category.text',
  'attr_tenure_id.text',
  'attr_bedrooms',
  'attr_bathrooms',
  'attr_toilets',
  'owner.0.contact',
  'owner.0.contact.emails',
  'owner.0.contact.phones',
  'active_appraisal.agent_1.name',
  'active_appraisal.agent_2.name',
  'active_appraisal.appraisal_date',
  'active_appraisal.price_min',
  'active_appraisal.price_max',
  'active_appraisal.price_asking'
];

export function usePropertyModule() {
  const region = useRegion();
  const ww = useWhichWordFactory();
  const {
    properties: propertyCustomFields,
    contacts: contactCustomFields
  } = useCustomFields();

  const propertyModuleConfig: ModuleConfigItem = useMemo(
    () => ({
      ...moduleNameMap.properties,
      moduleLabel: 'Property',
      exportPrivilege: 'exporting.properties',
      queryConfig: {
        graphQlQuery: PropertyReportQuery,
        queryVariables: propertyReportQueryDefaultVariables,
        defaultSort: [
          {
            name: 'system_modtime',
            direction: 'descending' as OrderByDirectionType
          }
        ]
      },
      columns: [
        {
          headerName: 'Property',
          children: makeEntity({
            entity: 'property',
            prefix: '',
            customFields: propertyCustomFields
          })
        },
        {
          headerName: 'Property Owner',
          children: makeEntity({
            entity: 'contact',
            prefix: 'owner.0.contact',
            subresource: 'property_owner',
            customFields: contactCustomFields,
            region
          })
        },
        {
          headerName: ww('valuation'),
          children: makeEntity({
            entity: 'propertyAppraisal',
            prefix: 'active_appraisal',
            subresource: 'appraisal',
            whichWord: ww
          })
        },
        {
          headerName: 'OAB',
          children: makeEntity({
            entity: 'oab',
            prefix: 'active_oab',
            subresource: 'oab'
          })
        }
      ],
      defaultVisibleColumns: defaultColumns,
      defaultDatePreset: 'this_year_to_date',
      defaultUserFieldPreference: 'system_owner_user_id',
      defaultDateFieldPreference: 'system_ctime',
      topLevelFilters: {
        date: {
          availableFields: [
            {
              fieldName: 'system_ctime',
              label: 'Property Creation'
            }
          ]
        },
        user: {
          availableFields: [
            {
              fieldName: 'system_owner_user_id',
              label: 'Record owner'
            }
          ]
        }
      }
    }),
    [ww, region, propertyCustomFields, contactCustomFields]
  );

  return propertyModuleConfig;
}
