/* eslint-disable max-lines */
/*
|-------------------------------------------------------------------------------
| Dialog Mappings
|-------------------------------------------------------------------------------
|
| Mapping meta information onto the dialogs callName. The callName is the key
| under which the dialog is exposed in the bridge and in `AVM.shell.dialogs`!
| The following meta information is currently supported:
|
| systemName
| ----------
| To be able to open dialogs in the iframe, we need to specify the system path
| of the respective dialog. In order not having to remember this one every time,
| we simply map it once here.
|
| The systemName points to the folder that holds the resources.js & resources.php files and
| is relative to application/views/dialogs/. So adding the editTransaction dialog will have
| a systemName of "invoicing/editTransaction".
|
| mapArgs
| -------
| To have a more consistent API when dealing with dialogs, we decided to use
|
| ShellComponent
| --------------
| Shell dialog component to be rendered when `AVM.shell.dialogs.*.open(props)`
| is called.
|
*/

import _ from 'lodash';
import { MappingsProps } from './mapping-classic-interfaces';

const mappings: MappingsProps = {
  // Classic dialogs

  editTemplateLibrarySettings: {
    originalName: 'templateLibraryAddTo',
    systemName: 'system/template-libraries/add-to',
    mapArgs: ({ data, recordId, className, callback }) => [
      data,
      { recordId, className },
      callback
    ]
  },

  downloadFile: {
    systemName: 'system/download-file',
    mapArgs: (args) => {
      const { file, data = {} } = args;
      return [{ result: file }, data];
    }
  },

  mailoutMailMerge: {
    systemName: 'system/mail-merge/mailout',
    mapArgs: ({ data = {}, options = {} }) => {
      return [data, options];
    }
  },

  mailMergeWizard: {
    systemName: 'system/mail-merge/wizard',
    mapArgs: ({
      module,
      medium,
      criteriaOrReminders,
      saveCallback,
      scheduledData
    }) => {
      return [module, medium, criteriaOrReminders, saveCallback, scheduledData];
    }
  },

  addAgency: {
    originalName: 'editValue',
    systemName: 'system/edit-value',
    mapArgs: ({ title, content, callback }) => [title, content, callback]
  },

  assignUserRole: {
    systemName: 'user-rights/assign-role',
    mapArgs: ({ currentRole, callback }) => [currentRole, callback]
  },

  editDeal: {
    originalName: 'dealsEditDeal',
    systemName: 'deals/edit-deal',
    mapArgs: ({ id, data = {}, options, callback }) => {
      data.id = id;
      return [data, callback, options];
    }
  },

  editMailMerge: {
    systemName: 'system/mail-merge/single',
    mapArgs: ({ data = {}, options, callback }) => {
      // NOTE: NOT spreading here, cause it will remove all prototypes from the object
      // which we rely on for a lot of the Rex data models!

      // NOTE: to get around the issue of the new r2.u.app.MailMerge not being accessible from shell, all we do is pass through the config options for the mailMerge constructor
      // and the edit-mail-merge dialog creates the mailMerge itself after checking we have passed the correct config

      // Config requirements/shape example
      // [EXAMPLE]
      // const mailMerge = {
      //   mode: 'contact',        [REQUIRED]
      //   medium: 'email',        [REQUIRED]
      //   data: {
      //     contact: {
      //       id: _.get(this.props, 'data.id'),
      //       name: this.getName()
      //     }
      //   }
      // };
      // DialogsBridge.editMailMerge.openClassic({
      //   mailMerge,
      //   options: { title: 'Email Contact' }
      // });
      // [EXAMPLE]

      // AVAILABLE MODES =>     contact | property | listing | project | project_stage
      // AVAILABLE MEDIUMS =>   email   |   sms    | letter

      return [data, options, callback];
    }
  },

  sendReferral: {
    systemName: 'referral/send',
    mapArgs: ({ mailMerge = {}, options, callback }) => {
      // NOTE: NOT spreading here, cause it will remove all prototypes from the object
      // which we rely on for a lot of the Rex data models!
      return [mailMerge, options, callback];
    }
  },

  editNote: {
    originalName: 'editNote',
    systemName: 'note/edit',
    mapArgs: ({ id, data = {}, options, callback }) => {
      data.id = id;
      return [data, options, callback];
    }
  },

  editReminder: {
    originalName: 'editReminder',
    systemName: 'reminders/edit',
    mapArgs: ({ id, data = {}, callback }) => {
      // This is not pretty... but this is how the EditReminder dialog expects options
      const options = {
        data: {
          id,
          ...data
        },
        id
      };

      return [options, callback];
    }
  },

  remindersAddTrack: {
    originalName: 'addTrack',
    systemName: 'reminders/add-track',
    mapArgs: ({ data = {}, callback }) => {
      return [data, callback];
    }
  },

  previewTrack: {
    originalName: 'previewTrack',
    systemName: 'reminders/preview-track',
    mapArgs: ({ data = {}, callback }) => {
      return [data, callback];
    }
  },

  editFeedback: {
    originalName: 'editFeedback',
    systemName: 'feedback/edit',
    mapArgs: ({ id, data, listingId, mode, options, callback }) => {
      const formArgs = {
        ...data,
        id: id
      };
      return [formArgs, mode, callback, listingId, options];
    }
  },

  reverseBuyerMatch: {
    originalName: 'reverseBuyerMatch',
    systemName: 'property-match/reverse-match',
    mapArgs: ({ id, data = {}, saveCallback }) => {
      // @ts-ignore
      data.record_id = id;
      return [data, saveCallback];
    }
  },

  createContact: {
    systemName: 'contact/create',
    mapArgs: ({ data = {}, options, callback }) => {
      return [data, options, callback];
    }
  },

  editProperties: {
    systemName: 'properties/edit',
    mapArgs: ({ id, data = {}, options, callback }) => {
      data.id = id;
      return [
        data, // formArgs
        callback,
        options
      ];
    }
  },

  auditLogs: {
    originalName: 'auditLogs',
    systemName: 'system/audit-log',
    mapArgs: ({ id, serviceName, data = {}, options = {} }) => {
      data.id = id;
      return [data, { ...options, serviceName }];
    }
  },

  editTabAccess: {
    systemName: 'custom-fields/edit-access',
    mapArgs: ({ id, type, options = {}, onRemove, callback }) => [
      id,
      type,
      options,
      onRemove,
      callback
    ]
  },

  editPermissionType: {
    systemName: 'user-rights/edit-permission-type',
    mapArgs: ({ data = {}, options = {}, callback }) => [
      data,
      options,
      callback
    ]
  },

  helpCentreContactSupport: {
    systemName: 'help-centre/contact-support',
    mapArgs: ({ options }) => [options]
  },

  editValue: {
    systemName: 'system/edit-value',
    mapArgs: ({ dialogTitle, fields, callback, options }) => {
      return [dialogTitle, fields, callback, options];
    }
  },

  previewMergeTemplate: {
    systemName: 'system/mail-merge/preview-template',
    mapArgs: ({ options = {}, callback }) => [options, callback]
  },

  buildingsEdit: {
    originalName: 'buildingEdit',
    systemName: 'buildings/edit',
    mapArgs: ({ data, callback }) => [data, callback]
  },

  viewEmail: {
    systemName: 'system/view-email',
    mapArgs: ({ data, options }) => [data, options]
  },

  editInvoice: {
    systemName: 'invoicing/editInvoice',
    mapArgs: ({ id, onSave, listingId }) => {
      // NOTE: We detect when the user passes a string as the second paramater and that's how we know to fetch the record manually
      return [listingId, `${id}`, onSave];
    }
  },

  editInvoiceTransaction: {
    systemName: 'invoicing/editTransaction',
    mapArgs: ({ id, options }) => {
      return [{ _id: id }, options];
    }
  },

  viewCommissionWorksheet: {
    originalName: 'editCommissionWorksheet',
    systemName: 'commissions/view-worksheet',
    mapArgs: ({ id, options = {}, callback }) => {
      return [{ worksheetId: id, ...options }, callback];
    }
  },

  editOAB: {
    originalName: 'propertiesOABEdit',
    systemName: 'properties/oab/edit',
    mapArgs: ({ id = null, onSave, recordData = null }) => {
      return [recordData, onSave, id];
    }
  },

  thirdpartyCredentials: {
    originalName: 'thirdparty_credentials',
    systemName: 'system/settings/third-party/credentials',
    mapArgs: ({ data = [], options = {}, callback }) => {
      return [data, options, callback];
    }
  },

  addPackage: {
    originalName: 'vpaAddPack',
    systemName: 'vpa/add-package',
    mapArgs: ({ callback = _.noop }) => [callback]
  },

  portalSettings: {
    originalName: 'portalSettings',
    systemName: 'portals/settings',
    mapArgs: ({ listingId, isPublished = false, callback = _.noop }) => [
      { listingId, isPublished },
      callback
    ]
  },
  recordPermissions: {
    originalName: 'editRecordPermissions',
    systemName: 'user-rights/edit-record-permissions',
    mapArgs: ({ recordId, recordType, changeOwnerCallback }) => [
      recordId,
      recordType,
      changeOwnerCallback
    ]
  },

  // obj structure for these in classic:
  // oabs: {
  //   data: self.data()._oabs(),
  //   edit: _.partial(self.editOAB, null, null)
  // },
  // appraisals: {
  //   data: self.data()._appraisals(),
  //   edit: _.partial(self.editAppraisal, null, null)
  // }
  propertyHistory: {
    originalName: 'propertyHistoryView',
    systemName: 'properties/view-history',
    mapArgs: ({ propertyHistory, appraisals, oabs }) => [
      propertyHistory,
      { appraisals, oabs }
    ]
  },

  advancedCriteria: {
    systemName: 'system/advanced-criteria',
    mapArgs: ({ criteria, serviceName, callback }) => [
      criteria,
      serviceName,
      callback
    ]
  },

  editProject: {
    systemName: 'projects/edit',
    mapArgs: ({ id, callback }) => [id, callback]
  },

  // NOTE: While this is called leadEdit in classic, we seem to follow a pattern of leading with edit
  // So this should have been called editLead, hence why I'm doing this for shell.
  editLead: {
    originalName: 'leadEdit',
    systemName: 'leads/edit',
    mapArgs: ({ id, options, callback }) => [id, options, callback]
  },

  exportRecord: {
    originalName: 'export',
    systemName: 'export',
    mapArgs: ({ options, ids, callback }) => [options, ids, callback]
  },

  massActionProgressRecord: {
    originalName: 'massActionProgress',
    systemName: 'mass-actions/action-progress',
    mapArgs: ({ token, deferred }) => [token, deferred]
  },

  /**
   * TODO: type this function
   * Options:
   *  - refreshListFn
   *  - resultTitle
   *  - className
   *  - mappingFn
   *  - showLog
   *  - response
   *  - status
   */
  massActionResultsRecord: {
    originalName: 'massActionResults',
    systemName: 'mass-actions/action-results',
    mapArgs: (options) => [options]
  },

  massActionResultList: {
    originalName: 'massResultList',
    systemName: 'mass-actions/result-list',
    mapArgs: ({ records, options, callback }) => {
      return [records, options, callback];
    }
  },

  asyncFileResult: {
    originalName: 'asyncFileResult',
    systemName: 'system/async/file-result',
    mapArgs: ({
      data,
      options: { title, resultButtons, icon, message, notifications }
    }) => [data, { title, resultButtons, icon, message, notifications }]
  },

  crossServiceNavigation: {
    systemName: 'cross-service-navigation',
    mapArgs: ({ service, criteria, callback, options }) => [
      service,
      criteria,
      callback,
      options
    ]
  },

  // TODO: Rename this to editContract once the one in mappings-shell is renamed
  editContractDetails: {
    originalName: 'editContract',
    systemName: 'listings/legal/edit',
    mapArgs: ({ contractIdOrFormArgs, callback, options }) => {
      return [contractIdOrFormArgs, callback, options];
    }
  },

  mergeTemplateOtf: {
    originalName: 'mergeTemplateOtf',
    systemName: 'system/mail-merge/on-the-fly',
    mapArgs: ({ template, options, callback }) => [template, options, callback]
  },

  systemSelectList: {
    systemName: 'system/select-list',
    mapArgs: ({ options, selectedRecords, callback }) => [
      options,
      callback,
      selectedRecords
    ]
  }
};

export default mappings;
