import { Criteria } from 'types/criteria';
import {
  User,
  Property,
  Feedback,
  Appraisal,
  CustomFields
} from 'features/custom-reporting/data/fragments';
import { gql } from 'hooks/use-graphql-query';
import {
  Contact,
  ContactEmails,
  ContactPhones,
  MatchProfile
} from 'features/custom-reporting/modules/contacts/contact-data-fragments';
import {
  AppointmentType,
  Calendar,
  CalendarEvent,
  FollowupReminder
} from 'features/custom-reporting/modules/appointments/appointment-data-fragments';
import { Listing } from 'features/custom-reporting/modules/listings/listing-data-fragments';

export const appointmentsReportQueryDefaultVariables = {
  include_subresource_system_created_user: false,
  include_subresource_appointment_type: false,
  include_subresource_listing: false,
  include_subresource_guests: false,
  include_subresource_property: false,
  include_subresource_listing_property: false,
  include_subresource_appraisal: false,
  include_subresource_feedback: false,
  include_subresource_organiser_user: false,
  include_subresource_followup_reminder: false,
  include_subresource_listing_owner: false,
  include_subresource_calendar: false,
  include_guests_custom_fields: false,
  guests_custom_field_ids: [],
  include_listing_custom_fields: false,
  listing_custom_field_ids: [],
  include_property_custom_fields: false,
  property_custom_field_ids: []
};

export const AppointmentsReportQuery = gql`
  ${User}
  ${Property}
  ${Listing}
  ${Contact}
  ${ContactPhones}
  ${ContactEmails}
  ${MatchProfile}
  ${Calendar}
  ${AppointmentType}
  ${FollowupReminder}
  ${CalendarEvent}
  ${Feedback}
  ${Appraisal}
  ${CustomFields}

  query(
    $include_subresource_system_created_user: Boolean!
    $include_subresource_appointment_type: Boolean!
    $include_subresource_listing: Boolean!
    $include_subresource_guests: Boolean!
    $include_subresource_property: Boolean!
    $include_subresource_appraisal: Boolean!
    $include_subresource_feedback: Boolean!
    $include_subresource_organiser_user: Boolean!
    $include_subresource_followup_reminder: Boolean!
    $include_subresource_listing_owner: Boolean!
    $include_subresource_calendar: Boolean!
    $include_guests_custom_fields: Boolean!
    $guests_custom_field_ids: [Int!]!
    $include_listing_custom_fields: Boolean!
    $listing_custom_field_ids: [Int!]!
    $include_property_custom_fields: Boolean!
    $property_custom_field_ids: [Int!]!
    $criteria: [Criterion!]
    $order_bys: [OrderBy!]
    $limit: Int!
    $cursor: String
  ) {
    rowData: calendar_events(
      use_case: reporting
      criteria: $criteria
      order_bys: $order_bys
      limit: $limit
      cursor: $cursor
    ) {
      pagination {
        next_page {
          cursor
        }
      }
      rows {
        ...CalendarEvent
        calendar @include(if: $include_subresource_calendar) {
          ...Calendar
        }
        system_created_user
          @include(if: $include_subresource_system_created_user) {
          ...User
        }
        organiser_user @include(if: $include_subresource_organiser_user) {
          ...User
        }
        appointment_type @include(if: $include_subresource_appointment_type) {
          ...AppointmentType
        }
        followup_reminder @include(if: $include_subresource_followup_reminder) {
          ...FollowupReminder
        }
        guests: records(service: "Contacts", limit: 2)
          @include(if: $include_subresource_guests) {
          id
          service
          stub {
            ... on Contact {
              ...Contact
              custom_field_values(field_ids: $guests_custom_field_ids)
                @include(if: $include_guests_custom_fields) {
                ...CustomFields
              }
              phones {
                ...ContactPhones
              }
              emails {
                ...ContactEmails
              }
              system_owner_user {
                ...User
              }
              latest_match_profile {
                ...MatchProfile
              }
            }
          }
        }
        property: records(service: "Properties", limit: 1)
          @include(if: $include_subresource_property) {
          id
          service
          stub {
            ... on Property {
              ...Property
              custom_field_values(field_ids: $property_custom_field_ids)
                @include(if: $include_property_custom_fields) {
                ...CustomFields
              }
              system_owner_user {
                ...User
              }
            }
          }
        }
        feedback: records(service: "Feedback", limit: 1)
          @include(if: $include_subresource_feedback) {
          id
          service
          stub {
            ... on Feedback {
              ...Feedback
              agent {
                ...User
              }
              system_created_user {
                ...User
              }
            }
          }
        }
        appraisal: records(service: "Appraisals", limit: 1)
          @include(if: $include_subresource_appraisal) {
          id
          service
          stub {
            ... on Appraisal {
              ...Appraisal
              agent_1 {
                ...User
              }
              agent_2 {
                ...User
              }
            }
          }
        }
        listing: records(service: "Listings", limit: 1)
          @include(if: $include_subresource_listing) {
          id
          service
          stub {
            ... on Listing {
              ...Listing
              custom_field_values(field_ids: $listing_custom_field_ids)
                @include(if: $include_listing_custom_fields) {
                ...CustomFields
              }
              listing_agent_1 {
                name
                id
              }
              listing_agent_2 {
                name
              }
              system_owner_user {
                ...User
              }
            }
          }
        }
        listing: records(service: "Listings", limit: 1)
          @include(if: $include_subresource_listing_owner) {
          stub {
            ... on Listing {
              owner: contact_relationships(limit: 1, type: "owner") {
                relationship_type {
                  id
                }
                contact {
                  ...Contact
                  phones {
                    ...ContactPhones
                  }
                  emails {
                    ...ContactEmails
                  }
                  system_owner_user {
                    ...User
                  }
                  latest_match_profile {
                    ...MatchProfile
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
