import React from 'react';
import _ from 'lodash';
import { AxiosResponse } from 'axios';

import DefaultOption from 'view/components/input/select/defaults/option';
import Value from 'view/components/input/select/values';
import { Generator } from 'shared/utils/models';
import { api } from 'shared/utils/api-client';
import {
  AddApplicationPayload,
  EditApplicationPayload,
  HistoryItem,
  HistoryPayload,
  TenancyApplicationItem
} from 'features/tenancy-applications/data/types';

export interface TenancyApplicationsModel {
  fetchItem: ({
    id
  }: {
    id: string;
  }) => Promise<AxiosResponse<{ result: TenancyApplicationItem }>>;
  fetchList: (
    args: Record<string, unknown>
  ) => Promise<AxiosResponse<Array<{ item: TenancyApplicationItem }>>>;
  trashItem: (id: string) => Promise<AxiosResponse<{ result: boolean }>>;
  getOfferHistory: ({
    application_id
  }: {
    application_id: string;
  }) => Promise<AxiosResponse<{ result: HistoryItem[] }>>;
  createOfferHistory: ({
    application_id,
    data
  }: {
    application_id: string;
    data: HistoryPayload;
  }) => Promise<AxiosResponse<{ result: HistoryItem[] }>>;
  updateItem: ({
    data
  }: {
    data: Partial<EditApplicationPayload> & {
      id: TenancyApplicationItem['id'];
    };
  }) => Promise<AxiosResponse<{ result: TenancyApplicationItem }>>;
  createItem: (
    data: AddApplicationPayload
  ) => Promise<AxiosResponse<{ result: TenancyApplicationItem }>>;
}

const TYPE = 'tenancyApplications';

const actionCreators = {
  fetchItem: {
    request: ({ id }) => api.post('TenancyApplications::read', { id }),
    reduce: {
      initial: _.identity,
      success: _.identity,
      failure: _.identity
    }
  },
  getOfferHistory: {
    request: (args) => {
      return api.post('TenancyApplications::getOfferHistory', args);
    },
    reduce: {
      initial: _.identity,
      success: _.identity,
      failure: _.identity
    }
  },
  createItem: {
    request: (data: AddApplicationPayload) => {
      return api.post('TenancyApplications::create', { data });
    },
    reduce: {
      initial: _.identity,
      success: _.identity,
      failure: _.identity
    }
  },
  updateItem: {
    request: (args) => {
      return api.post('TenancyApplications::update', args);
    },
    reduce: {
      initial: _.identity,
      success: _.identity,
      failure: _.identity
    }
  },
  createOfferHistory: {
    request: (args) => {
      return api.post('TenancyApplications::createOfferHistoryEntry', args);
    },
    reduce: {
      initial: _.identity,
      success: _.identity,
      failure: _.identity
    }
  },
  trashItem: {
    request: (id: string) => {
      return api.post('TenancyApplications::purge', { id });
    },
    reduce: {
      initial: _.identity,
      success: _.identity,
      failure: _.identity
    }
  }
};

const tenancyApplicationsModel = new Generator<
  TenancyApplicationItem,
  typeof actionCreators
>(TYPE).createEntityModel({
  actionCreators
});

// eslint-disable-next-line
// @ts-ignore
tenancyApplicationsModel.select = {
  autocomplete: async (searchTerm) => {
    return [];
  },
  Option: DefaultOption,
  Value: (props) => <Value {...props} service={TYPE} />
};

export const applicationLeaseType = new Generator(
  'applicationLeaseType'
).createSystemListModel();

export const applicationAgreementType = new Generator(
  'applicationAgreementType'
).createSystemListModel();

export const applicationPeriod = new Generator(
  'applicationPeriod'
).createSystemListModel();

export const applicationTenantEmploymentStatus = new Generator(
  'applicationTenantEmploymentStatus'
).createSystemListModel();

export const applicationBondDepositType = new Generator(
  'applicationBondDepositType'
).createSystemListModel();

export default tenancyApplicationsModel;
