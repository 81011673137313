import React, { ReactNode, useMemo } from 'react';
import { compose } from 'redux';
import {
  Id,
  query,
  useEntityQuery,
  useModelState
} from '@rexlabs/model-generator';

import { useAddReminder } from 'hooks/use-add-reminder';

import { ErrorDialog, withErrorDialog } from 'hocs/with-error-dialog';
import { ICONS } from 'shared/components/icon';
import { Popout } from 'view/components/popout';

import session from 'data/models/custom/session';

import { RecordPreviewAddAppoinmentAction } from 'components/record-preview/actions/record-preview-add-appointment-action';
import {
  RecordPreviewSendEmailAction,
  useSendEmail
} from 'components/record-preview/actions/record-preview-send-email';
import { RecordPreviewAddNoteAction } from 'components/record-preview/actions/record-preview-add-note-action';
import { RecordPreviewClickToDialAction } from 'components/record-preview/actions/record-preview-click-to-dial';

import { useAddFeedback } from 'components/record-preview/actions/record-preview-add-feedback';
import { useSendSMS } from 'components/record-preview/actions/record-preview-send-sms';

import {
  RecordPreviewActionBar,
  RecordPreviewActionBarMoreMenu
} from 'components/record-preview/record-preview-action-bar';
import { RecordPreviewContainer } from 'components/record-preview/record-preview-container';
import { RecordPreviewContentContainer } from 'components/record-preview/record-preview-content-container';
import { RecordPreviewImage } from 'components/record-preview/record-preview-image';
import { RecordPreviewLatestNote } from 'components/record-preview/record-preview-latest-note';
import { RecordPreviewLoadingSpinner } from 'components/record-preview/record-preview-loading-spinner';
import { RecordPreviewRecordType } from 'components/record-preview/record-preview-record-type';
import { RecordPreviewSubheading } from 'components/record-preview/record-preview-subheading';
import { RecordPreviewViewButton } from 'components/record-preview/record-preview-view-button';

import contactsModel, { ContactItem } from 'data/models/entities/contacts';
import { ContactPreviewPopoutHeatIndication } from './contact-preview-popout-heat-indication';
import { ContactPreviewHeading } from './contact-preview-heading';
import { ContactPreviewPhone } from './contact-preview-phone';

import { getContactPreviewPopoutData } from './utils';

interface ContactPreviewPopoutProps {
  id: Id;
}

const getContactQuery = (contactId) => {
  return query`{
    ${contactsModel} (id: ${contactId}) {
      id
      contact_image
      type
      name
      company_name
      phone_number
      email_address
      interest_level
      security_user_rights
    }
  }`;
};

function FirstActionBarItem({
  contact,
  hasClickToDial,
  newMailMergeData
}: {
  contact?: ContactItem;
  hasClickToDial: boolean;
  newMailMergeData: any;
}) {
  const hasPhone = contact?.related?.contact_phones?.length;

  if (hasClickToDial && contact?.id && hasPhone) {
    return <RecordPreviewClickToDialAction contactId={contact.id} />;
  }

  if (!hasClickToDial && newMailMergeData) {
    return <RecordPreviewSendEmailAction record={newMailMergeData} />;
  }

  return null;
}

export function ContactPreviewPopoutCoreComponent({
  id,
  errorDialog
}: ContactPreviewPopoutProps & { errorDialog: ErrorDialog }) {
  const contactQuery = useMemo(() => (id ? getContactQuery(id) : undefined), [
    id
  ]);
  const contacts = useEntityQuery(contactQuery);
  const contact = contacts.data;

  const isLoading = contacts.status !== 'loaded';
  const hasReadRights = contact?.security_user_rights?.includes('read');

  const viewPath = `/contacts/#id=${id}`;

  const { telephony_available_methods } = useModelState(session);
  const hasClickToDial = telephony_available_methods?.length > 0;

  const {
    contactImage,
    recordTypeHeading,
    contactName,
    contactPhoneNumber,
    contactEmailAddress,
    buyerInterestLevel,
    newMailMergeData,
    newNoteData,
    newAppointmentData,
    feedbackData,
    isDoNotContact,
    phoneIsOnTPS,
    shouldOverrideTPS
  } = useMemo(() => getContactPreviewPopoutData({ contact }), [contact]);

  const onAddReminderClick = useAddReminder({
    reminderData: { contact },
    errorDialog
  });

  const onSendSMSClick = useSendSMS({ record: newMailMergeData });

  const onAddFeedbackClick = useAddFeedback({
    feedbackData,
    errorDialog
  });

  const onSendEmailClick = useSendEmail({ record: newMailMergeData });

  const placeHolderIcon =
    (contact?.type === 'company' && ICONS.COMPANY) ||
    (contact?.type === 'person' && ICONS.CONTACTS) ||
    undefined;

  return (
    <RecordPreviewContainer>
      <RecordPreviewContentContainer
        leftColumn={
          <>
            <RecordPreviewImage
              recordImage={contactImage}
              placeHolderIcon={placeHolderIcon}
            />
            <RecordPreviewViewButton viewPath={viewPath}>
              View
            </RecordPreviewViewButton>
          </>
        }
        rightColumn={
          <>
            <RecordPreviewRecordType>
              {recordTypeHeading}
            </RecordPreviewRecordType>
            {isLoading ? (
              <RecordPreviewLoadingSpinner />
            ) : (
              <>
                <ContactPreviewHeading isDoNotContact={isDoNotContact}>
                  {contactName}
                </ContactPreviewHeading>
                {contactPhoneNumber && (
                  <ContactPreviewPhone
                    contactPhoneNumber={contactPhoneNumber}
                    phoneIsOnTPS={phoneIsOnTPS}
                    shouldOverrideTPS={shouldOverrideTPS}
                  />
                )}
                {contactEmailAddress && (
                  <RecordPreviewSubheading>
                    {contactEmailAddress}
                  </RecordPreviewSubheading>
                )}
                {buyerInterestLevel && (
                  <ContactPreviewPopoutHeatIndication
                    tagType={buyerInterestLevel}
                  />
                )}
                <RecordPreviewLatestNote id={id} serviceName='Contacts' />
              </>
            )}
          </>
        }
      />
      {/* TODO: Really need to reduce the number of conditions here to show this bar.
      https://app.shortcut.com/rexlabs/story/64802/record-popout-preview-clean-up-actions-so-less-conditions-to-render-the-action-bar */}
      <RecordPreviewActionBar>
        {hasReadRights && !isLoading && (
          <>
            {/* Usually Email, replaced by Click to Dial for Spicerhaart */}
            <FirstActionBarItem
              contact={contact}
              hasClickToDial={hasClickToDial}
              newMailMergeData={newMailMergeData}
            />

            {newNoteData && (
              <RecordPreviewAddNoteAction noteData={newNoteData} />
            )}
            {newAppointmentData && (
              <RecordPreviewAddAppoinmentAction
                appointmentData={newAppointmentData}
              />
            )}
            <RecordPreviewActionBarMoreMenu
              items={[
                // Email only shows in More dropdown if it's missing from the action items
                ...(hasClickToDial
                  ? [
                      {
                        label: 'Send Email',
                        onClick: onSendEmailClick
                      }
                    ]
                  : []),
                {
                  label: 'Send SMS',
                  onClick: onSendSMSClick
                },
                {
                  label: 'Add Reminder',
                  onClick: onAddReminderClick
                },
                {
                  label: 'Add Feedback',
                  onClick: onAddFeedbackClick
                }
              ]}
            />
          </>
        )}
      </RecordPreviewActionBar>
    </RecordPreviewContainer>
  );
}

export const ContactPreviewPopoutCore = compose<
  React.ComponentType<ContactPreviewPopoutProps>
>(withErrorDialog)(ContactPreviewPopoutCoreComponent);

export function ContactPreviewPopout({
  id,
  children,
  stopPropagation
}: {
  id: string;
  children: ReactNode;
  stopPropagation?: boolean;
}) {
  // HACK: current version of popout doesn't let us manage state and causes rerender
  // loop if we don't memoize.
  // https://app.shortcut.com/rexlabs/story/58232/popouts-the-vivid-popout-component-cannot-maintain-state-within-the-popout-content
  const PopoutContent = useMemo(
    () => () => <ContactPreviewPopoutCore id={id} />,
    [id]
  );

  return (
    <Popout
      distance='11px'
      Content={PopoutContent}
      stopPropagation={stopPropagation}
    >
      {children}
    </Popout>
  );
}
