import { store } from 'store';
import session from 'data/models/custom/session';

const SessionBridge = {
  actionCreators: {
    updateThirdPartyIntegrations:
      session.actionCreators.updateThirdPartyIntegrations,
    switchToAccount: (payload, callback) => (
      dispatch,
      getState,
      extraArgument
    ) => {
      const isRedirecting = !callback;
      return session.actionCreators
        .switchToAccount({
          ...payload,
          isRedirecting
        })(dispatch, getState, extraArgument)
        .then(() => {
          if (callback) {
            callback();
          }
        });
    },
    setGlobalSettings: session.actionCreators.setGlobalSettings,
    setApiToken: session.actionCreators.setApiToken,
    refresh: session.actionCreators.refresh,
    softRefresh: session.actionCreators.softRefresh,
    pushRecentItem: session.actionCreators.pushRecentItem,
    getQuickStats: session.actionCreators.getQuickStats
  },
  hasSwitchedAccounts: ({ apiToken }) => {
    const state = store.getState();
    return state.session.api_token !== apiToken;
  },
  isRosieAccount: () => {
    const state = store.getState();
    return state.session.isRosieAccount;
  }
};

export default SessionBridge;
