import { useDialog } from 'hooks/use-dialog';
import appraisals, { AppraisalData } from 'data/models/entities/appraisals';
import { useWhichWordFactory } from 'hooks/use-which-word';
import { PropertyStub } from 'data/models/entities/properties';
import { SecurityUserRights } from 'data/models/entities/contacts';
import { ActionMenuItem } from 'view/components/action-menu/core';
import { useModelActions } from '@rexlabs/model-generator';

export function usePipelineDropdownActionsAppraisal({
  data,
  onDelete
}: {
  data: AppraisalData;
  onDelete: (
    props?:
      | { id: string; type?: string | undefined; args?: object | undefined }
      | undefined
  ) => Promise<void>;
}) {
  const addEditAppraisal = useDialog('addEditAppraisal');
  const reverseMatch = useDialog('reverseBuyerMatch');
  const errorDialog = useDialog('errorDialog');
  const confirmationDialog = useDialog('confirmation');
  const archiveAppraisalDialog = useDialog('archiveAppraisal');
  const { moveItemToNewLists } = useModelActions(appraisals);
  const ww = useWhichWordFactory();

  const hasRights = (right: SecurityUserRights) =>
    data.security_user_rights?.includes(right);

  const actions: ActionMenuItem[] = [];

  if (hasRights('update')) {
    actions.push({
      label: 'Edit',
      onClick: () =>
        addEditAppraisal.open({
          id: data.id,
          propertyCategoryId: ((data.property as unknown) as PropertyStub)
            .property_category.id
        })
    });
  }

  if (hasRights('update') && data.appraisal_state === 'active') {
    actions.push({
      label: 'Archive',
      onClick: () =>
        archiveAppraisalDialog.open({
          id: data.id,
          onSuccess: (item: AppraisalData) => moveItemToNewLists({ item })
        })
    });
  }

  if (hasRights('read')) {
    actions.push({
      label: 'Reverse Match',
      onClick: () =>
        reverseMatch.open({
          id: data.id,
          data: {
            service_name: 'Appraisals',
            sale_or_lease:
              data.appraisal_type.id === 'sale' ? 'sale' : 'rental',
            property_category: data?.property?.property_category?.id
          },
          saveCallback: (viewstateId) => {
            if (!viewstateId) {
              errorDialog.open({
                message: `No ${ww(
                  'buyer'
                )}s were found with match criteria that matches this ${ww(
                  'valuation'
                ).toLowerCase()}`
              });
            } else {
              window.open(`/contacts/#viewstate=${viewstateId}`, '_blank');
            }
          }
        })
    });
  }

  if (hasRights('purge')) {
    actions.push({
      label: 'Delete',
      onClick: () =>
        confirmationDialog.open({
          title: `Delete ${ww('valuation')}`,
          confirmText: 'Delete',
          onConfirm: () => onDelete({ id: data.id }),
          message: `Are you sure you want to delete this ${ww(
            'valuation'
          ).toLowerCase()}? This action cannot be undone`
        })
    });
  }

  return actions;
}
