import React, { PureComponent } from 'react';
import { KEYFRAMES, COLORS, ZINDEX } from 'theme';
import { PortalTarget } from '@rexlabs/portal';
import {
  styled,
  StyleSheet,
  StylesProvider,
  keyframes
} from '@rexlabs/styling';
import CSSAnimationGroup from '@rexlabs/css-animation-group';

import _ from 'lodash';
import { DialogTooltipProvider } from 'view/context/tooltip';

const defaultStyles = StyleSheet({
  wrapItems: {
    position: 'fixed',
    top: 0,
    zIndex: ZINDEX.DIALOGS_OVERLAY,
    left: 0,
    right: 0,
    bottom: 0,
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },

  wrapItemsActive: {
    opacity: 1,
    pointerEvents: 'auto'
  },

  wrapItem: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    overflow: 'auto'
  },

  wrapDialog: {
    transformOrigin: 'center'
  },

  backdrop: {
    background: COLORS.BLACK,
    opacity: 0.4,
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0
  },
  frosted: {
    opacity: 1,
    background: 'rgba(125, 130, 140, 0.4)',
    transition: 'unset',
    backdropFilter: 'blur(3px)'
  }
});

const getDialogZindex = (dialogStackLevel) => {
  return ZINDEX.DIALOGS_OVERLAY + dialogStackLevel * 2;
};

const getTooltipStyleOverrides = _.memoize((dialogsZindex) => {
  // Ensures the tooltips always position themselves above the dialog they are being launched from
  const tooltipsZIndex = dialogsZindex + 1;

  return {
    Tether: StyleSheet({
      wrapContent: {
        zIndex: tooltipsZIndex
      }
    })
  };
});

const overlayComponentOverrides = {
  // Button: StyleSheet({
  //   container: {
  //     height: '40px !important'
  //   }
  // })
  // STYLING TODO
  // 'Tooltip Button': StyleSheet({
  //   container: {
  //     height: '34px !important',
  //     '&:hover': {
  //       opacity: 1
  //     },
  //     '&:focus': {
  //       outline: 'none'
  //     }
  //   }
  // })
};

const backdropAnimations = {
  frosted: {
    leaveAnimation: keyframes({
      '0%': { opacity: 1, backdropFilter: 'blur(3px)' },
      '100%': { opacity: 0, backdropFilter: 'blur(0px)' }
    }),
    leaveDuration: '150ms',
    enterAnimation: keyframes({
      '0%': { opacity: 0, backdropFilter: 'blur(0px)' },
      '100%': { opacity: 1, backdropFilter: 'blur(3px)' }
    }),
    enterDuration: '150ms',
    enterOnAppear: true,
    enterTimingFunction: 'ease-out',
    leaveTimingFunction: 'ease-out'
  },
  normal: {
    leaveAnimation: keyframes({
      '0%': { opacity: 1 },
      '100%': { opacity: 0 }
    }),
    leaveDuration: '150ms',
    enterAnimation: keyframes({
      '0%': { opacity: 0 },
      '100%': { opacity: 1 }
    }),
    enterDuration: '150ms',
    enterOnAppear: true,
    enterTimingFunction: 'ease-out',
    leaveTimingFunction: 'ease-out'
  }
};

@styled(defaultStyles)
class DialogOverlayPortal extends PureComponent {
  containerRef = React.createRef();
  mostRecentTopmostDialog = React.createRef();

  render() {
    const { styles: s } = this.props;

    return (
      <PortalTarget name='dialogOverlayStack'>
        {({ children }) => {
          // HACK: this should really just be temporary, we need a way to force dialogs
          // to always be on top of other dialogs. To do so, we sort the portal children
          // here depending on weather or not the child has a `alwaysOnTop` prop
          // Note - sort causes different effects based on browser. _.sortBy doesn't.
          const newChildren = _.sortBy([...children], (a) => {
            return _.get(a, 'props.alwaysOnTop') ? 1 : 0;
          });

          const lastDialogIndex = _.findLastIndex(newChildren, (child) => {
            return child.props.isDialog;
          });

          const firstChild = newChildren[0];
          if (firstChild) {
            this.mostRecentTopmostDialog.current = firstChild;
          }

          return (
            <div
              ref={this.containerRef}
              {...s('wrapItems', { wrapItemsActive: newChildren.length })}
            >
              <DialogTooltipProvider target={this.containerRef}>
                <CSSAnimationGroup
                  {...backdropAnimations[
                    this.mostRecentTopmostDialog.current?.props.variant
                  ]}
                >
                  {React.Children.map(newChildren, (child, i) => {
                    const zIndexOverride = _.get(child.props, 'zIndexOverride');
                    const dialogZIndex = zIndexOverride || getDialogZindex(i);

                    return child.props.isDialog && i === lastDialogIndex ? (
                      <StylesProvider
                        key={i}
                        components={getTooltipStyleOverrides(dialogZIndex)}
                        prioritiseParentStyles={false}
                      >
                        <div
                          {...s.with('wrapItem')({ zIndex: dialogZIndex })}
                          key={`backdrop-${child.key || child.props.id}`}
                        >
                          <div
                            {...s('backdrop', {
                              frosted: child.props.variant === 'frosted'
                            })}
                          />
                        </div>
                      </StylesProvider>
                    ) : null;
                  })}
                </CSSAnimationGroup>

                <CSSAnimationGroup
                  leaveAnimation={KEYFRAMES.DIALOG_FADE_OUT}
                  leaveDuration='150ms'
                  enterAnimation={KEYFRAMES.DIALOG_FADE_IN}
                  enterDuration='150ms'
                  enterTimingFunction='ease-out'
                  leaveTimingFunction='ease-out'
                >
                  {React.Children.map(newChildren, (child, i) => {
                    const zIndexOverride = _.get(child.props, 'zIndexOverride');
                    const dialogZIndex = zIndexOverride || getDialogZindex(i);

                    return child.props.isDialog ? (
                      <StylesProvider
                        components={getTooltipStyleOverrides(dialogZIndex)}
                        prioritiseParentStyles={false}
                      >
                        <div
                          {...s.with(
                            'wrapItem',
                            'wrapDialog'
                          )({
                            zIndex: dialogZIndex
                          })}
                          key={`dialogs-${child.key || child.props.id}`}
                        >
                          {child}
                        </div>
                      </StylesProvider>
                    ) : null;
                  })}
                </CSSAnimationGroup>

                <StylesProvider
                  components={overlayComponentOverrides}
                  prioritiseParentStyles={false}
                >
                  <CSSAnimationGroup
                    leaveAnimation={KEYFRAMES.OVERLAY_FADE_OUT}
                    leaveDuration='300ms'
                    enterAnimation={KEYFRAMES.OVERLAY_FADE_IN}
                    enterDuration='300ms'
                  >
                    {React.Children.map(newChildren, (child, i) => {
                      const zIndexOverride = _.get(
                        child.props,
                        'zIndexOverride'
                      );
                      const dialogZIndex = zIndexOverride || getDialogZindex(i);

                      return !child.props.isDialog ? (
                        <StylesProvider
                          components={getTooltipStyleOverrides(dialogZIndex)}
                          prioritiseParentStyles={false}
                        >
                          <div
                            {...s.with('wrapItem')({ zIndex: dialogZIndex })}
                            key={`overlays-${child.key || child.props.id}`}
                          >
                            {child}
                          </div>
                        </StylesProvider>
                      ) : null;
                    })}
                  </CSSAnimationGroup>
                </StylesProvider>
              </DialogTooltipProvider>
            </div>
          );
        }}
      </PortalTarget>
    );
  }
}

export default DialogOverlayPortal;
