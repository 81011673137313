import _ from 'lodash';
import LocalStorage from 'shared/utils/local-storage';
import * as Options from 'view/components/navigation/app-search/options';
import { getHazardly } from 'shared/utils/prefix-hell';
import { store } from 'shell/src/store';
import fontColorContrast from 'font-color-contrast';

/*
|-------------------------------------------------------------------------------
| DEFAULTS
|-------------------------------------------------------------------------------
|
| Just some empty variables that can be reused inside of React.Component render method.
|
*/
export const DEFAULT_ARRAY = [];
export const DEFAULT_OBJECT = {};
export const DEFAULT_STRING = '';

export const OPTION_TYPES = {
  CONTACT: 'CONTACT',
  CURRENT_LISTING: 'CURRENT_LISTING',
  ARCHIVED_LISTING: 'ARCHIVED_LISTING',
  LISTING: 'LISTING',
  PROPERTY: 'PROPERTY',
  BUILDING: 'BUILDING',
  FAVOURITE_FILTER: 'FAVOURITE_FILTER',
  SAVED_FILTER: 'SAVED_FILTER',
  AGENT_LEDGER: 'AGENT_LEDGER',
  COMMISSION_WORKSHEET: 'COMMISSION_WORKSHEET',
  CONTRACT: 'CONTRACT',
  HOLD: 'HOLD',
  INVOICE: 'INVOICE',
  INVOICE_TRANSACTION: 'INVOICE_TRANSACTION',
  NEWSLETTER: 'NEWSLETTER',
  PROJECT: 'PROJECT',
  PROJECT_STAGE: 'PROJECT_STAGE',
  REPORTING: 'REPORTING',
  REPORT: 'REPORT',
  TRUST_ACCOUNT: 'TRUST_ACCOUNT',
  MARKET_LEAD: 'MARKET_LEAD',
  CAMPAIGN: 'CAMPAIGN'
};

export const LISTING_STATES = {
  CURRENT: 'current',
  WITHDRAWN: 'withdrawn',
  SOLD: 'sold',
  LEASED: 'leased'
};

function getPermission(addons = {}, addon) {
  return ![null, '0', 0].includes(addons[addon]);
}

// NOTE: Once we have moved to the new search completely, we can modify the classNames for
// the listings, and remove the request args for them too. See below for what can be changed.
// https://rexsoftware.atlassian.net/browse/RADI-6135
export const PROVIDER_REQUEST_MAPPING = {
  [OPTION_TYPES.CONTACT]: {
    className: 'Contacts',
    OptionType: OPTION_TYPES.CONTACT,
    labelPlucker: (data) => _.get(data, 'name') || DEFAULT_STRING,
    valuePlucker: (data) => `${_.get(data, 'id')}`,
    Option: Options.ContactOption,
    hasPermission: () => true
  },
  [OPTION_TYPES.CURRENT_LISTING]: {
    // Change the class name to 'CurrentListings'
    className: 'Listings',
    OptionType: OPTION_TYPES.CURRENT_LISTING,
    labelPlucker: (data) => _.get(data, 'address') || DEFAULT_STRING,
    valuePlucker: (data) => `${_.get(data, 'id')}`,
    Option: Options.ListingOption,
    hasPermission: () => true,
    // Remove the requestArgs
    requestArgs: {
      listing_states: [LISTING_STATES.CURRENT]
    }
  },
  [OPTION_TYPES.ARCHIVED_LISTING]: {
    // Change the class name to ArchivedListings
    className: 'Listings',
    OptionType: OPTION_TYPES.ARCHIVED_LISTING,
    labelPlucker: (data) => _.get(data, 'address') || DEFAULT_STRING,
    valuePlucker: (data) => `${_.get(data, 'id')}`,
    Option: Options.ListingOption,
    hasPermission: () => true,
    // Remove the requestArgs
    requestArgs: {
      listing_states: [
        LISTING_STATES.WITHDRAWN,
        LISTING_STATES.SOLD,
        LISTING_STATES.LEASED
      ]
    }
  },
  [OPTION_TYPES.PROPERTY]: {
    className: 'Properties',
    OptionType: OPTION_TYPES.PROPERTY,
    labelPlucker: (data) => _.get(data, 'address') || DEFAULT_STRING,
    valuePlucker: (data) => `${_.get(data, 'id')}`,
    Option: Options.PropertyOption,
    hasPermission: () => true
  },
  [OPTION_TYPES.BUILDING]: {
    className: 'Buildings',
    OptionType: OPTION_TYPES.BUILDING,
    labelPlucker: (data) => _.get(data, 'name') || DEFAULT_STRING,
    valuePlucker: (data) => `${_.get(data, 'id')}`,
    Option: Options.BuildingOption,
    hasPermission: () => true
  },
  [OPTION_TYPES.INVOICE]: {
    className: 'Invoices',
    OptionType: OPTION_TYPES.INVOICE,
    labelPlucker: (data) =>
      _.get(data, 'listing.property.system_search_key') ||
      _.get(data, 'reference') ||
      DEFAULT_STRING,
    valuePlucker: (data) => `${_.get(data, 'id')}`,
    Option: Options.InvoiceOption,
    hasPermission: () => true
  },
  [OPTION_TYPES.PROJECT]: {
    className: 'Projects',
    OptionType: OPTION_TYPES.PROJECT,
    labelPlucker: (data) => _.get(data, 'name') || DEFAULT_STRING,
    valuePlucker: (data) => `${_.get(data, 'id')}`,
    Option: Options.ProjectOption,
    hasPermission: (addons) => getPermission(addons, 'projects')
  },
  [OPTION_TYPES.PROJECT_STAGE]: {
    className: 'ProjectStages',
    OptionType: OPTION_TYPES.PROJECT_STAGE,
    labelPlucker: (data) => _.get(data, 'name') || DEFAULT_STRING,
    valuePlucker: (data) => `${_.get(data, 'id')}`,
    Option: Options.ProjectStageOption,
    hasPermission: (addons) => getPermission(addons, 'projects')
  },
  [OPTION_TYPES.MARKET_LEAD]: {
    className: 'MarketLeads',
    OptionType: OPTION_TYPES.MARKET_LEAD,
    labelPlucker: (data) => _.get(data, 'system_label') || DEFAULT_STRING,
    valuePlucker: (data) => `${_.get(data, 'id')}`,
    Option: Options.MarketLeadOption,
    hasPermission: (addons) => getPermission(addons, 'market_leads')
  },
  [OPTION_TYPES.CAMPAIGN]: {
    className: 'Campaigns',
    OptionType: OPTION_TYPES.CAMPAIGN,
    labelPlucker: (data) => _.get(data, 'name') || DEFAULT_STRING,
    valuePlucker: (data) => `${_.get(data, 'id')}`,
    Option: Options.CampaignOption,
    hasPermission: (addons) => getPermission(addons, 'campaigns')
  }
};

export const getFilteredProviders = () => {
  const state = store.getState();
  const addOns = state.session.subscription_limits.add_ons;

  return (
    Object.keys(PROVIDER_REQUEST_MAPPING)
      // eslint-disable-next-line array-callback-return
      .filter((PROVIDER_KEY) => {
        const provider = PROVIDER_REQUEST_MAPPING[PROVIDER_KEY];
        if (provider.hasPermission) {
          return !!provider.hasPermission(addOns);
        }
      })
      .reduce((obj, key) => {
        obj[key] = PROVIDER_REQUEST_MAPPING[key];
        return obj;
      }, {})
  );
};

export const MAXIMUM_SCOPED_RESULTS = 100; // Used to limit API request
export const MAXIMUM_GLOBAL_RESULTS = 11; // Used to limit API request
export const MAXIMUM_GLOBAL_SHOWN_RESULTS = 2; // How many global options we show
export const MAXIMUM_RECENT_ENTRIES = 20; // Limits the recents

export const formatOptionToHeading = (heading) => {
  const knownOption = _.includes(OPTION_TYPES, heading.toUpperCase());

  return (_.last(heading).toLowerCase() === 'y'
    ? heading.replace(/y$/gi, 'ies')
    : knownOption
    ? `${heading}s`
    : heading
  )
    .split('_')
    .join(' ');
};

export const isOwner = (systemOwnerUser) => {
  const accountInfo = LocalStorage.get('account_info');
  return (
    getHazardly(systemOwnerUser, 'id') ===
    getHazardly(accountInfo, 'user_details._id')
  );
};

export const hasPermission = (securityUserRights, permissionName) => {
  return _.includes(securityUserRights, permissionName);
};

export const pluralise = (toBePlural) => {
  return (_.last(toBePlural).toLowerCase() === 'y'
    ? toBePlural.replace(/y$/i, 'ies')
    : `${toBePlural}s`
  )
    .split(' ')
    .join('_')
    .toLowerCase();
};

export const getServiceTypeForOptionType = (type) => {
  switch (type) {
    case OPTION_TYPES.CURRENT_LISTING:
    case OPTION_TYPES.ARCHIVED_LISTING:
      return 'listings';
    case OPTION_TYPES.PROPERTY:
      return 'properties';
    case OPTION_TYPES.MARKET_LEAD:
      return 'market-leads';
    default:
      return pluralise(type);
  }
};

export function headerColorContrast(hex) {
  // Thresholds above 0.5 increase the likelihood the result is white text
  return fontColorContrast(hex, 0.7);
}
