// TODO: replace with `@rexlabs/dialog`

import React, {
  Suspense,
  ComponentType,
  useMemo,
  useState,
  useCallback
} from 'react';
import invariant from 'invariant';
import _ from 'lodash';

import { getDisplayName } from 'shared/utils/react';

type Options = {
  propName?: string;
  open?: boolean;
};

export const withDialog = (
  DialogComponent: ComponentType<any>,
  options: Options = {}
) => (WrappedComponent) => {
  invariant(
    DialogComponent,
    'You need to pass in the dialog component to "@withDialog"!'
  );

  const dialogName = getDisplayName(DialogComponent);
  const propName = options.propName || _.camelCase(dialogName);

  function WithDialog(props) {
    const [dialogProps, setProps] = useState({});
    const [isOpen, setOpen] = useState(options?.open);

    const open = useCallback((props) => {
      setProps(props);
      setOpen(true);
    }, []);

    const close = useCallback(() => {
      setOpen(false);
    }, []);

    const hasUserValidationExceptionError = [
      props.problem,
      (dialogProps as { problem?: string }).problem
    ].includes('UserValidationException');

    // triggered by a user validation exception, because we handle those differently.
    // This is not the place for this code. Ticket here:
    // https://app.clubhouse.io/rexlabs/story/56017
    const showDialog =
      isOpen &&
      !(propName === 'errorDialog' && hasUserValidationExceptionError);

    const wrapperProps = useMemo(() => ({ open, close }), [close, open]);

    return (
      <>
        <WrappedComponent {...{ [propName]: wrapperProps }} {...props} />
        {showDialog && (
          <Suspense fallback={null}>
            <DialogComponent
              {...dialogProps}
              closeDialog={close}
              // Treating `message` special here, because in @withErrorDialog we potentially
              // pass in error objects that can not be spread properly
              message={_.get(dialogProps, 'message')}
            />
          </Suspense>
        )}
      </>
    );
  }

  const name = getDisplayName(WrappedComponent);
  WithDialog.displayName = `withDialog:${dialogName}(${name})`;
  return WithDialog;
};
