import React, { ReactNode, useState } from 'react';
import { InfiniteListProps, RecordWithId } from 'components/infinite-list';
import {
  DndContext,
  DragEndEvent,
  DragOverlay,
  DragStartEvent,
  PointerSensor,
  useSensor,
  useSensors
} from '@dnd-kit/core';
import Box from '@rexlabs/box';
import { createPortal } from 'react-dom';
import { PipelineStageData } from 'features/pipelines/data/admin-pipeline-stages';
import { Criteria } from 'types/criteria';

export type KanbanBoardColumnData<
  ItemData extends RecordWithId,
  ColumnCriteria extends Criteria[]
> = {
  id: number | string;
  pipelineStage: PipelineStageData;
  bulkActions?: {
    label: string;
    onClick: (props: { criteria: ColumnCriteria }) => void;
  }[];
  criteria?: ColumnCriteria;
  renderColumnHeader?: (
    props: KanbanBoardColumnData<any, ColumnCriteria>
  ) => ReactNode;
  renderColumnFooter?: (
    props: Pick<
      KanbanBoardColumnData<any, ColumnCriteria>,
      'id' | 'hasNextPage' | 'pipelineStage'
    >
  ) => ReactNode;
  isDraggingEnabled: (data: ItemData) => boolean;
} & InfiniteListProps<ItemData>;

export type KanbanBoardDropEvent<
  ItemData extends RecordWithId,
  ColumnCriteria extends Criteria[]
> = {
  item: ItemData;
  fromColumn: KanbanBoardColumnData<ItemData, ColumnCriteria>;
  toColumn: KanbanBoardColumnData<ItemData, ColumnCriteria>;
};

export interface KanbanBoardProps {
  children: ReactNode;
  onItemColumnChange: <
    ItemData extends RecordWithId,
    ColumnCriteria extends Criteria[]
  >(
    event: KanbanBoardDropEvent<ItemData, ColumnCriteria>
  ) => void;
}

export function KanbanBoard({
  children,
  onItemColumnChange
}: KanbanBoardProps) {
  const [activeItem, setActiveItem] = useState<{
    item: any;
    columnProps: KanbanBoardColumnData<any, any>;
  } | null>(null);
  function handleDragStart(event: DragStartEvent) {
    setActiveItem(event.active.data.current as any);
  }

  function handleDragEnd(event: DragEndEvent) {
    setActiveItem(null);

    if (
      onItemColumnChange &&
      event.over?.id !== event.active.data.current?.columnProps.id
    ) {
      onItemColumnChange({
        item: event.active.data.current?.item,
        fromColumn: event.active.data.current?.columnProps,
        toColumn: event.over?.data.current as any
      });
    }
  }

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 6
      }
    })
  );

  return (
    <DndContext
      sensors={sensors}
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
    >
      <Box
        width={'100%'}
        height={'100%'}
        flex={1}
        display={'flex'}
        flexDirection={'row'}
        style={{
          gap: '10px',
          overflowX: 'scroll' as any
        }}
      >
        {children}
      </Box>
      {createPortal(
        <DragOverlay>
          {activeItem
            ? activeItem.columnProps.renderItem(activeItem.item)
            : null}
        </DragOverlay>,
        document.getElementById('app') as HTMLElement
      )}
    </DndContext>
  );
}
