import React, { ReactNode } from 'react';
import { StyleSheet, useStyles } from '@rexlabs/styling';

type AiTooltipProps = {
  children: ReactNode;
  content: ReactNode;
};

const aiTooltipStyles = StyleSheet({
  wrapper: {
    position: 'relative',
    '& [data-tooltip-content]': {
      position: 'absolute',
      display: 'block',
      transformOrigin: 'bottom left',
      left: '50%',
      pointerEvents: 'none',
      transform: 'translateX(-50%) translateY(calc(-100% + 5px))',
      boxShadow: '0px 0px 5px 0px rgba(170, 170, 170, 1)',
      zIndex: 3,
      opacity: 0,
      transition: 'opacity 0.15s ease-out, transform 0.15s ease-out',
      whiteSpace: 'nowrap',
      backgroundColor: 'rgba(10, 10, 10, 0.9)',
      fontSize: '14px',
      borderRadius: '8px',
      padding: '9px 14px',
      color: 'white',
      minWidth: '0px',
      textAlign: 'center'
    },
    '&:hover [data-tooltip-content], &:focus [data-tooltip-content], &:active [data-tooltip-content]': {
      opacity: 1,
      pointerEvents: 'auto',
      transform: 'translateX(-50%) translateY(calc(-100% + 3px))'
    }
  }
});

export function AiTooltip({ children, content }: AiTooltipProps) {
  const s = useStyles(aiTooltipStyles);

  return (
    <div {...s('wrapper')}>
      <div {...s('tooltipContent')} data-tooltip-content=''>
        {content}
      </div>
      {children}
    </div>
  );
}
