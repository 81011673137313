import { useCallback } from 'react';
import { TenancyApplicationItem } from '../data/types';
import { useRegion } from 'hooks/use-region';
import { formatAddress, formatCurrency } from 'src/utils/formatters';
import dayjs from 'dayjs';
import { ListingStub } from 'data/models/entities/listings';
import { useDialog } from 'hooks/use-dialog';
import { useErrorDialog } from 'hooks/use-error-dialog';
import { pick } from 'lodash';
import { useModelActions } from '@rexlabs/model-generator';
import tenancyApplicationsModel from '../data/tenancy-applications';
import { dateFormat } from '../data/formatter';

export function useSendToListingOwner(
  listing: ListingStub,
  applications: TenancyApplicationItem[],
  onSuccess?: () => void
) {
  const region = useRegion();
  const { updateItem } = useModelActions(tenancyApplicationsModel);
  const currency = region.financial.currency.symbol;
  const editMailMerge = useDialog('editMailMerge');
  const errorDialog = useErrorDialog();

  const getApplicationDetails = useCallback(
    (a, index) => {
      const tenants = a.related.listing_application_tenants;

      const getTenantInfo = (tenant) => {
        const age = dayjs().diff(tenant.dob, 'year');
        const employmentStatus = tenant.employment_status.id.includes(
          'employed'
        )
          ? tenant.employment_status.text.toLowerCase()
          : `employed ${
              tenant.employment_status.id === 'casual'
                ? 'casually'
                : tenant.employment_status.text.toLowerCase()
            }`;
        return `${tenant.contact.name} (${age}, ${employmentStatus})`;
      };

      const pluralize = (count, word) =>
        `${count} ${word}${count !== 1 ? 's' : ''}`;

      const details = [
        [
          `Application ${index + 1} — ${currency}${formatCurrency(
            a.offer_amount
          )} ${a.offer_amount_period?.text}`,
          (v) => `<strong>${v}</strong>`
        ],
        [
          tenants.map(getTenantInfo).join(', '),
          (v) => `${pluralize(tenants.length, 'adult')} - ${v}`
        ],
        ...(!region.isUK
          ? [[a.num_of_dependents ?? 0, (v) => pluralize(v, 'dependent')]]
          : [
              [a.num_of_households ?? 0, (v) => pluralize(v, 'household')],
              [a.num_of_occupants ?? 0, (v) => pluralize(v, 'occupant')]
            ]),
        [a.has_pets, (v) => (v ? 'Has pets' : 'No pets')],
        [
          a.system_total_income ? formatCurrency(+a.system_total_income) : 0,
          (v) =>
            `Household income: ${currency}${v} ${a.offer_amount_period?.text.toLowerCase()}`
        ],
        [
          dayjs(a.start_date).format('D MMM YYYY'),
          (v) =>
            `Start date: ${v}${
              a.agreement_length_months
                ? ` for ${a.agreement_length_months} months`
                : ''
            }`
        ],
        [
          dayjs(a.end_date).format('D MMM YYYY'),
          (v) => (a.agreement_length_months === null ? `End date: ${v}` : null)
        ],
        [a.conditions, (v) => (v ? `Other conditions: ${v}` : null)],
        [a.notes, (v) => (v ? `Other notes: ${v}` : null)]
      ];

      return `<p>${details
        .map(([value, formatter], index) => {
          const formattedValue = formatter(value);
          return formattedValue
            ? `
                <span>${formattedValue}</span>
                <br />
              `
            : null;
        })
        .filter(Boolean)
        .join('')}</p>`;
    },
    [currency, region]
  );

  const sendToListingOwner = () => {
    const owners = listing._related.contact_reln_listing?.filter(
      (contact) => contact.reln_type.id === 'owner'
    );

    if (!owners?.length) {
      errorDialog.open({ message: 'No listing owners can be found' });
      return;
    }

    const formattedOwners = owners.map((owner) => ({
      id: owner.contact.id,
      name: ((owner.contact as unknown) as { _name: string })._name
    }));

    const contact = {
      id: formattedOwners[0].id,
      name: formattedOwners[0].name
    };

    editMailMerge.open({
      data: {
        mode: 'listing',
        medium: 'email',
        data: {
          listing: {
            ...listing,
            name: formatAddress(listing.property)
          },
          contact,
          cc_contact_ids: formattedOwners.slice(1).map((owner) => owner.id),
          customSubject: `Your applications for ${formatAddress(
            pick(listing.property, [
              'adr_street_name',
              'adr_unit_number',
              'adr_street_number',
              'adr_building',
              'property_category'
            ]),
            region
          )}`,
          customBody: `
            <p>Hi ${contact.name},</p>
            <p>You have ${applications.length} new application${
            applications.length > 1 ? 's' : ''
          } for ${formatAddress(
            pick(listing.property, [
              'adr_street_name',
              'adr_unit_number',
              'adr_street_number',
              'adr_building',
              'property_category'
            ]),
            region
          )}. Please review them below and let me know if you would like to proceed with one of them.</p>
          ${applications.map(getApplicationDetails).join('')}
          `
        }
      },
      options: {
        title: 'Send Email',
        templateDisabled: true
      },
      callback: async () => {
        const promises = applications.map((application) => {
          return updateItem({
            data: {
              id: application.id,
              date_communicated: dayjs().format(dateFormat)
            }
          });
        });

        try {
          await Promise.all(promises);
          onSuccess?.();
        } catch (error) {
          errorDialog.open(error as Error);
        }
      }
    });
  };

  return sendToListingOwner;
}
