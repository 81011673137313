import _ from 'lodash';
import { autobind } from 'core-decorators';
import React, { Component } from 'react';

import Box from '@rexlabs/box';
import { styled, StyleSheet } from '@rexlabs/styling';
import { parseUrlToRoute, push } from '@rexlabs/whereabouts';
import { PLACEMENTS } from '@rexlabs/tooltip';
import sessionModel from 'data/models/custom/session';

import Icon, { ICONS } from 'shared/components/icon';
import { createKey } from 'shared/utils/react';

import { COLORS } from 'theme';
import { store } from 'store';

import Circle from '../components/circle';
import NotificationBadge from '../components/notification-badge';
import { NavigationPopout } from './navigation-popout';
import { headerColorContrast } from 'view/components/navigation/app-search/utils';

@styled(
  StyleSheet({
    container: {
      width: 'inherit',
      minWidth: '270px',
      display: 'flex',
      flexDirection: 'column'
    },
    menuItem: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      color: 'rgba(0,0,0,0.75)',
      minHeight: '50px',
      fontSize: '15px',
      lineHeight: '22px',
      cursor: 'pointer',

      '& svg': {
        marginRight: '15px',
        marginLeft: '20px',

        width: '15px',
        height: '15px'
      },

      '&:hover': {
        backgroundColor: '#F7F8F9',
        color: 'black'
      }
    },
    iconLabelWrapper: {
      display: 'flex',
      alignItems: 'center'
    },
    label: {},
    badge: {
      textAlign: 'center',
      marginRight: '20px',
      padding: '0 7.5px',
      backgroundColor: '#29a2e2',
      color: 'white',
      borderRadius: '85px'
    },
    separator: {
      height: '1px',
      backgroundColor: '#E1E1E1',
      margin: '13px 20px',
      opacity: '0.5'
    },

    metaWrapper: {
      display: 'flex',
      padding: '15px'
    },
    profilePicture: {
      height: '56px',
      minWidth: '56px',
      marginRight: '15px',
      borderRadius: '100%',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',

      color: 'white',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      fontWeight: '600',
      fontSize: '20px'
    },
    metaInfo: {
      fontSize: '15px',
      lineHeight: '22px',
      fontWeight: '600',
      width: '100%'
    },
    fullName: {
      color: '#424242',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      width: 'calc(100% - 20px)'
    },
    role: {
      color: '#A8AFB4'
    }
  })
)
@autobind
class UserProfileMenu extends Component {
  constructor(props) {
    super(props);

    this.MENU_CONFIG = [
      {
        icon: ICONS.SETTINGS,
        name: 'My Settings',
        href: '/user/profile/#page=details'
      },
      {
        icon: ICONS.LETTER,
        name: 'Email Dropbox',
        count: 0,
        href:
          '/email_dropbox#filters=[[%22system_filing_status%22,%22unfiled%22]]'
      },
      {
        icon: ICONS.ANNOUNCEMENTS,
        name: 'Announcements',
        count: 0,
        href: '/announcements'
      },
      {
        isSeparator: true,
        name: 'Separator 1'
      },
      {
        icon: ICONS.RELOAD,
        name: 'Reload',
        onClick: (e) => {
          e.preventDefault();
          store
            .dispatch(sessionModel.actionCreators.refresh())
            .then(() => window.location.reload(true));
        }
      },
      {
        icon: ICONS.LOGOUT,
        name: 'Logout',
        onClick: (e) => {
          e.preventDefault();
          store.dispatch(
            sessionModel.actionCreators.logout({
              redirectUrl: window.location.href
            })
          );
        }
      }
    ];
  }

  handleNavigation(url) {
    const { closePopout } = this.props;
    return () => {
      closePopout();
      push({ config: parseUrlToRoute(url) });
    };
  }

  render() {
    const { styles: s, meta } = this.props;
    const { name, role, initials, profilePicture } = meta;

    return (
      <Box {...s('container')}>
        <Box {...s('metaWrapper')}>
          <Box
            {...s.with('profilePicture')({
              backgroundImage: `url(${profilePicture})`,
              backgroundColor: profilePicture
                ? 'transparent'
                : COLORS.NAV_MENU.BLUE
            })}
          >
            {!profilePicture && initials}
          </Box>
          <Box
            flex={1}
            flexDirection={'column'}
            justifyContent={'center'}
            {...s('metaInfo')}
          >
            <Box {...s('fullName')}>{name}</Box>
            {role && <Box {...s('role')}>{role}</Box>}
          </Box>
        </Box>

        {this.MENU_CONFIG.map((menuItem, index) => {
          const { name, count, href, onClick, icon, isSeparator } = menuItem;
          const sensibleCount = count > 999 ? '999+' : count;
          const shouldShowCount = !!count > 0;
          const onClickHandler = onClick || this.handleNavigation(href);
          const key = createKey(menuItem.id || menuItem.name);

          if (isSeparator) {
            return <Box key={`separator-${index}`} {...s('separator')} />;
          }

          return (
            <span key={key} {...s('menuItem')} onClick={onClickHandler}>
              <Box {...s('iconLabelWrapper')}>
                <Icon style={{ display: 'flex' }} type={icon} />
                <Box {...s('label')}>{name}</Box>
              </Box>
              {shouldShowCount && <Box {...s('badge')}>{sensibleCount}</Box>}
            </span>
          );
        })}
      </Box>
    );
  }
}

@styled(
  StyleSheet({
    container: {
      color: 'white',
      height: '36px',
      width: '45px',
      borderRadius: '3px 0 0 3px',
      backgroundColor: 'rgba(0,0,0,0.1)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      cursor: 'pointer',

      '& svg': {
        width: '15px',
        height: '15px'
      },

      '&:hover': {
        backgroundColor: 'rgba(0,0,0,0.5)'
      }
    },
    profilePicture: {
      width: 'inherit',
      height: 'inherit',
      borderRadius: 'inherit',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',

      color: 'white',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      fontWeight: '600',
      fontSize: '14px'
    }
  })
)
@autobind
class UserProfile extends Component {
  render() {
    const { styles: s, meta, currentAgencyColor, ...props } = this.props;
    const name = _.get(meta, 'name', '');
    const profilePicture = _.get(meta, 'profilePicture', false);
    const names = name.split(' ');
    const initials = `${_.first(_.first(names))}${_.first(_.last(names))}`;

    return (
      <NavigationPopout
        name='user-profile'
        placement={PLACEMENTS.BOTTOM_END}
        Content={({ close }) => (
          <UserProfileMenu
            closePopout={close}
            meta={{ ...meta, initials }}
            {...props}
          />
        )}
      >
        <Circle
          style={{ overflow: 'hidden' }}
          render={() => {
            return (
              <span style={{ width: 'inherit', height: 'inherit' }}>
                <NotificationBadge count={0} />
                <div
                  {...s.with('profilePicture')({
                    color: headerColorContrast(currentAgencyColor),
                    backgroundImage: `url(${profilePicture})`
                  })}
                >
                  {!profilePicture && initials}
                </div>
              </span>
            );
          }}
        />
      </NavigationPopout>
    );
  }
}

export default UserProfile;
