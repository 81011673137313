import {
  getGroupIdFromDateCriteria,
  isSystemPipelineStage
} from 'features/pipelines/data/utils/date-grouping';
import { api, transformList, transformListArgs } from 'shared/utils/api-client';
import { upperFirst } from 'lodash';
import { transformListIds } from 'shared/utils/api-client/transform';
import { AxiosResponse } from 'axios';
import {
  AppraisalData,
  PipelineGroupBy
} from 'data/models/entities/appraisals';
import { PipelineStageData } from 'features/pipelines/data/admin-pipeline-stages';

type PromiseResolve = (value: AxiosResponse) => void;

type GroupSearchResponse = {
  result: {
    groups: GroupSearchGroup[];
  };
};

type GroupSearchGroup = {
  rows: AppraisalData[];
  aggregates: GroupSearchAggregates;
  id: string | number;
  details: {
    pipeline_stage: PipelineStageData;
    type: string;
  };
};

type GroupSearchAggregates = {
  count: number;
  sum_comm_est_amount_net_of_tax: number | null;
};

export function generateBatchFetchFunctions({
  extraOptions,
  limit,
  groupByDateField
}: {
  extraOptions: Record<string, unknown>;
  limit: number;
  groupByDateField: string;
}) {
  let listIdsToAlwaysBatch: string[] = [];
  let requestsThatNeedBatching: Record<string, string> = {};

  let batchPromise: Promise<AxiosResponse<GroupSearchResponse>> | null = null;
  let resolveBatchPromise: PromiseResolve | null = null;
  let shouldBatch = true;
  let batchGroupBy: PipelineGroupBy;

  function setListIdsToAlwaysBatch(ids) {
    listIdsToAlwaysBatch = ids;
  }

  function setGroupBy(groupBy: typeof batchGroupBy) {
    batchGroupBy = groupBy;
  }

  function batchNextCall() {
    shouldBatch = true;
  }

  return {
    setListIdsToAlwaysBatch,
    setGroupBy,
    batchNextCall,
    fetchList: (type, args, { payload, actions, model }) => {
      let groupId;

      if (batchGroupBy === 'pipeline_stage') {
        groupId = args.criteria?.find?.((c) => c.name === 'pipeline_stage_id')
          ?.value;
      } else {
        groupId = getGroupIdFromDateCriteria(
          args.criteria,
          groupByDateField,
          args.criteria?.find?.((c) => c.name === 'pipeline_stage_id')?.value
        );
      }

      // Logic to dynamically delay & batch fetching of multiple API calls
      // Relies on storing an array of list ids in a singleton (listIdsToAlwaysBatch).
      // If one of those list IDs tries to fetch, it waits until it receives
      // fetches from all other lists in the array and then batches them together
      //
      // Main use case: kanban board with multiple useEntityListQueries.

      if (
        listIdsToAlwaysBatch.includes(payload.id) &&
        shouldBatch // can be used to opt out of batching (e.g. pagination requests)
      ) {
        if (!Object.keys(requestsThatNeedBatching).length) {
          batchPromise = new Promise((resolve) => {
            resolveBatchPromise = resolve;
          });
        }

        requestsThatNeedBatching[payload.id] = groupId;

        if (
          listIdsToAlwaysBatch.every((id) => !!requestsThatNeedBatching[id])
        ) {
          api
            .post(`${upperFirst(type)}::searchGrouped`, {
              order_by: args.order_by,
              group_by: batchGroupBy,
              extra_options: extraOptions,
              criteria: (args.criteria || []).filter(
                (c) =>
                  ![
                    'pipeline_stage_id',
                    groupByDateField,
                    'archive_date'
                  ].includes(c.name)
              ),
              limit
            })
            .then((response) => {
              resolveBatchPromise?.(response);
              requestsThatNeedBatching = {};
              batchPromise = null;
              resolveBatchPromise = null;
            });
        }

        return batchPromise?.then(({ data, config }) => {
          shouldBatch = false;

          const group = (data.result.groups.find(
            (g) =>
              String(g.id) === String(groupId) ||
              (groupId === 'null' && g.id === 'unqualified') ||
              (isSystemPipelineStage(g.details?.pipeline_stage) &&
                String(g.details?.pipeline_stage?.system_purpose?.id) ===
                  String(groupId))
          ) as unknown) as GroupSearchGroup;

          const aggregates: GroupSearchAggregates = group
            ? group.aggregates
            : {
                count: 0,
                sum_comm_est_amount_net_of_tax: 0
              };

          // Bug in model generator with inconsistent parameters
          // Some actions send "actions", others use "model"
          (actions || model).setAggregatesForListId({
            listId: payload.id,
            aggregates
          });

          return transformList(
            {
              config,
              data: {
                result: {
                  rows: group?.rows || [],
                  total: aggregates.count
                }
              }
            },
            { pageLimit: args.limit || limit }
          );
        });
      }

      return api
        .post(`${upperFirst(type)}::search`, transformListArgs({ args }))
        .then(args.result_format === 'ids' ? transformListIds : transformList);
    }
  };
}
