import React, { useMemo } from 'react';

import { withValueLists } from '@rexlabs/model-generator';
import { ReactForms } from '@rexlabs/form';
import { PLACEMENTS } from '@rexlabs/tooltip';
import { parseUrlToRoute, push } from '@rexlabs/whereabouts';
import Box from '@rexlabs/box';

import ApplyCancelButtonBar from 'shared/components/button-bar/apply-cancel';

import { PADDINGS } from 'theme';
import PaddingBox from 'view/components/padding-box';
import { FormField, Form } from 'view/components/form';
import { RadioGroupInput } from 'view/components/input/radio-buttons';
import { Popout } from 'view/components/popout';
import { DialogBody } from 'components/text/dialog-body';
import { SubmoduleSelectorButton } from 'components/button/reporting-filter-button/submodule-selector-button';

import { ModuleName } from 'features/custom-reporting/modules/module-config-types';
import {
  useAllModuleConfigs,
  useCurrentModuleConfig,
  useGroupForModule
} from 'features/custom-reporting/modules/module-provider';

interface FormValues {
  selected_module: ModuleName;
}

interface ModuleSwitcherComponentProps {
  moduleName: ModuleName;
}

interface PopoutContentProps {
  moduleName: ModuleName;
}

const handleSave = (values: FormValues) => {
  const module = values.selected_module;

  push({ config: parseUrlToRoute(`/report/${module}`) });
};

function PopoutContent(props: PopoutContentProps) {
  const { moduleName } = props;

  const group = useGroupForModule(moduleName);
  const allModules = useAllModuleConfigs();

  const radioButtonOptions = group?.modules?.map?.((feModuleName) => ({
    label:
      allModules[feModuleName]?.shortLabel ||
      allModules[feModuleName]?.moduleLabel,
    value: feModuleName
  }));

  const initialValues = useMemo(() => ({ selected_module: moduleName }), [
    moduleName
  ]);

  return (
    <PaddingBox width='340px'>
      <ReactForms handleSubmit={handleSave} initialValues={initialValues}>
        {({ submitForm, isSubmitting }) => {
          return (
            <Form>
              <FormField
                name={'selected_module'}
                Input={RadioGroupInput}
                inputProps={{
                  options: radioButtonOptions
                }}
              />
              <Box pt={PADDINGS.XXS} pb={PADDINGS.XXS}>
                <DialogBody light>
                  Changing this will reset your columns and filters.
                </DialogBody>
              </Box>
              <ApplyCancelButtonBar
                isLoading={isSubmitting}
                onCancel={close}
                onSave={submitForm}
              />
            </Form>
          );
        }}
      </ReactForms>
    </PaddingBox>
  );
}

export function ModuleSwitcherPopout() {
  const { shortLabel, moduleLabel, moduleName } = useCurrentModuleConfig();
  const label = shortLabel || moduleLabel;

  return useMemo(
    () => (
      <Popout
        placement={PLACEMENTS.BOTTOM_START}
        Content={() => <PopoutContent moduleName={moduleName} />}
      >
        <SubmoduleSelectorButton>{label}</SubmoduleSelectorButton>
      </Popout>
    ),
    [moduleName, label]
  );
}
