import { usePermissionGroupOptions } from 'hooks/use-permission-group-options';
import { useMemo } from 'react';
import sessionModel from 'data/models/custom/session';
import { useModelState } from '@rexlabs/model-generator';
import { reportingFiltersModel } from 'features/custom-reporting/data/reporting-filters';
import { usePermissions } from 'hooks/use-permissions';

export function useGetSelectedUsers() {
  const { office_details, user_details, group_member_ids } = useModelState(
    sessionModel
  );
  const accountId = office_details?.id;
  const userId = user_details?.id;

  const { check } = usePermissions();
  const hasViewAllPermissions = check('custom_reports.view_all');

  const filters = useModelState(reportingFiltersModel);
  const users = filters[accountId]?.users || [];

  const {
    groupOptions,
    status: accountUsersStatus
  } = usePermissionGroupOptions();

  // Make a new user selection if the user doesn't have sufficient privileges to
  // see data for the users who are currently in the selection.
  const selectedUsersForPermissions = useMemo(() => {
    if (
      accountUsersStatus === 'loaded' &&
      groupOptions.length > 0 &&
      !hasViewAllPermissions
    ) {
      const groupMemberIds = group_member_ids || [];

      const includesNonGroupUsers = users.find((user) => {
        return user.value !== userId && !groupMemberIds.includes(user.value);
      });

      if (includesNonGroupUsers || !users.length) {
        return groupOptions;
      }
      return users;
    }
    return users;
  }, [accountUsersStatus]);

  return { selectedUsersForPermissions, status: accountUsersStatus };
}
