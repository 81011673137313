/* eslint-disable max-lines */

// TODO: clean up

import _ from 'lodash';
import axios from 'axios';

import { match, parseUrlToRoute, push } from '@rexlabs/whereabouts';

import LocalStorage from 'shared/utils/local-storage';
import { Generator } from 'shared/utils/models';
import { api, parseUserPrivilegesResponse } from 'shared/utils/api-client';
import {
  getRedirectToAuthService,
  restoreLongUrl
} from 'shared/utils/api-client/redirect';
import { stripPrefixFromProperties } from 'shared/utils/prefix-hell';
import config from 'shared/utils/config';
import Analytics from 'shared/utils/vivid-analytics';
import authModel from 'shared/data/models/custom/auth';
import { initFlags } from 'shared/contexts/flags';

import sortNaturally from 'utils/sort';
import { resetPusherConnection } from 'utils/pusher';
import { checkUserHasPermission } from 'utils/rights';
import { checkFeatureFlags } from 'utils/feature-flags';

import RecentActivity from 'data/recent-activity';
import uiModel from 'data/models/custom/ui';
import apiCacheModel from 'data/models/custom/api-cache';

import { CustomReportItem } from 'features/custom-reporting/data/custom-reports-model';
import { ValueListItem } from '../value-lists/value-list';

interface AccountItem {
  account_id: string;
  application: string;
  name: string;
}

interface ValueObj {
  value: number;
}

interface LeadNotificationsItem {
  breakdown: {
    assigned_to_me: ValueObj;
    assigned_to_others: ValueObj;
    assigned_to_team: ValueObj;
    unassigned: ValueObj;
  };
  value: number;
}

interface UserDetails {
  account_user_id: string;
  email: string;
  first_name: string;
  full_name: string;
  ghost_access: boolean;
  id: string;
  is_account_owner: boolean;
  last_name: string;
  segmentation_role: {
    id: string;
    sub_text: string;
    text: string;
    segmentation_tech_skill: string;
    segmentation_transactions_commercial: any;
    segmentation_transactions_residential: any;
  };
  settings: {
    app_color: any;
    default_location: ValueListItem;
    email_signature: boolean;
    phone_direct: string | null;
    phone_mobile: string;
    portal_agent_email: string | null;
    portal_agent_name: string | null;
    portal_agent_phone: string | null;
    position: string | null;
    profile_bio: string | null;
    profile_image: string | null;
  };
  user_links: {
    calendar: string;
  };
}
export interface SessionModel {
  api_token?: string;
  id: string;
  accessibleAccounts: AccountItem[];
  checkUserHasPermission: (accessRights: string) => boolean;
  exchangeApiToken: (payload: string) => string;
  getLocationCoords: any;
  getQuickStats: any;
  init: any;
  initSegment: any;
  initWebsockets: any;
  isRosieAccount: boolean;
  leadNotifications: LeadNotificationsItem;
  locationCoords: any;
  logout: any;
  pushRecentItem: any;
  recentActivity: [];
  refresh: any;
  setApiToken: any;
  setCalendarDate: any;
  setCalendarRange: any;
  setCalendarView: any;
  setGlobalSettings: any;
  setRegion: any;
  softRefresh: any;
  switchToAccount: any;
  updateThirdPartyIntegrations: any;
  // TODO: move out of session model
  dashboardsList: any;

  office_details: any;
  accessible_accounts: any;
  user_details: UserDetails;
  global_settings: any;
  subscription_limits: any;
  managed_libraries: any;
  group_member_ids: string[];
  has_group: any;
  third_party_extensions: any;
  privileges: any;
  websocketChannelTypes: any;
  telephony_available_methods: { label: string; method_id: string }[];
  office_locations: any;
  favouriteCustomReports: CustomReportItem[];
  updateFavouriteCustomReports: () => void;
  api_region: any;

  childAccountIds: any[];
}

/*
|-------------------------------------------------------------------------------
| Persisting the Session
|
| Synchronous rehydration outside of redux-persist, because RP doesn't fit our
| cases for SHARED data between shell & classic.
|
*/

// LocalStorage.reset();
const accountInfo = _.defaults(LocalStorage.get('account_info'), {
  office_details: {},
  accessible_accounts: [],
  user_details: {},
  global_settings: {},
  subscription_limits: {},
  managed_libraries: [],
  group_member_ids: [],
  has_group: false,
  third_party_extensions: [],
  privileges: {},
  websocketChannelTypes: null,
  dashboardsList: [],
  office_locations: [],
  favouriteCustomReports: []
});
const globalVar: any = global;
const initialState = {
  ready: false,
  isSwitching: false,
  isExchangingToken: false,
  locationCoords: {},
  regions: {},
  api_token: LocalStorage.get('api_token') || null,
  api_region: config.API_REGION_URL
    ? { base_url: config.API_REGION_URL }
    : LocalStorage.get('api_region') || null,
  recent_activity: RecentActivity.getRecentActivity(),
  calendar: LocalStorage.get('calendar') || {
    view: 'week',
    range: { changed: null, start: null, end: null }
  },
  isRosieAccount: false,
  ...stripPrefixFromProperties(accountInfo, '_')
};

function isRosieAccount(parentAccountIds, officeDetails) {
  return (
    parentAccountIds.some((id) => ['1732', '4956'].includes(`${id}`)) ||
    (__DEV__ && officeDetails?.id?.toString() === '4')
  );
}

function hasIntercomChat(parentAccountIds, officeDetails, subscriptionLimits) {
  if (!subscriptionLimits?.add_ons?.live_support_chat) {
    return false;
  }

  if (
    config.INTERCOM_CHAT_ENABLED_UK &&
    officeDetails?.locale?.code?.toUpperCase() === 'UK'
  ) {
    // We never want to enable intercom for SH accounts, so even if
    // UK is enabled we want to filter those out!
    return !isRosieAccount(parentAccountIds, officeDetails);
  }
  return true;
}

function hasZendeskChat(parentAccountIds, officeDetails) {
  return (config.ZENDESK_CHAT_WHITELIST as any[])?.includes?.(
    officeDetails?.id?.toString?.()
  );
}

const actionCreators = {
  initSegment: {
    request: async (payload, actions, dispatch, getState) => {
      /* tslint:disable-next-line */
      if (window.$zopim) {
        const liveChat = _.get(window, '$zopim.livechat');
        if (liveChat) {
          const currSession = getState().session;
          const parentAccountIds =
            payload?.parentAccountIds || currSession?.parentAccountIds;
          const officeDetails =
            payload?.officeDetails || currSession?.office_details;

          // Position in the bottom right
          liveChat.button.setPosition('br');
          liveChat.window.setPosition('br');

          // Show or hide based on account id
          if (hasZendeskChat(parentAccountIds, officeDetails)) {
            liveChat.button.show();
          } else {
            liveChat.hideAll();
          }
        }
      }
    },
    reduce: {
      initial: _.identity,
      success: _.identity,
      failure: _.identity
    }
  },

  init: {
    request: async (payload, actions, dispatch, getState) => {
      const currSession = getState().session;
      const authState = getState().auth;
      const route = parseUrlToRoute(window.location.href);

      const whereabouts = getState().whereabouts;
      const isPublicAction = match(whereabouts.locations[whereabouts.current], {
        path: '/actions/(.*)'
      });
      const isCrashRefresh = route?.query?.crashed;

      const userProfile = _.get(currSession, 'user_details');
      let parentAccountIds = _.get(currSession, 'parentAccountIds');
      const officeDetails = _.get(currSession, 'office_details');
      const alphaEnabled = !!_.get(
        currSession,
        'subscription_limits.add_ons.alpha_enabled'
      );

      if (isPublicAction) {
        return;
      }

      checkFeatureFlags();

      api.setApp('rex');

      if (_.get(currSession, 'api_token')) {
        api.setAuthToken(currSession.api_token);
      }
      if (_.get(currSession, 'api_region.base_url')) {
        api.setBaseUrl(`${currSession.api_region.base_url}/v1/rex/`);
      }

      // We're coming back from a session
      // that already exists so set the user
      if (!_.isEmpty(userProfile)) {
        // HACK: load parents manually if not already in store and add them
        // to our account info local storage setup
        if (!parentAccountIds) {
          const r = await api.post('AccountHierarchy::getParentAccountIds');
          parentAccountIds = r?.data?.result || [];
          const info = LocalStorage.get('account_info');
          LocalStorage.set(
            'account_info',
            {
              ...info,
              parentAccountIds
            },
            undefined
          );
        }

        // NOTE: Analytics.identify relies on both initFlags and initSegment, so we need to
        // call it after the promise
        await Promise.all([
          initFlags(config.FLAGSMITH_ID),
          actions.initSegment({ parentAccountIds, officeDetails })
        ]);

        Analytics.identify({
          userId: userProfile.id,
          properties: {
            user: userProfile,
            office: officeDetails,
            alphaEnabled: alphaEnabled,
            parentAccountIds
          },
          options: {
            Intercom: {
              hideDefaultLauncher: !hasIntercomChat(
                parentAccountIds,
                officeDetails,
                currSession?.subscription_limits
              )
            }
          }
        });
      }

      if (route && isCrashRefresh) {
        delete route.query?.crashed;
        await actions.refresh();
        route.hash = `${_.map(
          route.hashQuery,
          (param, key) => `${key}=${param}`
        ).join('&')}`;
        route.path = window.location.pathname;
        push({ config: route });
      }

      const shouldLoginViaAuthService =
        route.query?.login_source === 'authentication-service';
      const shouldLoginViaRexAuthApp = !!(
        route &&
        route.hashQuery?.token &&
        route.query?.region_id
      );
      const shouldLoginViaCallPopper = !!globalVar.popper;
      const isLoggedOut =
        !getState().session.api_region || !getState().session.api_token;

      if (shouldLoginViaAuthService) {
        await dispatch(
          authModel.actionCreators.loginViaAuthService({
            appId: 'rex_crm',
            ...actions
          })
        );
      } else if (shouldLoginViaRexAuthApp) {
        await actions.loginViaRexAuthApp();
      } else if (shouldLoginViaCallPopper) {
        await actions.loginViaCallPopper();
      } else if (isLoggedOut) {
        actions.logout();
      }

      // restore url from localstorage if urlId present. See the function for context.
      if (route?.hashQuery?.urlId) {
        restoreLongUrl({
          urlId: route.hashQuery.urlId
        });
      }

      // TODO: Websockets (atm) are not super crucial for the app, so we want to defer
      // the API call until all important calls for the intial screen are done
      setTimeout(() => actions.initWebsockets(), 0);
    },
    reduce: {
      initial: (state) => ({ ...state, ready: false }), // We set this to false to clear any persisted state
      success: (state) => ({ ...state, ready: true }),
      // NOTE: we probably need actual error handling here as well, at least rn it
      // should allow the app to be rendered (so user won't get stuck in loading state)
      // and then potentially error out :/
      failure: (state) => ({ ...state, ready: true })
    }
  },

  loginViaRexAuthApp: {
    request: async (payload, actions, dispatch, getState) => {
      const currSession = getState().session;
      const authState = getState().auth;
      const route = parseUrlToRoute(window.location.href);

      dispatch(
        authModel.actionCreators.setLastAccount({
          email:
            _.get(currSession, 'user_details.email') ||
            _.get(authState, 'loginInfo.email'),
          appId: 'rex',
          accountId: route.query?.account_id
        })
      );

      // Token exchange
      await actions.exchangeApiToken({
        token: route.hashQuery?.token,
        accountId: route.query?.account_id,
        regionId: route.query?.region_id
      });

      delete route.query?.region_id;
      delete route.query?.account_id;
      delete route.hashQuery?.token;
      route.hash = `${_.map(
        route.hashQuery,
        (param, key) => `${key}=${param}`
      ).join('&')}`;
      route.path = window.location.pathname;
      push({ config: route });
    },
    reduce: {
      initial: _.identity,
      success: _.identity,
      failure: _.identity
    }
  },

  loginViaCallPopper: {
    request: async (
      { accountId }: { accountId: string },
      actions,
      dispatch,
      getState
    ) => {
      const currSession = getState().session;
      const authState = getState().auth;
      const route = parseUrlToRoute(window.location.href);

      // If global.popper is defined we know we're in the
      // call popper context and have the token already
      dispatch(
        authModel.actionCreators.setLastAccount({
          email:
            _.get(currSession, 'user_details.email') ||
            _.get(authState, 'loginInfo.email'),
          appId: 'rex',
          accountId: globalVar.popper.account.id
        })
      );

      // We already have a local token from logging in through
      // the call popper so we just need to set those and refresh
      await actions.setRegion({ regionId: globalVar.popper.region.id });
      actions.setApiToken(globalVar.popper.token);
      await actions.refresh();

      route.hash = `${_.map(
        route.hashQuery,
        (param, key) => `${key}=${param}`
      ).join('&')}`;
      route.path = window.location.pathname;
      push({ config: route });
    },
    reduce: {
      initial: _.identity,
      success: _.identity,
      failure: _.identity
    }
  },

  initWebsockets: {
    request: () =>
      api
        .post('RealTimeWebNotifications::getAvailableChannelTypes', {})
        .then((response) => {
          const channelTypes = response?.data?.result || [];
          const channels = channelTypes.reduce(
            (acc, channelType) => ({
              ...acc,
              [channelType.id]: channelType
            }),
            {}
          );
          return channels;
        }),
    reduce: {
      initial: _.identity,
      success: (state, action) => ({
        ...state,
        websocketChannelTypes: action.payload
      })
    }
  },

  setRegion: {
    request: async (payload, actions, dispatch) => {
      const { regionId } = payload;

      const regions = _.get(
        await dispatch(authModel.actionCreators.getRegions()),
        'data.result'
      );
      LocalStorage.set('regions', regions, undefined);

      const region = _.find(regions, (region) => region.id === regionId);
      if (!region) {
        throw new Error('Region for token exchange not found!');
      }

      api.setBaseUrl(`${region.base_url}/v1/rex/`);
      LocalStorage.set('api_region', region, true);

      return { region, regions };
    },
    reduce: {
      initial: _.identity,
      success: (state, action) => ({
        ...state,
        regions: action.payload.regions,
        api_region: action.payload.region
      }),
      failure: _.identity
    }
  },

  getLocationCoords: {
    request: async (payload, actions, dispatch, getState) => {
      // Early exit if we've already got details for this location.
      const existingCoords = getState().session.locationCoords[payload];
      if (existingCoords) return existingCoords;

      const officeLocation = await api.post('AdminOfficeLocations::read', {
        id: payload
      });

      // This field typically has a lot of newlines, which need to be replaced
      // with commas for Mapbox to understand it correctly.
      const address = _.replace(
        _.get(officeLocation, 'data.result.address_physical'),
        /\n/g,
        ','
      );

      if (!address) return null;

      const mapboxResponse = await axios.get(
        `https://api.mapbox.com/geocoding/v5/mapbox.places/${address}.json` +
          `?access_token=${config.MAPBOX_TOKEN}` +
          '&types=address&limit=1'
      );

      const feature = _.get(mapboxResponse, 'data.features.0');
      if (!feature) return null;
      return {
        coords: _.get(feature, 'center'),
        id: payload,
        address: _.get(officeLocation, 'data.result.address_physical')
      };
    },
    reduce: {
      initial: _.identity,
      success: (state, action) => {
        if (!action.payload) return state;
        const {
          payload: { id, coords, address }
        } = action;
        return {
          ...state,
          locationCoords: {
            ...state.locationCoords,
            [id]: { coords, address }
          }
        };
      },
      failure: _.identity
    }
  },

  exchangeApiToken: {
    request: async (payload, actions, dispatch) => {
      const { token, accountId, regionId } = payload;

      await actions.setRegion({ regionId });
      const exchange = await dispatch(
        authModel.actionCreators.exchangeApiToken({
          token,
          accountId
        })
      );

      const apiToken = _.get(exchange, 'data.result');
      actions.setApiToken(apiToken);

      return actions.refresh();
    },
    reduce: {
      initial: (state) => ({ ...state, isExchangingToken: true }),
      success: (state) => ({ ...state, isExchangingToken: false }),
      failure: (state) => ({ ...state, isExchangingToken: false })
    }
  },

  softRefresh: {
    request: async (payload, actions, dispatch, getState) => {
      dispatch(
        uiModel.actionCreators.loadingIndicatorOn({
          message: 'Refreshing'
        })
      );

      const currentRegion = LocalStorage.get('api_region');
      await actions.setRegion({ regionId: _.get(currentRegion, 'id') });

      const apiToken = LocalStorage.get('api_token');
      await actions.setApiToken(apiToken);

      const userInfo = LocalStorage.get('account_info');

      const {
        user_details: userProfile,
        office_details: officeDetails,
        parentAccountIds
      } = userInfo;
      const alphaEnabled = !!_.get(
        getState().session,
        'subscription_limits.add_ons.alpha_enabled'
      );

      Analytics.identify({
        userId: userProfile.id,
        properties: {
          user: userProfile,
          office: officeDetails,
          alphaEnabled: alphaEnabled,
          parentAccountIds
        },
        options: {
          Intercom: {
            hideDefaultLauncher: !hasIntercomChat(
              parentAccountIds,
              officeDetails,
              getState().session?.subscription_limits
            )
          }
        }
      });

      checkFeatureFlags();

      dispatch(
        authModel.actionCreators.setLastAccount({
          email: _.get(userProfile, 'email'),
          appId: 'rex',
          accountId: _.get(officeDetails, 'id')
        })
      );

      return new Promise((resolve) =>
        setTimeout(() => {
          dispatch(uiModel.actionCreators.loadingIndicatorOff());
          resolve(userInfo);
        }, 0)
      );
    },
    reduce: {
      initial: (state) => ({ ...state, isSwitching: true }),
      success: (state, action) => ({
        ...state,
        ...action.payload,
        isSwitching: false
      }),
      failure: (state) => ({ ...state, isSwitching: false })
    }
  },

  switchToAccount: {
    request: async (payload, actions, dispatch, getState) => {
      const { id, isRedirecting = true } = payload;

      resetPusherConnection();

      const globalToken = await api.post('UserProfile::getGlobalAuthToken', {
        limit_to_account_ids: [id]
      });

      const account = _.get(globalToken, 'data.result.accounts', []).find(
        (a) => a.account_id === id
      );
      if (!account) {
        throw new Error('Account not found!');
      }

      LocalStorage.reset(false);
      await actions.exchangeApiToken({
        token: _.get(globalToken, 'data.result.token'),
        accountId: account.account_id,
        regionId: _.get(account, 'region.id')
      });

      await actions.refresh();

      dispatch(apiCacheModel.actionCreators.refresh());

      const currSession = getState().session;
      dispatch(
        authModel.actionCreators.setLastAccount({
          email: _.get(currSession, 'user_details.email'),
          appId: 'rex',
          accountId: id
        })
      );

      if (isRedirecting) {
        push({ config: parseUrlToRoute('/') });
        uiModel.actionCreators.reloadRexFrame({
          forceUrl: `/embedded/?__releaseHash=${config.RELEASE?.HASH}`
        })(dispatch, getState);
      }
    },
    reduce: {
      initial: (state) => ({ ...state, isSwitching: true }),
      success: (state) => ({ ...state, isSwitching: false }),
      failure: (state) => ({ ...state, isSwitching: false })
    }
  },

  setApiToken: {
    reduce: (state, action) => {
      if (state.api_token === action.payload) return state;

      // We need to sync set this to enable api calls in the same event loop
      LocalStorage.set('api_token', action.payload, true);

      api.setAuthToken(action.payload);

      return {
        ...state,
        api_token: action.payload
      };
    }
  },

  setGlobalSettings: {
    request: ({ settings }) =>
      api.post('UserProfile::setGlobalSettings', { settings }),
    reduce: {
      initial: _.identity,
      success: (state, action) => {
        const settings = _.get(action, 'payload.data.result');

        const info = LocalStorage.get('account_info');
        LocalStorage.set(
          'account_info',
          {
            ...info,
            global_settings: settings
          },
          undefined
        );

        return { ...state, global_settings: settings };
      },
      failure: _.identity
    }
  },

  refresh: {
    request: async (payload, actions, dispatch, getState) => {
      dispatch(
        uiModel.actionCreators.loadingIndicatorOn({
          message: 'Refreshing'
        })
      );
      const region = _.get(getState(), 'session.office_details.locale.code');

      const sessionBatchPromise = api.batch([
        'AccountSettings::read',
        'UserProfile::getAccessibleAccounts',
        'UserProfile::read',
        'UserProfile::getGlobalSettings',
        'AccountBilling::getSubscriptionDetails',
        'AdminTemplateLibraries::getLibrariesManagedByAccount',
        'UserProfile::getGroupMemberIds',
        [
          'ThirdPartyServices::getConnections',
          { include_inherited: true, include_definition: true }
        ],
        [
          'SecurityPrivileges::getEffectivePrivilegesForUser',
          { describe_privileges: 1 }
        ],
        [
          'QuickStats::getStats',
          {
            required_stats: [
              { stat_id: 'leads' },
              region === 'uk' && { stat_id: 'workflows' }
            ].filter(Boolean)
          }
        ],
        'AccountHierarchy::getParentAccountIds',
        'AccountHierarchy::getSubAccountIds',
        'Telephony::getAvailableMethods',
        'AdminOfficeLocations::search',
        // TODO: Sort on BE when available
        //  JIRA: https://rexsoftware.atlassian.net/browse/RADI-6068
        [
          'CustomReports::search',
          {
            criteria: [{ name: 'is_favourite', type: '=', value: true }],
            limit: 30
          }
        ]
      ]);

      // This dashboard list request will be rejected if the user doesn't have any
      // dashboard privileges.
      // Request it separately so as not to break the main batch request.
      const dashboardsBatchPromise = api
        .batch(['ReportingDashboards::search', 'EmbeddedApps::search'])
        .catch(console.error);

      return Promise.all([sessionBatchPromise, dashboardsBatchPromise]).then(
        async ([response, dashboardResponse]) => {
          const result = _.get(response, 'data.result', []);
          const dashboardResult = _.get(dashboardResponse, 'data.result', []);

          const dashboardsList = dashboardResult[0]?.rows || [];

          const customDashboardsList =
            dashboardResult[1]?.rows?.filter(
              (row) => row.type.id === 'custom_dashboard' && row.is_enabled
            ) || [];

          const [
            officeDetails,
            accounts,
            userProfile,
            globalSettings,
            subscriptions,
            accountLibraries,
            groupIds,
            connections,
            privileges,
            { leads },
            parentAccountIds,
            childAccountIds,
            telephonyAvailableMethods,
            { rows: officeLocations },
            { rows: favouriteCustomReports }
          ] = result;

          const newState = {
            office_details: officeDetails,
            accessible_accounts: accounts,
            user_details: userProfile,
            global_settings: globalSettings,
            subscription_limits: subscriptions,
            managed_libraries: accountLibraries,
            group_member_ids: groupIds,
            has_group: !!groupIds.length,
            third_party_extensions: connections,
            leadNotifications: leads,
            privileges: parseUserPrivilegesResponse(privileges),
            recent_activity: RecentActivity.getRecentActivity(
              _.get(userProfile, 'id'),
              _.get(officeDetails, 'id')
            ),
            // Account id of the SH Master Template Account. Will never change.
            // Using some instead of includes just in case id is a Number
            isRosieAccount: isRosieAccount(parentAccountIds, officeDetails),
            parentAccountIds,
            childAccountIds,
            telephony_available_methods: telephonyAvailableMethods,
            // @ts-ignore
            dashboardsList: sortNaturally(dashboardsList, 'title'),
            office_locations: officeLocations,
            customDashboardsList,
            favouriteCustomReports: favouriteCustomReports.sort((a, b) =>
              a.name.localeCompare(b.name)
            )
          };

          // Preserve recent activity on refresh
          const oldActivity = LocalStorage.get('recent_activity');

          LocalStorage.reset(false);
          LocalStorage.remove('custom_tabs', false);
          LocalStorage.set('account_info', newState, undefined);
          LocalStorage.set('recent_activity', oldActivity, undefined);
          const alphaEnabled = !!_.get(
            getState().session,
            'subscription_limits.add_ons.alpha_enabled'
          );

          // NOTE: Analytics.identify relies on both initFlags and initSegment, so we need to
          // call it after the promise
          await Promise.all([
            initFlags(config.FLAGSMITH_ID),
            actions.initSegment({ parentAccountIds, officeDetails })
          ]);

          Analytics.identify({
            userId: userProfile.id,
            properties: {
              user: userProfile,
              office: officeDetails,
              alphaEnabled: alphaEnabled,
              parentAccountIds
            },
            options: {
              Intercom: {
                hideDefaultLauncher: !hasIntercomChat(
                  parentAccountIds,
                  officeDetails,
                  subscriptions
                )
              }
            }
          });

          checkFeatureFlags();

          // Some of the websocket channels depend on the user id, so when switching
          // accounts we want to fetch the new channels. Doing it during the refresh
          // seems like the safest way
          await actions.initWebsockets();

          // Wait a tick before resolving
          // Without this the layout seems to be re-rendering before all information
          // is in the correct place?!
          return new Promise((resolve) =>
            setTimeout(() => {
              dispatch(uiModel.actionCreators.loadingIndicatorOff());
              resolve(newState);
            }, 0)
          );
        }
      );
    },
    reduce: {
      initial: _.identity,
      success: (state, action) => ({
        ...state,
        ...action.payload
      }),
      failure: _.identity
    }
  },

  getQuickStats: {
    request: (payload, actions, dispatch, getState) => {
      const region = _.get(getState(), 'session.office_details.locale.code');
      const accountHasWorkflows =
        _.get(getState(), 'session.subscription_limits.add_ons.workflows') ===
        '1';

      return api
        .post('QuickStats::getStats', {
          required_stats: [
            { stat_id: 'leads' },
            region === 'uk' && accountHasWorkflows && { stat_id: 'workflows' }
          ].filter(Boolean)
        })
        .then(({ data }) => data.result);
    },
    reduce: {
      initial: _.identity,
      success: (state, action) => {
        return {
          ...state,
          leadNotifications: action.payload?.leads
        };
      },
      failure: _.identity
    }
  },

  pushRecentItem: {
    reduce: (state, action) => {
      RecentActivity.pushRecentItem(action.payload);
      return {
        ...state,
        recent_activity: RecentActivity.getRecentActivity()
      };
    }
  },

  setCalendarView: {
    reduce: (state, action) => {
      const newCalendar = { ...state.calendar, view: action.payload };
      LocalStorage.set('calendar', newCalendar, true);
      return {
        ...state,
        calendar: newCalendar
      };
    }
  },

  setCalendarRange: {
    reduce: (state, action) => {
      const newCalendar = {
        ...state.calendar,
        range: { ...action.payload, changed: new Date().getTime() }
      };
      LocalStorage.set('calendar', newCalendar, undefined);
      return {
        ...state,
        calendar: newCalendar
      };
    }
  },

  setCalendarDate: {
    reduce: (state, action) => {
      const newCalendar = {
        ...state.calendar,
        date: { value: action.payload, changed: new Date().getTime() }
      };
      LocalStorage.set('calendar', newCalendar, undefined);
      return {
        ...state,
        calendar: newCalendar
      };
    }
  },

  logout: {
    request: async (
      {
        shouldLogout = true,
        silent = false,
        clearRemoteToken = false
      }: {
        shouldLogout?: boolean;
        clearRemoteToken?: boolean;
        silent?: boolean;
      } = {},
      actions,
      dispatch
    ) => {
      if (clearRemoteToken) {
        await api.post('Authentication::logout');
      }

      LocalStorage.reset(false);
      LocalStorage.set('api_token', undefined, undefined);
      LocalStorage.set('api_region', undefined, undefined);

      dispatch(authModel.actionCreators.clear());

      if (!silent) {
        window.location.href = getRedirectToAuthService(
          'rex_crm',
          shouldLogout
        ).redirectUrl;
      }
    },
    reduce: {
      initial: _.identity,
      success: _.identity,
      failure: _.identity
    }
  },

  updateThirdPartyIntegrations: {
    request: async (payload, actions, dispatch) => {
      const connections = await dispatch(
        apiCacheModel.actionCreators.fetch({
          method: 'ThirdPartyServices::getConnections',
          args: {
            include_inherited: true,
            include_definition: true
          },
          force: true
        })
      );

      return {
        third_party_extensions: connections
      };
    },
    reduce: {
      initial: _.identity,
      success: (state, action) => {
        const newState = { ...state, ...action.payload };
        LocalStorage.set('account_info', newState, undefined);
        return newState;
      },
      failure: _.identity
    }
  },

  updateFavouriteCustomReports: {
    request: async () => {
      return api.post('CustomReports::search', {
        criteria: [{ name: 'is_favourite', type: '=', value: true }],
        limit: 30
      });
    },
    reduce: {
      initial: _.identity,
      success: (state, action) => {
        const newReportState = (
          action?.payload?.data?.result?.rows || []
        ).sort((a, b) => a.name.localeCompare(b.name));

        return {
          ...state,
          favouriteCustomReports: newReportState
        };
      },
      failure: _.identity
    }
  }
};

const selectors = {
  apiToken: (state) => _.get(state, 'session.api_token'),
  ready: (state) => _.get(state, 'session.ready'),
  locationCoords: (state) => _.get(state, 'session.locationCoords'),
  leadNotifications: (state) => _.get(state, 'session.leadNotifications'),
  checkUserHasPermission: (state) => (accessRights) =>
    checkUserHasPermission((dotNotation) =>
      _.get(state, `session.${dotNotation}`)
    )(accessRights),
  recentActivity: (state) => _.get(state, 'session.recent_activity'),
  accessibleAccounts: (state) => _.get(state, 'session.accessible_accounts'),
  isRosieAccount: (state) => _.get(state, 'session.isRosieAccount'),

  // HACK: make every key from the initial state available
  ...Object.keys(initialState).reduce((all, key) => {
    all[key] = (state) => _.get(state, `session.${key}`);
    return all;
  }, {})
};

export default new Generator<SessionModel, typeof actionCreators>(
  'session'
).createModel({
  initialState,
  actionCreators,
  selectors
});
