/* eslint-disable max-lines */
import React, { useEffect, useRef, useState } from 'react';
import Box from '@rexlabs/box';
import { COLORS, PADDINGS } from 'theme';
import { TextGenerationFormBottomBar } from 'features/ai/components/text-generation/text-generation-form-bottom-bar';
import { apiStreamRequest } from 'features/ai/utils/api-stream-request';
import { RefinementType } from 'features/ai/data/refinement-type';
import { StyleSheet, useStyles } from '@rexlabs/styling';
import { newlinesToHtml } from 'features/ai/utils/html';
import { AiButton } from 'features/ai/components/primitives/ai-button';
import { TextGenerationListingDetails } from 'features/ai/components/text-generation/text-generation-listing-details';
import {
  getCompletionHtml,
  getCompletionText,
  printCompletion,
  setCompletionHtml,
  TextGenerationFormCompletion
} from 'features/ai/components/text-generation/text-generation-form-completion';
import { useTextGenerationFormState } from 'features/ai/hooks/use-text-generation-form-state';
import { TextGenerationCommunicationInputs } from 'features/ai/components/text-generation/communication/text-generation-communication-inputs';
import Analytics from 'shared/utils/vivid-analytics';
import { EVENTS } from 'shared/utils/analytics/index';
import { suggestedRefinements } from 'features/ai/components/refinement-popout';
import { AIFailureError } from 'features/ai/errors/ai-failure-error';

export enum TextGenerationFormType {
  AdText = 'ad-text',
  Email = 'email',
  SMS = 'sms'
}

const endpointMap = {
  [TextGenerationFormType.AdText]: 'AiCompletions::completeAdText',
  [TextGenerationFormType.Email]: 'AiCompletions::completeCommunication',
  [TextGenerationFormType.SMS]: 'AiCompletions::completeCommunication'
};

const textGenerationStyles = StyleSheet({
  container: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    height: 500,
    padding: '20px',
    borderRadius: '6px',
    background:
      'linear-gradient(85.67deg, rgba(232, 238, 242, 0.99) 9.24%, rgba(255, 255, 255, 0.3) 112.54%)',
    backgroundBlendMode: 'overlay'
  },
  editorContainer: {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    gap: '10px',
    flex: 1
  },
  disclaimer: {
    fontSize: '12px',
    lineHeight: '14px',
    color: COLORS.GREY_MEDIUM,
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 92,
    textAlign: 'center'
  },
  disclaimerLink: {
    fontWeight: 600,
    textDecoration: 'underline',
    cursor: 'pointer'
  },
  canHide: {
    transition: 'opacity 0.2s ease-out',
    opacity: 1,
    pointerEvents: 'auto'
  },
  hidden: {
    opacity: 0,
    pointerEvents: 'none'
  }
});

export default function TextGenerationForm({
  listingId,
  onInsert,
  closeDialogSuccess,
  closeDialogCancel,
  type,
  initialRefinement,
  initialData
}: {
  listingId: string;
  onInsert: (args: {
    headline?: string;
    subject?: string;
    body?: string;
  }) => void;
  closeDialogSuccess: () => void;
  closeDialogCancel: () => void;
  type: TextGenerationFormType;
  initialRefinement?: RefinementType;
  initialData?: {
    headline?: string;
    subject?: string;
    body?: string;
  };
}) {
  const s = useStyles(textGenerationStyles);
  const [isCompletionFinished, setIsCompletionFinished] = useState(
    !!initialData?.body
  );
  const completionRef = useRef<HTMLSpanElement>(null);
  const [outputs, setOutputs] = useState<string[]>(
    initialData?.headline && initialData?.body
      ? [`"${initialData?.headline}"\n\n${initialData?.body}`]
      : []
  );
  const [refinementsCount, setRefinementsCount] = useState(0);
  const [errorsCount, setErrorsCount] = useState(0);
  const [body, setBody] = useState(initialData?.body || '');
  const [formValues, setFormValues] = useTextGenerationFormState(
    type === TextGenerationFormType.AdText
      ? {
          headline: initialData?.headline || ''
        }
      : { subject: initialData?.subject || '', topic: '', call_to_action: '' }
  );
  const [isFormValid, setIsFormValid] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);
  const [audience, setAudience] = useState<RefinementType | null>(null);
  const [tone, setTone] = useState<RefinementType | null>(null);
  const [isListingDetailsOpen, setIsListingDetailsOpen] = useState(false);

  const shouldGenerateInitialText = useRef(
    (!initialData?.body || !!initialRefinement) &&
      type === TextGenerationFormType.AdText
  );
  useEffect(() => {
    if (shouldGenerateInitialText.current) {
      shouldGenerateInitialText.current = false;
      onSubmit({
        generateFresh: !initialRefinement,
        refinementType: initialRefinement
      });
    }
  }, [onSubmit, initialRefinement]);

  function onRefine(refinementType: RefinementType) {
    if (refinementType.startsWith('audience_')) {
      Analytics.track({
        event: EVENTS.AI_CONTENT.AI_CONTENT_AUDIENCE_ADJUSTED,
        properties: {
          contentType: type,
          audience: refinementType
        }
      });
      setAudience(refinementType);
    } else if (refinementType.startsWith('tone_')) {
      Analytics.track({
        event: EVENTS.AI_CONTENT.AI_CONTENT_TONE_ADJUSTED,
        properties: {
          contentType: type,
          tone: refinementType
        }
      });
      setTone(refinementType);
    } else if (refinementType === RefinementType.SHORTER) {
      Analytics.track({
        event: EVENTS.AI_CONTENT.AI_CONTENT_SHORTER,
        properties: {
          contentType: type
        }
      });
    } else if (refinementType === RefinementType.LONGER) {
      Analytics.track({
        event: EVENTS.AI_CONTENT.AI_CONTENT_LONGER,
        properties: {
          contentType: type
        }
      });
    }
    setRefinementsCount((prev) => prev + 1);
    onSubmit({ refinementType });
  }

  function onCustomRefine(extraGuidance: string) {
    const suggestedRefinement = suggestedRefinements.find((r) =>
      extraGuidance.includes(r)
    );

    Analytics.track({
      event: EVENTS.AI_CONTENT.AI_CONTENT_CUSTOM_REFINEMENT,
      properties: {
        contentType: type,
        ...(suggestedRefinement
          ? { suggestedRefinement }
          : { customRefinementText: extraGuidance })
      }
    });
    setRefinementsCount((prev) => prev + 1);
    onSubmit({ extraGuidance });
  }

  function onSubmit({
    extraGuidance,
    refinementType,
    generateFresh = false
  }: {
    extraGuidance?: string;
    refinementType?: RefinementType;
    generateFresh?: boolean;
  }) {
    const oldInnerHTML = getCompletionHtml(completionRef);

    setCompletionHtml(completionRef, '');

    setIsLoading(true);
    setError(null);
    setIsCompletionFinished(false);

    if (generateFresh) {
      setAudience(null);
      setTone(null);
      setOutputs([]);
    }

    let rawOutputBuffer = '';

    apiStreamRequest(
      endpointMap[type],
      {
        extra_guidance:
          generateFresh && initialData?.headline
            ? `Use this headline: "${initialData?.headline}". ${
                extraGuidance || ''
              }`
            : extraGuidance,
        // Note that currently the backend only supports a single previous output,
        // so we don't pass the full array of outputs to the backend.
        previous_outputs: generateFresh ? [] : outputs.slice(-1),
        refinement_type: refinementType,
        listing_id: listingId,
        ...(type !== TextGenerationFormType.AdText
          ? {
              medium: type,
              ...formValues
            }
          : {})
      },
      (chunk) => {
        if (!completionRef.current) return;

        if (chunk === 'ERROR') {
          throw new AIFailureError();
        }

        rawOutputBuffer += chunk;

        printCompletion({ chunk: chunk, type, completionRef });
      },
      () => {
        setIsCompletionFinished(true);
        setIsLoading(false);
        setOutputs((o) => [...o, rawOutputBuffer]);
        rawOutputBuffer = '';
        const innerText = getCompletionText(completionRef);

        const body =
          type === TextGenerationFormType.AdText
            ? innerText.split('\n\nBody\n')[1]
            : innerText;
        setBody(body);

        if (type === TextGenerationFormType.AdText) {
          const headline = innerText.split('Headline\n')[1].split('\n\n')[0];
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          setFormValues({ headline });
        }
      },
      (error) => {
        setErrorsCount((prev) => prev + 1);
        console.error(error);
        if (type === TextGenerationFormType.AdText) {
          Analytics.track({
            event: EVENTS.AI_AD_TEXT.AI_AD_TEXT_FAILED,
            properties: {
              headline: formValues.headline
            }
          });
        } else {
          Analytics.track({
            event: EVENTS.AI_COMMUNICATIONS.AI_COMMUNICATIONS_FAILED,
            properties: {
              communicationType: type,
              subject: formValues.subject,
              topic: formValues.topic,
              callToAction: formValues.call_to_action
            }
          });
        }

        setCompletionHtml(completionRef, oldInnerHTML);
        setIsLoading(false);
        setError(error);
        setIsCompletionFinished(true);
      }
    );
  }

  return (
    <>
      <Box pr={PADDINGS.M} pl={PADDINGS.M} pb={PADDINGS.M} pt={PADDINGS.XXS}>
        {type !== TextGenerationFormType.AdText ? (
          <TextGenerationCommunicationInputs
            onSubmit={onSubmit}
            isLoading={isLoading}
            formValues={formValues}
            setFormValues={setFormValues}
            setIsFormValid={setIsFormValid}
          />
        ) : null}
        <div {...s('container')}>
          {listingId ? (
            <TextGenerationListingDetails
              listingId={listingId}
              isOpen={isListingDetailsOpen}
              setIsOpen={setIsListingDetailsOpen}
            />
          ) : null}
          <div
            {...s('editorContainer')}
            style={{ visibility: isListingDetailsOpen ? 'hidden' : 'visible' }}
          >
            <TextGenerationFormCompletion
              isLoading={isLoading}
              error={error}
              headline={formValues.headline}
              body={body}
              initialData={initialData}
              completionRef={completionRef}
              outputs={outputs}
              type={type}
              setError={setError}
            />
            <div
              {...s('canHide', 'disclaimer', {
                hidden: !isCompletionFinished || !!error
              })}
            >
              AI generated text can contain mistakes. Consider checking
              important information.{' '}
              {listingId ? (
                <span
                  {...s('disclaimerLink')}
                  onClick={() => setIsListingDetailsOpen(true)}
                >
                  Learn More
                </span>
              ) : null}
            </div>
            <div
              {...s('canHide', {
                hidden:
                  type !== TextGenerationFormType.AdText
                    ? !isCompletionFinished || !!error
                    : !!error
              })}
            >
              <TextGenerationFormBottomBar
                tone={tone}
                audience={audience}
                onBuiltInRefinement={onRefine}
                onCustomRefinement={onCustomRefine}
                isDisabled={!isCompletionFinished}
              />
            </div>
          </div>
        </div>
      </Box>
      <Box
        p={PADDINGS.M}
        flexDirection={'row'}
        justifyContent={'space-between'}
        style={{ gap: '10px', borderTop: '1px solid rgba(255,255,255,0.4)' }}
        {...s('canHide', {
          hidden:
            type !== TextGenerationFormType.AdText
              ? !isCompletionFinished || !!error
              : !!error || isListingDetailsOpen
        })}
      >
        <AiButton
          disabled={
            !isCompletionFinished ||
            (type !== TextGenerationFormType.AdText && !isFormValid)
          }
          onClick={() => {
            Analytics.track({
              event: EVENTS.AI_CONTENT.AI_CONTENT_GENERATE_NEW,
              properties: {
                errorsCount,
                refinementsCount
              }
            });
            onSubmit({ generateFresh: true });
          }}
        >
          Clear & Generate New
        </AiButton>
        <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
          <AiButton variant={'ghost'} onClick={() => closeDialogCancel()}>
            Cancel
          </AiButton>
          <AiButton
            disabled={
              !isCompletionFinished ||
              (type !== TextGenerationFormType.AdText && !isFormValid)
            }
            onClick={() => {
              Analytics.track({
                event:
                  type === TextGenerationFormType.AdText
                    ? EVENTS.AI_AD_TEXT.AI_AD_TEXT_INSERTED
                    : EVENTS.AI_COMMUNICATIONS.AI_COMMUNICATIONS_INSERTED,
                properties: {
                  refinementsCount,
                  errorsCount,
                  ...(type !== TextGenerationFormType.AdText
                    ? {
                        subject: formValues.subject,
                        topic: formValues.topic,
                        callToAction: formValues.call_to_action,
                        communicationType: type
                      }
                    : {})
                }
              });
              onInsert({
                headline: formValues.headline,
                subject: formValues.subject,
                body:
                  type === TextGenerationFormType.Email
                    ? newlinesToHtml(body)
                    : body
              });
              closeDialogSuccess();
            }}
          >
            Insert
          </AiButton>
        </Box>
      </Box>
    </>
  );
}
