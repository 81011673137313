import { gql } from 'hooks/use-graphql-query';
import {
  User,
  Appraisal,
  Property,
  CustomFields
} from 'features/custom-reporting/data/fragments';
import {
  Contact,
  ContactEmails,
  ContactPhones,
  MatchProfile
} from 'features/custom-reporting/modules/contacts/contact-data-fragments';

export const appraisalReportQueryDefaultVariables = {
  include_subresource_property: false,
  include_subresource_record_owner: false,
  include_subresource_property_owner: false,
  include_property_owner_custom_fields: false,
  include_property_custom_fields: false,
  property_owner_custom_field_ids: [],
  property_custom_field_ids: []
};

export const AppraisalReportQuery = gql`
  ${User}
  ${Appraisal}
  ${Property}
  ${Contact}
  ${ContactPhones}
  ${ContactEmails}
  ${MatchProfile}
  ${CustomFields}

  query(
    $criteria: [Criterion!]
    $order_bys: [OrderBy!]
    $limit: Int!
    $cursor: String
    $include_subresource_property: Boolean!
    $include_subresource_record_owner: Boolean!
    $include_subresource_property_owner: Boolean!
    $include_property_custom_fields: Boolean!
    $property_custom_field_ids: [Int!]!
    $property_owner_custom_field_ids: [Int!]!
    $include_property_owner_custom_fields: Boolean!
  ) {
    rowData: appraisals(
      limit: $limit
      criteria: $criteria
      use_case: reporting
      order_bys: $order_bys
      cursor: $cursor
    ) {
      pagination {
        next_page {
          cursor
        }
      }
      rows {
        property @include(if: $include_subresource_property) {
          ...Property
          custom_field_values(field_ids: $property_custom_field_ids)
            @include(if: $include_property_custom_fields) {
            ...CustomFields
          }

          system_owner_user @include(if: $include_subresource_record_owner) {
            ...User
          }
          owner: contact_relationships(limit: 1, type: "owner")
            @include(if: $include_subresource_property_owner) {
            relationship_type {
              id
            }
            contact {
              ...Contact
              custom_field_values(field_ids: $property_owner_custom_field_ids)
                @include(if: $include_property_owner_custom_fields) {
                ...CustomFields
              }
              phones {
                ...ContactPhones
              }
              emails {
                ...ContactEmails
              }
              system_owner_user {
                ...User
              }
              latest_match_profile {
                ...MatchProfile
              }
            }
          }
        }
        ...Appraisal
        agent_1 {
          ...User
        }
        agent_2 {
          ...User
        }
      }
    }
  }
`;
