/* eslint-disable max-lines */
import React from 'react';
import _ from 'lodash';
import { Id, ModelGeneratorLoaded } from '@rexlabs/model-generator';

import { api } from 'shared/utils/api-client';
import { Generator } from 'shared/utils/models';
import Value from 'view/components/input/select/values';
import ListingsOption from 'view/components/input/select/options/listings';
import { ContactAutocomplete } from './contacts';
import { UserItem } from 'data/models/value-lists/account-users';
import { ProjectStageStub } from './project-stages';
import { PropertyStub } from './properties';

export type ListingModel = ModelGeneratorLoaded<ListingItem, any>;

export type ListingCategory = {
  id: string;
  text: string;
};

type SystemPublicationStatus = string;

interface BaseListingItem {
  id: string;
  security_user_rights: string[];
  system_owner_user: UserItem;
}

export interface ListingSelectStub extends BaseListingItem {
  address: string;
  category?: ListingCategory;
  image?: string;
  status?: SystemPublicationStatus;
}

interface ThumbnailObject {
  uri: string;
  url: string;
}
interface Thumbnails {
  '80x60': ThumbnailObject;
  '200x150': ThumbnailObject;
  '400x300': ThumbnailObject;
  '800x600': ThumbnailObject;
}

interface ListingImageItem {
  inbound_index: null;
  inbound_last_update: null;
  inbound_original_src_url: null;
  priority: string | number;
  system_modtime: number;
  uri: string;
  id: Id;
  thumbs: Thumbnails;
  url: string;
}

// Currently incomplete types to work with basic withModel usage
// The actual record types will need to be filled in
// CH: https://app.clubhouse.io/rexlabs/story/54959/listings-model
export interface ListingStub extends BaseListingItem {
  authority_date_expires: string;
  authority_date_start: string;
  authority_duration_days: number;
  authority_type: { text: string; id: string };
  available_from_date: string | null;
  baseline_price: any;
  comm_amount_fixed: number;
  comm_amount_model: any;
  comm_amount_percentage: any;
  comm_base_amount_override: number | null;
  comm_est_amount_inc_tax: number | null;
  comm_est_amount_net_of_tax: number | null;
  comm_est_based_on_amount: string | null;
  comm_est_based_on_object_id: string | null;
  comm_est_based_on_service: string;
  comm_is_inc_tax: any;
  comm_structure: any;
  epc_combined_chart?: { thumbs: string[]; uri: string; url: string };
  epc_current_eer: number | null;
  epc_current_eir: number | null;
  epc_eer_chart: any;
  epc_eir_chart: any;
  epc_expiry_date: string | null;
  epc_lodgement_date: string | null;
  epc_not_required: number | null;
  epc_not_required_reason: string | null;
  epc_potential_eer: number | null;
  epc_potential_eir: number | null;
  etag: string;
  exclusivity: any | null;
  feedback_notes: string;
  feedback_offer_level: number;
  feedback_price_rank: number;
  image_cycling_delay_in_hours: any;
  image_cycling_delayed_until: any;
  inbound_last_update: any;
  inbound_unique_id: any;
  inspection_alarm_code: string;
  inspection_notes: string;
  inspection_type: any;
  is_multiple: any;
  legal_prop_address: any;
  legal_prop_lot: any;
  legal_prop_subdivision: any;
  legal_prop_titleref: any;
  legal_prop_description: string | null;
  legal_solicitor: ContactAutocomplete;
  legal_solicitor_contact: ContactAutocomplete;
  legal_vendor_name: any;
  legal_vendor_residence: any;
  let_agreed: any;
  listing_agent_1: UserItem;
  listing_agent_2: UserItem | null;
  listing_category: ListingCategory;
  location: { id: string; text: string };
  meta_highlight_1: any;
  meta_highlight_2: any;
  meta_highlight_3: any;
  meta_other_features: any;
  new_home: any;
  outgoings_annual: any;
  outgoings_rent_is_plus: any;
  parent_listing_id: string | null;
  price_advertise_as: string;
  price_bond: number | null;
  price_est_rent_pw: number | null;
  price_match: number | null;
  price_match_max: number | null;
  price_match_max_sale: number | null;
  price_match_rent_max_pa_inc_tax: number | null;
  price_match_rent_pa_inc_tax: number | null;
  price_match_sale: number | null;
  price_rent: number | null;
  price_rent_max: number | null;
  price_rent_max_per_m2: number | null;
  price_rent_per_m2: number | null;
  price_rent_period: number | null;
  price_rent_tax: number | null;
  project_stage: ProjectStageStub;
  property: PropertyStub;
  publish_to_automatch: boolean | null;
  publish_to_external: boolean | null;
  publish_to_general: boolean | null;
  publish_to_portals: boolean | null;
  related: {
    listing_subcategories: {
      priority: string;
      id: string;
      subcategory: { id: string; text: 'string' };
    };
    contact_reln_listing?: {
      reln_type: { id: string };
      contact: ContactAutocomplete;
    }[];
    listing_images: ListingImageItem[];
    property_features: { feature: { id: string; text: string } }[];
    property_views: { view: { id: string; text: string } }[];
    listing_idealfors: { idealfor: { id: string; text: string } }[];
  };
  _related: {
    listing_subcategories: {
      priority: string;
      id: string;
      subcategory: { id: string; text: 'string' };
    };
    contact_reln_listing?: {
      reln_type: { id: string };
      contact: ContactAutocomplete;
    }[];
    listing_images: ListingImageItem[];
  };
  state_change_timestamp: any;
  state_date: string;
  state_lost_agency_id: any;
  state_reason_id: any;
  state_reason_note: string | null;
  state_value_deposit: any;
  state_value_price: number | null;
  status_is_not_for_sale: any;
  system_created_user: UserItem;
  system_ctime: number;
  system_has_preupload_errors: number | null;
  system_listing_state: string;
  system_modified_user: UserItem | null;
  system_modtime: number;
  system_overpayment_balance: number;
  system_publication_status: SystemPublicationStatus;
  system_publication_time: number;
  system_publication_user_id: string;
  tenancy_type: { text: string; id: string };
  _id: string;
}

interface ImageLocation {
  uri: string;
  url: string;
}

interface ListingDetails {
  listing_primary_image: ImageLocation & {
    thumbs: {
      ['800x600']: ImageLocation;
      ['400x300']: ImageLocation;
      ['200x150']: ImageLocation;
      ['80x60']: ImageLocation;
    };
  };
  under_contract: boolean;
  hold_status: any;
  contract_status: any;
  project_listing_status: any;
}

export type ListingItem = ListingStub & ListingDetails;

const TYPE = 'listings';

const actionCreators = {
  trashEpcData: {
    request: (payload, model) =>
      model.updateItem({
        args: {
          epc_not_required: null,
          epc_not_required_reason: null,
          epc_current_eer: null,
          epc_current_eir: null,
          epc_potential_eer: null,
          epc_potential_eir: null,
          epc_lodgement_date: null,
          epc_expiry_date: null,
          id: payload.id
        },
        id: payload.id
      }),
    reduce: {
      initial: _.identity,
      success: _.identity,
      failure: _.identity
    }
  },
  updatePremiumListing: {
    request: ({ id, data }) =>
      api.post('ListingPortalUploads::updatePortals', { listing_id: id, data }),
    reduce: {
      intial: _.identity,
      success: _.identity,
      failure: _.identity
    }
  },
  getPortalStatuses: {
    request: ({ id }) =>
      api.post('ListingPortalUploads::getStatuses', { listing_id: id }),
    reduce: {
      intial: (state) => {
        return {
          ...state,
          lists: {
            ...state.lists,
            status: 'loading'
          }
        };
      },
      success: (state) => {
        return {
          ...state,
          lists: {
            ...state.lists,
            status: 'loaded'
          }
        };
      },
      failure: (state) => {
        return {
          ...state,
          lists: {
            ...state.lists,
            status: 'error'
          }
        };
      }
    }
  }
};

const listingsModel = new Generator<ListingItem, typeof actionCreators>(
  TYPE
).createEntityModel({ actionCreators });

// TEMPORARY SOLUTION
// For now we will define select specific behaviour in the models, to control
// all EntitySelects that use this model

// TODO: work out how to add select to model so TS stops yelling
// https://app.shortcut.com/rexlabs/story/64672/select-object-on-entity-models-causing-issues-with-types
// eslint-disable-next-line
// @ts-ignore
listingsModel.select = {
  resultsToSelectOptions: (results) =>
    (results || []).map((listing) => ({
      value: listing.id,
      label: listing.property.system_search_key,
      data: listing,
      model: listingsModel
    })),
  autocomplete: (searchTerm, { states }) =>
    api
      .post('Listings::autocomplete', {
        search_string: searchTerm,
        listing_states: states || ['current', 'withdrawn', 'sold', 'leased']
      })
      .then(({ data }) =>
        (_.get(data, 'result') || []).map((listing) => ({
          value: listing.id,
          label: listing.address,
          data: listing,
          model: listingsModel
        }))
      ),
  Option: ListingsOption,
  Value: (props) => <Value {...props} service={TYPE} />,
  // TODO: Remove once we delete the old shell popout.
  getPopoutData: (id) => api.post('Listings::read', { id })
};

export default listingsModel;
