import React, { useMemo } from 'react';

import Box from '@rexlabs/box';
import { Id } from '@rexlabs/model-generator';
import { PLACEMENTS } from '@rexlabs/tooltip';

import { Select } from 'view/components/input/select';
import { SelectItem } from 'data/models/value-lists/account-users';
import { Form, FormField } from 'view/components/form';
import { Popout } from 'view/components/popout';
import PaddingBox from 'view/components/padding-box';
import { Link } from 'components/text/link';

import { SaveCancelButtonBar } from 'view/components/button-bar';
import { PADDINGS } from 'src/theme';
import { SetFieldValue } from '@rexlabs/form';

interface FormContentsProps {
  allUsers: SelectItem[];
  isSubmitting: boolean;
  setFieldValue: SetFieldValue<any>;
  submitForm: (event: any) => Promise<void>;
  currentUser: Id;
  initialValue?: Id;
  hasUser: boolean;
}

interface PopoutContentProps {
  close: () => void;
}

export function FormContents({
  submitForm,
  setFieldValue,
  isSubmitting,
  currentUser,
  allUsers,
  hasUser,
  initialValue
}: FormContentsProps) {
  // We have done this because react doesn't like Contents being unstable and
  // It goes ahead and rerenders the popout prop. This sucks because this causes the
  // form field to register itself again which causes an infinite loop.
  // We probably need a better way to pass the contents into popout
  // maybe if the popout memoized its content itself?
  const contents = useMemo(
    () => ({ close }: PopoutContentProps) => {
      return (
        <PaddingBox width='340px'>
          <FormField
            name='assignee_user_id'
            label='assignee'
            initialValue={initialValue}
            Input={Select}
            inputProps={{
              options: allUsers,
              withTags: true,
              isSearchable: true
            }}
          />
          <Box mt={PADDINGS.XS}>
            <Link
              light
              onClick={() => setFieldValue('assignee_user_id', currentUser)}
            >
              Assign to me
            </Link>
          </Box>

          <SaveCancelButtonBar
            isLoading={isSubmitting}
            onCancel={close}
            onSave={async (event: any) => {
              await submitForm(event);

              close();
            }}
            isDisabled={false}
          />
        </PaddingBox>
      );
    },
    [
      initialValue,
      allUsers,
      isSubmitting,
      setFieldValue,
      currentUser,
      submitForm
    ]
  );
  return (
    <Form>
      <Popout placement={PLACEMENTS.BOTTOM_START} Content={contents}>
        <Link regular>{hasUser ? 'Re-assign' : 'Assign'}</Link>
      </Popout>
    </Form>
  );
}
