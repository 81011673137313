import invariant from 'invariant';
import React, { PureComponent } from 'react';
import types from 'prop-types';
import { styled, StyleSheet } from '@rexlabs/styling';

import TabBarDefault from './tab-bar';

const defaultStyles = StyleSheet({
  container: {
    width: '100%'
  },

  tabHidden: {
    display: 'none'
  },

  tabContent: {
    width: '100%',
    position: 'relative',
    zIndex: '100'
  },

  topBorder: {
    borderTop: '2px solid #b4b1a9'
  }
});

@styled(defaultStyles)
class Tabs extends PureComponent {
  prevIndex = null;

  static displayName = 'Tabs';

  static propTypes = {
    children: types.func,
    items: types.arrayOf(
      types.shape({
        label: types.oneOfType([types.string, types.node]).isRequired,
        name: types.string.isRequired,
        Tab: types.oneOfType([types.element, types.func])
      })
    ).isRequired,
    activeTab: types.string,
    onChange: types.func,
    TabBar: types.oneOfType([types.element, types.func]),
    alwaysRenderAll: types.bool
  };

  static defaultProps = {
    TabBar: TabBarDefault
  };

  componentWillReceiveProps(nextProps) {
    if (this.props.activeTab !== nextProps.activeTab) {
      this.prevIndex = this.props.items.findIndex(
        (i) => i.name === this.props.activeTab
      );
    }
  }

  render() {
    const {
      children,
      items,
      activeTab,
      onChange,
      TabBar,
      styles: s,
      alwaysRenderAll,
      tabBarProps,
      nested,
      withBottomBar,
      light,
      withContentBorder, // This helps create the pattern where a dark line appears under the tab items
      ...rest
    } = this.props;

    // Some invariant validations of props with meaningful error messages
    invariant(
      activeTab !== undefined,
      "You have to define an 'activeTab' prop on Tabs.\n\n" +
        "If you don't want to deal with the tab state handling, " +
        "you can use 'TabStateful' instead!"
    );

    invariant(
      onChange !== undefined,
      "You have to define an 'onChange' hanlder on Tabs.\n\n" +
        "If you don't want to deal with the tab state handling, " +
        "you can use 'TabStateful' instead!"
    );

    items.forEach((item) => {
      invariant(
        item.name,
        'You have to define a unique name for each tab item!'
      );
    });

    const tabs = items.map(({ name, Tab = () => null }, index) => {
      const isActive = name === activeTab || (!activeTab && index === 0);
      const shouldBeRendered = alwaysRenderAll || isActive;
      return shouldBeRendered ? (
        <div
          {...s('tab', { tabHidden: !isActive })}
          key={name}
          role='tabpanel'
          aria-hidden={false}
        >
          <Tab index={index} {...rest} />
        </div>
      ) : null;
    });

    const info = {
      tabs,
      prevIndex: this.prevIndex
    };

    return (
      <div {...s.with('container')(this.props)} {...rest}>
        <TabBar
          {...tabBarProps}
          activeTab={activeTab}
          onChange={onChange}
          items={items}
          withBottomBar={withBottomBar}
          light={light}
          nested={nested}
        />
        <div
          {...s('tabContent', {
            topBorder: nested || withContentBorder
          })}
        >
          {children ? children(info) : tabs}
        </div>
      </div>
    );
  }
}

export default Tabs;
