import { persistReducer } from 'redux-persist';
import localForage from 'localforage';

import { Generator } from 'shared/utils/models';
import LocalStorage from 'shared/utils/local-storage';

import { SelectItem } from 'types/select';

import { DateRangeSelectValue } from 'features/custom-reporting/utils/date-ranges';

type AccountId = string;

export interface DateRangeItem {
  range?: [string, string];
  preset?: DateRangeSelectValue;
}

interface ModelState {
  [key: string]: {
    users: SelectItem[];
    location?: SelectItem[];
  };
}

const modelName = 'reportingFilters';

const initialFilters = {
  users: [],
  location: []
};

const actionCreators = {
  refresh: {
    reduce: (state) => {
      const accountId: AccountId = LocalStorage.get('account_info')
        ?.office_details?.id;
      return {
        ...state,
        [accountId]: initialFilters
      };
    }
  },
  update: {
    reduce: (state: ModelState, action: { payload: Partial<ModelState> }) => {
      const { payload } = action;
      const accountId: AccountId = LocalStorage.get('account_info')
        ?.office_details?.id;

      return {
        ...state,
        [accountId]: {
          ...state[accountId],
          ...payload
        }
      };
    }
  },
  persistUserFilter: {
    reduce: (state: ModelState, action: { payload: SelectItem[] }) => {
      const { payload } = action;
      const accountId: AccountId = LocalStorage.get('account_info')
        ?.office_details?.id;

      return {
        ...state,
        [accountId]: {
          ...state[accountId],
          users: payload
        }
      };
    }
  },
  persistLocationFilter: {
    reduce: (state: ModelState, action: { payload?: SelectItem[] }) => {
      const { payload } = action;
      const accountId: AccountId = LocalStorage.get('account_info')
        ?.office_details?.id;
      return {
        ...state,
        [accountId]: {
          ...state[accountId],
          location: payload
        }
      };
    }
  }
};

export const reportingFiltersModel = new Generator<
  ModelState,
  typeof actionCreators
>(modelName).createModel({
  actionCreators
});

export const persistReportingFilters = persistReducer(
  {
    key: 'rex.reportingFilter',
    storage: localForage
  },
  reportingFiltersModel
);
