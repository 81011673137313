import { StyleSheet, useStyles } from '@rexlabs/styling';
import { AiButton } from 'features/ai/components/primitives/ai-button';
import React from 'react';
import { Body } from 'components/text/body';
import BetaTag from 'components/beta-tag';
import { hasFeatureFlags } from 'shared/utils/has-feature-flags';
import { usePermissions } from 'hooks/use-permissions';

const styles = StyleSheet({
  container: {
    background:
      'linear-gradient(45.92deg, #5FBEEC33 0%, #96AFEF33 68.34%, #B455FF33 138.83%)',
    borderRadius: '6px',
    padding: '20px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '15px'
  },
  text: {
    display: 'flex',
    flexDirection: 'row',
    gap: '10px',
    alignItems: 'center'
  }
});

export function AiFiltersPromoBanner() {
  const s = useStyles(styles);
  const permissions = usePermissions();

  if (
    !permissions.hasAddon('ai_features') ||
    !hasFeatureFlags('initial_ai_features')
  ) {
    return null;
  }

  function onClick() {
    const customEvent = new CustomEvent('click', {
      bubbles: true,
      detail: {
        isFromPromoBanner: true
      }
    });

    const classicFrame = document.querySelector<HTMLIFrameElement>('#AppFrame')
      ?.contentDocument;
    classicFrame
      ?.querySelector<HTMLButtonElement>('.ai__filter-button')
      ?.dispatchEvent(customEvent);
  }

  return (
    <div {...s('container')}>
      <div {...s('text')}>
        <BetaTag showTooltip={false} />
        <Body dark>Use Rex AI to generate your filters</Body>
      </div>
      <AiButton withIcon onClick={onClick}>
        Try AI Filters
      </AiButton>
    </div>
  );
}
