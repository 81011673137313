import React, { PureComponent } from 'react';
import types from 'prop-types';
import Dialog from 'view/components/dialog';
import { autobind } from 'core-decorators';
import {
  withQuery,
  query,
  withValueLists,
  withModel
} from '@rexlabs/model-generator';
import accountSettingsComplianceModel from 'data/models/custom/account-settings-compliance';
import { getAmlQuery } from 'data/queries/contact-aml-checks';
import contactIDVerificationsModel from 'data/models/entities/contact-identity-verifications';
import adminWorkflowsModel from 'data/models/entities/admin-workflows';
import sessionModel from 'data/models/custom/session';
import amlProviders from 'data/models/system-lists/anti-money-laundering-providers';
import _ from 'lodash';
import { withErrorDialog } from 'src/hocs/with-error-dialog';

import AMLSubmissionComplete from './submission-complete';
import AMLSubmission from './submission';
import AMLCheck from './check';

function getCriteria(props) {
  return _.get(props, 'contact.id')
    ? [
        {
          name: 'contact_id',
          type: 'in',
          value: [_.get(props, 'contact.id')]
        }
      ]
    : [];
}

// Loading ID Checks here as well, so we can disable AML if there is no
// ID Check yet!
const idQuery = query`{
  ${contactIDVerificationsModel} (criteria: ${getCriteria}) {
    id
    verified_at
  }
}`;

@withModel(accountSettingsComplianceModel)
@withModel(adminWorkflowsModel)
@withModel(sessionModel)
@withValueLists(amlProviders)
@withQuery(getAmlQuery((p) => _.get(p, 'contact.id')))
@withQuery(idQuery)
@withErrorDialog
@autobind
class AMLDialog extends PureComponent {
  static propTypes = {
    contact: types.shape({
      id: types.oneOf([types.number, types.string]).isRequired
    })
  };

  // This is the top level dialog, that will handle multiple content states,
  // depending on these values. We also expose methods to change those values
  // to the content components to switch from one state to another!
  state = {
    isManual: true,
    isSubmitted: false,
    workflowId: 'loading'
  };

  setManual(isManual) {
    this.setState({ isManual });
  }

  setSubmitted(isSubmitted) {
    this.setState({ isSubmitted });
  }

  componentWillReceiveProps(nextProps) {
    const {
      valueLists: { antiMoneyLaunderingProviders }
    } = nextProps;

    if (
      _.get(this.props, 'amlChecks.list.status') === 'loading' &&
      _.get(nextProps, 'amlChecks.list.status') === 'loaded' &&
      _.get(antiMoneyLaunderingProviders, 'status') === 'loaded'
    ) {
      const providers = _.get(antiMoneyLaunderingProviders, 'items', [])
        .map((provider) =>
          window.Rex2IFrame.contentWindow.r2.u.api
            .getAccountInfo('third_party_extensions')
            .find(
              (extension) =>
                _.get(extension, 'service_type_id') === _.get(provider, 'value')
            )
        )
        .filter(Boolean);

      const isCompany = _.get(nextProps, 'contact.type') === 'company';

      // NOTE: setting this is state, because we just want to use the value from
      // the props as initial value, the user can change it through the UI
      this.setState({
        providers,
        isManual:
          isCompany ||
          !providers ||
          providers.length === 0 ||
          _.get(nextProps, 'amlChecks.list.items.0.check_type.id') === 'manual'
      });
    }
  }

  componentDidMount() {
    const {
      accountSettingsCompliance,
      errorDialog,
      adminWorkflows,
      session
    } = this.props;

    accountSettingsCompliance
      .getEffectiveSetting({ key: 'aml_check_workflow_id' })
      .then(({ data }) => {
        const workflowId = _.get(data, 'result', null);

        if (!workflowId) {
          // Sets to undefined for the sake of later logic in the manual check and early returns
          this.setState({
            workflowId
          });

          return;
        }

        (session.checkUserHasPermission('addon.workflows') ||
          session.checkUserHasPermission('addon.system_workflows')) &&
          adminWorkflows
            .fetchItem({ id: workflowId })
            .then(({ data }) => {
              this.setState({
                workflowId,
                workflowName: data.name
              });
            })
            .catch(errorDialog.open);
      })
      .catch(errorDialog.open);

    // If we don't have the addon, we don't want to keep workflowId as 'loading'
    !(
      session.checkUserHasPermission('addon.workflows') ||
      session.checkUserHasPermission('addon.system_workflows')
    ) &&
      this.setState({
        workflowId: null
      });
  }

  render() {
    const {
      onLoad,
      closeDialog,
      amlChecks,
      contactIdentityVerifications
    } = this.props;
    const {
      isSubmitted,
      isManual,
      providers = [],
      workflowId,
      workflowName
    } = this.state;

    const title = isSubmitted
      ? 'AML Submission Complete'
      : isManual
      ? 'AML Check Status'
      : 'AML Submission';

    const Content = isSubmitted
      ? AMLSubmissionComplete
      : isManual
      ? AMLCheck
      : AMLSubmission;

    const isLoading =
      _.get(amlChecks, 'list.status') === 'loading' ||
      _.get(contactIdentityVerifications, 'list.status') === 'loading' ||
      workflowId === 'loading';

    return (
      <Dialog
        hasPadding={false}
        isLoading={isLoading}
        onLoad={onLoad}
        closeDialog={closeDialog}
        title={title}
        height={400}
        showLoadingSpinner
      >
        <Content
          {...this.props}
          isSubmitted={isSubmitted}
          setManual={this.setManual}
          setSubmitted={this.setSubmitted}
          providers={providers}
          workflowId={workflowId}
          workflowName={workflowName}
        />
      </Dialog>
    );
  }
}

export default AMLDialog;
