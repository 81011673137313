import { gql } from 'hooks/use-graphql-query';
import {
  CustomFields,
  KeyActivity,
  KeyRegister,
  Property,
  User
} from 'features/custom-reporting/data/fragments';
import { Contact } from 'features/custom-reporting/modules/contacts/contact-data-fragments';
import { Listing } from 'features/custom-reporting/modules/listings/listing-data-fragments';

export const KeyActivityReportQueryDefaultVariables = {
  include_subresource_listing: false,
  include_subresource_property: false,
  include_subresource_system_checked_out_user: false,
  include_subresource_checked_out_to: false,
  include_subresource_checked_in_by: false,
  include_listing_custom_fields: false,
  listing_custom_field_ids: [],
  include_property_custom_fields: false,
  property_custom_field_ids: []
};

export const KeyActivityReportQuery = gql`
  ${KeyActivity}
  ${KeyRegister}
  ${Contact}
  ${Listing}
  ${Property}
  ${User}
  ${CustomFields}

  query(
    $include_subresource_listing: Boolean!
    $include_subresource_property: Boolean!
    $include_subresource_system_checked_out_user: Boolean!
    $include_subresource_checked_out_to: Boolean!
    $include_subresource_checked_in_by: Boolean!
    $include_listing_custom_fields: Boolean!
    $listing_custom_field_ids: [Int!]!
    $include_property_custom_fields: Boolean!
    $property_custom_field_ids: [Int!]!
    $criteria: [Criterion!]
    $order_bys: [OrderBy!]
    $limit: Int!
    $cursor: String
  ) {
    rowData: key_register_history(
      criteria: $criteria
      use_case: reporting
      order_bys: $order_bys
      limit: $limit
      cursor: $cursor
    ) {
      pagination {
        next_page {
          cursor
        }
      }
      rows {
        ...KeyRegisterHistory
        system_checked_out_user
          @include(if: $include_subresource_system_checked_out_user) {
          ...User
        }
        checked_out_to_user @include(if: $include_subresource_checked_out_to) {
          ...User
        }
        checked_out_to_contact
          @include(if: $include_subresource_checked_out_to) {
          ...Contact
        }
        checked_in_by_user @include(if: $include_subresource_checked_in_by) {
          ...User
        }

        key_register {
          ...KeyRegister
          # we need the listing id to know if the key is a listing or property key
          listing {
            id
          }
          listing @include(if: $include_subresource_listing) {
            ...Listing
            custom_field_values(field_ids: $listing_custom_field_ids)
              @include(if: $include_listing_custom_fields) {
              ...CustomFields
            }
            listing_agent_1 {
              name
            }
            listing_agent_2 {
              name
            }
            system_owner_user {
              ...User
            }
          }
          property @include(if: $include_subresource_property) {
            ...Property
            custom_field_values(field_ids: $property_custom_field_ids)
              @include(if: $include_property_custom_fields) {
              ...CustomFields
            }
            system_owner_user {
              ...User
            }
          }
        }
      }
    }
  }
`;
