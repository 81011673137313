import React, { PureComponent } from 'react';
import { STATUS_CIRCLE } from 'theme';

class StatusCircle extends PureComponent {
  render() {
    const {
      green,
      lightgreen,
      yellow,
      red,
      lightred,
      black,
      grey
    } = this.props;

    if (lightgreen) {
      return <div style={STATUS_CIRCLE.STATUS_CIRCLE_LIGHTGREEN} />;
    }

    if (green) {
      return <div style={STATUS_CIRCLE.STATUS_CIRCLE_GREEN} />;
    }

    if (yellow) {
      return <div style={STATUS_CIRCLE.STATUS_CIRCLE_YELLOW} />;
    }

    if (red) {
      return <div style={STATUS_CIRCLE.STATUS_CIRCLE_RED} />;
    }

    if (lightred) {
      return <div style={STATUS_CIRCLE.STATUS_CIRCLE_LIGHTRED} />;
    }

    if (black) {
      return <div style={STATUS_CIRCLE.STATUS_CIRCLE_BLACK} />;
    }

    if (grey) {
      return <div style={STATUS_CIRCLE.STATUS_CIRCLE_GREY} />;
    }

    return <div style={STATUS_CIRCLE.STATUS_CIRCLE_EMPTY} />;
  }
}

export default StatusCircle;
