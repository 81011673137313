// NOTE: this is pretty much an exact copy of `withDialog`, so might be worth creating a
// generic abstraction of this pattern at some point?!

import React, { PureComponent } from 'react';
import { getDisplayName } from 'shared/utils/react';
import _ from 'lodash';
import invariant from 'invariant';

const withOverlay = (OverlayComponent, options = {}) => (WrappedComponent) => {
  invariant(
    OverlayComponent,
    'You need to pass in the overlay component to "@withOverlay"!'
  );

  const overlayName = getDisplayName(OverlayComponent);
  const propName = options.propName || _.camelCase(overlayName);

  class WithOverlay extends PureComponent {
    constructor(props) {
      super(props);

      this.state = {
        open: !!options.open,
        props: {}
      };

      this.overlayProps = {
        open: (props) => {
          this.setState({
            open: true,
            props
          });
        },
        close: () => {
          this.setState({
            open: false,
            props: {}
          });
        }
      };
    }

    render() {
      const { open, props } = this.state;

      // NOTE: replace <div> with <Fragment> once we switch to React 16!!
      return (
        <div>
          <WrappedComponent
            {...{ [propName]: this.overlayProps }}
            {...this.props}
          />
          {open && <OverlayComponent {...props} />}
        </div>
      );
    }
  }

  const name = getDisplayName(WrappedComponent);
  WithOverlay.displayName = `WithOverlay:${overlayName}(${name})`;
  return WithOverlay;
};

export default withOverlay;
