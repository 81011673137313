import React, { useEffect, useState } from 'react';
import Box from '@rexlabs/box';
import Spinner from 'shared/components/spinner';
import SaveCancelOverlayHandler from 'view/components/save-cancel-overlay-handler';

import { PADDINGS } from 'shared/theme';
import { StyleSheet, useStyles } from '@rexlabs/styling';
import { useWhereaboutsWithViewpath } from 'hooks/use-whereabouts-with-viewpath';
import { IconButton } from 'src/view/components/button';
import { ICONS } from 'shared/components/icon';
import {
  ReactForms,
  Form,
  FormField,
  HiddenField
} from 'src/view/components/form';
import { useErrorDialog } from 'hooks/use-error-dialog';
import {
  SlidingScaleSetting,
  SlidingScaleTier
} from 'features/settings/components/sliding-scale-settings/data/types';
import { useSlidingScaleSetting } from 'features/settings/components/sliding-scale-settings/hooks/use-sliding-scale-setting';
import { RecordActions } from 'features/settings/components/sliding-scale-settings/record-actions';
import { ApiActions } from 'data/models/custom/api-model';
import { Body } from 'components/text/body';
import { Column, Grid } from 'shared/components/grid';
import {
  DateSelect,
  Select,
  ValueListSelect
} from 'src/view/components/input/select';
import TieredComponent from 'features/settings/components/sliding-scale-settings/tiered-component';
import { APIFieldSelect } from 'src/view/components/input/select/api-field-select';
import { isEqual } from 'lodash';
import agentSlidingScaleCalculationBase from 'data/models/system-lists/agent-sliding-scale-calculation-base';
import agentSlidingScaleCalculateListingStatus from 'data/models/system-lists/agent-sliding-scale-calculate-listing-status';
import { useDialog } from 'hooks/use-dialog';

const defaultStyles = StyleSheet({
  name: {
    fontSize: '27px',
    fontWeight: 600,
    '& button': {
      display: 'inline'
    }
  }
});

export const SlidingScaleSettingRecord = ({
  slidingScaleActions
}: {
  slidingScaleActions: ApiActions<SlidingScaleSetting>;
}) => {
  const s = useStyles(defaultStyles);
  const errorDialog = useErrorDialog();
  const editSlidingScale = useDialog('addSlidingScaleSetting');
  const whereabouts = useWhereaboutsWithViewpath();
  const [
    slidingScaleSetting,
    setSlidingScaleSetting
  ] = useState<SlidingScaleSetting | null>(null);
  const [tiers, setTiers] = useState<SlidingScaleTier[]>([]);
  const { isLoading, setIsLoading } = useSlidingScaleSetting({
    slidingScaleActions
  });

  const onSubmit = async (values: SlidingScaleSetting, { resetForm }) => {
    try {
      const res = await slidingScaleActions.update({
        ...values,
        related: {
          admin_comm_agent_sliding_scale_tiers: tiers.map((tier, index) =>
            index === tiers.length - 1 ? { ...tier, end_of_range: null } : tier
          )
        }
      });
      setSlidingScaleSetting(res);
      setTiers(res.related.admin_comm_agent_sliding_scale_tiers);
      resetForm(res);
    } catch (error) {
      errorDialog.open({
        message: (error as Error).message,
        onClose: () => {
          resetForm();
          setTiers(
            slidingScaleSetting?.related.admin_comm_agent_sliding_scale_tiers ||
              []
          );
        },
        hasCloseButton: true
      });
    }
  };

  useEffect(() => {
    const fetchCondition = async (id: string) => {
      setIsLoading(true);
      try {
        const result = await slidingScaleActions.read(id);
        setSlidingScaleSetting(result);
        setTiers(result.related.admin_comm_agent_sliding_scale_tiers);
      } catch (error) {
        errorDialog.open(error as Error);
      }
      setIsLoading(false);
    };

    fetchCondition(whereabouts.hashQuery?.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [whereabouts.hashQuery?.id]);

  if (isLoading || !slidingScaleSetting)
    return (
      <Box flex={1} justifyContent={'center'} alignItems={'center'}>
        <Spinner dark small />
      </Box>
    );

  return (
    <Box w='100%' p={`24px ${PADDINGS.M} ${PADDINGS.M}`}>
      <RecordActions
        slidingScaleSetting={slidingScaleSetting}
        slidingScaleActions={slidingScaleActions}
      />
      <Box alignItems={'center'} flexWrap='wrap' mb={2} mt={20} spacing={10}>
        <h2 {...s('name')}>
          {slidingScaleSetting?.name}{' '}
          <IconButton
            circle
            red
            Icon={ICONS.EDIT}
            onClick={() =>
              editSlidingScale.open({
                slidingScaleSetting,
                action: 'edit',
                onSuccess: (newSettings: SlidingScaleSetting) =>
                  setSlidingScaleSetting(newSettings)
              })
            }
          />
        </h2>
      </Box>
      <Body normal dark style={{ marginBottom: 20 }}>
        Sliding commissions are calculated are based on the following
        configuration set below.
      </Body>
      <Box flexDirection='column' spacing={10} mb={10} width={'100%'}>
        <Body dark semibold>
          Calculation base
        </Body>
        <Body dark normal>
          <Body is='span' dark semibold>
            Office Contribution (Gross):
          </Body>{' '}
          Agent’s earnings based on the Salesperson share of the Real Estate Fee
          before deductions.
        </Body>
        <Body dark normal>
          <Body is='span' dark semibold>
            Office Contribution Less Referral (Gross):
          </Body>{' '}
          Agent’s earnings based on the Salesperson share of the Real Estate Fee
          before deductions after referral.
        </Body>
        <Body dark normal>
          <Body is='span' dark semibold>
            Office Contribution (Net):
          </Body>{' '}
          Agent’s earnings based on the Salesperson share of the Real Estate Fee
          after deductions.
        </Body>
        <Body dark normal>
          <Body is='span' dark semibold>
            Actual Earnings To Date:
          </Body>{' '}
          Agent’s earnings based on the actual Salesperson take-home earnings
          after deductions.
        </Body>
      </Box>
      <ReactForms<SlidingScaleSetting, unknown>
        handleSubmit={onSubmit}
        initialValues={slidingScaleSetting}
      >
        {({ submitForm, isDirty, resetForm }) => {
          return (
            <Form hasErrorPadding>
              <HiddenField name='id' />
              <Grid mb={30} columns={2}>
                <Column width={1}>
                  <FormField
                    label='calculation base'
                    name='calculation_base'
                    Input={APIFieldSelect}
                    inputProps={{
                      SelectComponent: ValueListSelect,
                      searchOnMount: true,
                      valueAsObject: true,
                      models: [agentSlidingScaleCalculationBase]
                    }}
                  />
                </Column>
                <Column width={1}>
                  <FormField
                    label='only calculate listing when it reaches'
                    name='calculate_listings_status'
                    Input={APIFieldSelect}
                    inputProps={{
                      SelectComponent: ValueListSelect,
                      searchOnMount: true,
                      valueAsObject: true,
                      models: [agentSlidingScaleCalculateListingStatus]
                    }}
                  />
                </Column>
                <Column width={1}>
                  <FormField
                    label='period'
                    name='period'
                    Input={APIFieldSelect}
                    inputProps={{
                      SelectComponent: Select,
                      options: [
                        {
                          label: 'Annually',
                          value: 'annually'
                        },
                        {
                          label: 'Quarterly',
                          value: 'quarterly'
                        },
                        {
                          label: 'Monthly',
                          value: 'monthly'
                        }
                      ]
                    }}
                  />
                </Column>
                <Column width={1}>
                  <FormField
                    label='period start date'
                    name='period_start_date'
                    Input={DateSelect}
                  />
                </Column>
              </Grid>
              <TieredComponent tiers={tiers} setTiers={setTiers} />
              <SaveCancelOverlayHandler
                isVisible={
                  isDirty ||
                  !isEqual(
                    tiers,
                    slidingScaleSetting.related
                      .admin_comm_agent_sliding_scale_tiers
                  )
                }
                onSave={submitForm}
                onCancel={() => {
                  resetForm();
                  setTiers(
                    slidingScaleSetting.related
                      .admin_comm_agent_sliding_scale_tiers
                  );
                }}
              />
            </Form>
          );
        }}
      </ReactForms>
    </Box>
  );
};
