import React, { PureComponent } from 'react';
import types from 'prop-types';
import { autobind } from 'core-decorators';
import Dialog from 'view/components/dialog';
import { SaveCancelButtonBar } from 'view/components/button-bar';
import { Heading } from 'components/text/heading';
import { Hint } from 'components/text/hint';
import { ReactForms, FormField, Form } from 'view/components/form';
import { RexBasicCell, RexHeadingCell } from 'view/components/list';
import { StyleSheet, styled } from '@rexlabs/styling';
import { Select } from 'view/components/input/select';
import Box from '@rexlabs/box';
import { COLORS } from 'theme';
import Checkbox from 'view/components/input/checkbox';
import washListNameSubstitutesModel from 'data/models/system-lists/wash-list-name-substitutes';
import { withValueLists } from '@rexlabs/model-generator';
import _ from 'lodash';
import SuffixOption from 'view/components/input/select/options/suffix';

const defaultStyles = StyleSheet({
  contactContainer: {
    '&:nth-child(odd)': {
      backgroundColor: COLORS.BACKGROUNDS.SAND_LIGHT
    }
  }
});

const checkBoxCellStyles = {
  width: '50px',
  paddingRight: 0
};

const contactCellStyles = {
  flex: 1,
  paddingLeft: 0
};

@withValueLists(washListNameSubstitutesModel)
@styled(defaultStyles)
@autobind
class ReviewMPSContactsDialog extends PureComponent {
  static propTypes = {
    contacts: types.array.isRequired,
    callback: types.func.isRequired
  };

  static defaultProps = {};

  state = {};

  onSaveClick(values) {
    const { contacts, callback, closeDialog } = this.props;
    const { addressAs, ...rest } = values;

    const updatedContacts = Object.keys(rest).map((key) => {
      const id = parseInt(key.split('-')[1], 10);
      const contact = contacts.find((c) => parseInt(c.id, 10) === id);

      contact.selected = rest[key];

      return contact;
    });

    callback(updatedContacts, addressAs);
    closeDialog();
  }

  getSelectAllInitialValue() {
    const { contacts } = this.props;
    return contacts.every(({ selected }) => selected);
  }

  getSelectAllValue(values) {
    const { ...rest } = values;

    return Object.values(rest).every((value) => !value);
  }

  onSelectClick(e, values, setValue) {
    setValue(
      'selectAll',
      !this.getSelectAllValue({ ...values, [e.target.name]: e.target.checked })
    );
  }

  onSelectAllClick(values, setValues) {
    const { selectAll, ...rest } = values;

    Object.keys(rest).forEach((key) => {
      values[key] = !selectAll;
    });

    setValues(values);
  }

  onAddressAsChange(e, setValue) {
    const {
      valueLists: { washListNameSubstitutes }
    } = this.props;

    const value = _.get(e, 'target.value', _.get(e, 'value') || e);
    setValue('addressAs', value);

    if (
      !value ||
      washListNameSubstitutes.items.find((sub) => value === sub.value)
    ) {
      this.setState({
        customInput: null
      });

      return;
    }

    this.setState({
      customInput: value
    });
  }

  // NOTE: memoizing so we're not creating a new array of options on every render
  // Could potentially move this into `componentDidUpdate` with the other parts?!
  getSubstituteOptions = _.memoize((washListNameSubstitutes) => {
    const substitutes = _.get(washListNameSubstitutes, 'items', []).map(
      (item) => ({
        ...item,
        suffix:
          item.value === 'anonymous'
            ? 'e.g. Dear Property Owner,'
            : 'e.g. Dear James,'
      })
    );

    return substitutes;
  });

  componentWillMount() {
    const {
      customInput,
      valueLists: { washListNameSubstitutes }
    } = this.props;

    if (
      customInput &&
      washListNameSubstitutes.status === 'loaded' &&
      !washListNameSubstitutes.items.find((sub) => customInput === sub.value)
    ) {
      this.setState({
        customInput
      });
    }
  }

  componentDidUpdate(prevProps) {
    const {
      customInput,
      valueLists: { washListNameSubstitutes }
    } = this.props;

    if (
      customInput &&
      prevProps.valueLists.washListNameSubstitutes.status === 'loading' &&
      washListNameSubstitutes.status === 'loaded' &&
      !washListNameSubstitutes.items.find((sub) => customInput === sub.value)
    ) {
      this.setState({
        customInput
      });
    }
  }

  render() {
    const {
      styles: s,
      closeDialog,
      contacts,
      customInput: customInputProp,
      valueLists: { washListNameSubstitutes }
    } = this.props;

    const { customInput } = this.state;

    return (
      <Dialog
        title={'MPS Registered Contacts'}
        closeDialog={closeDialog}
        height={520}
        width={500}
      >
        <ReactForms
          handleSubmit={this.onSaveClick}
          validateOnChange={false}
          validateOnBlur={false}
        >
          {({ submitForm, values, setValues, setFieldValue }) => {
            return (
              <Form>
                <Box mt={10} mb={25}>
                  <FormField
                    name={'addressAs'}
                    label={'address mps registered contact as'}
                    initialValue={customInput || customInputProp || 'anonymous'}
                    Input={Select}
                    inputProps={{
                      options: this.getSubstituteOptions(
                        washListNameSubstitutes
                      ),
                      Option: SuffixOption
                    }}
                  />
                </Box>
                <Box mb={15}>
                  <Heading level={2}>
                    Review MPS Registered - Do Not Contact
                  </Heading>
                </Box>
                <Hint semibold>
                  Select MPS Registered Contacts to include in this mail merge
                  from the list below.
                </Hint>
                <Box mt={20} mb={15}>
                  <Box alignItems={'center'}>
                    <RexHeadingCell align={'center'} style={checkBoxCellStyles}>
                      <FormField
                        name={'selectAll'}
                        Input={Checkbox}
                        initialValue={this.getSelectAllInitialValue()}
                        onChange={() => {
                          this.onSelectAllClick(values, setValues);
                        }}
                      />
                    </RexHeadingCell>
                    <RexHeadingCell style={contactCellStyles}>
                      contact
                    </RexHeadingCell>
                  </Box>
                  {contacts.map((item) => (
                    <Box
                      {...s('contactContainer')}
                      alignItems={'center'}
                      key={item.id}
                    >
                      <RexBasicCell align={'center'} style={checkBoxCellStyles}>
                        <FormField
                          name={`select-${item.id}`}
                          Input={Checkbox}
                          initialValue={item.selected}
                          onChange={(e) =>
                            this.onSelectClick(e, values, setFieldValue)
                          }
                        />
                      </RexBasicCell>
                      <RexBasicCell style={contactCellStyles}>
                        {item.name}
                      </RexBasicCell>
                    </Box>
                  ))}
                </Box>
                <SaveCancelButtonBar
                  onCancel={closeDialog}
                  onSave={submitForm}
                />
              </Form>
            );
          }}
        </ReactForms>
      </Dialog>
    );
  }
}

export default ReviewMPSContactsDialog;
