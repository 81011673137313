import React, { Component } from 'react';
import { Select as SelectContainer, SelectInput } from '@rexlabs/select-input';
import { styled, StyleSheet } from '@rexlabs/styling';

import MenuSwitcher from '../menus/menu-switcher';
import { getNull } from '../utils';
import { SEARCH_INPUT_WIDTH } from 'view/components/navigation/shell/header/desktop-tablet';

@styled(
  StyleSheet({
    container: {
      width: SEARCH_INPUT_WIDTH
    },
    input: {},
    menu: {}
  })
)
class AppSearchInput extends Component {
  render() {
    const {
      selectContainerProps,
      selectInputProps,
      menuSwitcherProps,
      styles: s,
      className
    } = this.props;

    return (
      <SelectContainer
        {...s.with('container')(className)}
        {...selectContainerProps}
      >
        <SelectInput
          {...s('input')}
          name='global_search'
          debounce={0}
          autoBlur
          shouldCloseOnBlur
          shouldOpenOnFocus
          shouldBackspaceRemove={false}
          shouldDeleteRemove={false}
          shouldTabSelectValue={false}
          shouldBlurResetInput={false}
          shouldCloseResetInput={false}
          isClearable={false}
          DropdownIndicator={getNull}
          {...selectInputProps}
        />
        <MenuSwitcher {...s('menu')} {...menuSwitcherProps} />
      </SelectContainer>
    );
  }
}

export default AppSearchInput;
