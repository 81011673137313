import React, { useState, useCallback, useMemo } from 'react';

import Box from '@rexlabs/box';
import { TextInput } from '@rexlabs/text-input';
import { StyleSheet, useStyles } from '@rexlabs/styling';
import { Id, useModelState } from '@rexlabs/model-generator';

import { ICONS } from 'shared/components/icon';

import { PADDINGS, COLORS } from 'theme';
import { Popout } from 'view/components/popout';
import sessionModel, { SessionModel } from 'data/models/custom/session';

import { InputLabel } from 'components/text/input-label';
import { Link } from 'components/text/link';

const PermalinkIcon: any = ICONS.PERMALINK;

interface PermalinkPopoutProps {
  serviceName?: string;
  id: Id;
  getPermalink?: ({ id, accountId }: { id: Id; accountId: Id }) => string;
}

const defaultStyles = StyleSheet({
  permalinkStyles: {
    '& label': {
      backgroundColor: COLORS.BACKGROUNDS.SAND_LIGHT,
      width: 'auto'
    },
    '& input': {
      '&:hover': {
        border: '0px'
      },
      '&:focus': {
        border: '0px'
      }
    }
  },

  floatingLinkButton: {
    position: 'absolute',
    right: '0',
    zIndex: 10
  },

  linkButtonWrapper: {
    position: 'relative'
  }
});

function PermalinkPopoutContent(props: PermalinkPopoutProps) {
  const { id, serviceName, getPermalink } = props;
  const s = useStyles(defaultStyles);
  const { office_details } = useModelState(sessionModel) as SessionModel;

  const accountId = office_details?.id;

  const [copied, setCopied] = useState(false);

  const permalinkAdjusted = getPermalink
    ? getPermalink({ id, accountId })
    : `${window.location.origin}/${serviceName}/` +
      `#id=${id}&_account_id=${accountId}`;

  const copyPermalinkCallback = useCallback(
    function copyPermalink() {
      window.navigator.clipboard.writeText(permalinkAdjusted);
      setCopied(true);
      setTimeout(() => setCopied(false), 3000);
    },
    [permalinkAdjusted]
  );

  return (
    <Box p={PADDINGS.M} width={380}>
      <Box mb={PADDINGS.XS}>
        <InputLabel formField>record ID</InputLabel>
        <Box {...s('permalinkStyles')}>
          <TextInput value={id} />
        </Box>
      </Box>
      <Box {...s('linkButtonWrapper')}>
        <Link
          light
          {...s('floatingLinkButton')}
          onClick={copyPermalinkCallback}
        >
          {copied ? 'Copied!' : 'Copy permalink'}
        </Link>
      </Box>
      <Box>
        <InputLabel formField>record permalink</InputLabel>
        <Box {...s('permalinkStyles')}>
          <TextInput value={permalinkAdjusted} />
        </Box>
      </Box>
    </Box>
  );
}

export default function PermalinkPopout(props: PermalinkPopoutProps) {
  const contents = useMemo(() => () => <PermalinkPopoutContent {...props} />, [
    props
  ]);

  return (
    <Popout placement='bottom-end' Content={contents}>
      <PermalinkIcon width={25} height={25} color={COLORS.PRIMARY.SAND} />
    </Popout>
  );
}
