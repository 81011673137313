import { gql } from 'hooks/use-graphql-query';
import {
  User,
  Chain,
  Property,
  CustomFields
} from 'features/custom-reporting/data/fragments';

import { Contract } from 'features/custom-reporting/modules/contracts/contract-data-fragments';
import { CommissionWorksheet } from 'features/custom-reporting/modules/commission-by-sale/commission-by-sale-data-fragments';
import { CommissionWorksheetAgentAllocation } from 'features/custom-reporting/modules/commission-by-agent/commission-by-agent-data-fragments';
import { Listing } from 'features/custom-reporting/modules/listings/listing-data-fragments';

export const commissionByAgentReportQueryDefaultVariables = {
  include_subresource_listing: false,
  include_subresource_contract: false,
  include_subresource_worksheet: false,
  include_subresource_property: false,
  include_subresource_agent: false,
  include_listing_custom_fields: false,
  listing_custom_field_ids: [],
  include_contract_custom_fields: false,
  contract_custom_field_ids: [],
  include_property_custom_fields: false,
  property_custom_field_ids: []
};

export const CommissionByAgentReportQuery = gql`
  ${User}
  ${Listing}
  ${Property}
  ${Contract}
  ${Chain}
  ${CommissionWorksheet}
  ${CommissionWorksheetAgentAllocation}
  ${CustomFields}

  query(
    $include_subresource_listing: Boolean!
    $include_subresource_contract: Boolean!
    $include_subresource_worksheet: Boolean!
    $include_subresource_property: Boolean!
    $include_subresource_agent: Boolean!
    $include_listing_custom_fields: Boolean!
    $listing_custom_field_ids: [Int!]!
    $include_contract_custom_fields: Boolean!
    $contract_custom_field_ids: [Int!]!
    $include_property_custom_fields: Boolean!
    $property_custom_field_ids: [Int!]!
    $criteria: [Criterion!]
    $order_bys: [OrderBy!]
    $limit: Int!
    $cursor: String
  ) {
    rowData: commission_worksheet_agent_allocations(
      use_case: reporting
      criteria: $criteria
      order_bys: $order_bys
      limit: $limit
      cursor: $cursor
    ) {
      pagination {
        next_page {
          cursor
        }
      }
      rows {
        ...CommissionWorksheetAgentAllocation
        agent @include(if: $include_subresource_agent) {
          ...User
        }
        worksheet @include(if: $include_subresource_worksheet) {
          ...CommissionWorksheet
        }
        contract: worksheet @include(if: $include_subresource_contract) {
          contract {
            ...Contract
            custom_field_values(field_ids: $contract_custom_field_ids)
              @include(if: $include_contract_custom_fields) {
              ...CustomFields
            }
            agent {
              ...User
            }
            chain_instance {
              assignee_user {
                ...User
              }
              chain {
                ...Chain
              }
            }
          }
        }
        listing: worksheet @include(if: $include_subresource_listing) {
          contract {
            listing {
              ...Listing
              custom_field_values(field_ids: $listing_custom_field_ids)
                @include(if: $include_listing_custom_fields) {
                ...CustomFields
              }
              listing_agent_1 {
                name
              }
              listing_agent_2 {
                name
              }
              system_owner_user {
                ...User
              }
            }
          }
        }
        property: worksheet @include(if: $include_subresource_property) {
          contract {
            listing {
              property {
                ...Property
                custom_field_values(field_ids: $property_custom_field_ids)
                  @include(if: $include_property_custom_fields) {
                  ...CustomFields
                }
                system_owner_user {
                  ...User
                }
              }
            }
          }
        }
      }
    }
  }
`;
