import React from 'react';

import Box from '@rexlabs/box';

import { PADDINGS } from 'theme';

import { LeadsResponse } from 'data/models/entities/leads';
import { Activity } from 'data/models/entities/activities-model';

import { convertTimestampToDayjs } from 'utils/date';

import { LabelledValue } from 'components/labelled-value';
import { Heading } from 'components/text/heading';

import { LeadsDialogAssigneePopout } from './leads-dialog-assignee-popout';

import { LeadsDialogTag } from './leads-dialog-tag';
import { useDialog } from 'hooks/use-dialog';
import { Link } from 'components/text/link';

interface LeadsDialogStatusSectionProps {
  data: LeadsResponse;
  status: 'loaded' | 'error';
  lastActivity?: Activity;
  closeDialog?: () => void;
}

export default function LeadsDialogStatusSection({
  data,
  status,
  lastActivity,
  closeDialog
}: LeadsDialogStatusSectionProps) {
  const reassignToSubAccountDialog = useDialog('reassignToSubAccount');
  const reassignToParentAccountDialog = useDialog('reassignToParentAccount');

  const {
    lead_status,
    system_ctime,
    security_user_rights,
    system_completed_time,
    assignee,
    id
  } = data;

  const canReassignToSubAccount = [
    'reassign_to_sub_account',
    'update'
  ].every((r) => security_user_rights?.includes(r));
  const canReassignToParentAccount = [
    'reassign_back_to_parent_account',
    'update'
  ].every((r) => security_user_rights?.includes(r));

  const isLeadCompleted = () => {
    return lead_status.text.toLowerCase() === 'completed';
  };

  const leadTimeFromNow = () => {
    const leadReceivedTime = convertTimestampToDayjs(system_ctime);
    if (!leadReceivedTime) {
      return '-';
    }

    return leadReceivedTime.fromNow();
  };

  const leadTimeBeforeCompletion = () => {
    const leadReceivedTime = convertTimestampToDayjs(system_ctime);
    const leadCompletedTime = convertTimestampToDayjs(system_completed_time);
    if (!(leadReceivedTime && leadCompletedTime)) {
      return '-';
    }
    // Second param specifies whether we want the `ago` suffix - in this case we don't.
    return leadReceivedTime.from(leadCompletedTime, true);
  };

  const getLatestAction = () => {
    return lastActivity?.related.activity_outcomes[0].type.text || '—';
  };

  const leadTimeStatus = isLeadCompleted()
    ? `Within ${leadTimeBeforeCompletion()}`
    : `Received ${leadTimeFromNow()}`;
  const latestAction = getLatestAction();

  return (
    // NOTE: For anyone wondering why have nested boxes, it is because of the spacing prop.
    // Because we use sy in the parent component, it overrides the sy in this component.
    // Wrapping in a box stops this from happening, as it adds an additionally layer.
    <Box p={PADDINGS.S}>
      <Box sy={PADDINGS.XXS}>
        <Box flexDirection='row' alignItems='center' sx={PADDINGS.XS}>
          <LeadsDialogTag data={data} />

          <Heading level={2}>{leadTimeStatus}</Heading>
        </Box>

        <Box flexDirection='row' mt={PADDINGS.XS} alignItems={'baseline'}>
          <LabelledValue label='assignee'>
            {assignee?.name || 'Unassigned'}
          </LabelledValue>

          <Box ml={PADDINGS.XXS}>
            <LeadsDialogAssigneePopout user={assignee} leadsId={id} />
          </Box>
        </Box>

        <LabelledValue label='last action'>{latestAction}</LabelledValue>

        {canReassignToSubAccount ? (
          <Box marginTop={PADDINGS.S}>
            <Link
              regular
              onClick={() =>
                reassignToSubAccountDialog.open({
                  id,
                  status,
                  onSuccess: closeDialog
                })
              }
            >
              Assign to Sub Account
            </Link>
          </Box>
        ) : null}

        {canReassignToParentAccount ? (
          <Box marginTop={PADDINGS.S}>
            <Link
              regular
              onClick={() =>
                reassignToParentAccountDialog.open({
                  id,
                  status,
                  onSuccess: closeDialog
                })
              }
            >
              Re-assign to Parent Account
            </Link>
          </Box>
        ) : null}
      </Box>
    </Box>
  );
}
