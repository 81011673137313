import React, { Component } from 'react';
import { styled, StyleSheet } from '@rexlabs/styling';
import Box from '@rexlabs/box';
import { COLORS, PADDINGS, FONT } from 'theme';

@styled(
  StyleSheet({
    container: {
      width: '100%',
      backgroundColor: COLORS.WARM_GREY,

      color: COLORS.GREY_DARK,
      paddingLeft: PADDINGS.XS,
      paddingTop: PADDINGS.XXS,
      paddingBottom: PADDINGS.XXS,
      fontSize: '15px',
      fontWeight: FONT.WEIGHTS.MEDIUM
    },
    content: {
      height: '100%',
      display: 'flex',
      alignItems: 'center'
    }
  })
)
class DataCell extends Component {
  render() {
    const { styles: s, datum, dataKey } = this.props;

    return (
      <Box styles={{ padding: 0 }} {...s('container')}>
        <div {...s('content')}>{datum[dataKey]}</div>
      </Box>
    );
  }
}

const PafDataCell = (dataKey) => ({ datum }) => (
  <DataCell dataKey={dataKey} datum={datum} />
);

@styled(
  StyleSheet({
    content: {
      backgroundColor: COLORS.PRIMARY.SAND_LIGHT,
      color: 'white',
      width: '100%',

      fontSize: '12px',
      fontWeight: FONT.WEIGHTS.MEDIUM,
      paddingTop: PADDINGS.XXS,
      paddingBottom: PADDINGS.XXS,

      display: 'flex',
      alignItems: 'center'
    },
    container: {
      width: '100%',
      height: '100%',
      display: 'flex',
      alignItems: 'flex-end'
    }
  })
)
class HeaderCell extends Component {
  render() {
    const { styles: s, label } = this.props;
    return (
      <Box {...s('container')}>
        <Box
          styles={{ container: { padding: 0, paddingLeft: PADDINGS.XS } }}
          {...s('content')}
        >
          <span>{label}</span>
        </Box>
      </Box>
    );
  }
}

const PafHeaderCell = (label) => <HeaderCell label={label} />;

export { PafDataCell, PafHeaderCell };
