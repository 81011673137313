import { useMemo } from 'react';

import { useRegion } from 'hooks/use-region';
import { useWhichWordFactory } from 'hooks/use-which-word';

import { makeEntity } from 'features/custom-reporting/modules/helpers';

import {
  AppraisalReportQuery,
  appraisalReportQueryDefaultVariables
} from './appraisal-report.query';
import {
  ModuleConfigItem,
  OrderByDirectionType
} from 'features/custom-reporting/modules/module-config-types';
import { moduleNameMap } from 'features/custom-reporting/modules/module-name-map';
import { useCustomFields } from 'features/custom-reporting/hooks/use-async-report-data';

export const defaultColumns = [
  'property',
  'agent_1.name',
  'agent_2.name',
  'appraisal_date',
  'price_min',
  'price_max',
  'price_asking',
  'archive_date',
  'archive_reason.text',
  'archive_lost_agency.text'
];

export function useAppraisalModule() {
  const ww = useWhichWordFactory();
  const {
    properties: propertyCustomFields,
    contacts: contactCustomFields
  } = useCustomFields();
  const region = useRegion();
  const appraisalModuleConfig: ModuleConfigItem = useMemo(
    () => ({
      ...moduleNameMap[ww('valuations').toLowerCase()],
      moduleLabel: ww('valuation'),
      exportPrivilege: 'exporting.appraisals',
      queryConfig: {
        graphQlQuery: AppraisalReportQuery,
        queryVariables: appraisalReportQueryDefaultVariables,
        defaultSort: [
          {
            name: 'system_modtime',
            direction: 'desc' as OrderByDirectionType
          }
        ]
      },
      columns: [
        {
          headerName: ww('valuation'),
          children: makeEntity({
            entity: 'appraisal',
            prefix: '',
            subresource: 'appraisal',
            whichWord: ww
          })
        },
        {
          headerName: 'Property',
          children: makeEntity({
            entity: 'property',
            prefix: 'property',
            subresource: 'property',
            customFields: propertyCustomFields
          })
        },
        {
          headerName: 'Property Owner',
          children: makeEntity({
            entity: 'contact',
            prefix: 'property.owner.0.contact',
            subresource: 'property_owner',
            customFields: contactCustomFields,
            region
          })
        }
      ],
      defaultVisibleColumns: defaultColumns,
      defaultDatePreset: 'this_year_to_date',
      defaultUserFieldPreference: 'agent_1_id',
      defaultDateFieldPreference: 'system_ctime',
      topLevelFilters: {
        date: {
          availableFields: [
            {
              fieldName: 'system_ctime',
              label: `${ww('valuation')} Creation`
            }
          ]
        },
        user: {
          availableFields: [
            {
              fieldName: 'agent_1_id',
              label: 'user is agent 1'
            },
            {
              fieldName: 'agent_id',
              label: 'user is agent 1 or agent 2'
            }
          ]
        }
      }
    }),
    [propertyCustomFields, ww]
  );

  return appraisalModuleConfig;
}
