/* eslint-disable max-lines */
import React, { PureComponent } from 'react';
import { autobind } from 'core-decorators';
import { withModel } from '@rexlabs/model-generator';
import { filterOptionsByValue } from './utils';
import invariant from 'invariant';
import _ from 'lodash';
import { COLORS } from 'src/theme';
import Icon, { ICONS } from 'shared/components/icon';
import { api } from 'shared/utils/api-client';

import Select from './select';
import ModelOption from './options/model';
import ModelValue from './values/model';

import { contactPrivacy } from 'data/models/custom/contact-privacy';
import DialogBridge from 'data/classic-bridges/dialogs-shell';
import { hasFeatureFlags } from 'shared/utils/has-feature-flags';

@withModel(contactPrivacy)
@autobind
class WithEntityModels extends PureComponent {
  static defaultProps = {
    models: [],
    options: [],
    debounce: 300,
    onConsentCheck: _.noop
  };

  constructor(props) {
    super(props);

    const { options, value, valueAsObject, consentFeature } = props;

    invariant(props.models, 'You need to define models for EntitySelect!');
    invariant(
      !(consentFeature && !valueAsObject),
      'To use EntitySelect consentFeature functionality you also need to use valueAsObject (so we can check the value selected is a contact and not another model type)'
    );

    let selected = [];
    if (props.value && props.options) {
      selected = filterOptionsByValue(
        options,
        value,
        valueAsObject,
        undefined,
        props.models
      );
    }

    this.state = {
      searchTerm: '',
      options,
      selected,
      initialOptions: options || [],
      relatedRecords: props.relatedDropdownSettings ? [] : null, // Can be an Array/null/'Loading'
      valueLoading: false
    };
  }

  componentDidMount() {
    const {
      searchOnMount,
      relatedDropdownSettings,
      valueAsObject,
      value,
      multi
    } = this.props;
    if (searchOnMount) {
      this.autocompleteModels('', searchOnMount);
    }
    if (relatedDropdownSettings) {
      this.getRelatedEntities(relatedDropdownSettings);
    }

    // Prefill value
    if (valueAsObject && Array.isArray(value) && multi) {
      this.setState({ selected: value });
    } else if (valueAsObject && value) {
      this.setState({ selected: [value] });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const changedValue = !_.isEqual(prevProps.value, this.props.value);
    const changedOptions = prevProps.options !== this.props.options;
    const {
      alwaysShowAll,
      keepLastSearch,
      relatedDropdownSettings
    } = this.props;

    const changedSearchCriteria =
      prevProps.searchCriteria !== this.props.searchCriteria;

    // TODO: Figure out why selected options are removed when we have an input value of empty string
    if (changedValue || changedOptions) {
      const selectedOptions = filterOptionsByValue(
        _.uniqBy(
          [
            ...this.state.options,
            ...this.props.options,
            ...this.state.selected,
            ...prevState.selected
          ],
          'value',
          this.props.valueAsObject
        ),
        this.props.value,
        this.props.valueAsObject,
        alwaysShowAll,
        this.props.models
      );

      const options = keepLastSearch
        ? [...this.state.options, ...this.props.options, ...selectedOptions]
        : [...selectedOptions];

      this.setState({
        selected: selectedOptions,
        options: _.uniqBy(
          // [...this.state.options, ...this.props.options, ...selectedOptions],
          options,
          'value'
        )
      });
    }
    if (changedSearchCriteria) {
      this.searchModels(this.props.searchCriteria);
    }
    if (
      !_.isEqual(
        _.get(relatedDropdownSettings, 'recordIds', []),
        _.get(prevProps.relatedDropdownSettings, 'recordIds', [])
      )
    ) {
      this.getRelatedEntities(relatedDropdownSettings);
    }
  }

  searchModels(criteria) {
    const { models } = this.props;

    const fetchAll = models
      .map((model) => {
        return model.select && model.select.search
          ? model.select.search(criteria, this.props)
          : undefined;
      })
      .filter(Boolean);

    this.setState({ isFetching: true });
    Promise.all(fetchAll).then((results) => {
      this.setState((state) => ({
        options: results.reduce(
          (all, options) =>
            _.uniqBy([...(all || []), ...(options || [])], 'value'),
          state.selected
        ),
        isFetching: false
      }));
    });
  }

  getRelatedEntities(baseRecord) {
    const { models } = this.props;
    this.setState({ relatedRecords: 'loading' });

    Promise.all(
      models.map((model) => {
        return api
          .post('CrossServiceViewstates::getRecordIdsForRecordIds', {
            related_service: model.serviceName,
            service: baseRecord.service,
            record_ids: baseRecord.recordIds
          })
          .then(({ data }) =>
            api
              .post(`${model.serviceName}::Search`, {
                criteria: [{ name: 'id', type: 'in', value: data.result }]
              })
              .then(({ data }) => ({
                serviceName: model.serviceName,
                options: model.select.resultsToSelectOptions(
                  _.get(data, 'result.rows')
                )
              }))
          );
      })
    ).then((results) => {
      this.setState({ relatedRecords: results });
    });
  }

  autocompleteModels(searchTerm, searchOnMount) {
    const { initialOptions } = this.state;
    let models;

    // If searchOnMount has been given as an array of models, use those for the search instead
    if (_.isArray(searchOnMount)) {
      models = searchOnMount;
    } else {
      models = this.props.models;
    }

    this.setState({ isFetching: true });
    const fetchAll = models
      .map((model) => {
        return model.select && model.select.autocomplete
          ? model.select.autocomplete(searchTerm, this.props)
          : undefined;
      })
      .filter(Boolean);

    Promise.all(fetchAll).then((results) => {
      this.setState((state) => {
        const remainingOptions = results.reduce(
          (all, options) =>
            _.uniqBy([...(all || []), ...(options || [])], 'value'),
          state.selected
        );

        return {
          options: remainingOptions,
          // If this is the searchOnMount search, setup initialOptions (combining with any initially passed options)
          ...(searchOnMount && {
            initialOptions: initialOptions.concat(remainingOptions)
          }),
          isFetching: false,
          searchTerm
        };
      });
    });
  }

  debouncedInputChange(event) {
    const { onInputChange } = this.props;

    if (onInputChange) {
      onInputChange(event);
    }

    const searchTerm = _.get(event, 'target.value', '');

    this.autocompleteModels(searchTerm);
  }

  inputChangeTimeout = null;
  handleInputChange(event) {
    const { selected } = this.state;

    if (!_.get(event, 'target.value', '')) {
      /**
       * We set the options to the selected state so that they don't disappear.
       *
       * We use options to show the selected values, we were previously setting this
       * to an empty array, which cause the values to disappear after clearing the
       * searchTerm.
       */
      this.setState({ searchTerm: '', options: selected });
    }

    if (_.get(event, 'target.value', '').length >= 2) {
      event.persist && event.persist();
      clearTimeout(this.inputChangeTimeout);
      this.setState({ isFetching: true }, () => {
        this.inputChangeTimeout = setTimeout(
          () => this.debouncedInputChange(event),
          this.props.debounce
        );
      });
    }
  }

  componentWillReceiveProps(nextProps) {
    const { shouldDoNoValueSearch } = nextProps;

    if (shouldDoNoValueSearch) {
      this.triggerNoValueOptionRequest();
    }
  }

  triggerNoValueOptionRequest() {
    this.autocompleteModels('');
  }

  // TODO: This is not the place for this functionality
  //  https://app.clubhouse.io/rexlabs/story/52340/cq-clean-up-select-component
  handleConsentCheck(value) {
    const {
      multi,
      consentFeature,
      onConsentCheck,
      contactPrivacy: { checkConsentForFeature }
    } = this.props;

    const currentValue = multi ? _.last(value) : value;

    if (!currentValue) {
      onConsentCheck();
      return;
    }

    const modelName = _.get(currentValue, 'model.modelName');

    if (modelName === 'contacts') {
      const contactId = _.get(currentValue, 'value');
      const contactName = _.get(currentValue, 'label');

      if (contactId) {
        this.setState({ valueLoading: true });

        checkConsentForFeature({
          contactId,
          featureId: consentFeature
        }).then((consents) => {
          onConsentCheck({
            consents,
            contact: { name: contactName, id: contactId }
          });

          if (consents.feature === 'block') {
            DialogBridge.notice.open({
              messageStyles: {
                newline: true
              },
              message: `You cannot send an email to ${contactName} because they have not given consent to receive email merges.`,
              title: 'Consent Error'
            });
          }

          this.setState({ valueLoading: false });
        });
      } else {
        onConsentCheck(); // This is called without any data to remove the consent warnings
      }
    }
  }

  handleChange(e) {
    const { consentFeature } = this.props;

    this.setState({ searchTerm: '' });
    if (hasFeatureFlags('enhanced_privacy') && !!consentFeature) {
      this.handleConsentCheck(_.get(e, 'target.value'));
    }
    this.props.onChange(e);
  }

  renderDropdownIndicator() {
    const { suggestive } = this.props;
    return (
      <Icon
        style={{
          color: COLORS.STATES.IDLE,
          height: '24px',
          width: '24px',
          transform: suggestive ? undefined : 'rotate(270deg)',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
        type={suggestive ? ICONS.CIRCLE_HOLLOW : ICONS.CIRCLE_FULL}
      />
    );
  }

  entitySelectDefaultFilter(options) {
    const filteredOptions = options.filter((opt) => !opt.isFixture);
    const fixtureOptions = options.filter((opt) => Boolean(opt.isFixture));

    return filteredOptions.concat(fixtureOptions);
  }

  render() {
    const {
      selected,
      searchTerm,
      options,
      initialOptions,
      relatedRecords,
      valueLoading
    } = this.state;
    const {
      hasFixtures,
      models,
      hasEmptyStateFixture,
      multi,
      filter,
      searchOnMount
    } = this.props;

    // Only show autocomplete fixtures once the user types
    // something in
    const fixtures =
      searchTerm || hasEmptyStateFixture
        ? models
            .map((model, index) => ({
              model,
              value: index,
              hasFixture: !!_.get(model, 'select.Fixture')
            }))
            .filter((fixture) => fixture.hasFixture)
        : [];

    const isSelected = selected.length > 0;

    const withTags =
      this.props.withTags === false
        ? false
        : models.some((model) => _.get(model, 'select.Value'));

    return (
      <Select
        filter={filter || this.entitySelectDefaultFilter}
        Option={ModelOption}
        OptionSelected={ModelOption}
        Value={ModelValue}
        isLoading={this.state.isLoading || this.state.isFetching}
        onInputChange={this.handleInputChange}
        shouldSelectResetInput={true}
        fixtures={hasFixtures ? fixtures : undefined}
        {...this.props}
        withTags={withTags || multi}
        readOnly={withTags && isSelected && !multi ? 'readonly' : undefined}
        shouldMenuOpen={withTags ? multi || !isSelected : true}
        DropdownIndicator={
          withTags && isSelected && !multi ? null : this.renderDropdownIndicator
        }
        onChange={this.handleChange}
        shouldOpenOnFocus={!!searchOnMount}
        // The combining of option sources here ensures selected options will show inside the select input
        options={
          !!initialOptions.length && !searchTerm
            ? _.uniqBy([...initialOptions, ...options], 'value')
            : options
        }
        relatedRecords={relatedRecords}
        valueLoading={valueLoading}
      />
    );
  }
}

class EntitySelect extends PureComponent {
  constructor(props) {
    super(props);

    // HACK: until we have a `@withModels` in model generator, we loop over all
    // models here once per component
    this.Component = WithEntityModels;
    props.models.forEach((model) => {
      this.Component = withModel(model)(this.Component);
    });
  }

  render() {
    const { Component } = this;
    return <Component {...this.props} />;
  }
}

export default EntitySelect;
