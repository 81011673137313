import Box from '@rexlabs/box';
import React from 'react';
import { StyleSheet, useStyles } from '@rexlabs/styling';

type CircleProps = {
  render?: () => React.ReactNode;
  children?: React.ReactNode;
};

const defaultStyles = StyleSheet({
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '100%',
    width: '36px',
    height: '36px',
    backgroundColor: 'rgba(0,3,6,0.1)',
    position: 'relative',
    margin: '0 5px',
    cursor: 'pointer'
  }
});

function Circle({ render, children, ...props }: CircleProps) {
  const s = useStyles(defaultStyles);

  return (
    <Box {...s.with('container')(props)}>
      {render?.()}
      {children}
    </Box>
  );
}

export default Circle;
