import React, { useMemo, createContext, ReactNode, useContext } from 'react';

import { noop } from 'lodash';
import { useRegion } from 'hooks/use-region';

import {
  ModuleName,
  ReportColumn
} from 'features/custom-reporting/modules/module-config-types';
import { useCurrentModuleConfig } from 'features/custom-reporting/modules/module-provider';
import { getReportColumns } from 'features/custom-reporting/utils/get-report-columns';

import { useReportQuery } from './use-report-query';
import {
  useUserSelectedReportState,
  UserSelectedReportState,
  UseUserSelectedReportStateResponse
} from './use-user-selected-report-state';
import { useSaveReportStateToUrl } from './use-save-report-state-to-url';
import { useReportCriteria } from './use-report-criteria';

export interface ReportState
  extends Pick<
    UseUserSelectedReportStateResponse,
    | 'userSelectedReportState'
    | 'reportDetailsToSave'
    | 'setUserFilterState'
    | 'setDateFilterState'
    | 'setLocationFilterState'
    | 'isSavedReportStateModified'
  > {
  criteria: any[];
  reportRows: any;
  status: any;
  allColumns: ReportColumn[];
  setSelectedPivotMode: (
    pivotMode: UserSelectedReportState['selectedPivotMode']
  ) => void;
  setSelectedGridFilters: (
    pivotMode: UserSelectedReportState['selectedGridFilters']
  ) => void;
  setSelectedGridColumns: (
    pivotMode: UserSelectedReportState['selectedGridColumns']
  ) => void;
  moduleName: ModuleName;
  reloadReportData: () => void;
}

const ReportStateContext = createContext<ReportState>({
  criteria: [],
  reportRows: [],
  status: null,
  allColumns: [],
  userSelectedReportState: {},
  reportDetailsToSave: {},
  setSelectedPivotMode: () => {},
  setSelectedGridFilters: () => {},
  setSelectedGridColumns: () => {},
  setUserFilterState: () => {},
  setDateFilterState: () => {},
  setLocationFilterState: () => {},
  moduleName: 'listings',
  isSavedReportStateModified: false,
  reloadReportData: noop
});

interface ReportStateProviderProps {
  children: ReactNode;
  moduleName: ModuleName;
  savedReportId?: string;
}

export function ReportStateProvider({
  children,
  moduleName,
  savedReportId
}: ReportStateProviderProps) {
  const region = useRegion();

  const {
    columns: allColumns,
    queryConfig,
    defaultVisibleColumns
  } = useCurrentModuleConfig();

  // memo'd because the grid resets columns when the column def changes
  const allColumnsMapped = useMemo(
    () =>
      getReportColumns({
        columns: allColumns,
        visibleColumns: defaultVisibleColumns,
        regionCode: region.code
      }),
    [allColumns, defaultVisibleColumns, region.code]
  );

  const {
    userSelectedReportState,
    reportDetailsToSave,
    setSelectedPivotMode,
    setSelectedGridFilters,
    setSelectedGridColumns,
    setSelectedUserFieldPreference,
    setSelectedDateFieldPreference,
    setShouldFilterByDate,
    setUserFilterState,
    setDateFilterState,
    setLocationFilterState,
    isSavedReportStateModified
  } = useUserSelectedReportState({ allColumnsMapped });

  const { criteria } = useReportCriteria({
    moduleName,
    setSelectedUserFieldPreference,
    setSelectedDateFieldPreference,
    setShouldFilterByDate,
    userSelectedReportState
  });

  const { data, status, reload: reloadReportData } = useReportQuery({
    allColumnsMapped,
    queryConfig,
    criteria,
    selectedGridColumns: userSelectedReportState?.selectedGridColumns,
    filteredGridColumns: userSelectedReportState?.selectedGridFilters
  });

  useSaveReportStateToUrl({ userSelectedReportState });

  const reportState = useMemo(
    () => ({
      moduleName,
      criteria,
      reportRows: data?.rowData?.rows,
      status,
      allColumns: allColumnsMapped,
      userSelectedReportState,
      reportDetailsToSave,
      setSelectedPivotMode,
      setSelectedGridFilters,
      setSelectedGridColumns,

      setSelectedUserFieldPreference,
      setSelectedDateFieldPreference,
      setShouldFilterByDate,
      setUserFilterState,
      setDateFilterState,
      setLocationFilterState,
      isSavedReportStateModified,
      reloadReportData
    }),
    [
      criteria,
      data,
      status,
      allColumnsMapped,
      moduleName,
      userSelectedReportState,
      reportDetailsToSave,
      setSelectedPivotMode,
      setSelectedGridFilters,
      setSelectedGridColumns,
      setSelectedUserFieldPreference,
      setSelectedDateFieldPreference,
      setShouldFilterByDate,
      setUserFilterState,
      setDateFilterState,
      setLocationFilterState,
      isSavedReportStateModified,
      reloadReportData
    ]
  );

  return (
    <ReportStateContext.Provider value={reportState} children={children} />
  );
}

export function useReportState() {
  return useContext(ReportStateContext);
}
