import { gql } from 'hooks/use-graphql-query';
import {
  CustomFields,
  KeyLocation,
  KeyRegister,
  Property,
  User
} from 'features/custom-reporting/data/fragments';
import { Listing } from 'features/custom-reporting/modules/listings/listing-data-fragments';

export const KeyLocationsReportQueryDefaultVariables = {
  include_subresource_listing: false,
  include_subresource_property: false,
  include_subresource_key_location: false,
  include_listing_custom_fields: false,
  listing_custom_field_ids: [],
  include_property_custom_fields: false,
  property_custom_field_ids: []
};

export const KeyLocationsReportQuery = gql`
  ${KeyRegister}
  ${KeyLocation}
  ${Listing}
  ${Property}
  ${User}
  ${CustomFields}

  query(
    $include_subresource_listing: Boolean!
    $include_subresource_property: Boolean!
    $include_subresource_key_location: Boolean!
    $include_listing_custom_fields: Boolean!
    $listing_custom_field_ids: [Int!]!
    $include_property_custom_fields: Boolean!
    $property_custom_field_ids: [Int!]!
    $criteria: [Criterion!]
    $order_bys: [OrderBy!]
    $limit: Int!
    $cursor: String
  ) {
    rowData: key_register(
      use_case: reporting
      criteria: $criteria
      order_bys: $order_bys
      limit: $limit
      cursor: $cursor
    ) {
      pagination {
        next_page {
          cursor
        }
      }
      rows {
        ...KeyRegister
        location @include(if: $include_subresource_key_location) {
          ...KeyLocation
        }
        # we need the listing id to know if the key is a listing or property key
        listing {
          id
        }
        listing @include(if: $include_subresource_listing) {
          ...Listing
          custom_field_values(field_ids: $listing_custom_field_ids)
            @include(if: $include_listing_custom_fields) {
            ...CustomFields
          }
          listing_agent_1 {
            name
          }
          listing_agent_2 {
            name
          }
          system_owner_user {
            ...User
          }
        }
        property @include(if: $include_subresource_property) {
          ...Property
          custom_field_values(field_ids: $property_custom_field_ids)
            @include(if: $include_property_custom_fields) {
            ...CustomFields
          }
          system_owner_user {
            ...User
          }
        }
      }
    }
  }
`;
