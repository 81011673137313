import React, { PureComponent, Fragment } from 'react';
import types from 'prop-types';

import { StyleSheet, styled } from '@rexlabs/styling';
import RexBasicCell from './basic';
import { ArrowDropdown } from 'view/components/action-menu';
import { autobind } from 'core-decorators';

const defaultStyles = StyleSheet({
  arrowDropdown: {
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    transition: 'opacity .2s',
    right: 10,
    zIndex: 10
  }
});

const basicCellStyles = StyleSheet({
  textSpanContainer: {
    width: '100%',
    position: 'relative'
  }
});

@styled(defaultStyles)
@autobind
class RexPrimaryTextCell extends PureComponent {
  static propTypes = {
    actionMenuItems: types.arrayOf(
      types.shape({
        label: types.node.isRequired,
        onClick: types.func
      })
    ).isRequired
  };

  _refs = {};

  state = {
    hovered: false
  };

  handleRowHover() {
    this.setState({ hovered: true });
  }

  handleRowUnhover() {
    this.setState({ hovered: false });
  }

  handleDropdownChange(open) {
    this.setState({ open });
  }

  componentDidMount() {
    // Sorry :(
    // We want the action menu button to appear when hovering the list row, not just
    // this cell, so this is a hacky workaround to do this by using event listeners
    // and local state instead of css for the hover styling!
    this._refs.row = this._refs.arrow.parentNode.parentNode.parentNode.parentNode;
    this._refs.row.addEventListener('mouseenter', this.handleRowHover);
    this._refs.row.addEventListener('mouseleave', this.handleRowUnhover);
  }

  componentWillUnmount() {
    this._refs.row.removeEventListener('mouseenter', this.handleRowHover);
    this._refs.row.removeEventListener('mouseleave', this.handleRowUnhover);
  }

  render() {
    const {
      styles: s,
      text,
      children,
      actionMenuItems,
      basicCellStyleOverrides,
      ...props
    } = this.props;
    return (
      <RexBasicCell
        styles={{ ...basicCellStyles, ...basicCellStyleOverrides }}
        {...props}
      >
        <Fragment>{text || children}</Fragment>
        <div
          {...s.with('arrowDropdown')({
            opacity: this.state.hovered || this.state.open ? 1 : 0
          })}
          ref={(e) => (this._refs.arrow = e)}
        >
          <ArrowDropdown
            small
            items={actionMenuItems}
            onChange={this.handleDropdownChange}
          />
        </div>
      </RexBasicCell>
    );
  }
}

export default RexPrimaryTextCell;
