/*
|-------------------------------------------------------------------------------
| Global Util Setup
|-------------------------------------------------------------------------------
|
| - Setup of environment
| - Log app configuration at start
| - Attach globals to the window object, like utilities shared between frames
|   - Shell <-> Classic Bridges
|
*/

import migrateLocalStorage from 'shared/utils/migration-local-storage';
import { PressAndEscapePropagator } from 'utils/events';
import config from 'shared/utils/config';
import BluebirdPromise from 'bluebird';

if (!global) {
  // eslint-disable-next-line
  global = global || window;
}

global.__DEV__ = process.env.NODE_ENV !== 'production';
if (__DEV__) {
  // eslint-disable-next-line no-console
  console.log(
    `
Development Environment:

  __DEV__ = true
  ${Object.keys(config).reduce(
    (all, key) => `${all}\n${key}: ${config[key]}`,
    ''
  )}`
  );
}

migrateLocalStorage();
PressAndEscapePropagator.register(window.document.body);

// Replaces Promise with the Bluebird implementation, and adds a global rejection
// handler.
global.Promise = BluebirdPromise;
global.Promise.onPossiblyUnhandledRejection(function (rejectionError) {
  const error = new Error(
    'Promise rejections should always be handled with catch().'
  );
  error.message = `${error.message}\n\n${rejectionError.message}`;
  error.name = `Unhandled Promise Rejection (${rejectionError.name})`;
  error.stack = rejectionError.stack;
  throw error;
});

// Disable warnings for missed returns in Bluebird.
// See https://github.com/petkaantonov/bluebird/issues/903
global.Promise.config({
  // Disables all warnings.
  warnings: false
});
