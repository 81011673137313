/* eslint-disable max-lines */
import { StyleSheet } from '@rexlabs/styling';
import { COLORS, PADDINGS } from 'src/theme';

const MENU_COLORS = {
  MAIN_HEADING: '#515151',
  MAIN_HEADING_ICON: '#7f8794',
  MAIN_HEADING_HOVER: '#0e0e10',
  MAIN_HEADING_ICON_HOVER: '#3F3F3F',
  SUB_HEADING: '#7f8794',
  SUB_HEADING_ITEM: '#515151',
  HOVER_BG: '#f7f8f9',
  BORDER: 'rgba(100,116,140,0.07)'
};

const EASE = 'cubic-bezier(0.25,0.1,0.25,1)';

// TODO: This is crap. We should split out separate components with their own styles. This is a stop gap solution.
//  https://app.clubhouse.io/rexlabs/story/58213/desktoptabletmenu-component-split-out-styles
export default StyleSheet({
  container: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    position: 'absolute',
    zIndex: 10,
    background: 'white',

    '&:before': {
      content: '" "',
      position: 'absolute',
      top: 0,
      right: 0,
      height: '100%',
      width: '1px',
      background: 'rgba(100, 116, 140, .05)',
      zIndex: 30,
      pointerEvents: 'none'
    },

    '& ul': {
      padding: 0,
      margin: 0,
      listStyle: 'none'
    },

    '& li': {
      transition: `width .25s ${EASE}, background 0.1s ease-out, color 0.1s ease-out`
    }
  },

  listItem: {
    padding: 0,
    margin: 0,
    listStyle: 'none',

    position: 'relative',
    zIndex: 2,
    overflow: 'hidden',
    flexShrink: 0,
    color: MENU_COLORS.MAIN_HEADING,
    cursor: 'pointer',

    height: '50px ',

    '& svg': {
      color: MENU_COLORS.MAIN_HEADING_ICON,
      transition: 'color 0.1s ease-out'
    },

    '&:hover': {
      background: MENU_COLORS.HOVER_BG,
      color: MENU_COLORS.MAIN_HEADING_HOVER
    },

    '&:hover > span': {
      color: MENU_COLORS.MAIN_HEADING_HOVER
    },

    '&:hover svg': {
      color: MENU_COLORS.MAIN_HEADING_ICON_HOVER
    }
  },

  listItemCurrent: {},

  listItemCurrentIndicator: {
    position: 'absolute',
    width: '3px',
    height: '50px',
    left: 0,
    top: '5px',
    backgroundColor: '#3AA6F1',
    borderRadius: '0 1px 1px 0'
  },

  listItemActive: {
    background: MENU_COLORS.HOVER_BG,
    color: MENU_COLORS.MAIN_HEADING_HOVER,

    '& svg': {
      color: MENU_COLORS.MAIN_HEADING_ICON_HOVERI
    }
  },

  mainListItem: {
    '@media (min-height: 1000px)': {
      height: '60px'
    }
  },

  subListItem: {
    display: 'flex',
    width: '100%',
    paddingTop: '10px',
    paddingBottom: '10px',
    marginTop: '-1px',
    fontWeight: 500,
    height: 'auto'
  },

  listWrapper: {
    overflow: 'auto',
    overflowX: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    position: 'relative',
    zIndex: 21
  },

  listMain: {
    marginBottom: '-1px !important',
    '@media (min-height: 795px)': {
      flex: 1,
      marginBottom: '0 !important'
    },
    zIndex: 20,
    position: 'relative',
    background: 'white',

    '& li': {
      width: '60px'
    }
  },

  listFixed: {
    zIndex: 20,
    position: 'relative',
    background: 'white',

    '& li': {
      width: '60px'
    }
  },

  containerTablet: {
    '& li': {
      width: 0
    },

    '&:before': {
      opacity: 0
    }
  },

  containerExpanded: {
    '& li': {
      width: '200px'
    },

    '&:before': {
      opacity: 1
    }
  },

  icon: {
    position: 'absolute',
    zIndex: 10,
    width: '60px',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    '& svg': {
      width: '20px',
      height: 'auto'
    }
  },

  newTagSubItem: {
    position: 'absolute',
    right: '10px',
    top: '9px',
    '& > div > div': {
      padding: '0 8px',
      '& > span': {
        fontSize: '10px',
        fontWeight: '700'
      }
    }
  },

  label: {
    // width: '200px',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    paddingLeft: '60px',
    position: 'absolute',
    top: 0,
    left: 0,
    opacity: 1,
    transition: `opacity .25s ${EASE}, left .25s ${EASE}`,
    fontSize: '15px',
    fontWeight: 600,
    whiteSpace: 'nowrap'
  },

  labelHidden: {
    opacity: 0,
    left: '-30px'
  },

  labelVisible: {
    opacity: 1,
    left: 0,
    width: '100%'
  },

  listTop: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    position: 'absolute'
  },

  listSub: {
    width: '220px',
    left: '100%',
    height: '100%',
    position: 'absolute',
    zIndex: 15,
    top: 0,
    background: 'white',
    transform: 'translateX(-220px)',
    transition: `transform .25s ${EASE}`,
    boxShadow: '2px 0 20px rgba(7, 17, 47, .05)',
    overflow: 'auto',

    '& li': {
      width: '220px'
    }
  },

  listSubVisible: {
    transform: 'translateX(0)'
  },

  noAnimate: {
    transitionDuration: '0s'
  },

  labelSubVisible: {},

  separator: {
    height: '1px',
    background: MENU_COLORS.BORDER,
    position: 'relative',
    zIndex: 2
  },

  subListItemHeading: {
    marginTop: '18px',
    marginBottom: '0px',
    height: '36px',
    cursor: 'default',
    paddingTop: 0,
    paddingBottom: 0,

    '&:before': {
      content: '" "',
      position: 'absolute',
      height: '1px',
      zIndex: -1,
      width: '170px',
      left: '50%',
      marginLeft: '-85px',
      background: 'rgba(90,126,150,0.1)',
      bottom: 0
    },

    '&:hover': {
      background: 'white'
    },

    // HACK: with the upgrade of the styling library, some styling precendences
    // got a bit messed up :/
    '&& svg': {
      width: '15px',
      height: 'auto',
      color: MENU_COLORS.SUB_HEADING
    },

    '&:hover svg': {
      color: MENU_COLORS.SUB_HEADING
    },

    '&:hover span': {
      color: MENU_COLORS.SUB_HEADING
    }
  },

  subIcon: {
    left: '12px',
    width: '36px',
    height: '36px'
  },

  subLabel: {
    paddingLeft: '25px',
    paddingRight: '25px',
    fontSize: '14px',
    lineHeight: '20px',
    opacity: 1,
    color: MENU_COLORS.SUB_HEADING_ITEM,
    position: 'relative',
    fontWeight: 500,
    whiteSpace: 'normal'
  },
  subLabelText: {
    '-webkit-line-clamp': '2',
    // note the below properties are required for line clamp to work
    display: '-webkit-box',
    '-webkit-box-orient': 'vertical',
    overflow: 'hidden'
  },

  subLabelHeading: {
    paddingLeft: '44px',
    position: 'absolute',
    color: MENU_COLORS.SUB_HEADING,
    fontSize: '12px',
    lineHeight: '16px',
    textTransform: 'uppercase',
    letterSpacing: '0.1px',
    fontWeight: 700
  },

  expandMenu: {
    position: 'relative',
    zIndex: 30,
    backgroundColor: MENU_COLORS.HOVER_BG,
    borderTop: `1px solid ${MENU_COLORS.BORDER}`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '18px',
    height: '60px',
    cursor: 'pointer',
    transition: `width 0.25s ${EASE}`,
    width: '100%'
  },

  expandMenuIcon: {
    color: MENU_COLORS.MAIN_HEADING,
    transition: 'transform 0.15s ease'
  },

  fakeItem: {
    position: 'absolute',
    zIndex: 1,
    top: 0,
    bottom: 0,
    left: 0,
    right: 0
  },

  info: {
    padding: '10px 25px',
    fontSize: '13px',
    lineHeight: '17px',
    fontStyle: 'italic',
    color: COLORS.PRIMARY.SAND
  },

  hide: {
    display: 'none'
  },

  sparkle: {
    marginLeft: '6px',
    height: '10px',
    width: 'auto'
  },

  externalLinkIconWrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    paddingRight: PADDINGS.S
  },

  externalLinkIcon: {
    '> svg': {
      color: `${COLORS.PRIMARY.BLUE} !important`,
      width: '1.2rem',
      heigth: '1.2rem'
    }
  }
});
