import { useCallback } from 'react';
import { parseRouteToUrl, push, useWhereabouts } from '@rexlabs/whereabouts';

import { useLens } from 'components/record-list-screen/utils/use-lens';
import { OrderBy } from 'features/pipelines/components/order-by-dropdown';

export type OrderByString = string;
export type OrderDir = 'asc' | 'desc';

type getOrderBy = () => { orderDir: OrderDir; orderBy: OrderByString };

export function useOrderBy({ lenses }) {
  const { path, query, hashQuery } = useWhereabouts();
  const { orderBy: lensOrderBy, orderDir: lensOrderDir } = useLens(lenses);

  const getOrderByParams: getOrderBy = () => {
    if (!hashQuery?.orderBy) {
      return { orderBy: lensOrderBy, orderDir: lensOrderDir };
    }

    // NOTE: we indicate the order direction in the URL through a `'` that
    // will be appended to the column id for descending order direction
    const orderDir: OrderDir = hashQuery?.orderBy.endsWith("'")
      ? 'desc'
      : 'asc';

    const orderBy: OrderByString =
      orderDir === 'desc'
        ? hashQuery?.orderBy?.slice?.(0, -1)
        : hashQuery?.orderBy;

    return { orderDir, orderBy };
  };

  const { orderBy, orderDir } = getOrderByParams();

  const setOrderBy = useCallback(
    (fieldId: string, dir?: string) => {
      // HACK: to mimic current classic behaviour, we want to avoid
      // encoding the `orderBy` URL argument, so we replace it in the
      // final path after the route has been parsed
      const newValue = dir === 'desc' ? `${fieldId}'` : fieldId;
      const newpath = parseRouteToUrl({
        path,
        query,
        hash: undefined,
        hashQuery: {
          ...hashQuery,
          orderBy: '-----ORDERBY-----'
        }
      });
      push({
        config: {
          path: newpath.replace('-----ORDERBY-----', newValue)
        }
      });
    },
    [path, query, hashQuery]
  );

  return { orderBy, orderDir, setOrderBy };
}

export function convertOrderByStringToObject(
  orderByString: OrderByString
): OrderBy {
  const direction = orderByString.endsWith("'") ? 'desc' : 'asc';
  return {
    [orderByString.endsWith("'")
      ? orderByString.slice(0, -1)
      : orderByString]: direction
  };
}
