/*
|-------------------------------------------------------------------------------
| App Setup
|-------------------------------------------------------------------------------
|
| Connects the DOM to our App. Exposes core App data at DEV time.
|
| It also should be the only file that is importing the store - all other usages
| of store are through react connectors or middleware.
|
*/

import 'core-js';
// IMPORTANT: this needs to be imported before anything else, because it registers behaviours
// to `@rexlabs/styling` that change the way classes are generated!
import 'utils/global-error-handler';
import 'utils/globals';

// Replace the lodash memoize cache implementation.
// This import has a side effect and must occur early - before anything calls memoize.
import 'utils/memoize-cache';

// Run this before we begin rendering anything so we have feature flags configured globally
import 'utils/feature-flags';

import React, { Component, Fragment } from 'react';

import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { TextProvider } from '@rexlabs/text';
import { PortalProvider } from '@rexlabs/portal';
import { api } from 'shared/utils/api-client';
import { initThirdPartyScripts } from 'shared/utils/analytics';
import config from 'shared/utils/config';
import LocalStorage from 'shared/utils/local-storage';
import Analytics from 'shared/utils/vivid-analytics';
import { FlagsProvider } from 'shared/contexts/flags';
import { webVitals } from 'shared/utils/analytics/web-vitals';
import analyticsMiddleware from 'shared/utils/analytics/middleware';
import { store } from 'src/store';
import sessionModel from 'data/models/custom/session';
import { Bridges } from 'data/classic-bridges';
import { initTheme, TEXTS } from 'src/theme';
import ShellApp from 'view/app';

// Note: Ensure this is imported last, as it needs to inject styles last.
import LEGACY_COMPONENTS from 'src/theme/legacy/components';
import { COMPONENTS } from 'src/theme/components';
import ShellStylesProvider from 'components/shell-styles-provider';
import { FullviewScript } from 'src/lib/fullview-script';

interface BugsnagClient {
  user: any;
  metaData: any;
  notify: (name: string, args?: any) => void;
  leaveBreadcrumb: (name: string, args?: any) => void;
}

declare global {
  interface Window {
    FS?: {
      getCurrentSessionURL: (arg: boolean) => string;
      event: (name: string, args: any) => void;
    };

    bugsnagClient?: BugsnagClient;
    bugsnag?: (config: any) => BugsnagClient;

    flagsmith: any;
    $zopim: any;

    sessionStorage: any;
    app: {
      config: any;
      store: any;
      api: any;
      storage: any;
    };
    Rex2FrameWindow: any;
    Shell: {
      Bridges: any;
      Release: {
        HASH?: string;
        STAGE?: string;
        VERSION?: string;
      };
      version?: string;
    };
  }
}

Analytics.setHandlers([analyticsMiddleware]);
initThirdPartyScripts(config, store, sessionModel);

const webVitalsHandler = ({ name, data: { value } }) => {
  Analytics.performance({
    event: 'WebVitals',
    properties: {
      name,
      value,
      applicationName: 'rex-shell'
    }
  });
};
webVitals({ applicationName: 'rex-shell', webVitalsHandler });

window.app = {
  config,
  store,
  api,
  storage: LocalStorage
};

window.Shell = {
  Bridges,
  Release: config.RELEASE,
  version: config.RELEASE?.VERSION
};

// we use window.performance here as it's an existing browser api that we can use to track performance events
// before our analytics singleton is loaded.
window.performance?.mark?.('shellAppInit');

const performanceMarks = window.performance?.getEntriesByType?.('mark') || [];
performanceMarks.forEach((mark) =>
  Analytics.performance({
    task: 'Shell-FullInit',
    event: mark.name,
    taskOriginTime: mark.startTime
  })
);

// Setup global parts of theme
initTheme();

const COMBINED_COMPONENTS = {
  ...LEGACY_COMPONENTS,
  ...COMPONENTS
};

class ShellMount extends Component {
  render() {
    return (
      <ShellStylesProvider>
        <PortalProvider>
          <Provider store={store}>
            <FlagsProvider>
              <TextProvider text={TEXTS}>
                <Fragment>
                  <FullviewScript />
                  <ShellApp />
                </Fragment>
              </TextProvider>
            </FlagsProvider>
          </Provider>
        </PortalProvider>
      </ShellStylesProvider>
    );
  }
}

render(<ShellMount />, document.querySelector('#app'), () => {
  const root = document.getElementsByTagName('html')[0];
  root.classList.add('ready');
});
