import React from 'react';
import { render } from 'react-dom';
import CatastrophicErrorScreen from 'view/screens/catastrophic-error';
import LocalStorage from 'shared/utils/local-storage';
import Analytics from 'shared/utils/vivid-analytics';

/**
 * Global Error Handler
 */
(() => {
  const attemptToShowCatastrophicError = (args) => {
    const [message, source, lineno, colno, error] = args || [];

    const errorObject = error || new Error(message);

    // NOTE: This prevents showing the error screen prematurely as Shell
    // errors can fire before the iFrame has even started rendering on the page.
    // We want to detect each error separately and compare it against its own root element.
    const isClassicError = !!errorObject.isClassic;

    /**
     * The timeout is here so we can wait for the main execution to finish.
     *
     * Essentially we only want to show the error page if the main app didn't load
     * correctly. We don't want this page to show up randomly while the user is
     * navigating around Rex. This is the best way we are able to check for critical errors.
     */
    setTimeout(() => {
      const shellRoot =
        window && window.document && window.document.querySelector('#app');
      const isShellCatastrophicFailure =
        !isClassicError && !shellRoot.innerHTML;

      const classicRoot =
        window &&
        window.Rex2FrameWindow &&
        window.Rex2FrameWindow.document &&
        window.Rex2FrameWindow.document.querySelector('#stageform');

      // NOTE: offsetHeight of 0 means we have "display: none" on the target element
      // https://davidwalsh.name/offsetheight-visibility
      const isClassicCatastrophicFailure =
        isClassicError &&
        ((classicRoot && !classicRoot.offsetHeight) || !classicRoot);

      // This is caused when a deploy has occured and the user is still
      // using old JS files
      const chunkFailure =
        message.includes('Loading chunk') && message.includes('failed.');

      const shouldShowErrorScreen =
        chunkFailure ||
        isClassicCatastrophicFailure ||
        isShellCatastrophicFailure;

      if (shouldShowErrorScreen) {
        Analytics.error({
          error: errorObject,
          options: {
            beforeSend: (report) => {
              report.errorMessage = `CATASTROPHIC: ${errorObject.message}`;
              report.updateMetaData('isCatastrophic', true);
              report.updateMetaData('errorDetails', {
                message,
                source,
                lineno,
                colno,
                error,
                isClassicError,
                isCatastrophic: true
              });

              /**
               * The below is to make sure we are sending office details when we can. If the redux
               * store has not been initialised yet then we may be able to grab their informartion
               * from local storage.
               *
               * We try to fetch the users office details from local storage but if that fails
               * we fallback to just sending a generic message. It implies that the user
               * has gotten them selves into a very bad session state.
               */
              if (!report.metaData.officeDetails) {
                let possibleOfficeDetails = null;
                const badSessionState = {
                  state: 'very-broken',
                  user: 'unknown'
                };
                try {
                  possibleOfficeDetails = LocalStorage.get('office_details');
                  report.updateMetaData(
                    'officeDetails',
                    possibleOfficeDetails || badSessionState
                  );
                } catch (e) {
                  report.updateMetaData('officeDetails', badSessionState);
                }
              }
            }
          }
        });
        render(<CatastrophicErrorScreen />, shellRoot);
      } else {
        // Even if we don't think it's a catastrophic error, we still want to track it in bugsnag
        Analytics.error({ error: errorObject });
      }
    }, 0);
  };

  /**
   * NOTE: This does not break Bugsnag reporting. Bugsnag relies on window.onerror to report
   * uncaught thrown errors.
   *
   * The way Bugsnag works means we can use our own window.onerror and Bugsnags reporting.
   * We are loading this global error handler file before Bugsnag is initialised. Once Bugsnag is
   * initialised it takes any current assigned functions to window.onerror and wraps it. Bugsnag
   * will report the error and then call the wrapped function after it has finished reporting.
   *
   * NOTE: It always calls the wrapped function even if error reporting fails.
   */
  window.onerror = (...args) => {
    attemptToShowCatastrophicError(args);
  };
})();
