import { gql } from 'hooks/use-graphql-query';
import {
  User,
  Property,
  Chain,
  CustomFields
} from 'features/custom-reporting/data/fragments';
import {
  Contact,
  ContactEmails,
  ContactPhones,
  MatchProfile
} from 'features/custom-reporting/modules/contacts/contact-data-fragments';
import { Contract } from './contract-data-fragments';
import { CommissionWorksheet } from 'features/custom-reporting/modules/commission-by-sale/commission-by-sale-data-fragments';
import { Listing } from 'features/custom-reporting/modules/listings/listing-data-fragments';

export const contractReportQueryDefaultVariables = {
  contract_custom_field_ids: [],
  include_contract_custom_fields: false,
  include_subresource_agent: false,
  include_subresource_listing: false,
  include_listing_custom_fields: false,
  listing_custom_field_ids: [],
  include_subresource_property: false,
  include_property_custom_fields: false,
  property_custom_field_ids: [],
  include_subresource_vendor: false,
  include_vendor_custom_fields: false,
  vendor_custom_field_ids: [],
  include_subresource_vendor_solicitor: false,
  include_vendor_solicitor_custom_fields: false,
  vendor_solicitor_custom_field_ids: [],
  include_subresource_vendor_solicitor_contact: false,
  include_vendor_solicitor_contact_custom_fields: false,
  vendor_solicitor_contact_custom_field_ids: [],
  include_subresource_purchasers: false,
  include_purchasers_custom_fields: false,
  purchasers_custom_field_ids: [],
  include_subresource_purchaser_solicitor: false,
  include_purchaser_solicitor_custom_fields: false,
  purchaser_solicitor_custom_field_ids: [],
  include_subresource_purchaser_solicitor_contact: false,
  include_purchaser_solicitor_contact_custom_fields: false,
  purchaser_solicitor_contact_custom_field_ids: [],
  include_subresource_commissions: false,
  include_subresource_chain: false
};

export const ContractReportQuery = gql`
  ${User}
  ${Contract}
  ${Listing}
  ${Property}
  ${Contact}
  ${ContactEmails}
  ${ContactPhones}
  ${MatchProfile}
  ${Chain}
  ${CommissionWorksheet}
  ${CustomFields}

  fragment ContactForContracts on Contact {
    ...Contact
    phones {
      ...ContactPhones
    }
    emails {
      ...ContactEmails
    }
    system_owner_user {
      ...User
    }
    latest_match_profile {
      ...MatchProfile
    }
  }

  query(
    $include_subresource_agent: Boolean!
    $include_contract_custom_fields: Boolean!
    $contract_custom_field_ids: [Int!]!
    $include_subresource_listing: Boolean!
    $include_listing_custom_fields: Boolean!
    $listing_custom_field_ids: [Int!]!
    $include_subresource_property: Boolean!
    $include_property_custom_fields: Boolean!
    $property_custom_field_ids: [Int!]!
    $include_subresource_vendor: Boolean!
    $include_vendor_custom_fields: Boolean!
    $vendor_custom_field_ids: [Int!]!
    $include_subresource_vendor_solicitor: Boolean!
    $include_vendor_solicitor_custom_fields: Boolean!
    $vendor_solicitor_custom_field_ids: [Int!]!
    $include_subresource_vendor_solicitor_contact: Boolean!
    $include_vendor_solicitor_contact_custom_fields: Boolean!
    $vendor_solicitor_contact_custom_field_ids: [Int!]!
    $include_subresource_purchasers: Boolean!
    $include_purchasers_custom_fields: Boolean!
    $purchasers_custom_field_ids: [Int!]!
    $include_subresource_purchaser_solicitor: Boolean!
    $include_purchaser_solicitor_custom_fields: Boolean!
    $purchaser_solicitor_custom_field_ids: [Int!]!
    $include_subresource_purchaser_solicitor_contact: Boolean!
    $include_purchaser_solicitor_contact_custom_fields: Boolean!
    $purchaser_solicitor_contact_custom_field_ids: [Int!]!
    $include_subresource_commissions: Boolean!
    $include_subresource_chain: Boolean!
    $criteria: [Criterion!]
    $order_bys: [OrderBy!]
    $limit: Int!
    $cursor: String
  ) {
    rowData: contracts(
      criteria: $criteria
      use_case: reporting
      order_bys: $order_bys
      limit: $limit
      cursor: $cursor
    ) {
      pagination {
        next_page {
          cursor
        }
      }
      rows {
        ...Contract
        custom_field_values(field_ids: $contract_custom_field_ids)
          @include(if: $include_contract_custom_fields) {
          ...CustomFields
        }
        agent @include(if: $include_subresource_agent) {
          ...User
        }
        listing @include(if: $include_subresource_listing) {
          ...Listing
          custom_field_values(field_ids: $listing_custom_field_ids)
            @include(if: $include_listing_custom_fields) {
            ...CustomFields
          }
          listing_agent_1 {
            name
          }
          listing_agent_2 {
            name
          }
          system_owner_user {
            ...User
          }
        }
        property: listing @include(if: $include_subresource_property) {
          property {
            ...Property
            custom_field_values(field_ids: $property_custom_field_ids)
              @include(if: $include_property_custom_fields) {
              ...CustomFields
            }
            system_owner_user {
              ...User
            }
          }
        }
        owner: listing @include(if: $include_subresource_vendor) {
          contact_relationships(limit: 1, type: "owner") {
            relationship_type {
              id
            }
            contact {
              ...ContactForContracts
              custom_field_values(field_ids: $vendor_custom_field_ids)
                @include(if: $include_vendor_custom_fields) {
                ...CustomFields
              }
            }
          }
        }
        vendor_solicitor: listing
          @include(if: $include_subresource_vendor_solicitor) {
          legal_solicitor {
            ...ContactForContracts
            custom_field_values(field_ids: $vendor_solicitor_custom_field_ids)
              @include(if: $include_vendor_solicitor_custom_fields) {
              ...CustomFields
            }
          }
        }
        vendor_solicitor_contact: listing
          @include(if: $include_subresource_vendor_solicitor_contact) {
          legal_solicitor_contact {
            ...ContactForContracts
            custom_field_values(
              field_ids: $vendor_solicitor_contact_custom_field_ids
            ) @include(if: $include_vendor_solicitor_contact_custom_fields) {
              ...CustomFields
            }
          }
        }
        purchtenant_contacts @include(if: $include_subresource_purchasers) {
          ...ContactForContracts
          custom_field_values(field_ids: $purchasers_custom_field_ids)
            @include(if: $include_purchasers_custom_fields) {
            ...CustomFields
          }
        }
        purchtenant_solicitor
          @include(if: $include_subresource_purchaser_solicitor) {
          ...ContactForContracts
          custom_field_values(field_ids: $purchaser_solicitor_custom_field_ids)
            @include(if: $include_purchaser_solicitor_custom_fields) {
            ...CustomFields
          }
        }
        purchtenant_solicitor_contact
          @include(if: $include_subresource_purchaser_solicitor_contact) {
          ...ContactForContracts
          custom_field_values(
            field_ids: $purchaser_solicitor_contact_custom_field_ids
          ) @include(if: $include_purchaser_solicitor_contact_custom_fields) {
            ...CustomFields
          }
        }
        chain_instance @include(if: $include_subresource_chain) {
          assignee_user {
            ...User
          }
          chain {
            ...Chain
          }
        }
        commission_worksheet @include(if: $include_subresource_commissions) {
          ...CommissionWorksheet
        }
      }
    }
  }
`;
