import { useCallback, useMemo, useState } from 'react';
import { omit } from 'lodash';
import { useModelActions } from '@rexlabs/model-generator';

import { SelectItem } from 'types/select';

import {
  FilterModel,
  ColumnState
} from 'features/custom-reporting/components/data-grid';
import {
  DateRangeItem,
  reportingFiltersModel
} from 'features/custom-reporting/data/reporting-filters';
import { useIncomingUrlState } from 'features/custom-reporting/hooks/use-incoming-url-state';
import { usePersistedReportState } from 'features/custom-reporting/hooks/use-persisted-report-state';
import { sanitizeIncomingReportState } from 'features/custom-reporting/utils/sanitize-incoming-report-state';
import { useSavedReport } from './use-async-report-data';
import { useDefaultModuleSettings } from './use-default-module-settings';
export interface DateParams {
  dateRangeValue: DateRangeItem;
  dateFilterPreference: string;
}

export interface UserParams {
  // TODO: It would be better if we just used userIds the majority of the time,
  //  and did the query to replace the id with the selectItem later.
  userValue: SelectItem[];
  userFilterPreference?: string;
}

export interface LocationParams {
  locationValue: SelectItem[];
}

export type SetDateFilterStateFunction = ({
  dateRangeValue,
  dateFilterPreference
}: DateParams) => void;

export type SetUserFilterStateFunction = ({
  userValue,
  userFilterPreference
}: UserParams) => void;

export type SetLocationFilterStateFunction = ({
  locationValue
}: LocationParams) => void;

export interface UserSelectedReportState {
  selectedGridColumns?: ColumnState[];
  selectedGridFilters?: FilterModel;
  selectedPivotMode?: boolean;
  selectedDateFieldPreference?: string;
  selectedUserFieldPreference?: string;
  shouldFilterByDate?: boolean;
  selectedDateFilter?: DateParams['dateRangeValue'];
  selectedUserFilter?: UserParams['userValue'];
  selectedLocationFilter?: LocationParams['locationValue'];
  reportId?: string;
}

export type ReportDetailsToSave = Omit<
  UserSelectedReportState,
  'selectedUserFilter' | 'reportId'
>;

interface UseUserSelectedReportState {
  allColumnsMapped: any;
}

export interface UseUserSelectedReportStateResponse {
  userSelectedReportState: UserSelectedReportState;
  reportDetailsToSave: ReportDetailsToSave;
  setSelectedGridColumns: (
    gridColumns: UserSelectedReportState['selectedGridColumns']
  ) => void;
  setSelectedGridFilters: (
    gridFilters: UserSelectedReportState['selectedGridFilters']
  ) => void;
  setSelectedPivotMode: (
    pivotMode: UserSelectedReportState['selectedPivotMode']
  ) => void;
  setSelectedUserFieldPreference: (preference: string) => void;
  setSelectedDateFieldPreference: (preference: string) => void;
  setShouldFilterByDate: (shouldFilterByDate: boolean) => void;
  setDateFilterState: SetDateFilterStateFunction;
  setUserFilterState: SetUserFilterStateFunction;
  setLocationFilterState: SetLocationFilterStateFunction;
  isSavedReportStateModified: boolean;
}

export function useUserSelectedReportState({
  allColumnsMapped
}: UseUserSelectedReportState): UseUserSelectedReportStateResponse {
  // the things we want to persist in localstorage, we store in reportingFiltersModel
  const { persistUserFilter, persistLocationFilter } = useModelActions(
    reportingFiltersModel
  );

  const { selectedSavedReport } = useSavedReport();

  const initialStateFromLocalStorage = usePersistedReportState();

  const incomingUrlState = useIncomingUrlState({ allColumnsMapped });

  const defaultSettings = useDefaultModuleSettings();

  const selectedCustomReportDetails = useMemo(
    () =>
      sanitizeIncomingReportState(
        allColumnsMapped,
        selectedSavedReport?.report_details || {}
      ),
    // We only want this to run if the report changes - so on load or save
    [selectedSavedReport?.report_details]
  );

  const initialState: UserSelectedReportState = {
    ...(selectedSavedReport?.id ? { reportId: selectedSavedReport.id } : {}),
    ...defaultSettings,
    ...initialStateFromLocalStorage,
    ...selectedCustomReportDetails,
    ...incomingUrlState
  };

  const [
    userSelectedReportState,
    setUserSelectedReportState
  ] = useState<UserSelectedReportState>(initialState);

  /**
   * NOTE: We need to get the userSelectedReportState to a shape that we can save
   * to the BE. This may change over time, but for now:
   *   - exclude the reportId which is in the url
   *   - exclude selectedUserFilter, as we don't want to store the selected users
   */

  const reportDetailsToSave: ReportDetailsToSave = useMemo(
    () => omit(userSelectedReportState, ['selectedUserFilter', 'reportId']),
    [userSelectedReportState]
  );

  // if this gets much more crazy we might look to move this into a reducer
  const setSelectedGridColumns = useCallback(
    (gridColumns: UserSelectedReportState['selectedGridColumns']) => {
      return setUserSelectedReportState((state) => ({
        ...state,
        selectedGridColumns: gridColumns
      }));
    },
    [setUserSelectedReportState]
  );

  const setSelectedGridFilters = useCallback(
    (gridFilters: UserSelectedReportState['selectedGridFilters']) => {
      return setUserSelectedReportState((state) => ({
        ...state,
        selectedGridFilters: gridFilters
      }));
    },
    [setUserSelectedReportState]
  );

  const setSelectedPivotMode = useCallback(
    (pivotMode: UserSelectedReportState['selectedPivotMode']) => {
      return setUserSelectedReportState((state) => ({
        ...state,
        selectedPivotMode: pivotMode
      }));
    },
    [setUserSelectedReportState]
  );

  const setSelectedUserFieldPreference = useCallback(
    (selectedUserFieldPreference: string) => {
      return setUserSelectedReportState((state) => ({
        ...state,
        selectedUserFieldPreference
      }));
    },
    [setUserSelectedReportState]
  );

  const setSelectedDateFieldPreference = useCallback(
    (selectedDateFieldPreference: string) => {
      return setUserSelectedReportState((state) => ({
        ...state,
        selectedDateFieldPreference
      }));
    },
    [setUserSelectedReportState]
  );

  const setShouldFilterByDate = useCallback(
    (shouldFilterByDate) => {
      return setUserSelectedReportState((state) => ({
        ...state,
        shouldFilterByDate
      }));
    },
    [setUserSelectedReportState]
  );

  const setDateFilterState = useCallback(
    ({ dateRangeValue, dateFilterPreference }: DateParams) => {
      if (dateRangeValue?.preset === 'none') {
        setUserSelectedReportState((state) => ({
          ...state,
          selectedDateFilter: {
            preset: dateRangeValue.preset
          },
          shouldFilterByDate: false
        }));
      } else {
        setUserSelectedReportState((state) => ({
          ...state,
          shouldFilterByDate: true,
          selectedDateFilter: dateRangeValue
        }));
      }
      if (dateFilterPreference) {
        setSelectedDateFieldPreference(dateFilterPreference);
      }
    },
    [setUserSelectedReportState]
  );

  const setUserFilterState = useCallback(
    ({ userValue, userFilterPreference }: UserParams) => {
      setUserSelectedReportState((state) => ({
        ...state,
        selectedUserFilter: userValue,
        ...(userFilterPreference
          ? {
              selectedUserFieldPreference: userFilterPreference
            }
          : {})
      }));
      persistUserFilter(userValue);
    },
    [setUserSelectedReportState]
  );

  const setLocationFilterState = useCallback(
    ({ locationValue }: LocationParams) => {
      setUserSelectedReportState((state) => ({
        ...state,
        selectedLocationFilter: locationValue
      }));
      persistLocationFilter(locationValue);
    },
    [setUserSelectedReportState]
  );

  const isSavedReportStateModified = useMemo(() => {
    return (
      !!userSelectedReportState?.reportId &&
      // NOTE: I've switched from using a deep compare as for some reason is was returning
      // incorrect results for what I could see. We don't need to compare prototype properties
      // so we can just use a shallow compare.
      JSON.stringify(selectedCustomReportDetails) !==
        JSON.stringify(reportDetailsToSave)
    );
  }, [
    reportDetailsToSave,
    selectedCustomReportDetails,
    userSelectedReportState?.reportId
  ]);

  return {
    userSelectedReportState,
    reportDetailsToSave,
    setSelectedGridColumns,
    setSelectedGridFilters,
    setSelectedPivotMode,
    setSelectedUserFieldPreference,
    setSelectedDateFieldPreference,
    setShouldFilterByDate,
    setDateFilterState,
    setUserFilterState,
    setLocationFilterState,
    isSavedReportStateModified
  };
}
