import { useState } from 'react';

export function useTextGenerationFormState(initialValues = {}) {
  const [state, setState] = useState<Record<string, any>>(initialValues);
  const setFormValues = (values: typeof state) => {
    setState({ ...state, ...values });
  };

  return [state, setFormValues];
}
