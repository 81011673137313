import _ from 'lodash';

import { Generator } from 'shared/utils/models';
import { api } from 'shared/utils/api-client';
import { ContractConditionsArgs } from './types';

export interface ContractCondition {
  id: string;
  name: string;
  description: string;
  _is_editable: boolean | null;
  is_enabled: boolean;
  is_hidden: boolean;
  library: null | {
    id: string;
    library_name: string;
  };
  account_id: string;

  allow_business_sale: boolean;
  allow_commercial_rental: boolean;
  allow_commercial_sale_rental: boolean;
  allow_commercial_sale: boolean;
  allow_land_sale: boolean;
  allow_residential_sale: boolean;
  allow_rural_sale: boolean;

  add_business_sale: boolean;
  add_commercial_rental: boolean;
  add_commercial_sale_rental: boolean;
  add_commercial_sale: boolean;
  add_land_sale: boolean;
  add_residential_sale: boolean;
  add_rural_sale: boolean;
  is_editable: boolean;
  order: number;
}

export interface ContractConditionsModel {
  fetchContractConditions: (
    args?: ContractConditionsArgs
  ) => Promise<ContractCondition[]>;
  addContractCondition: (name: string) => Promise<ContractCondition>;
  fetchContractCondition: (id: string) => Promise<ContractCondition>;
  updateContractCondition: (
    payload: ContractCondition
  ) => Promise<ContractCondition>;
  duplicateContractCondition: (args: {
    id: string;
    name: string;
  }) => Promise<string>;
  removeContractCondition: (id: string) => void;
  archiveContractCondition: (id: string) => void;
  sortContractConditions: (conditions: ContractCondition[]) => void;
}

const TYPE = 'contractConditions';

const actionCreators = {
  fetchContractCondition: {
    request: (id: string) =>
      api
        .post('AdminContractConditions::read', { id })
        .then((response) => response.data.result),
    reduce: {
      initial: _.identity,
      success: _.identity,
      failure: _.identity
    }
  },
  fetchContractConditions: {
    request: (args?: ContractConditionsArgs) =>
      api
        .post('AdminContractConditions::search', args)
        .then((response) =>
          response.data.result?.rows.sort((a, b) => a.order - b.order)
        ),
    reduce: {
      initial: _.identity,
      success: _.identity,
      failure: _.identity
    }
  },
  addContractCondition: {
    request: (name: string) =>
      api
        .post('AdminContractConditions::create', { data: { name } })
        .then((response) => response.data.result),
    reduce: {
      initial: _.identity,
      success: _.identity,
      failure: _.identity
    }
  },
  updateContractCondition: {
    request: (args: ContractCondition) =>
      api
        .post('AdminContractConditions::update', { data: args })
        .then((response) => response.data.result),
    reduce: {
      initial: _.identity,
      success: _.identity,
      failure: _.identity
    }
  },
  removeContractCondition: {
    request: (id: string) => api.post('AdminContractConditions::purge', { id }),
    reduce: {
      initial: _.identity,
      success: _.identity,
      failure: _.identity
    }
  },
  archiveContractCondition: {
    request: (id: string) =>
      api.post('AdminContractConditions::archive', { id }),
    reduce: {
      initial: _.identity,
      success: _.identity,
      failure: _.identity
    }
  },
  duplicateContractCondition: {
    request: (payload: { id: string; name: string }) =>
      api
        .post('AdminContractConditions::duplicate', payload)
        .then((response) => response.data.result),
    reduce: {
      initial: _.identity,
      success: _.identity,
      failure: _.identity
    }
  },
  sortContractConditions: {
    request: (conditions: ContractCondition[]) =>
      api.post('AdminContractConditions::sort', { data: conditions }),
    reduce: {
      initial: _.identity,
      success: _.identity,
      failure: _.identity
    }
  }
};

const contractConditionsModel = new Generator<
  ContractCondition,
  typeof actionCreators
>(TYPE).createEntityModel({
  actionCreators
});

// TODO: work out how to add select to model so TS stops yelling
// https://app.shortcut.com/rexlabs/story/64672/select-object-on-entity-models-causing-issues-with-types
// eslint-disable-next-line
// @ts-ignore
contractConditionsModel.select = {
  resultsToSelectOptions: (results: ContractCondition[]) =>
    (results || []).map((condition) => ({
      value: condition.id,
      label: condition.name,
      data: condition,
      model: contractConditionsModel
    })),
  autocomplete: () =>
    api.post('AdminContractConditions::search').then(({ data }) =>
      (_.get(data, 'result') || []).map((condition: ContractCondition) => ({
        value: condition.id,
        label: condition.name,
        data: condition,
        model: contractConditionsModel
      }))
    )
};

export default contractConditionsModel;
