import React from 'react';
import {
  TextButton,
  PrimaryButton,
  LoadingButton
} from 'shared/components/button';
import ButtonBar from './button-bar';

interface ApplyCancelButtonBarProps {
  onCancel: () => void;
  onSave: () => void;
  isLoading: boolean;
  isDisabled?: boolean;
}

function ApplyCancelButtonBar({
  onCancel,
  onSave,
  isDisabled,
  isLoading,
  ...props
}: ApplyCancelButtonBarProps) {
  return (
    <ButtonBar {...props}>
      <TextButton key='cancel' blue onClick={onCancel}>
        Cancel
      </TextButton>
      {isLoading ? (
        <LoadingButton />
      ) : (
        <PrimaryButton isDisabled={isDisabled} key='save' blue onClick={onSave}>
          Apply
        </PrimaryButton>
      )}
    </ButtonBar>
  );
}

export default ApplyCancelButtonBar;
