import React, { Component } from 'react';
import FileUploadInput from '@rexlabs/file-upload-input';
import FileUploadInputChunked from '@rexlabs/file-upload-input-chunked';
import { autobind } from 'core-decorators';
import FileUploadBody from './file-upload-body';
import _ from 'lodash';
import Box from '@rexlabs/box';
import { api } from 'shared/utils/api-client';
import moment from 'moment';
import UploadHistoryContainer from './paf-upload-history';
import { withModel } from '@rexlabs/model-generator';
import pafUpload from 'data/models/custom/paf-upload';
import { styled, StyleSheet } from '@rexlabs/styling';
import { PADDINGS } from 'theme';

const fileProps = {
  acceptTypes: ['application/pdf', 'application/csv', 'text/plain', 'text/csv'],
  acceptExtensions: ['.csv', '.txt', '.pdf'],
  maxFileSize: 16 * 1024 * 1024 * 1024
};

const CHUNK_SIZE = 1024 * 1024 * 16;

const defaultStyles = StyleSheet({
  container: {
    paddingBottom: PADDINGS.L
  }
});

@withModel(pafUpload)
@styled(defaultStyles)
@autobind
class PostcodeUpload extends Component {
  state = {
    isUploading: !!this.props.pafUpload.uris.length,
    hasCompleted: false,
    hasConnectionError: false,
    totalChunks: this.props.pafUpload.totalChunks,
    currentChunk: this.getCurrentChunk(),
    timePerChunk: 999,
    offset: 0
  };

  componentDidMount() {
    this.getStatus();
  }

  getCurrentChunk() {
    const {
      isPending,
      isProcessing,
      totalChunks,
      currentChunk
    } = this.props.pafUpload;
    return isPending || isProcessing ? totalChunks : currentChunk;
  }

  getStatus() {
    const { pafUpload } = this.props;
    const { uris } = pafUpload;

    api
      .post('ThirdPartyServicePostcodeFile/getUploadedFiles')
      .then(({ data }) => {
        const latestFile = data.result.filter(
          (file) => file.status.id !== 'complete' && file.status.id !== 'failed'
        )[0];

        if (!latestFile) {
          pafUpload.clear();
          return;
        }

        const status = latestFile.status.id;

        const completeState = {
          // if complete, currentChunks === totalChunks
          // if processing or pending, the number doens't matter
          hasCompleted: false,
          currentChunk: uris.length,
          totalChunks: uris.length
        };

        if (status === 'processing') {
          this.setState(
            {
              isUploading: true,
              ...completeState
            },
            pafUpload.processing
          );
          return;
        }

        if (status === 'pending') {
          this.setState(
            {
              isUploading: true,
              ...completeState
            },
            pafUpload.pending
          );
        }

        if (status === 'failed') {
          this.setState(
            {
              isUploading: false,
              ...completeState
            },
            pafUpload.failed
          );
        }
      });
  }

  onPause() {
    const { pafUpload } = this.props;

    pafUpload.pause({
      currentChunk: this.state.currentChunk
    });
  }

  onResume() {
    this.setState({
      isUploading: true,
      hasConnectionError: false,
      hasFailed: false
    });
  }

  onCancel() {
    const { pafUpload } = this.props;

    this.setState(
      {
        isUploading: false,
        hasCompleted: false,
        hasConnectionError: false,
        hasFailed: false,
        hasCancelled: true,
        currentChunk: 0,
        totalChunks: 0
      },
      () => {
        pafUpload.clear();
      }
    );
  }

  setCurrentChunk(currentChunk, startTime) {
    const timePerChunk = Date.now() - startTime;
    this.setState({ currentChunk, timePerChunk });
  }

  getTimeRemaining() {
    const { currentChunk, totalChunks, timePerChunk } = this.state;

    const ms = (totalChunks - currentChunk) * timePerChunk;
    return moment.utc(ms).format('HH [hrs] mm [mins]');
  }

  onChunk(chunkNumber, chunk) {
    const { isUploading } = this.state;
    const { isPaused } = this.props.pafUpload;

    if (isPaused || !isUploading) {
      return { done: true };
    }

    const { pafUpload } = this.props;
    const start = Date.now();

    this.setState({ currentChunk: chunkNumber });
    pafUpload.setChunk({ currentChunk: chunkNumber });

    const formData = new FormData();

    formData.append('file', new File([new Blob([chunk])], pafUpload.filename));

    return api
      .post('Upload::uploadFile', formData, {
        headers: {
          'content-type': 'multipart/form-data'
        }
      })
      .then(({ data }) => {
        // In case pause was fired during a chunk posting request
        if (!isUploading || isPaused) {
          return;
        }
        this.setCurrentChunk(chunkNumber, start);
        pafUpload.addUri(data.result);
      })
      .catch(() => {
        this.onPause();
        this.setState({
          hasConnectionError: true
        });
      });
  }

  onProgressChange(n) {
    this.setState({
      ...this.state,
      progress: Math.floor((n / this.state.totalChunks) * 100)
    });
  }

  onComplete() {
    if (this.props.pafUpload.isPaused || this.state.hasCancelled) {
      return;
    }

    const { pafUpload } = this.props;

    this.setCurrentChunk(this.state.totalChunks, 0);
    pafUpload.setChunk({ currentChunk: pafUpload.totalChunk });

    return api
      .post('Upload::combineMultipleFiles', {
        uris: pafUpload.uris.filter(Boolean)
      })
      .then(({ data }) => {
        const { uri } = data.result;

        api
          .post('ThirdPartyServicePostcodeFile::ingest', {
            uri,
            column_mappings: pafUpload.columnMappings,
            name: pafUpload.filename
          })
          .then(() => {
            this.setState(
              { hasCompleted: true, isPending: true },
              pafUpload.pending
            );
          });
      })
      .catch(() => {
        this.setState(
          {
            hasConnectionError: true
          },
          () => {
            pafUpload.clear();
          }
        );
      });
  }

  onSubmit(startChunkedUpload, file) {
    this.setState(
      {
        totalChunks: Math.floor(file.size / CHUNK_SIZE) + 1,
        isUploading: true,
        isPending: false,
        isPaused: false,
        isProcessing: false,
        hasCancelled: false,
        hasFailed: false
      },
      () => {
        startChunkedUpload(file);
        this.props.pafUpload.startUpload({
          totalChunks: this.state.totalChunks
        });
      }
    );
  }

  onUploadSuccessConfirm() {
    this.setState({
      hasCompleted: false
    });
  }

  onRollback() {
    this.getStatus();
  }

  percent() {
    const { currentChunk, totalChunks } = this.state;

    if (!totalChunks) {
      return 0;
    }
    return Math.floor((currentChunk / totalChunks) * 100);
  }

  render() {
    const { styles: s, pafUpload } = this.props;
    const { isPaused, isPending, isProcessing, hasFailed } = pafUpload;

    const {
      isUploading,
      hasCompleted,
      hasConnectionError,
      currentChunk
    } = this.state;

    const progressBarProps = {
      isPaused,
      isPending,
      isProcessing,
      hasFailed,
      onPause: this.onPause,
      onResume: this.onResume,
      onCancel: this.onCancel,
      percent: this.percent()
    };

    return (
      <Box {...s('container')}>
        <FileUploadInputChunked
          base64={false}
          base64prefix={false}
          chunkSize={CHUNK_SIZE}
          onInit={_.noop}
          onChunk={this.onChunk}
          onComplete={this.onComplete}
          // TODO: Implement offset prop in vivid/FileUploadInputChunked
          startAtChunk={currentChunk}
        >
          {({ onSubmit }) => (
            <FileUploadInput {...fileProps}>
              {(props) => (
                <FileUploadBody
                  {...props}
                  {...progressBarProps}
                  hasConnectionError={hasConnectionError}
                  hasFailed={hasFailed}
                  pafUpload={pafUpload}
                  timeRemaining={this.getTimeRemaining()}
                  isUploading={isUploading}
                  hasCompleted={hasCompleted}
                  onUploadSuccessConfirm={this.onUploadSuccessConfirm}
                  onSubmit={(file) => this.onSubmit(onSubmit, file)}
                />
              )}
            </FileUploadInput>
          )}
        </FileUploadInputChunked>
        <UploadHistoryContainer onRollback={this.onRollback} />
      </Box>
    );
  }
}

export default PostcodeUpload;
