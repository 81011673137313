/* eslint-disable max-lines */
import _ from 'lodash';
import Types from 'prop-types';
import React, { CSSProperties } from 'react';
import ReactDOM from 'react-dom';
import { autobind } from 'core-decorators';
import {
  styled,
  StyleSheet,
  StylesProvider,
  StylesProps
} from '@rexlabs/styling';
import { WhereaboutsProps, withWhereabouts } from '@rexlabs/whereabouts';
import { compose } from 'redux';

import { ZINDEX } from 'theme/index';

import ui from 'data/models/custom/ui';
import nav from 'data/models/custom/nav';
import { withModel } from '@rexlabs/model-generator';
import session from 'data/models/custom/session';
import workflowInstancesModel from 'data/models/entities/workflow-instances';
import mailMergeEventLogsModel, {
  MailMergeEventLogsModel
} from 'data/models/entities/mail-merge-event-logs';
import mailMergeModel from 'data/models/entities/mail-merge';
import announcementsModel, {
  getAppAnnouncement
} from 'data/models/custom/announcements';

import { withRegion } from 'src/hocs/with-region';
import MobileHeader from './header/mobile';
import MobileMenu from './menu/mobile';
import MENU_CONFIG from './menu/menu-config';
import DesktopTabletHeader from './header/desktop-tablet';
import DesktopTabletMenu from './menu/desktop-tablet';
import { getOverlaySync } from 'utils/overlays';
import {
  DESKTOP_TABLET_MENU_COMPONENTS,
  MOBILE_MENU_COMPONENTS
} from 'theme/legacy/components';
import { withDevice } from 'view/containers/with-device';
import OverlayClose from 'view/classic-bridges/overlay-close';
import LoadingIndicatorPure from 'view/components/navigation/shell/loading-indicator';
import config from 'shared/utils/config';
import { withWhichWord } from 'hocs/with-which-word';
import { WhichWord } from 'hooks/use-which-word';

interface NavigationProps {
  session: any;
  workflowInstances: any;
  mailMerge: any;
  mailMergeEventLogs: MailMergeEventLogsModel;
  desktopMenuItems: any;
  announcements: any;
  nav: any;
  device: any;
  ui: any;
  styles: any;
  region: any;
  ww: WhichWord;
}

interface NavigationComponent {
  propTypes: any;
  desktopMenuItems: any;
  mobileMenuItems: any;
  agencyColor: string;
  desktopThemedComponents: any;
  mobileThemedComponents: any;
  intervalId: any;
  intervalTime: number;
}

interface State {
  workflowsAwaitingInputCount: number;
  failedMessagesCount: number;
  failedScheduledMessagesCount: number;
}

// HACK: move mobile menu into outer div via React portals
const portalTarget = window.document.createElement('div');
window.document.body.appendChild(portalTarget);

const ContainerDiv = StyleSheet({
  hide: {
    display: 'none'
  },
  disabled: {
    opacity: 0.6,
    pointerEvents: 'none',
    userSelect: 'none'
  }
});

const BLOCKABLE_MESSAGES = ['navigating', 'loading app', 'loading data'];

/**
 * The below function is used to create the styles required to fade out
 * the menu and the header in Shell. We use this so that when an Overlay
 * is opened it appears as though the menu and header are behind it.
 */
const getOverlayRelatedStyles = _.memoize(
  ({ brightness, isBlocking }) => {
    const styles = {
      filter: undefined,
      pointerEvents: undefined,
      cursor: undefined
    } as CSSProperties;

    if (brightness !== 1) {
      styles.filter = `brightness(${brightness})`;
    }

    if (isBlocking) {
      styles.pointerEvents = 'none';
      styles.cursor = 'auto';
    }

    return styles;
  },
  ({ brightness, isBlocking }) => `${brightness}-${isBlocking}`
);

@withWhereabouts
@autobind
class NavigationComponent extends React.PureComponent<
  StylesProps<typeof ContainerDiv> & WhereaboutsProps & NavigationProps,
  State
> {
  static propTypes = {
    device: Types.any
  };

  constructor(props) {
    // TODO: fix types, `WhereaboutsProps` doesn't play nicely with contructor
    // https://app.shortcut.com/rexlabs/story/60839
    // eslint-disable-next-line
    /* ts-ignore */
    super(props);
    const menuConfig = MENU_CONFIG(
      props.region,
      props.session?.office_details?.id,
      props.session,
      props.ww
    );

    /** Setup Desktop Menu Config */
    // @ts-ignore
    this.desktopMenuItems = menuConfig.filter((item) => !item.mobileOnly);
    this.mobileMenuItems = menuConfig;
    this.agencyColor = props.session?.office_details?.settings?.app_color;

    this.desktopThemedComponents = DESKTOP_TABLET_MENU_COMPONENTS({
      agencyColor: this.agencyColor
    });

    this.mobileThemedComponents = MOBILE_MENU_COMPONENTS({
      agencyColor: this.agencyColor
    });

    this.state = {
      workflowsAwaitingInputCount: 0,
      failedMessagesCount: 0,
      failedScheduledMessagesCount: 0
    };

    this.fetchAnnouncements();
    this.intervalTime = 600e3;
    this.intervalId = setInterval(this.fetchAnnouncements, this.intervalTime);
  }

  componentWillUnmount() {
    clearInterval(this.intervalId);
  }

  fetchAnnouncements() {
    const { announcements } = this.props;
    announcements.fetch({ force: true });
  }

  componentDidUpdate(
    prevProps: Readonly<
      StylesProps<typeof ContainerDiv> & WhereaboutsProps & NavigationProps
    >,
    prevState: Readonly<State>,
    snapshot?: any
  ) {
    // sometimes, the session isn't fully instantiated when the menu is calculated.  This is a bit of a hack to ensure
    // the menu config is regenerated whenever the session is changed.
    if (prevProps.session !== this.props.session) {
      const menuConfig = MENU_CONFIG(
        this.props.region,
        this.props.session?.office_details?.id,
        this.props.session,
        this.props.ww
      );

      /** Setup Desktop Menu Config */
      // @ts-ignore
      this.desktopMenuItems = menuConfig.filter((item) => !item.mobileOnly);
      this.mobileMenuItems = menuConfig;
    }
  }

  componentDidMount() {
    const {
      session,
      workflowInstances,
      mailMerge,
      mailMergeEventLogs,
      region
    } = this.props;
    const userId = session?.user_details?.id;
    const promises: any[] = [];
    let totalAwaitingInput = 0;
    let totalFailedScheduledMessages = 0;
    let totalFailedMessages = 0;

    // Get pending Workflows
    session.getQuickStats();
    if (region.isEU && session.checkUserHasPermission('addon.workflows')) {
      promises.push(
        workflowInstances
          .fetchList({
            id: 'workflows-awaiting-input',
            args: {
              limit: 0,
              criteria: [
                {
                  name: 'status_id',
                  type: 'in',
                  value: ['awaiting_input']
                },
                {
                  name: 'can_submit',
                  type: '=',
                  value: true
                }
              ]
            }
          })
          .then((response) => {
            totalAwaitingInput = _.get(response, 'meta.pagination.total') || 0;
          })
      );
    }

    // Get failed and failed scheduled messages
    if (config.ENABLE_SCHEDULED_MESSAGES) {
      promises.push(
        mailMergeEventLogs
          .searchMailMergeEventLogs({
            criteria: [
              {
                name: 'system_send_status_id',
                type: '=',
                value: 'failed'
              },
              {
                name: 'system_record_state',
                type: '=',
                value: 'active'
              },
              {
                name: 'merge_type',
                type: '!=',
                value: 'letter'
              },
              {
                name: 'system_record_state',
                type: '=',
                value: 'active'
              },
              {
                name: 'from_user_id',
                type: '=',
                value: `${userId}`
              },
              // TODO: remove this when this is completed
              // https://app.clubhouse.io/rexlabs/story/51635/bulk-archive-messages-older-than-1606089600
              {
                name: 'system_ctime',
                type: '>',
                value: '1606089600'
              }
            ]
          })
          .then((response) => {
            totalFailedMessages = _.get(response, 'data.result.total');
          })
      );
    }

    if (config.ENABLE_SCHEDULED_MESSAGES) {
      promises.push(
        mailMerge
          .searchMailMergeMessages({
            criteria: [
              {
                name: 'scheduled_to_send_at',
                type: 'isnot',
                value: 'null'
              },
              {
                name: 'system_send_status_id',
                type: '=',
                value: 'failed'
              },
              {
                name: 'mail_merge_sends.from_user_id',
                type: '=',
                value: `${userId}`
              }
            ]
          })
          .then((response) => {
            totalFailedScheduledMessages = _.get(response, 'data.result.total');
          })
      );
    }

    Promise.all(promises)
      .catch(() => {
        const globalSelf: any = self;
        return globalSelf.errorHandler;
      })
      .then(() => {
        this.setState({
          workflowsAwaitingInputCount: totalAwaitingInput,
          failedMessagesCount: totalFailedMessages,
          failedScheduledMessagesCount: totalFailedScheduledMessages
        });
      });
  }

  componentWillReceiveProps(nextProps) {
    // Update the themed components styles if we have changed agency account
    const currentAgencyId = this.props.session?.office_details?.id;
    const nextAgencyId = nextProps.session?.office_details?.id;
    const nextAgencyColor =
      nextProps.session?.office_details?.settings?.app_color;

    if (
      currentAgencyId !== nextAgencyId ||
      this.agencyColor !== nextAgencyColor
    ) {
      this.agencyColor = nextAgencyColor;
      /* tslint:disable */
      const menuConfig = MENU_CONFIG(
        nextProps.session?.office_details?.locale.code,
        nextProps.session?.office_details?.id,
        undefined,
        this.props.ww
      );
      /* tslint:enable */
      // @ts-ignore
      this.desktopMenuItems = menuConfig.filter((item) => !item.mobileOnly);
      this.mobileMenuItems = menuConfig;

      this.desktopThemedComponents = DESKTOP_TABLET_MENU_COMPONENTS({
        agencyColor: this.agencyColor
      });

      this.mobileThemedComponents = MOBILE_MENU_COMPONENTS({
        agencyColor: this.agencyColor
      });
    }
  }

  handleModeUpdate() {
    this.props.nav.toggleOpen();
  }

  render() {
    const {
      whereabouts,
      device,
      session,
      ui,
      announcements,
      styles: s
    } = this.props;
    const { reloadRexFrame } = ui;

    const overlaySync = getOverlaySync(ui);

    const agencyName = session?.office_details?.name;
    const agencies = session?.accessible_accounts;
    const leadNotifications = session?.leadNotifications?.breakdown || {};
    const totalLeads = Object.keys(leadNotifications).reduce(
      (result, keyName) => {
        return result + _.get(leadNotifications, `${keyName}.value`, 0);
      },
      0
    );
    const totalNotifications =
      totalLeads +
      this.state.workflowsAwaitingInputCount +
      this.state.failedMessagesCount +
      this.state.failedScheduledMessagesCount;
    const thirdPartyApps = session?.third_party_extensions;

    const announcement = getAppAnnouncement(announcements.items);

    const applicationState = {
      notifications: {
        myLeads: _.get(leadNotifications, 'assigned_to_me.value', 0),
        teamLeads: _.get(leadNotifications, 'assigned_to_team.value', 0),
        unassignedLeads: _.get(leadNotifications, 'unassigned.value', 0),
        othersLeads: _.get(leadNotifications, 'assigned_to_others.value', 0),
        workflowsAwaitingInput: this.state.workflowsAwaitingInputCount,
        failedMessages: this.state.failedMessagesCount,
        failedScheduledMessages: this.state.failedScheduledMessagesCount,
        totalLeads: totalLeads,
        totalNotifications: totalNotifications
      },
      user: {
        name: session?.user_details?.full_name || 'Loading...',
        role: session?.user_details?.settings?.position || '',
        profilePicture:
          session?.user_details?.settings?.profile_image?._url ||
          session?.user_details?.settings?.profile_image?.url
      },
      applications: thirdPartyApps,
      dashboardsList: session?.dashboardsList,
      customDashboardsList: session?.customDashboardsList,
      favouriteCustomReports: session?.favouriteCustomReports
    };

    const shouldBlockNav =
      config.ENABLE_NAV_BLOCKING &&
      ui.loadingIndicator === 'ON' &&
      BLOCKABLE_MESSAGES.includes(ui.loadingIndicatorMessage);

    const headerProps = {
      localState: applicationState,
      agencies: agencies,
      currentAgencyName: agencyName,
      currentAgencyColor: this.agencyColor,
      handleHamburgerClick: this.handleModeUpdate,
      disabled: shouldBlockNav
    };

    const overlayRelatedStyles = getOverlayRelatedStyles(overlaySync);

    return (
      <div
        {...s({ hide: overlaySync.isHeaderHidden })}
        style={{ position: 'relative', zIndex: ZINDEX.SHELLNAVIGATION }}
      >
        <OverlayClose />
        <LoadingIndicatorPure />

        {/* @ts-ignore */}
        <div style={{ position: 'relative', ...overlayRelatedStyles }}>
          {(device.isDesktop || device.isTablet) && (
            <DesktopTabletHeader {...headerProps} announcement={announcement} />
          )}

          {device.isMobile && <MobileHeader {...headerProps} />}

          {(device.isDesktop || device.isTablet) &&
            ReactDOM.createPortal(
              <StylesProvider
                components={this.desktopThemedComponents}
                prioritiseParentStyles={false}
              >
                <DesktopTabletMenu
                  style={overlayRelatedStyles}
                  hide={overlaySync.isMenuHidden}
                  device={device}
                  activeRoute={whereabouts.path}
                  items={this.desktopMenuItems}
                  localState={applicationState}
                  handleModeUpdate={this.handleModeUpdate}
                  disabled={shouldBlockNav}
                  agencyColor={this.agencyColor}
                  clickThrough={overlaySync.isBlocking || shouldBlockNav}
                  announcement={announcement}
                />
              </StylesProvider>,
              portalTarget
            )}

          {device.isMobile &&
            ReactDOM.createPortal(
              <StylesProvider
                components={this.mobileThemedComponents}
                prioritiseParentStyles={false}
              >
                <MobileMenu
                  activeRoute={whereabouts.path}
                  items={this.mobileMenuItems}
                  localState={applicationState}
                  onClickParams={{ reloadFrame: reloadRexFrame }}
                />
              </StylesProvider>,
              portalTarget
            )}
        </div>
      </div>
    );
  }
}

const Navigation = compose(
  styled(ContainerDiv),
  withModel(mailMergeEventLogsModel),
  withModel(mailMergeModel),
  withModel(announcementsModel),
  withModel(workflowInstancesModel),
  withModel(session),
  withModel(nav),
  withDevice,
  withModel(ui),
  withDevice,
  withRegion,
  withWhichWord
)(NavigationComponent);

export default Navigation;
