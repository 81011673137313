/*
|-------------------------------------------------------------------------------
| DialogsBridge
|-------------------------------------------------------------------------------
|
| This file creates the bridge methods for classic to deal with dialogs in
| Shell. Dialogs handled through the bridge are opened and closed via simple
| events emitted here and listened to in the DialogOverlayStack component.
|
| NOTE: we're just looping over the dialogs mapping here, which is where
| all the meta information regarding dialog is and should be defined!
|
*/

import { dialogEmitter, DIALOG_ACTIONS } from 'data/emitters/dialogs';
import mappings from 'utils/dialogs/mapping-classic';
import invariant from 'invariant';
import { DialogsBridgeProps } from 'utils/dialogs/mapping-classic-interfaces';

let counter = 0;

const DialogsBridge: DialogsBridgeProps = Object.keys(
  mappings
).reduce<DialogsBridgeProps>(
  (all, callName) => {
    all[callName] = {
      // Open classic dialog (in shell, but from classic)
      open: (args) => {
        const { systemName, mapArgs, originalName } = mappings[callName];

        invariant(
          systemName,
          `No classic system name defined for dialog with callName "${callName}"!`
        );

        invariant(
          mapArgs,
          `No argument mapping found for dialog with callName "${callName}"! ` +
            'To make dealing with dialogs easier for everyone we decided to map argument ' +
            'for classic dialogs to make them consistent. Please make sure you add a proper ' +
            'mapping to "/shell/src/utils/dialogs/mapping-shell.js".'
        );

        const uuid = ++counter;

        // NOTE: don't manually set isClassicalArgs, the mapArgs is to make openClassic take a more consistent object
        // when normally used, isClassicalArgs allows us to skip that when we are automatically opening the dialog
        // straight from it's old Classic call site (using it's old Classic arguments)
        // Ignoring because the emit function is not typed
        // @ts-ignore
        dialogEmitter.emit(DIALOG_ACTIONS.OPEN_CLASSIC, {
          uuid,
          systemName,
          callName: originalName || callName,
          args: args && args.isClassicalArgs ? args : mapArgs(args || {})
        });
      },

      // Close dialog (doesn't matter if classic or shell!)
      close: (uuid) => {
        // TODO: look into while this doesn't get triggered
        // Ignoring because the emit function is not typed
        // @ts-ignore
        dialogEmitter.emit(DIALOG_ACTIONS.CLOSE, {
          uuid,
          callName
        });
      }
    };

    // openClassic is deprecated, here for backwards compatibility
    all[callName].openClassic = all[callName].open;
    return all;
  },
  {
    closeAll: () => {
      dialogEmitter.emit(DIALOG_ACTIONS.CLOSE_ALL);
    }
  }
);

export default DialogsBridge;
