import React, { ReactNode } from 'react';
import Icon, { ICONS } from 'shared/components/icon';
import { Heading, HeadingContainer } from '../heading-with-button';

interface HeadingWithIconProps {
  heading: ReactNode;
  icon: unknown;
}

function HeadingWithIcon({ heading, icon = ICONS.ADD }: HeadingWithIconProps) {
  return (
    <HeadingContainer>
      {icon && (
        <Icon
          type={icon}
          width={22}
          height={22}
          style={{ marginRight: 6, display: 'flex' }}
        />
      )}
      <Heading>{heading}</Heading>
    </HeadingContainer>
  );
}

export default HeadingWithIcon;
