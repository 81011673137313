/**
 * NOTE: this should replace the current Batch API calls we make on
 * page load for all services, so we only load what we need
 */
import { useCallback, useEffect, useMemo, useState } from 'react';

import { api } from 'shared/utils/api-client';

import { getModuleName } from 'data/module-names';

const tabsCache = {};

export function useCustomTabs({ id, serviceName }) {
  const fromCache = tabsCache[serviceName];

  const [state, setState] = useState({
    isLoading: true,
    customTabs: fromCache?.data,
    customData: {}
  });

  useEffect(
    () => {
      Promise.all([
        api.post('CustomFields::getValuesKeyedByFieldId', {
          service_name: serviceName,
          service_object_id: id
        }),
        !fromCache
          ? api.post('AdminCustomFields::getDefinition', {
              module_name: getModuleName(serviceName)
            })
          : fromCache
      ])
        .then(([{ data: customData }, { data: customTabs }]) => {
          tabsCache[serviceName] = { data: customTabs };
          setState({
            customTabs: customTabs?.result,
            customData: customData?.result,
            isLoading: false
          });
        })
        .catch((e) => {
          console.error(e);
          setState((state) => ({
            ...state,
            isLoading: false
          }));
        });
    },
    // eslint-disable-next-line
    [id, serviceName]
  );

  const updateCustomData = useCallback(
    (customData) =>
      api.post('CustomFields::setFieldValues', {
        service_name: serviceName,
        service_object_id: id,
        value_map: customData
      }),
    [id, serviceName]
  );

  return useMemo(() => ({ ...state, updateCustomData }), [
    state,
    updateCustomData
  ]);
}
