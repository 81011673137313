import _ from 'lodash';
import { getHazardly } from './prefix-hell';

/**
 * Checks which type of permission we are trying to find on the users session model.
 * @param permission - The permission we are looking for. E.g: contacts.update or addon.leads or
 * feature.navigation_upgrade
 * @returns {{requiredPermission: string, sessionPropertyName: string}} - The actual permission key
 * we are looking for and the correct session model key we need to look in.
 */
const getPermissionType = (permission) => {
  const splitPermissions = permission.split('.');
  const permissionType = _.first(splitPermissions);
  let sessionPropertyName = 'privileges';
  let requiredPermission = splitPermissions.slice(1, splitPermissions.length);

  switch (permissionType) {
    case 'addon':
      sessionPropertyName = 'subscription_limits.add_ons';
      break;
    case 'feature':
      sessionPropertyName = 'subscription_limits';
      break;
    case 'group_member_ids':
      sessionPropertyName = 'group_member_ids';
      break;
    case 'has_group':
      sessionPropertyName = 'has_group';
      break;
    default:
      requiredPermission = permission;
      break;
  }

  return {
    requiredPermission,
    sessionPropertyName
  };
};

export function checkUserHasPermission(stateGetter) {
  return (accessRights) => {
    let hasProperty = true; // Assume innocent until proven guilty

    if (_.isArray(accessRights)) {
      accessRights.forEach((permission) => {
        const { requiredPermission, sessionPropertyName } = getPermissionType(
          permission
        );
        if (
          !getHazardly(stateGetter(sessionPropertyName), requiredPermission)
        ) {
          hasProperty = false;
        }
      });
    } else if (_.isString(accessRights)) {
      // Running an eval function below only on known 'safe' operators
      const accessRightsExpression = accessRights.replace(
        /[\w.]+/g,
        (permission) => {
          const { requiredPermission, sessionPropertyName } = getPermissionType(
            permission
          );
          const sessionValue = getHazardly(
            stateGetter(sessionPropertyName),
            requiredPermission
          );

          // Need to parse any ints we may have gotten from the users session
          let intValue = sessionValue;
          if (_.isString(intValue)) {
            intValue = parseInt(sessionValue);

            if (_.isNaN(intValue)) {
              intValue = sessionValue;
            }
          }

          return intValue ? 'true' : 'false';
        }
      );

      if (
        /^(\s|true|false|>|<|=|\|\||\(|\)|&&|!)+$/.test(accessRightsExpression)
      ) {
        /* ಠ╭╮ಠ */
        // eslint-disable-next-line
        return new Function('return ( ' + accessRightsExpression + ')')();
      } else {
        const { requiredPermission, sessionPropertyName } = getPermissionType(
          accessRights
        );
        if (
          !getHazardly(stateGetter(sessionPropertyName), requiredPermission)
        ) {
          hasProperty = false;
        }
      }
    }

    return hasProperty;
  };
}
