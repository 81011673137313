import _ from 'lodash';

const keyToFunction = (key) => {
  if (_.isFunction(key)) {
    return key;
  }

  return (x) => x[key];
};

export default function sortNaturally(
  collection,
  iteratees = null,
  caseFirst = false
) {
  const collator = new Intl.Collator(undefined, {
    numeric: true,
    sensitivity: 'base',
    caseFirst: caseFirst
  });

  const sortedCollection = [...collection];

  if (!_.isArray(collection)) {
    throw new Error('Expected collection to be an array.');
  }

  if (
    iteratees &&
    !_.isString(iteratees) &&
    !_.isFunction(iteratees) &&
    !_.isArray(iteratees)
  ) {
    throw new Error('Expected iteratees to be a string or a function.');
  }

  if (_.isString(iteratees)) {
    iteratees = keyToFunction(iteratees);
  }

  // Primitive sort
  if (!iteratees) {
    sortedCollection.sort(function (currentValue, nextValue) {
      if (_.isObject(nextValue) || _.isObject(currentValue)) {
        throw new Error(
          'Expected collection to contain primitives only as no iteratees were passed.'
        );
      }

      return collator.compare(currentValue, nextValue);
    });
  } else {
    // Advanced sort
    sortedCollection.sort(function (currentValue, nextValue) {
      if (!_.isObject(nextValue) || !_.isObject(currentValue)) {
        throw new Error(
          'Expected collection to contain objects only because iteratees were passed.'
        );
      }

      if (!_.isArray(iteratees)) {
        iteratees = [iteratees];
      }

      let iteratee, sortResult;
      for (const i in iteratees) {
        iteratee = keyToFunction(iteratees[i]);
        sortResult = collator.compare(
          iteratee(currentValue),
          iteratee(nextValue)
        );

        // sort order has changed, break out of loop
        if (sortResult !== 0) {
          break;
        }
      }

      return sortResult;
    });
  }

  return sortedCollection;
}
