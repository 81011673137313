import ModelGenerator, {
  ModelGeneratorOptions,
  EntityModelConfig,
  APIConfig,
  ValueListModel
} from '@rexlabs/model-generator';
import { upperFirst, snakeCase, merge } from 'lodash';

import {
  api,
  transformList,
  transformItem,
  transformAutocomplete,
  transformValueList,
  transformListArgs,
  transformItemArgs,
  transformAutocompleteArgs
} from 'shared/utils/api-client';
import { transformListIds } from './api-client/transform';

interface SelectItem {
  value: string;
  label: string;
}

type FetchSystemList = (
  type?: string
) => Promise<ReturnType<typeof transformValueList>>;

export type SystemListModel<Type, Actions> = ValueListModel<
  Type & SelectItem,
  Actions & { fetch: FetchSystemList }
> & { select?: Record<string, any> };

export type GeneralSystemListModel = SystemListModel<
  SelectItem,
  Record<string, (...args: any) => any>
>;

export function resetEntities(entities) {
  return Object.keys(entities).reduce((acc, key) => {
    acc[key] = { lists: {}, items: {} };
    return acc;
  }, {});
}

export function resetValueLists(valueLists) {
  return Object.keys(valueLists).reduce((acc, key) => {
    acc[key] = { items: [], status: null, errors: null };
    return acc;
  }, {});
}

const defaultConfig: ModelGeneratorOptions = {
  entities: {
    api: {
      fetchList: (type, args: any) => {
        const { method = 'search', ...rest } = args;
        return api
          .post(
            `${upperFirst(type)}::${method}`,
            method === 'search'
              ? transformListArgs({ args: rest })
              : transformAutocompleteArgs({ args: rest })
          )
          .then(
            method === 'search'
              ? args.result_format === 'ids'
                ? transformListIds
                : transformList
              : transformAutocomplete
          );
      },

      fetchItem: (type, args, id) =>
        api
          .post(`${upperFirst(type)}::read`, transformItemArgs({ id, args }))
          .then(transformItem),

      updateItem: (type, args, id) =>
        api
          .post(`${upperFirst(type)}::update`, { data: { ...args, id: id } })
          .then(transformItem),

      createItem: (type, args) =>
        api.post(`${upperFirst(type)}::create`, {
          data: { ...args }
        }),

      trashItem: (type, args, id) =>
        api
          .post(`${upperFirst(type)}::trash`, { ...args, id: id })
          .then((response) => response.data.result)
    }
  },

  valueLists: {
    api: {
      fetch: (type) =>
        api
          .post('AdminValueLists::getListValues', {
            list_name: snakeCase(type)
          })
          .then(transformValueList)
    }
  }
};

type PartialEntityModelConfig = Partial<Omit<EntityModelConfig, 'api'>> & {
  api?: Partial<APIConfig>;
};

export type GeneratorConfig = {
  entities?: PartialEntityModelConfig;
  valueLists?: PartialEntityModelConfig;
};

export class Generator<T, A = any> extends ModelGenerator<T, A> {
  constructor(
    type: string,
    config: GeneratorConfig & { serviceName?: string } = {}
  ) {
    // TODO: fix types
    // eslint-disable-next-line
    // @ts-ignore
    super(type, merge({}, defaultConfig, config));

    // eslint-disable-next-line
    // @ts-ignore
    this.serviceName = config?.serviceName || upperFirst(type);
  }

  createSystemListModel(): SystemListModel<SelectItem, A> {
    return this.createValueListModel({
      config: {
        api: {
          fetch: (type) =>
            api
              .post('SystemValues::getCategoryValues', {
                list_name: snakeCase(type)
              })
              .then(transformValueList)
        }
      }
    });
  }

  // TODO: fix types
  // eslint-disable-next-line
  // @ts-ignore
  createEntityModel(args?: any) {
    // NOTE: this adds the service name to the model, which is different
    // from the model name and is added as convenience whenever we need
    // to reference it e.g. to send stuff to the backend
    const model = super.createEntityModel(args);

    // eslint-disable-next-line
    // @ts-ignore
    model.serviceName = this.serviceName;

    return model;
  }
}
