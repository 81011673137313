import React, { useRef } from 'react';

import { StylesProvider, StyleSheet } from '@rexlabs/styling';
import { MultiEntitySelect } from '@rexlabs/select';
import { withRegion } from 'src/hocs/with-region';

import DefaultValue from 'view/components/input/select/defaults/value';
import DefaultOption from 'view/components/input/select/defaults/option';
import Loading from 'view/components/input/select/defaults/loading';
import NoResultsFound from 'view/components/input/select/defaults/not-found';

import Fixtures from './fixtures';
import normalizer from './normalizer';

import { api } from 'shared/utils/api-client';

function getAutocompleteStreet(searchTerm) {
  return api.post('SystemValues::autocompleteCategoryValues', {
    list_name: 'street_names',
    search_string: searchTerm
  });
}

function autoCompleteStreetSuburbUK(searchTerm) {
  return Promise.all([
    getAutocompleteStreet(searchTerm),
    api.post('SystemValues::autocompleteUkSuburbs', {
      search_suburb: searchTerm
    })
  ]).then(([streets, suburbs]) =>
    normalizer({
      streets: streets.data.result,
      suburbs: suburbs?.data?.result || []
    })
  );
}

async function autoCompleteStreetSuburbEU(searchTerm) {
  const items = await getAutocompleteStreet(searchTerm);
  return normalizer({
    streets: items.data.result,
    suburbs: []
  });
}

function freeformValueNormalizer(value) {
  let freeformValue;

  const fullPostcodeRegExp = /^([a-z][a-hj-y]?[0-9][a-z0-9]?\s[0-9][a-z]{2}|[g][i][r]\s0[a]{2})$/gi;
  const sectorPostcodeRegExp = /^[a-z][a-hj-y]?[0-9][a-z0-9]?\s[0-9a-z]$/gi;
  const districtPostcodeRegExp = /^[a-z][a-hj-y]?[0-9][a-z0-9]?$/gi;
  const areaPostcodeRegExp = /^[a-z][a-hj-y]?$/gi;

  const fullPostcode = value.match(fullPostcodeRegExp);
  const sectorPostcode = value.match(sectorPostcodeRegExp);
  const areaPostcode = value.match(areaPostcodeRegExp);
  const districtPostcode = value.match(districtPostcodeRegExp);

  if (fullPostcode) {
    freeformValue = normalizer({ postcodes: [{ id: value, text: value }] });
  } else if (areaPostcode) {
    freeformValue = normalizer({ postcodeAreas: [{ id: value, text: value }] });
  } else if (sectorPostcode) {
    freeformValue = normalizer({
      postcodeSectors: [{ id: value, text: value }]
    });
  } else if (districtPostcode) {
    freeformValue = normalizer({
      postcodeDistricts: [{ id: value, text: value }]
    });
  } else {
    freeformValue = normalizer({ streets: [{ id: value, text: value }] });
  }

  return freeformValue[0];
}

function StreetSuburbMultiSelect({ region, ...props }) {
  // HACK: for whatever reason defining the overrides outside makes them
  // get overwritten by other styles, all other "new" selects work fine
  // without this hack :/
  const overrides = useRef({
    TextInput: StyleSheet({
      wrapper: {
        flex: 1,
        justifyContent: 'center',
        minHeight: '22px',
        minWidth: '120px'
      },
      container: {
        height: '100%',
        border: 'none',
        padding: '0',
        // More details on why we do this can be found in shell/src/utils/styles.md
        '--styleCacheBustId': 1
      },
      cosmeticWrapper: {
        height: '100%'
      },
      input: {
        height: 'auto'
      }
    })
  });

  const autocomplete = region.isUK
    ? autoCompleteStreetSuburbUK
    : autoCompleteStreetSuburbEU;

  return (
    <StylesProvider
      components={overrides.current}
      prioritiseParentStyles={false}
    >
      <MultiEntitySelect
        multi
        shouldAddFreeformValue
        shouldOpenOnInputFocus={false}
        shouldOpenOnInputClick={false}
        defaultHighlightedIndex={0}
        placeholder='Search for a street, postcode or town/city'
        autocomplete={autocomplete}
        freeformValueNormalizer={freeformValueNormalizer}
        Value={DefaultValue}
        Option={DefaultOption}
        Fixtures={Fixtures}
        Loading={Loading}
        NoResultsFound={NoResultsFound}
        {...props}
      />
    </StylesProvider>
  );
}

export { normalizer };
export default withRegion(StreetSuburbMultiSelect);
