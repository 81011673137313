/* eslint-disable max-lines */
import React, { PureComponent } from 'react';
import Dialog from 'view/components/dialog';
import { ReactForms, Form, FormField, FieldArray } from 'view/components/form';
import { ButtonBar } from 'view/components/button-bar';
import { TextButton, DefaultButton } from 'view/components/button';
import { styled, StyleSheet } from '@rexlabs/styling';
import { COLORS, PADDINGS, TEXTS, UTILS } from 'theme';
import { autobind } from 'core-decorators';
import EntitySelect from 'view/components/input/select/entity-select';
import contactsModel from 'data/models/entities/contacts';
import { RadioGroupInput } from 'view/components/input/radio-buttons';
import Box from '@rexlabs/box';
import { Link } from 'components/text/link';
import { Body } from 'components/text/body';
import { InputLabel } from 'components/text/input-label';
import { ICONS } from 'shared/components/icon';
import { PLACEMENTS } from '@rexlabs/tooltip';
import Tooltip from 'view/components/tooltip';
import _ from 'lodash';
import { query, withQuery, withModel } from '@rexlabs/model-generator';
import telephonyModel from 'data/models/custom/telephony';
import { SplitButton } from 'view/components/action-menu';
import { connect } from 'react-redux';
import { withDialog } from 'shared/hocs/with-dialog';
import UpdateStatusDialog from 'view/dialogs/phone-address-washing/update-status';
import ThirdPartyServiceClickToDial from 'data/models/custom/third-party-service-click-to-dial';
import { withErrorDialog } from 'src/hocs/with-error-dialog';
import { TextInput } from '@rexlabs/text-input';
import { hasFeatureFlags } from 'shared/utils/has-feature-flags';
import ErrorMessage from 'view/components/error/message';

const radioStyles = {
  container: {
    width: 'auto',
    padding: '0',
    marginTop: `calc(${PADDINGS.L} / 2)`,
    marginBottom: `calc(${PADDINGS.L} / 2)`,
    minHeight: '30px',
    height: '30px',
    maxHeigt: '30px'
  },
  label: {
    display: 'none',
    alignItems: 'flex-start',
    flexDirection: 'column',
    padding: PADDINGS.S,
    borderBottom: '1px solid',
    overflow: 'hidden',
    '& > div': {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      marginLeft: '5px',
      paddingTop: '0'
    }
  },
  suffix: {
    display: 'none',
    '& div': {
      display: 'flex'
    },
    '& svg': {
      color: COLORS.STATES.IDLE,
      transform: 'none',
      width: '100%'
    }
  },
  input: {
    '& + label': {
      position: 'relative',
      display: 'inline-block',
      height: '17px',
      width: '17px',
      marginLeft: '0',
      marginRight: '0',
      borderRadius: '100%',
      backgroundColor: 'white',
      border: `2px solid ${COLORS.STATES.IDLE}`,
      transition: 'initial',
      '&:hover': {
        borderColor: COLORS.STATES.HOVER
      },
      '&:focus': {
        outline: 'none'
      },
      '&:before': {
        position: 'absolute',
        top: '2px',
        left: '2px',
        content: "''",
        height: '9px',
        width: '9px',
        opacity: '1',
        backgroundColor: COLORS.DARK_GREY,
        transform: 'scale(0)',
        transformOrigin: 'initial',
        border: 'none',
        borderRadius: '100%',
        transition: 'none'
      }
    },
    '&:disabled  + label': {
      cursor: 'default'
    },
    '&:checked + label': {
      transition: 'initial',
      ...TEXTS.CONTENT.FORM_FIELD_LABEL_1,
      '&:before': {
        opacity: '1',
        transform: 'scale(1)',
        transition: 'none'
      }
    },
    '&:checked ~ span:last-child': {
      display: 'block'
    }
  }
};

const fieldStyles = StyleSheet({
  field: {
    width: 'auto',
    marginRight: '-7px' // This is added because we can't override the styling for label to remove the margin
  }
});

export const contactsQuery = query`{
  ${contactsModel} (id: ${(p) => p.contactId}) {
    id
    system_search_key
    related
  }
}`;

const defaultStyles = StyleSheet({
  fakeLabelContainer: {
    display: 'flex',
    alignItems: 'center',
    height: '29px'
  },
  primaryText: {
    fontSize: '11px',
    fontWeight: '600',
    textTransform: 'uppercase',
    color: '#2aa2e3'
  },
  phoneNumberDetails: {
    paddingLeft: PADDINGS.XS,
    textAlign: 'center'
  },
  clickable: {
    cursor: 'pointer'
  },
  tpsNormalIcon: {
    color: COLORS.STATES.RED
  },
  tpsOverriddenIcon: {
    color: COLORS.STATES.SLATE
  },
  makeCallButtonLabel: {
    ...UTILS.truncate,
    maxWidth: '230px' // Specified by UX
  },
  hidden: {
    display: 'none'
  },
  messageList: {
    listStyle: 'none',
    paddingLeft: '14px',
    paddingTop: '0px',
    paddingBottom: PADDINGS.S,
    width: '100%',
    position: 'relative',

    '> li': {
      position: 'relative'
    },

    '> li:before': {
      content: '" "',
      position: 'absolute',
      top: '7px',
      left: '-10px',
      height: '4px',
      width: '4px',
      borderRadius: '4px',
      background: COLORS.BLACK
    }
  }
});

// `number` Takes number Object or number ID
// `allNumbers` allows us to pass just an id and find the number Object it's referring to
const isNumberOnTps = function (number, allNumbers) {
  if (number && !_.isObject(number)) {
    number = allNumbers.find((numberObject) => {
      return numberObject.id === number;
    });
  }
  if (number && _.isObject(number)) {
    return _.get(number, 'wash.present_on_lists', []).includes('tps');
  }
};

// `number` Takes number Object or number ID
// `allNumbers` allows us to pass just an id and find the number Object it's referring to
const isTpsOverridden = function (number, allNumbers) {
  if (number && !_.isObject(number)) {
    number = allNumbers.find((numberObject) => {
      return numberObject.id === number;
    });
  }
  if (number && _.isObject(number)) {
    return _.get(number, 'wash.user_override') === 'allow';
  }
};

@connect((state) => ({ session: state.session }))
@withModel(telephonyModel)
@withModel(ThirdPartyServiceClickToDial)
@withQuery(contactsQuery)
@withDialog(UpdateStatusDialog, { propName: 'updateWashingStatus' })
@withErrorDialog
@styled(defaultStyles)
@autobind
class MakeCall extends PureComponent {
  static defaultProps = {
    title: 'Make Phone Call',
    height: 300
  };

  constructor(props) {
    super(props);
    const telephonyMethods = _.get(
      this.props,
      'session.telephony_available_methods',
      []
    );

    this.state = {
      phoneNumbers: [],
      contactId: null,
      options: [],
      errorMessage: '',
      phoneNumbersLoading: true,
      credentials: {},
      step: 0,
      telephonyMethods: telephonyMethods,
      selectedMethod: _.get(_.first(telephonyMethods), 'method_id')
    };

    const [, ...rest] = telephonyMethods;
    // Don't include first method as it's the default onClick
    this.nonPrimaryTelephoneMethods = rest.map((method) => {
      return {
        label: method.label,
        onClick: () => {
          this.setState({ selectedMethod: method.method_id }, this.submitForm);
        }
      };
    });
    this.setFieldValue = _.noop;
    this.fixturePrefillData = {
      callback: (contactData) => {
        props.contacts.createItem({ data: contactData }).then(({ data }) => {
          const contact = {
            value: data.result.id,
            label: data.result.system_search_key,
            data: data.result,
            model: contactsModel
          };

          this.setState({ options: this.state.options.concat([contact]) });
          this.setFieldValue('contact', contact.value);
          this.onContactChange({
            target: {
              value: data.result.id.toString()
            }
          });
        });
      }
    };
    // For CredentialsForm
    this.initialFields =
      _.isArray(props.userInputs) &&
      props.userInputs.map(() => ({ initialValue: '' }));
    this.hasInitialised = false;
  }

  credentialsFormHandleSubmit(values) {
    this.setState({ credentials: values, step: 1 });
  }

  credentialsFormValidate(values) {
    const { userInputs } = this.props;
    const errorObject = {};

    userInputs.forEach(({ name, label }) => {
      if (!values[name]) {
        errorObject[name] = `${label} is required`;
      }
    });

    return errorObject;
  }

  renderUserInputsFieldArray({ fields, getFieldProps }) {
    const { userInputs } = this.props;

    return fields.map((field, index) => {
      const { key, ...rest } = getFieldProps(field, index);

      return <FormField key={key} {...rest} {...userInputs[index]} />;
    });
  }

  renderCredentialsForm() {
    return (
      <ReactForms
        handleSubmit={this.credentialsFormHandleSubmit}
        validate={this.credentialsFormValidate}
      >
        {({ submitForm, isSubmitting }) => {
          return (
            <Form>
              <Body>
                Passwords and other sensitive details are not returned from the
                server for security reasons and will need to be re-entered if
                updating any other details.
              </Body>
              <FieldArray
                name={'credentials'}
                initialFields={this.initialFields}
                Input={TextInput}
              >
                {this.renderUserInputsFieldArray}
              </FieldArray>
              <ButtonBar isLoading={isSubmitting}>
                <TextButton blue onClick={this.props.closeDialog}>
                  Cancel
                </TextButton>
                <DefaultButton blue onClick={submitForm}>
                  Continue to step 2
                </DefaultButton>
              </ButtonBar>
            </Form>
          );
        }}
      </ReactForms>
    );
  }

  makeCall(values) {
    const { selectedMethod, errorMessage } = this.state;
    const { telephony, closeDialog } = this.props;

    // Clear error message
    errorMessage && this.setState({ errorMessage: '' });

    return telephony
      .makePhoneCall({
        contactId: values.contact,
        contactPhoneId: values.phoneNumber,
        methodId: selectedMethod
      })
      .then(closeDialog)
      .catch((error) => {
        this.setState({ errorMessage: error.message });
      });
  }

  makeTestCall(values) {
    const { credentials } = this.state;
    const { closeDialog } = this.props;
    const {
      thirdPartyServiceClickToDial,
      settings,
      errorDialog,
      whereabouts
    } = this.props;

    return thirdPartyServiceClickToDial
      .makeTestPhoneCall({
        contact_id: values.contact,
        contact_phone_id: values.phoneNumber,
        connection_id: _.get(whereabouts, 'hashQuery.connection_id'),
        user_input: credentials,
        settings: settings
      })
      .then(closeDialog)
      .catch(errorDialog.open); // Just opening an errorDialog here as this test version should be used by an admin
  }

  onContactChange({ target }) {
    const contactId = target.value;
    const phoneNumberId = target.phoneNumberId;

    if (contactId) {
      this.setState({ phoneNumbersLoading: true });
      contactId &&
        this.props.contacts.fetchItem({ id: contactId }).then((res) => {
          const phoneNumbers = _.get(res, 'data.related.contact_phones');
          let defaultNumber = null;
          this.setState({ phoneNumbers: phoneNumbers, contactId: contactId });

          if (phoneNumberId) {
            defaultNumber = phoneNumbers.find((number) => {
              return number.id === phoneNumberId;
            });
          } else if (phoneNumbers.length > 0) {
            defaultNumber = phoneNumbers.find((number) => {
              return number.phone_primary;
            });
          }

          if (defaultNumber) {
            const defaultNumberIsOnTPS = isNumberOnTps(defaultNumber);
            const defaultNumberShouldOverrideTPS = isTpsOverridden(
              defaultNumber
            );

            if (!defaultNumberIsOnTPS || defaultNumberShouldOverrideTPS) {
              this.setFieldValue('phoneNumber', _.get(defaultNumber, 'id'));
            } else {
              this.setFieldValue('phoneNumber', '');
            }
            this.setState({ phoneNumbersLoading: false });
          }
        });
    } else {
      this.setState({ phoneNumbers: [], contactId: null });
    }
  }

  setInitialState(contacts, phoneNumberId) {
    this.setState({
      options: [
        {
          value: contacts.item.data.id.toString(),
          label: contacts.item.data.system_search_key,
          data: contacts.item.data,
          model: contactsModel
        }
      ]
    });
    this.onContactChange({
      target: {
        value: contacts.item.data.id.toString(),
        phoneNumberId: phoneNumberId
      }
    });
  }

  componentDidUpdate(prevProps) {
    const { contacts: oldContacts } = prevProps;
    const { contacts: newContacts, phoneNumberId } = this.props;

    if (
      !this.hasInitialised &&
      oldContacts.item.status === 'loading' &&
      newContacts.item.status === 'loaded'
    ) {
      this.hasInitialised = true;
      this.setInitialState(newContacts, phoneNumberId);
    }
  }

  componentDidMount() {
    const { contacts, phoneNumberId } = this.props;

    if (contacts.item.status === 'loaded') {
      this.hasInitialised = true;
      this.setInitialState(contacts, phoneNumberId);
    }
  }

  getInitialValues() {
    const { contactId: propsContactId, phoneNumberId } = this.props;
    const { contactId: stateContactId } = this.state;

    // Needs to be a string correct value option matching
    return {
      contact:
        (stateContactId && stateContactId.toString()) ||
        (propsContactId && propsContactId.toString()),
      phoneNumber: phoneNumberId
    };
  }

  // This just removes default term based filter, allowing phone number
  // based search results to show even if they don't match contact name
  customContactOptionFilter(options) {
    const filteredOptions = options.filter((opt) => !opt.isFixture);
    const fixtureOptions = options.filter((opt) => Boolean(opt.isFixture));

    return filteredOptions.concat(fixtureOptions);
  }

  getNumberRadioButton = _.memoize(
    (number) => {
      const isOnTPS = isNumberOnTps(number);
      const shouldOverrideTPS = isTpsOverridden(number);

      return {
        props: {
          disabled: isOnTPS && !shouldOverrideTPS
        },
        value: number.id
      };
    },
    (number) => `${number.id}${isTpsOverridden(number)}`
  );

  getNumberRadioButtonLabel = _.memoize(
    (number) => {
      const {
        styles: s,
        contacts,
        phoneNumberId,
        updateWashingStatus
      } = this.props;
      const { contactId } = this.state;

      const isOnTPS = isNumberOnTps(number);
      const shouldOverrideTPS = isTpsOverridden(number);

      return (
        <Box key={number.id} {...s('fakeLabelContainer')}>
          <Box flexDirection={'column'} justifyContent={'center'}>
            <Box flexDirection={'row'} alignItems={'center'}>
              {number.phone_type && (
                <InputLabel
                  bold
                  {...s('phoneNumberDetails', {
                    clickable: !isOnTPS || shouldOverrideTPS
                  })}
                  onClick={() => {
                    if (!isOnTPS || shouldOverrideTPS) {
                      this.setFieldValue('phoneNumber', _.get(number, 'id'));
                    }
                  }}
                >
                  {number.phone_type}
                </InputLabel>
              )}
              <Body
                dark
                normal
                {...s('phoneNumberDetails', {
                  clickable: !isOnTPS || shouldOverrideTPS
                })}
                onClick={() => {
                  if (!isOnTPS || shouldOverrideTPS) {
                    this.setFieldValue('phoneNumber', _.get(number, 'id'));
                  }
                }}
              >
                {number.phone_number}
              </Body>
              {isOnTPS && (
                <Tooltip
                  inline
                  width={'auto'}
                  hoverTimeout={1}
                  placement={PLACEMENTS.TOP}
                  Content={() => (
                    <Body normal white>
                      {shouldOverrideTPS
                        ? 'TPS overridden - Contact permitted'
                        : 'On TPS Registry - Contact no permitted'}
                    </Body>
                  )}
                >
                  <Box
                    {...s('phoneNumberDetails', {
                      tpsNormalIcon: !shouldOverrideTPS,
                      tpsOverriddenIcon: shouldOverrideTPS
                    })}
                  >
                    <ICONS.DONT_CALL
                      onClick={() => {
                        updateWashingStatus.open({
                          phone: number.phone_number,
                          service: 'TPS',
                          override: number.wash.user_override,
                          recordString: number.prettyPhone,
                          callback: () => {
                            contacts
                              .fetchItem({
                                id: contactId
                              })
                              .then((freshContact) => {
                                this.onContactChange({
                                  target: {
                                    value: freshContact.data.id.toString(),
                                    phoneNumberId: phoneNumberId
                                  }
                                });
                              });
                          }
                        });
                      }}
                    />
                  </Box>
                </Tooltip>
              )}
              {number.phone_primary && (
                <Box {...s('primaryText', 'phoneNumberDetails')}>primary</Box>
              )}
            </Box>
          </Box>
        </Box>
      );
    },
    (number) => `${number.id}${isTpsOverridden(number)}`
  );

  renderButtonBar(submitForm, isSubmitting, selectedPhoneNumber) {
    const { closeDialog, styles: s, isTest } = this.props;
    const { telephonyMethods } = this.state;

    return (
      <ButtonBar isLoading={isSubmitting}>
        <TextButton blue onClick={closeDialog}>
          Cancel
        </TextButton>
        {telephonyMethods.length > 1 && !isTest ? (
          <SplitButton
            dark
            isDisabled={!selectedPhoneNumber}
            onClick={submitForm}
            items={this.nonPrimaryTelephoneMethods}
          >
            {`Call from ${_.get(_.first(telephonyMethods), 'label')}`}
          </SplitButton>
        ) : (
          <DefaultButton
            dark
            isDisabled={!selectedPhoneNumber}
            onClick={submitForm}
          >
            <Box {...s('makeCallButtonLabel')}>
              {isTest
                ? 'Trigger test call'
                : `Call from ${_.get(_.first(telephonyMethods), 'label')}`}
            </Box>
          </DefaultButton>
        )}
      </ButtonBar>
    );
  }

  renderPhoneNumbers() {
    const { closeDialog } = this.props;
    const { phoneNumbers, contactId } = this.state;

    return (
      <Box flexDirection={'column'}>
        {phoneNumbers.map(this.getNumberRadioButtonLabel)}
        {phoneNumbers.length === 0 && contactId && (
          <Box>
            <Body>
              The selected contact does not have a valid number. A valid phone
              number is requred to make a phone call.
            </Body>
            <Link
              grey
              onClick={() => {
                window.parent.Rex2FrameWindow.r2.u.actions.redirect(
                  `/contacts/#id=${contactId}`
                );
                closeDialog();
              }}
            >
              View Contact
            </Link>
          </Box>
        )}
      </Box>
    );
  }

  renderWarningMessage() {
    const { phoneConsentGiven, styles: s } = this.props;
    const { options, contactId, errorMessage } = this.state;

    if (
      !hasFeatureFlags('enhanced_privacy') ||
      errorMessage ||
      !phoneConsentGiven
    )
      return null;

    // Get selected contact
    const selectedContact = options.find(
      (contact) => contact.value === contactId
    );
    if (!selectedContact) return null;

    let message = '';
    if (phoneConsentGiven.feature && phoneConsentGiven.phone) {
      message = `${selectedContact.label} has not given consent to receive phone calls, and has opted out of receiving phone calls. By continuing, you assert that you have lawful basis for communicating with this contact.`;
    } else if (phoneConsentGiven.feature) {
      message = `${selectedContact.label} has not given consent to receive phone calls. By continuing, you assert that you have a lawful basis for communicating with this contact.`;
    } else if (phoneConsentGiven.phone) {
      message = `${selectedContact.label} has opted out of receiving phone calls. You can still call, but it will not comply with the contact’s communication preferences.`;
    }

    if (!message) return null;

    return (
      <ErrorMessage warning>
        <ul {...s('messageList')}>
          <li>{message}</li>
        </ul>
      </ErrorMessage>
    );
  }

  renderErrorMessage() {
    const { errorMessage } = this.state;
    const { styles: s } = this.props;
    if (!hasFeatureFlags('enhanced_privacy') || !errorMessage) return null;

    return (
      <ErrorMessage>
        <ul {...s('messageList')}>
          <li>{errorMessage}</li>
        </ul>
      </ErrorMessage>
    );
  }

  render() {
    const {
      title,
      closeDialog,
      onLoad,
      height,
      width,
      isTest,
      contacts,
      contactId: preselectedContactId
    } = this.props;
    const { phoneNumbers, options, phoneNumbersLoading, step } = this.state;

    return (
      <Dialog
        title={isTest ? 'Test Integration' : title}
        width={width}
        closeDialog={closeDialog}
        onLoad={onLoad}
        height={height}
        alwaysRenderChildren={true}
        isLoading={
          preselectedContactId &&
          (contacts.item.status === 'loading' || phoneNumbersLoading)
        }
      >
        {isTest && step === 0 && this.renderCredentialsForm()}
        {this.renderWarningMessage()}
        {this.renderErrorMessage()}
        {(!isTest || (isTest && step === 1)) && (
          <ReactForms
            handleSubmit={isTest ? this.makeTestCall : this.makeCall}
            initialValues={this.getInitialValues()}
            asyncValuesReady={
              preselectedContactId &&
              (contacts.item.status === 'loading' || phoneNumbersLoading)
            }
          >
            {({ submitForm, values, setFieldValue, isSubmitting }) => {
              this.setFieldValue = setFieldValue;
              this.submitForm = submitForm;

              return (
                <Form>
                  <Box pb={`calc(${PADDINGS.L} / 2)`}>
                    {isTest && (
                      <Body>
                        A Phone Call needs to be done to run the test. Select
                        the contact you wish to call.
                      </Body>
                    )}
                    <FormField
                      name='contact'
                      label='contact'
                      Input={EntitySelect}
                      onChange={this.onContactChange}
                      inputProps={{
                        disabled: !!preselectedContactId,
                        hideCloseButton: !!preselectedContactId,
                        models: [contactsModel],
                        options: options,
                        filter: this.customContactOptionFilter,
                        hasFixtures: true,
                        fixturePrefillData: this.fixturePrefillData
                      }}
                    />
                  </Box>
                  <Box flexDirection={'row'} alignItems='center'>
                    <FormField
                      name='phoneNumber'
                      Input={RadioGroupInput}
                      inputProps={{
                        name: 'radio-group',
                        options: phoneNumbers.map(this.getNumberRadioButton),
                        radioButtonStyles: radioStyles
                      }}
                      sendImmediate
                      fieldStyles={fieldStyles}
                    />
                    {this.renderPhoneNumbers()}
                  </Box>
                  {this.renderButtonBar(
                    submitForm,
                    isSubmitting,
                    values.phoneNumber
                  )}
                </Form>
              );
            }}
          </ReactForms>
        )}
      </Dialog>
    );
  }
}

export default MakeCall;
