import React, { PureComponent } from 'react';
import Dialog from 'view/components/dialog';
import { autobind } from 'core-decorators';
import { withModel } from '@rexlabs/model-generator';
import { styled, StyleSheet } from '@rexlabs/styling';
import sessionModel from 'data/models/custom/session';
import ThirdPartyServiceSpokeSocial from 'data/models/custom/third-party-service-spoke-social';
import _ from 'lodash';
import Spinner from 'shared/components/spinner';
import Box from '@rexlabs/box';
import { withErrorDialog } from 'src/hocs/with-error-dialog';

const defaultStyles = StyleSheet({
  iframe: {
    width: '100%',
    height: '500px'
  },
  hide: {
    height: 0,
    width: 0
  }
});

@withErrorDialog
@styled(defaultStyles)
@withModel(sessionModel)
@withModel(ThirdPartyServiceSpokeSocial)
@autobind
class SpokeSocialDialog extends PureComponent {
  state = {
    loading: true,
    link: this.props.link
  };

  async componentDidMount() {
    const {
      link,
      thirdPartyServiceSpokeSocial,
      connectionId,
      recordId,
      createLink,
      errorDialog
    } = this.props;

    if (!link) {
      try {
        const spokeResponse = await thirdPartyServiceSpokeSocial.createRecordLink(
          {
            connectionId,
            recordId,
            serviceName: 'Listings'
          }
        );

        this.setState({
          link: _.get(
            spokeResponse,
            `data.result.links.${createLink ? 'create' : 'list'}`
          )
        });
      } catch (e) {
        errorDialog.open(e);
      }
    }
  }

  handleCloseDialog() {
    const { closeDialog, getSpoke } = this.props;
    getSpoke(true);
    closeDialog();
  }

  render() {
    const { session, styles: s } = this.props;
    const { loading, link } = this.state;
    const userEmail = session?.user_details?.email;
    let iframeSrc = link || '';
    if (__DEV__) {
      iframeSrc = iframeSrc.replace(/3000/, '3003');
    }

    return (
      <Dialog
        title={'Spoke Campaigns'}
        width={1000}
        closeDialog={this.handleCloseDialog}
        isLoading={false}
        hasPadding={false}
        height={540}
      >
        {loading && (
          <Box justifyContent='center' alignItems='center' {...s('iframe')}>
            <Spinner dark small />
          </Box>
        )}
        {iframeSrc && (
          <iframe
            allowFullScreen
            onLoad={() => this.setState({ loading: false })}
            src={iframeSrc ? `${iframeSrc}&email=${userEmail}` : iframeSrc}
            {...s({ iframe: !loading, hide: loading })}
          />
        )}
      </Dialog>
    );
  }
}

export default SpokeSocialDialog;
