import { Generator } from 'shared/utils/models';
import { Id } from '@rexlabs/model-generator';
import { api } from 'shared/utils/api-client';
import _ from 'lodash';
import { PipelineStagesOption } from 'view/components/input/select/options/pipeline-stages';
import { mapPipelineStageToSelectOption } from 'features/pipelines/components/pipeline-stage-select';

export type PipelineTypeId = 'appraisal';

export interface PipelineStageData {
  id: Id;
  name: string;
  pipeline_type_id: PipelineTypeId;
  percentage: number;
  color: string;
  system_purpose?:
    | { id: 'won'; text: 'Won' }
    | { id: 'lost'; text: 'Lost' }
    | null;
}

const config = {
  entities: {
    api: {
      trashItem: (type, args, id) =>
        api
          .post('AdminPipelineStages::archive', { ...args, id: id })
          .then((response) => response.data.result)
    }
  }
};

const TYPE = 'adminPipelineStages';

const adminPipelineStages = new Generator<PipelineStageData>(
  TYPE,
  config
).createEntityModel();

// @ts-ignore
adminPipelineStages.select = {
  autocomplete: (searchTerm, { pipelineTypeId }) =>
    api
      .post('AdminPipelineStages::search', {
        criteria: [
          { name: 'name', type: 'like', value: `%${searchTerm}%` },
          { name: 'pipeline_type_id', type: '=', value: pipelineTypeId },
          { name: 'system_purpose_id', type: 'is', value: 'null' }
        ],
        order_by: { percentage: 'asc' }
      })
      .then(({ data }) => {
        return (_.get(data, 'result.rows') || []).map(
          (pipelineStage: PipelineStageData) => {
            return mapPipelineStageToSelectOption(pipelineStage);
          }
        );
      }),
  Option: PipelineStagesOption,
  Value: PipelineStagesOption
};

export default adminPipelineStages;
