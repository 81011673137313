import { gql } from 'hooks/use-graphql-query';
import {
  User,
  Property,
  Chain,
  CustomFields
} from 'features/custom-reporting/data/fragments';
import {
  Contact,
  ContactEmails,
  ContactPhones,
  MatchProfile
} from 'features/custom-reporting/modules/contacts/contact-data-fragments';
import { Contract } from '../contracts/contract-data-fragments';

import { CommissionWorksheet } from 'features/custom-reporting/modules/commission-by-sale/commission-by-sale-data-fragments';
import {
  Listing,
  ListingEpc
} from 'features/custom-reporting/modules/listings/listing-data-fragments';
import { Project } from 'features/custom-reporting/modules/projects/project-data-fragments';
import { ProjectStage } from 'features/custom-reporting/modules/project-stages/project-stage-data-fragments';

export const listingReportQueryDefaultVariables = {
  include_subresource_property: false,
  include_subresource_contract: false,
  include_subresource_record_owner: false,
  include_subresource_listing_agent_1: false,
  include_subresource_listing_agent_2: false,
  include_subresource_listing_owner: false,
  include_subresource_commissions: false,
  include_subresource_epc: false,
  include_subresource_project_stage: false,
  include_subresource_project: false,
  include_property_custom_fields: false,
  property_custom_field_ids: [],
  include_contract_custom_fields: false,
  contract_custom_field_ids: [],
  include_listing_owner_custom_fields: false,
  listing_owner_custom_field_ids: [],
  include_listing_custom_fields: false,
  listing_custom_field_ids: [],
  include_project_custom_fields: false,
  project_custom_field_ids: [],
  include_project_stage_custom_fields: false,
  project_stage_custom_field_ids: []
};

export const ListingReportQuery = gql`
  ${User}
  ${Listing}
  ${Property}
  ${Contact}
  ${Contract}
  ${Chain}
  ${ContactPhones}
  ${ContactEmails}
  ${MatchProfile}
  ${CommissionWorksheet}
  ${ListingEpc}
  ${Project}
  ${ProjectStage}
  ${CustomFields}

  query(
    $include_subresource_property: Boolean!
    $include_subresource_contract: Boolean!
    $include_subresource_record_owner: Boolean!
    $include_subresource_listing_agent_1: Boolean!
    $include_subresource_listing_agent_2: Boolean!
    $include_subresource_listing_owner: Boolean!
    $include_subresource_commissions: Boolean!
    $include_subresource_epc: Boolean!
    $include_subresource_project_stage: Boolean!
    $include_subresource_project: Boolean!
    $include_property_custom_fields: Boolean!
    $property_custom_field_ids: [Int!]!
    $include_listing_custom_fields: Boolean!
    $listing_custom_field_ids: [Int!]!
    $include_listing_owner_custom_fields: Boolean!
    $listing_owner_custom_field_ids: [Int!]!
    $include_project_custom_fields: Boolean!
    $project_custom_field_ids: [Int!]!
    $include_project_stage_custom_fields: Boolean!
    $project_stage_custom_field_ids: [Int!]!
    $include_contract_custom_fields: Boolean!
    $contract_custom_field_ids: [Int!]!
    $criteria: [Criterion!]
    $order_bys: [OrderBy!]
    $limit: Int!
    $cursor: String
  ) {
    rowData: listings(
      limit: $limit
      cursor: $cursor
      criteria: $criteria
      order_bys: $order_bys
      use_case: reporting
    ) {
      pagination {
        next_page {
          cursor
        }
      }
      rows {
        ...Listing
        custom_field_values(field_ids: $listing_custom_field_ids)
          @include(if: $include_listing_custom_fields) {
          ...CustomFields
        }
        listing_agent_1 @include(if: $include_subresource_listing_agent_1) {
          name
        }
        listing_agent_2 @include(if: $include_subresource_listing_agent_2) {
          name
        }
        system_owner_user @include(if: $include_subresource_record_owner) {
          ...User
        }
        property @include(if: $include_subresource_property) {
          ...Property
          custom_field_values(field_ids: $property_custom_field_ids)
            @include(if: $include_property_custom_fields) {
            ...CustomFields
          }
          system_owner_user {
            ...User
          }
        }
        primary_active_contract @include(if: $include_subresource_contract) {
          ...Contract
          custom_field_values(field_ids: $contract_custom_field_ids)
            @include(if: $include_contract_custom_fields) {
            ...CustomFields
          }
          agent {
            ...User
          }
          chain_instance {
            assignee_user {
              ...User
            }
            chain {
              ...Chain
            }
          }
        }
        owner: contact_relationships(limit: 1, type: "owner")
          @include(if: $include_subresource_listing_owner) {
          relationship_type {
            id
          }
          contact {
            ...Contact
            custom_field_values(field_ids: $listing_owner_custom_field_ids)
              @include(if: $include_listing_owner_custom_fields) {
              ...CustomFields
            }
            phones {
              ...ContactPhones
            }
            emails {
              ...ContactEmails
            }
            system_owner_user {
              ...User
            }
            latest_match_profile {
              ...MatchProfile
            }
          }
        }

        commissions: primary_active_contract
          @include(if: $include_subresource_commissions) {
          commission_worksheet {
            ...CommissionWorksheet
          }
        }
        epc @include(if: $include_subresource_epc) {
          ...ListingEpc
        }
        project_stage @include(if: $include_subresource_project_stage) {
          ...ProjectStage
          custom_field_values(field_ids: $project_stage_custom_field_ids)
            @include(if: $include_project_stage_custom_fields) {
            ...CustomFields
          }
          system_created_user {
            ...User
          }
          system_owner_user {
            ...User
          }
        }
        project: project_stage @include(if: $include_subresource_project) {
          project {
            ...Project
            custom_field_values(field_ids: $project_custom_field_ids)
              @include(if: $include_project_custom_fields) {
              ...CustomFields
            }
            system_created_user {
              ...User
            }
            system_owner_user {
              ...User
            }
          }
        }
      }
    }
  }
`;
