import _ from 'lodash';
import React, { Component, PureComponent } from 'react';
import { autobind } from 'core-decorators';
import { styled, StyleSheet } from '@rexlabs/styling';
import {
  parseRouteToUrl,
  buildQueryString,
  withWhereabouts
} from '@rexlabs/whereabouts';
import { PressAndEscapePropagator } from 'utils/events';
import ui from 'data/models/custom/ui';
import uiClassicHeader from 'data/models/custom/ui-classic-header';
import { connect } from 'react-redux';
import invariant from 'invariant';
import SaveCancelContext from 'data/save-cancel-context';
import { clearMemoizeCaches } from 'utils/memoize-cache';
import { clearBridgeProxies } from 'utils/bridge-proxy';
import { withRegion } from 'src/hocs/with-region';
import { withPermissions } from 'src/hocs/with-permissions';
import NotFoundScreen from 'view/screens/not-found';
import config from 'shared/utils/config';
import Analytics from 'shared/utils/vivid-analytics';

const LOG_STYLE = `
  font-family: monospace;
  color: white;
  background-color: rgba(23, 81, 113, 1);
  border-radius: 2px;
  padding: 2px 3px;
`.trim();

const euOnlyUrls = [
  '/market-leads',
  '/chains',
  '/referrals',
  '/admin/metric_write_rules',
  '/admin/referrals/categories'
].reduce((all, url) => [...all, url, `${url}/`], []);

const addonUrls = [
  {
    url: '/admin/workflows',
    accessRights: 'addon.workflows && workflows.manage_workflows'
  },
  {
    url: '/admin/settings/aml-settings',
    accessRights: 'addon.aml_and_id_checks && admin_application.access_section'
  },
  {
    url: '/admin/identity/id_types',
    accessRights: 'addon.aml_and_id_checks && admin_application.access_section'
  },
  {
    url: '/admin/identity/contact-requirements',
    accessRights: 'addon.aml_and_id_checks && admin_application.access_section'
  },
  {
    url: '/admin/identity/company-requirements',
    accessRights: 'addon.aml_and_id_checks && admin_application.access_section'
  },
  {
    url: '/admin/identity/company-requirements',
    accessRights: 'addon.aml_and_id_checks && admin_application.access_section'
  },
  {
    url: '/admin/embedded_apps',
    accessRights: 'addon.embedded_apps && admin_application.access_section'
  }
].reduce(
  (all, urlConfig) => [
    ...all,
    urlConfig,
    { ...urlConfig, url: `${urlConfig.url}/` }
  ],
  []
);

/**
 * Rex2 app as a React Component. :O
 */

const iframeStyles = StyleSheet({
  container: {
    margin: 0,
    padding: 0,
    visibility: 'hidden'
  }
});

@withPermissions
@withWhereabouts
@withRegion
@styled(iframeStyles)
@autobind
class ClassicAppIFrame extends PureComponent {
  constructor(props, ...args) {
    super(props, ...args);
    // Note: See notes in componentWillReceiveProps as to why we set the url once.
    this.state = {
      url: props.url,
      refUrl: props.refUrl
    };
  }

  componentWillReceiveProps(nextProps) {
    const { url: nextUrl } = nextProps;
    const { url: pastUrl } = this.props;
    if (nextUrl !== pastUrl) {
      if (__DEV__) {
        const routeUrl = nextUrl.replace(new RegExp(`^.*${location.host}`), '');
        // eslint-disable-next-line no-console
        console.log(`%cClassic App\n👉 ${routeUrl} `, LOG_STYLE);
      }

      /*
      |-------------------------------------------------------------------------
      | We want to use `location.replace` instead of updating the `src` attr on
      | the <iframe />, because we NEED to avoid unintentionally adding another
      | history entry to the Browser's tab.
      */
      window.Rex2IFrame.__SHELL_PROVIDED_URL = nextProps.refUrl;
      if (nextUrl.replace(/#.*/, '') !== pastUrl.replace(/#.*/, '')) {
        window.Rex2FrameWindow.dispatchEvent(new Event('teardown'));
        clearMemoizeCaches();
        clearBridgeProxies();
      }
      window.Rex2FrameWindow.location.replace(nextProps.url);
    }
  }

  componentWillUnmount() {
    if (window.Rex2FrameWindow) {
      window.Rex2FrameWindow.dispatchEvent(new Event('teardown'));
      PressAndEscapePropagator.unregister(window.Rex2FrameWindow);
      window.Rex2FrameWindow = null;
      window.Rex2IFrame = null;
      clearMemoizeCaches();
      clearBridgeProxies();
    }
  }

  render() {
    const {
      styles: s,
      onPageLoad,
      whereabouts,
      permissions,
      region,
      ...props
    } = this.props;
    const requiresAddon = addonUrls.find(
      (config) => config.url === whereabouts.path
    );

    if (requiresAddon && !permissions.check(requiresAddon.accessRights)) {
      return <NotFoundScreen />;
    }

    if (euOnlyUrls.includes(whereabouts.path) && !region.isEU) {
      return <NotFoundScreen />;
    }

    return (
      <iframe
        {...s.with('container')(props)}
        referrerPolicy='origin'
        title='AppFrame'
        id='AppFrame'
        frameBorder={0}
        width='100%'
        height='100%'
        src={this.state.url}
        ref={this.setRef}
        onLoad={onPageLoad}
      />
    );
  }

  setRef(iFrame) {
    if (iFrame) {
      window.Rex2FrameWindow = iFrame.contentWindow;
      window.Rex2IFrame = iFrame;
      /*
      |-------------------------------------------------------------------------------
      | We attach the "__SHELL_PROVIDED_URL" to the HTMLIFrameElement, instead
      | of the frames inner `window`, because we know the IFrame's element will
      | persist through the lifespan of an iframe when it goes about performing
      | several kinds of external forced and internally managed navigations.
      |
      | A solid case is when the IFrame invokes a:
      |   `contentWindow.location.replace()`
      | This will create a new window object, which means we'd need to listen
      | to the right events to re-assign the shell provided url...
      */
      window.Rex2IFrame.__SHELL_PROVIDED_URL = this.props.refUrl;
    }
  }
}

const CLASSIC_SCREENS_TO_REDIRECT = [/\/classic\/?.+/];

const ANIMATION_BUFFER_MS = 300;
const LOAD_DONE_DELAY_MS = 150;

function assureHash(url) {
  return url.includes('#') ? url : url + '#';
}

// NOTE: using manual @connect instead of @withModel, since we don't care about the
// actual state here and just want the action dispatchers, @withModel would cause
// unnecessary re-renders whenever the state changes!
@connect(null, {
  updateHeaderCollapse: uiClassicHeader.actionCreators.updateHeaderCollapse,
  updateDialogActive: ui.actionCreators.updateDialogActive,
  updateClassicDialogActive: ui.actionCreators.updateClassicDialogActive,
  loadingIndicatorOn: ui.actionCreators.loadingIndicatorOn,
  loadingIndicatorOff: ui.actionCreators.loadingIndicatorOff
})
@autobind
class ClassicAppFrame extends Component {
  constructor(props, ...args) {
    super(props, ...args);
    this.state = this.getNextUrls(props);
  }

  componentWillReceiveProps(nextProps) {
    const nextUrl = parseRouteToUrl(nextProps.whereabouts);
    if (this.shouldHardRedirect(nextProps)) {
      window.parent.location.href = nextUrl;
    } else {
      // We can't be sure that query param or hash fragments mean we're changing screens!
      if (nextProps.whereabouts.path !== this.props.whereabouts.path) {
        // Reset the un-ideal state of our header
        nextProps.updateDialogActive(false);
        nextProps.updateClassicDialogActive(false);
        nextProps.updateHeaderCollapse(false);

        // Let the user know we're moving away from the screen!
        nextProps.loadingIndicatorOn({ message: 'navigating' });

        // Clear the save/cancel context cause we don't need it anymore!
        SaveCancelContext.reset();
      }
      const currentUrl = parseRouteToUrl(this.props.whereabouts);
      if (nextUrl !== currentUrl) {
        this.deferRouteChange(nextProps);
      }
    }
  }

  shouldComponentUpdate(nextProps) {
    // Block the render if we're going to do a hard redirect.
    return !this.shouldHardRedirect(nextProps);
  }

  componentWillUnmount() {
    this.deferRouteReset();
    this.deferClearLoadingIndicatorReset();
  }

  render() {
    const { className, style, whereabouts } = this.props;
    return (
      <ClassicAppIFrame
        style={style}
        className={className}
        url={this.state.url}
        refUrl={this.state.refUrl}
        onPageLoad={this.handlePageLoad}
        whereabouts={whereabouts}
      />
    );
  }

  handlePageLoad(ev) {
    ev.target.style.visibility = 'visible';
    this.deferClearLoadingIndicator(false);
    this.setupDialogsForStepthrough();

    if (window.Rex2FrameWindow) {
      invariant(
        ev.target.contentWindow === window.Rex2FrameWindow,
        'The same window must be passed to register and unregister.'
      );
      PressAndEscapePropagator.register(ev.target.contentWindow);
    }

    Analytics.performance({
      task: 'Classic-FullInit',
      event: 'classicAppIframePageLoad',
      hasCompletedTask: true
    });
  }

  setupDialogsForStepthrough() {
    /*
    |-------------------------------------------------------------------------
    | We then check to see if a Rex2StepthroughIFrame exists already, and if
    | so, setup it's dialogs with the dialogs available through this classic
    | app.
    */
    if (
      _.get(
        window,
        'Rex2StepthroughIFrame.__SHELL_STEPTHROUGH_ASSIGN_CLASSIC_DIALOGS_TO_STEPTHROUGH'
      )
    ) {
      window.Rex2StepthroughIFrame.__SHELL_STEPTHROUGH_ASSIGN_CLASSIC_DIALOGS_TO_STEPTHROUGH();
    }
  }

  shouldHardRedirect(props = this.props) {
    return CLASSIC_SCREENS_TO_REDIRECT.some((regexp) =>
      regexp.test(props.whereabouts.path)
    );
  }

  getEmbeddedUrl(props = this.props) {
    const { whereabouts: w } = props;
    const { protocol, host } = window.location;

    const path = w.path ? w.path : '';
    let query = buildQueryString(w.query);
    query = query ? `&${query}` : '';
    const hash = w.hash ? `#${w.hash}` : '';

    return `${protocol}//${host}/embedded${path}?__releaseHash=${config.RELEASE?.HASH}${query}${hash}`;
  }

  getNextUrls(props) {
    return {
      url: assureHash(this.getEmbeddedUrl(props)),
      refUrl: assureHash(parseRouteToUrl(props.whereabouts))
    };
  }

  deferRouteChange(props = this.props) {
    this.deferRouteReset();
    this.routeRequest = setTimeout(() => {
      this.setState(() => this.getNextUrls(props));
    }, ANIMATION_BUFFER_MS);
  }

  deferRouteReset() {
    clearTimeout(this.routeRequest);
  }

  deferClearLoadingIndicator() {
    this.deferClearLoadingIndicatorReset();
    this.loadingIndicatorTimer = setTimeout(() => {
      this.props.loadingIndicatorOff({ message: 'navigating' });
      this.props.loadingIndicatorOff({ message: 'loading app' });
    }, LOAD_DONE_DELAY_MS);
  }

  deferClearLoadingIndicatorReset() {
    clearTimeout(this.loadingIndicatorTimer);
  }
}

export default ClassicAppFrame;
