import Box from '@rexlabs/box';
import { withModel } from '@rexlabs/model-generator';
import { Body } from 'components/text/body';
import { SubHeading } from 'components/text/sub-heading';
import accountSettingsCommissionWorksheetModel, {
  AccountSettingsCommissionWorksheet,
  AccountSettingsCommissionWorksheetModel
} from 'data/models/entities/account-settings-commission-worksheet';
import commWorksheetVersion from 'data/models/value-lists/comm-worksheet-version';
import { useErrorDialog } from 'hooks/use-error-dialog';
import React, { useEffect, useState } from 'react';
import Spinner from 'shared/components/spinner';
import { Form, FormField, ReactForms } from 'src/view/components/form';
import { ValueListSelect } from 'src/view/components/input/select';
import PaddingBox from 'src/view/components/padding-box';
import SaveCancelOverlayHandler from 'src/view/components/save-cancel-overlay-handler';

interface WorksheetSettingsProps {
  accountSettingsCommissionWorksheet: AccountSettingsCommissionWorksheetModel;
}

const WorksheetSettings = ({
  accountSettingsCommissionWorksheet
}: WorksheetSettingsProps) => {
  const errorDialog = useErrorDialog();
  const {
    getEffectiveSettings,
    updateSettings
  } = accountSettingsCommissionWorksheet;
  const [isLoading, setIsLoading] = useState(true);
  const [initialValues, setInitialValues] = useState<
    Partial<AccountSettingsCommissionWorksheet>
  >();

  useEffect(() => {
    const getEffectiveSettingsAsync = async () => {
      try {
        const res = await getEffectiveSettings();
        setInitialValues({
          default_worksheet_version_id: res.data.result.default_worksheet_version_id.toString()
        });
      } catch (error) {
        errorDialog.open(error as Error);
      }
      setIsLoading(false);
    };
    getEffectiveSettingsAsync();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = async (
    values: AccountSettingsCommissionWorksheet,
    { resetForm }
  ) => {
    try {
      if (!values.default_worksheet_version_id) {
        errorDialog.open({
          message: 'The default worksheet format field is required.'
        });
        resetForm();
        return;
      }
      return await updateSettings({ settings: values });
    } catch (error) {
      errorDialog.open(error as Error);
      resetForm();
    }
  };

  if (isLoading)
    return (
      <Box
        justifyContent='center'
        alignItems='center'
        width={'100%'}
        height={'100%'}
      >
        <Spinner dark classic />
      </Box>
    );

  return (
    // Same padding with other pages
    <PaddingBox p={27.5}>
      <ReactForms handleSubmit={handleSubmit} initialValues={initialValues}>
        {({ resetForm, submitForm, isDirty }) => {
          return (
            <Form>
              <SubHeading semibold style={{ marginBottom: 4 }}>
                Default Worksheet Format
              </SubHeading>
              <Body regular dark>
                The default commission worksheet format will be used for all
                newly created worksheets.
              </Body>
              <FormField
                label='default worksheet format'
                name='default_worksheet_version_id'
                Input={ValueListSelect}
                inputProps={{
                  searchOnMount: true,
                  models: [commWorksheetVersion]
                }}
              />
              <Box flexDirection='column' mt={15} spacing={15}>
                <Body dark regular>
                  Version 1 takes fees out of the gross commission, then
                  allocates an awarded percentage of the remaining to each agent
                  (the % responsible for sale). Agent deductions are then taken
                  from this distributable commission. Agents can then be
                  assigned a percentage or fixed amount from the remaining
                  commission.
                </Body>
                <Body dark regular>
                  Version 2 takes fees out of the gross commission, then
                  allocates an awarded percentage of the remaining to each agent
                  (the % responsible for sale). Agents can then be assigned a
                  percentage or fixed amount from this distributable commission.
                  Agent deductions are then taken from this amount.
                </Body>
                <Body dark regular>
                  Version 3 allows agent commission (percentage or fixed amount)
                  to be distributed from the gross commission (less any upfront
                  deductions). Agent deductions are then taken from this amount,
                  with any further deductions taken out of the remaining.
                </Body>
              </Box>
              <SaveCancelOverlayHandler
                isVisible={isDirty}
                onSave={submitForm}
                onCancel={resetForm}
              />
            </Form>
          );
        }}
      </ReactForms>
    </PaddingBox>
  );
};

export default withModel(accountSettingsCommissionWorksheetModel)(
  WorksheetSettings
);
