/* eslint-disable max-lines */
import _ from 'lodash';
import { Generator } from 'shared/utils/models';
import { api } from 'shared/utils/api-client';

import AppraisalsOption from 'view/components/input/select/options/appraisals';
import AppraisalsValue from 'view/components/input/select/values/appraisals';
import AppraisalsFixture from 'view/components/input/select/fixtures/appraisals';
import { Id } from '@rexlabs/model-generator';
import { CommissionEstimateData } from 'features/appraisals/components/commission-estimate-fields';
import { PropertyStub } from 'data/models/entities/properties';
import { PipelineStageData } from 'features/pipelines/data/admin-pipeline-stages';
import { ValueListItem } from 'data/models/value-lists/value-list';
import { pipelineActionCreators } from 'features/pipelines/data/utils/pipeline-action-creators';
import { generateBatchFetchFunctions } from 'features/pipelines/data/utils/pipeline-batching';
import {
  ContactAutocomplete,
  SecurityUserRights
} from 'data/models/entities/contacts';

export type AppraisalData = {
  id: Id;
  agent_1: { id: string; text: string } | null;
  agent_2: { id: string; text: string } | null;
  appraisal_date: string;
  appraisal_state: 'active' | 'archived';
  archive_date?: string | null;
  archive_lost_agency?: string | null;
  archive_reason?: ValueListItem | { id: string };
  interest_level?: 'warm' | 'hot' | 'cold' | null;
  price_asking?: number | null;
  price_max?: number | null;
  property?: PropertyStub;
  price_min?: number | null;
  price_rent?: number | null;
  pipeline_stage: PipelineStageData | null;
  expected_close_date: string | null;
  price_rent_tax: ValueListItem | { id: string };
  related?: {
    property: {
      contact_reln_property: [
        { id: Id; reln_type: { id: string }; contact: ContactAutocomplete }
      ];
    };
  };
  appraisal_type:
    | { id: 'sale'; text: 'Sale' }
    | { id: 'rent'; text: 'Rent / Lease' }
    | { id: 'rent'; text: 'Let' };
  price_rent_period:
    | { id: 'week'; text: 'Week' }
    | { id: 'month'; text: 'Month' }
    | { id: 'fortnight'; text: 'Fortnight' }
    | { id: 'year'; text: 'Year' }
    | null;
  security_user_rights: SecurityUserRights[];
} & CommissionEstimateData;

export type PipelineGroupBy =
  | 'pipeline_stage'
  | 'days_to_expected_close_30_day_bucketed'
  | null;

const TYPE = 'appraisals';

const actionCreators: any = {
  getDynamicCommissionEstimate: {
    request: ({
      appraisalId,
      dataOverlay
    }: {
      appraisalId?: Id | null;
      dataOverlay: Partial<AppraisalData>;
    }) =>
      api
        .post('Appraisals::getDynamicCommissionEstimate', {
          appraisal_id: appraisalId,
          data_overlay: dataOverlay
        })
        .then((response) => response.data.result),
    reduce: {
      initial: _.identity,
      success: _.identity,
      failure: _.identity
    }
  },
  ...pipelineActionCreators
};

export const APPRAISALS_PER_PAGE_KANBAN = 20;

const {
  fetchList,
  setListIdsToAlwaysBatch: appraisalsSetListIdsToAlwaysBatch,
  setGroupBy: appraisalsSetGroupBy,
  batchNextCall: appraisalsBatchNextCall
} = generateBatchFetchFunctions({
  limit: APPRAISALS_PER_PAGE_KANBAN,
  groupByDateField: 'expected_close_date',
  extraOptions: {
    extra_fields: ['property.contact_reln_property']
  }
});

export {
  appraisalsSetListIdsToAlwaysBatch,
  appraisalsBatchNextCall,
  appraisalsSetGroupBy
};

const config: any = {
  entities: {
    api: {
      fetchList,
      trashItem: (type, args, id) =>
        api
          .post('Appraisals::purge', { ...args, id: id })
          .then((response) => response.data.result)
    }
  }
};

const appraisalsModel = new Generator<AppraisalData, typeof actionCreators>(
  TYPE,
  config
).createEntityModel({
  actionCreators
});

// @ts-ignore
appraisalsModel.select = {
  autocomplete: (searchTerm, { propertyId }) =>
    api
      .post('Appraisals::search', {
        criteria: [{ name: 'property_id', type: '=', value: propertyId }]
      })
      .then(({ data }) => {
        return (_.get(data, 'result.rows') || []).map((appraisal) => {
          return {
            value: appraisal.id,
            label: appraisal.address,
            data: appraisal,
            model: appraisalsModel
          };
        });
      }),
  Option: AppraisalsOption,
  Value: AppraisalsValue,
  Fixture: AppraisalsFixture
};

export default appraisalsModel;
