/* eslint-disable max-lines */
import React, { PureComponent, Fragment } from 'react';
import { autobind } from 'core-decorators';
import _ from 'lodash';
import { connect } from 'react-redux';

import Box from '@rexlabs/box';
import { StyleSheet, styled } from '@rexlabs/styling';
import { push } from '@rexlabs/whereabouts';

import DialogsBridge from 'data/classic-bridges/dialogs-shell';
import calendarEventsModel from 'data/models/entities/calendar-events';
import feedbackModel from 'data/models/entities/feedback';

import { Popout } from 'view/components/popout';
import PaddingBox from 'view/components/padding-box';
import { ButtonBar } from 'view/components/button-bar';
import { TextButton, DefaultButton } from 'view/components/button';
import { Dropdown, MergeActionMenu } from 'view/components/action-menu';
import { withPermissions } from 'src/hocs/with-permissions';
import { withCalendarContext } from 'view/context/calendar';
import withClassicDialog from 'view/containers/with-classic-dialog';
import { withErrorDialog } from 'src/hocs/with-error-dialog';

import PendingBanner from 'features/calendar/components/pending-banner';
import { Heading } from 'components/text/heading';

import { COLORS } from 'theme';
import ROUTES from 'routes/app';
import {
  DAY_MILLISECONDS,
  EVENT_TYPES,
  getEditMode,
  getTmpEventId,
  isSystemEventTypeId
} from 'utils/calendar';
import { withModel } from '@rexlabs/model-generator';
import { formatDateRange, formatTravelTime } from 'utils/time';
import { withRegion } from 'src/hocs/with-region';
import { sendEmail, sendSms } from 'features/calendar/utils/send-merge';
import { getCalendarEventStatus } from 'features/calendar/utils/get-calendar-event-status';

import { ICONS } from 'shared/components/icon';

const NullComponent = () => null;

const valueStyles = StyleSheet({
  wrapValue: {
    width: '100%',
    marginTop: '13px'
  },

  wrapValueFirst: {
    marginTop: 0
  },

  label: {
    fontSize: '14px',
    lineHeight: '19px',
    fontWeight: 600,
    paddingBottom: '2px'
  },

  value: {
    width: '100%',
    fontSize: '14px',
    lineHeight: '17px'
  },

  extraValue: {
    color: COLORS.GREY
  },

  icon: {
    marginRight: '6px',
    height: '11px',
    width: '11px'
  }
});

@styled(valueStyles)
class Value extends PureComponent {
  cropValue() {
    const { value, maxLength } = this.props;
    if (!maxLength || value.length <= maxLength) {
      return value;
    }
    return `${value.substring(0, maxLength)}...`;
  }

  render() {
    const { styles: s, label, Icon, value, extraValue, first } = this.props;

    if (!value) {
      return null;
    }

    return (
      <Box
        {...s('wrapValue', { wrapValueFirst: first })}
        flexDirection='column'
      >
        <Box {...s('label')} flexDirection='row' alignItems='center'>
          {Icon && <Icon {...s('icon')} />}
          <span>{label}</span>
        </Box>
        <span {...s('value')}>{this.cropValue()}</span>
        {extraValue && <span {...s('value', 'extraValue')}>{extraValue}</span>}
      </Box>
    );
  }
}

@withCalendarContext(['adminAppointmentTypes', 'userCalendars'])
@withRegion
@withClassicDialog('editFeedback')
@withErrorDialog
@withModel(calendarEventsModel)
@withModel(feedbackModel)
@withPermissions
@autobind
class ViewAppointmentPopoutContent extends PureComponent {
  async addRecord(record, updateEvent) {
    const { event } = this.props;

    const records = _.get(event, 'records', []);
    const services = {
      feedback: 'Feedback',
      appraisal: 'Appraisals'
    };

    const eventWithNewRecord = {
      ...event,
      records: [
        ...records,
        {
          id: record.value,
          name: record.label,
          data: record.data,
          model: record.model,
          type: record.type,
          service: services[record.type]
        }
      ]
    };

    await updateEvent(eventWithNewRecord);
  }

  addAppraisal(appraisal, updateEvent) {
    return this.addRecord(
      {
        ...appraisal,
        type: 'appraisal'
      },
      updateEvent
    );
  }

  async addFeedback(data, updateEvent) {
    const { errorDialog, feedback } = this.props;

    // Create feedback record
    const { data: newData } = await feedback
      .createItem({ data })
      .catch(errorDialog.open);

    // if undefined or 'pending', the BE sets the default of 'pending' anyway, so we don't need to make this request in that case
    if (data?.system_approval_status === 'approved') {
      // Attempt to set feedback approval status
      feedback.trySetApprovalStatus({
        id: newData?.result?.id,
        status: data?.system_approval_status
      });
    }

    // Regardless of feedback approval status request outcome, attach the feedback record
    // (that has been created anyway) to the appointment record
    return this.addRecord(
      {
        value: _.get(newData, 'result.id'),
        label: `${_.get(newData, 'result.feedback_type.text')} Feedback`,
        data: _.get(newData, 'result'),
        model: feedbackModel,
        type: 'feedback'
      },
      updateEvent
    );
  }

  handleRestoreAppointment(uncancelEvent) {
    const { calendarEvents, event } = this.props;
    const recurring = _.get(event, 'is_recurring');

    // eslint-disable-next-line no-new
    new Promise((resolve) => {
      if (recurring) {
        DialogsBridge.editRecurring.open({
          action: 'restore',
          callback: (val) => resolve(val),
          cancelCallback: () => resolve('cancelled')
        });
      } else {
        resolve();
      }
    }).then((val) => {
      if (val === 'cancelled') {
        return;
      }
      uncancelEvent(event.id, false);

      calendarEvents
        .updateItem({
          id: event.id,
          data: {
            id: event.id,
            is_recurring: recurring,
            rule: _.get(event, 'rule'),
            calendar_id: _.get(event, 'calendar.id'),
            event_status_id: 'confirmed',
            is_cancelled: false,
            update_recurring_events: _.get(val, 'applyTo') === 'following'
          }
        })
        .then(() => {
          // Runs the handle restore again to refetch the recurring events that may have changed
          // These aren't caught by the optimistic update as they haven't changed yet
          if (recurring) {
            uncancelEvent(event.id, recurring);
          }
        });
    });
  }

  renderAlerts(alerts) {
    if (alerts.length === 0) {
      return;
    }

    return (
      <p>
        <b>
          {/* We can just use any alert here as they are categorised by alert_type and recipient_type anyway */}
          {_.get(_.first(alerts), 'alert_type.id') === 'sms' ? 'SMS' : 'Email'}{' '}
          {_.get(_.first(alerts), 'recipient_type.id') === 'all'
            ? 'all guests'
            : _.get(_.first(alerts), 'recipient_type.id') === 'user'
            ? 'users '
            : 'contacts '}
        </b>{' '}
        <span>
          {alerts.map((alert, index) => {
            let time = parseInt(alert.minutes_before_event);
            let isHours = false;
            if (time % 60 === 0) {
              time = time / 60;
              isHours = true;
            }

            return (
              <span key={index}>
                {!!time && `${time} `}
                {!!time && (isHours ? 'hour' : 'minute')}
                {time ? (time > 1 ? 's before' : ' before') : 'at start time'}
                {index !== alerts.length - 1 && ' and '}
              </span>
            );
          })}
        </span>
      </p>
    );
  }

  getActionsDropdown() {
    const {
      permissions,
      event,
      officeDetails,
      editFeedback,
      region: { isEU }
    } = this.props;

    const {
      deleteEvent,
      cancelEvent,
      uncancelEvent,
      updateEvent
    } = this.props.context;
    const editMode = getEditMode(event, officeDetails);
    const isRemoteDeletable = event?.remote_permission_can_delete;
    const canDeleteEvent =
      permissions.check('calendars.purge_event') &&
      event?.security_user_rights?.includes('purge') &&
      isRemoteDeletable;

    const addAppraisalItem = {
      label: isEU ? 'Add Valuation' : 'Add Appraisal',
      onClick: () =>
        DialogsBridge.addAppraisal.open({
          records: event.records,
          callback: (appraisal) => this.addAppraisal(appraisal, updateEvent)
        })
    };
    const addFeedbackItem = permissions.check('feedback.create')
      ? [
          {
            label: 'Add Feedback',
            onClick: () =>
              editFeedback.open({
                options: {
                  fromCalendar: true,
                  records: event.records
                },
                mode: 'both',
                callback: (feedback) => this.addFeedback(feedback, updateEvent)
              })
          }
        ]
      : [];
    const cancelItem =
      _.get(event, 'event_status.id') !== 'cancelled'
        ? [
            {
              label: 'Cancel Appointment',
              onClick: () =>
                DialogsBridge.cancelAppointment.open({
                  id: event.id,
                  recurring: _.get(event, 'is_recurring'),
                  calendarId: _.get(event, 'calendar.id'),
                  callback: () =>
                    cancelEvent(event.id, _.get(event, 'is_recurring'))
                })
            }
          ]
        : [
            {
              label: 'Restore Appointment',
              onClick: () => this.handleRestoreAppointment(uncancelEvent)
            }
          ];

    const editItems = [addAppraisalItem, ...addFeedbackItem, ...cancelItem];

    const deleteItems = [
      {
        label: 'Delete',
        onClick: () =>
          DialogsBridge.deleteAppointment.open({
            id: event.id,
            recurring: _.get(event, 'is_recurring'),
            calendarId: _.get(event, 'calendar.id'),
            isRemoteDeletable,
            callback: () => deleteEvent(event.id, _.get(event, 'is_recurring'))
          })
      }
    ];

    const menuItems = [
      ...(editMode === 'default' ? editItems : []),
      ...(canDeleteEvent ? deleteItems : [])
    ];

    if (menuItems.length === 0) {
      return null;
    }

    return (
      <Dropdown left placement='bottom-start' items={menuItems}>
        Actions
      </Dropdown>
    );
  }

  getGuestNamesFromRecords(records) {
    if (!records) {
      return [];
    }

    return records
      .filter((record) => record.service === 'Contacts')
      .map((contact) => contact.label);
  }

  getGuestNamesFromGuestCalendars(guestCalendars) {
    if (!guestCalendars) {
      return [];
    }

    return guestCalendars.map((guest) => guest.name);
  }

  // Make a string of a certain length out of a list but don't cut
  // off any list item halfway through.
  limitListJoinByCharLength(list = [], charLength = 100, joinCharacter = ', ') {
    return list.length && !_.isString(list)
      ? list.reduce((result, currentString) => {
          if (result.includes('...')) {
            return result;
          }

          const newResult = `${result}${joinCharacter}${currentString}`;

          if (newResult.length > charLength) {
            // Appending '...' to the *old string* if new string is too long.
            return result + '...';
          }
          return newResult;
        })
      : '';
  }

  // convert array of guests to a single line of string
  // if there's more than 4 guests, it returns the following format e.g. James Brown, Les Brown, Kayne Brown + x others
  // if there's only 4 guests or less, it returns all names
  getGuestNamesAsString(guests) {
    if (!guests || guests.length === 0) {
      return;
    }

    if (guests.length > 4) {
      return `${guests.slice(0, 3).join(', ')} + ${guests.length - 3} others`;
    }

    return guests.join(', ');
  }

  render() {
    const {
      toggle,
      event,
      styles: s,
      officeDetails,
      userId,
      sendConfirmationMessagesDialog
    } = this.props;
    const { getEventColor } = this.props.context;

    const isTmpEvent = !!getTmpEventId(_.get(event, 'uuid'));
    const type = _.get(event, 'appointment_type.id');
    const isOpenHome = type === EVENT_TYPES.OPEN_HOME;
    const isAuction = type === EVENT_TYPES.AUCTION;
    const isEventBusy = event.security_user_rights?.includes(
      'read_availability'
    );

    const isSystem = isSystemEventTypeId(type);
    const editMode = getEditMode(event, officeDetails);
    const heading =
      isSystem && !isEventBusy
        ? _.get(event, 'appointment_type.name')
        : _.get(event, 'title');
    const subtitle =
      editMode === 'none'
        ? 'You don’t have permission to view details'
        : isSystem
        ? _.get(event, 'event_location.description')
        : _.get(event, 'appointment_type.name');

    const startDate = _.get(event, 'start');
    let endDate = _.get(event, 'end');

    if (_.get(event, 'allDay')) {
      // In the BE - and in react-big-calendar - all-day events are represented
      // as starting at 12:00 AM one day and finishing at 12:00 AM on the next
      // day. That messes with the date formatting as a single-day, all-day
      // event will appear to span two days. Subtract one day to take this into
      // account.
      endDate = Math.max(startDate, endDate - DAY_MILLISECONDS);
    }

    const date = formatDateRange(startDate, endDate, {
      time: !_.get(event, 'allDay')
    });

    const travelTime = +_.get(event, 'travel_time_minutes', 0);
    const travelOrigin = _.get(event, 'starting_location.address');
    const travelText = travelTime
      ? `${formatTravelTime(travelTime)} travel time${
          travelOrigin ? ` estimated from ${travelOrigin}` : ''
        }`
      : '';

    const eventOwner = _.get(
      event,
      'organiser_user.name',
      _.get(event, 'metadata.organiser_name', null)
    );

    const createdUserName = event?.system_created_user?.name;

    const shouldShowBookingUser =
      createdUserName && createdUserName !== eventOwner;

    const guests = [
      ...this.getGuestNamesFromGuestCalendars(event?.guest_calendars),
      ...this.getGuestNamesFromRecords(event?.records)
    ];
    const allGuests = guests.filter(Boolean);

    const agents = [];
    if (isOpenHome) {
      agents.push(_.get(event, 'organiser_user.name'));
    } else if (isAuction) {
      agents.push(_.get(event, 'organiser_user.name'));
      agents.push(...guests);
    }

    const guestString = this.getGuestNamesAsString(allGuests);

    const agentString = this.limitListJoinByCharLength(agents, 95);

    const isOtherAccount = editMode === 'other';

    const calendarOwnerId = _.get(event, 'calendar.owner_user.id');

    const calendarOwnerName = _.get(
      event,
      'calendar.owner_user.name',
      // name stored under _name when accessed from dashboard
      _.get(event, 'calendar.owner_user._name')
    );

    const calendarName = _.get(event, 'calendar.name');

    const calendarDisplayName =
      userId !== calendarOwnerId && calendarOwnerName !== calendarName
        ? `${calendarOwnerName} (${calendarName})`
        : calendarName;

    const { isUnconfirmed } = getCalendarEventStatus({ event });

    return (
      <Box width={350}>
        <Box
          {...s.with('header')({ backgroundColor: getEventColor(event) })}
          flexDirection='row'
          alignItems='top'
          justifyContent='space-between'
        >
          <Box flex={1} flexDirection='column' justifyContent='center'>
            {_.get(event, 'event_status.id') === 'cancelled' ? (
              <Box
                flexDirection='row'
                alignItems='center'
                {...s('headerStatusTitle')}
              >
                <ICONS.CANCELLED />
                <span>Cancelled</span>
              </Box>
            ) : _.get(event, 'transparency_id') === 'transparent' &&
              editMode !== 'none' ? (
              <Box
                flexDirection='row'
                alignItems='center'
                {...s('headerStatusTitle')}
              >
                <ICONS.BRIEFCASE style={{ width: '12px' }} />
                <span>Free</span>
              </Box>
            ) : null}

            <Box {...s('headerSection')} flexDirection='column'>
              <Heading white level={2}>
                {heading}
              </Heading>

              {!!isSystem && !isEventBusy && (
                <Fragment>
                  {type === EVENT_TYPES.OPEN_HOME ? (
                    <ICONS.OPEN_HOME {...s('headerIcon')} />
                  ) : (
                    <ICONS.AUCTION {...s('headerIcon')} />
                  )}
                </Fragment>
              )}
            </Box>

            {subtitle && (
              <Box
                flexDirection='row'
                alignItems='center'
                {...s('headerSubtitle')}
              >
                <span>
                  {!isEventBusy
                    ? subtitle
                    : 'You don’t have permission to view details'}
                </span>
              </Box>
            )}
          </Box>

          <Box {...s('closeBtn')} onClick={toggle}>
            <Box {...s('closeIcon')}>
              <ICONS.CLOSE_MEDIUM_THIN fill='white' />
            </Box>
          </Box>
        </Box>
        {!!isOtherAccount && (
          <Box flexDirection='row' alignItems='center' {...s('banner')}>
            <ICONS.CALENDAR_LOCATION {...s('bannerIcon')} />
            <span {...s('bannerText')}>
              From {_.get(event, 'account.name')}
            </span>
          </Box>
        )}
        <PaddingBox flexDirection='column'>
          <Value
            label='when'
            value={date}
            extraValue={travelText}
            Icon={ICONS.CALENDAR_TIME}
            first
          />
          <Value
            label='where'
            value={_.get(event, 'event_location.description')}
            Icon={ICONS.CALENDAR_LOCATION}
          />
          {!isAuction && (
            <Value
              label={
                allGuests.length === 1
                  ? `1 ${isOpenHome ? 'attendee' : 'guest'}`
                  : `${allGuests.length} ${isOpenHome ? 'attendees' : 'guests'}`
              }
              value={
                !(allGuests.length === 1 && allGuests[0] === eventOwner) // Don't show guests if the only guest is the organiser
                  ? guestString
                  : null
              }
              Icon={ICONS.CALENDAR_GUESTS}
            />
          )}
          {agents.length > 0 && !isEventBusy && (
            <Value
              label={agents.length === 1 ? 'agent' : 'agents'}
              value={agentString}
              Icon={ICONS.CALENDAR_ORGANISER}
            />
          )}
          {eventOwner && !isSystem && !isEventBusy && (
            <Value
              label={'organiser'}
              value={`${eventOwner}${
                shouldShowBookingUser ? ` (Booked by ${createdUserName})` : ''
              }`}
              Icon={ICONS.CALENDAR_ORGANISER}
            />
          )}
          {!isEventBusy && (
            <Value
              label='description'
              value={_.get(event, 'description', null)}
              Icon={ICONS.CALENDAR_DESCRIPTION}
              maxLength={130}
            />
          )}
          {/* TODO: Show Calendar Communications when ready. CH: https://app.clubhouse.io/rexlabs/story/35458/unhide-calendar-communications */}
          {/* <Value */}
          {/* label='alerts' */}
          {/* value={alerts.length ? alerts : null} */}
          {/* Icon={ICONS.CALENDAR_ALERTS} */}
          {/* /> */}
          <Value
            label='calendar'
            value={calendarDisplayName}
            extraValue={
              !!eventOwner && eventOwner !== calendarOwnerName
                ? `created by ${eventOwner}`
                : null
            }
            Icon={ICONS.CALENDAR_CALENDAR}
          />
          {isUnconfirmed && (
            <PendingBanner
              eventData={event}
              guests={allGuests}
              togglePopout={toggle}
              confirmationMessageDialog={sendConfirmationMessagesDialog}
            />
          )}
        </PaddingBox>
        {isSystem && !isEventBusy ? (
          <PaddingBox light>
            <ButtonBar hasPadding={false}>
              <TextButton blue onClick={toggle}>
                Cancel
              </TextButton>
              {!isOtherAccount && (
                <DefaultButton
                  dark
                  onClick={() =>
                    push({
                      config: {
                        path: `/listings/#id=${_.get(event, 'records.0.id')}`
                      }
                    })
                  }
                >
                  View Listing
                </DefaultButton>
              )}
            </ButtonBar>
          </PaddingBox>
        ) : (
          <PaddingBox light>
            <ButtonBar
              isLoading={isTmpEvent}
              hasPadding={false}
              onClick={(e) => e.stopPropagation()}
            >
              {this.getActionsDropdown()}

              {['read', 'other', 'default'].includes(editMode) && (
                <MergeActionMenu
                  left
                  sendEmail={() => sendEmail(event)}
                  sendSms={() => sendSms(event)}
                />
              )}

              {['read', 'other', 'default'].includes(editMode) && !isEventBusy && (
                <DefaultButton
                  dark
                  onClick={() => {
                    push({
                      config: {
                        path: ROUTES.CALENDAR.config.path,
                        hash:
                          `?event_id=${event.id}` +
                          `&calendar_id=${_.get(event, 'calendar.id')}`
                      }
                    });
                    toggle();
                  }}
                >
                  {['default', 'other'].includes(editMode)
                    ? 'View / Edit'
                    : 'View'}
                </DefaultButton>
              )}
            </ButtonBar>
          </PaddingBox>
        )}
      </Box>
    );
  }
}

const defaultStyles = StyleSheet({
  header: {
    color: COLORS.WHITE,
    padding: '10px 20px',
    minHeight: '80px'
  },

  headerSection: {
    width: '95%'
  },

  headerSubtitle: {
    fontSize: '12px',
    lineHeight: '14px',
    fontWeight: 600,
    opacity: 0.8,
    height: '20px'
  },

  headerStatusTitle: {
    fontSize: '12px',
    lineHeight: '14px',
    fontWeight: 600,
    opacity: 0.8,
    height: '20px',
    '& svg': {
      marginRight: '4px'
    }
  },

  headerIcon: {
    height: '20px',
    width: '20px',
    marginLeft: '8px',
    opacity: 0.8
  },

  closeBtn: {
    cursor: 'pointer',
    '&:hover': {
      opacity: 0.8
    },
    marginRight: -10,
    width: 32,
    height: 32
  },

  closeIcon: {
    marginTop: 3,
    marginLeft: 3
  },

  banner: {
    height: '30px',
    background: '#E6E6E2',
    color: '#A2A095',
    fontSize: '12px',
    lineHeight: '14px',
    fontWeight: 600,
    padding: '0 20px'
  },

  bannerIcon: {
    marginRight: '2px',
    marginTop: '3px'
  }
});

const mapSessionToProps = (state) => ({
  calendarsSettings: _.get(state, 'session.global_settings.calendars_settings'),
  officeDetails: _.get(state, 'session.office_details'),
  userId: _.get(state, 'session.user_details.id')
});

@connect(mapSessionToProps)
@styled(defaultStyles)
@autobind
class ViewAppointmentPopout extends PureComponent {
  renderContent(props) {
    return <ViewAppointmentPopoutContent {...this.props} {...props} />;
  }

  render() {
    const { ...props } = this.props;

    return (
      <Popout
        placement='right-start'
        {...props}
        Content={this.renderContent}
        Arrow={NullComponent}
      />
    );
  }
}

export default ViewAppointmentPopout;
