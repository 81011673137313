import { RecordListFilters } from 'components/record-list-screen/filters';
import Box from '@rexlabs/box';
import { COLORS, PADDINGS } from 'theme';
import { FilterAppraisalTypeDropdown } from 'features/pipelines/components/filter-appraisal-type-dropdown';
import {
  OrderBy,
  OrderByDropdown
} from 'features/pipelines/components/order-by-dropdown';
import { DefaultButton } from 'view/components/button';
import CoreToggle from '../../../view/components/button/toggle/core';
import { ICONS } from 'shared/components/icon';
import React, { useEffect } from 'react';
import { useCriteria } from 'components/record-list-screen/utils/use-criteria';
import { getListIdFromColumnId, PipelineScreenProps } from 'features/pipelines';
import { useLocalStoredFilter } from 'features/pipelines/hooks/use-local-stored-filter';
import {
  appraisalsSetListIdsToAlwaysBatch,
  PipelineGroupBy
} from 'data/models/entities/appraisals';
import { groupByColumns } from 'features/pipelines/data/group-by-columns';
import { PipelineStageData } from 'features/pipelines/data/admin-pipeline-stages';
import { EntityModel } from '@rexlabs/model-generator';
import { push } from '@rexlabs/whereabouts';
import Requires from 'view/containers/requires';
import { StyleSheet, useStyles } from '@rexlabs/styling';
import BetaTag from 'components/beta-tag';
import Analytics from 'shared/utils/vivid-analytics';
import { EVENTS } from 'shared/utils/analytics/index';

const Calendar = ICONS.CALENDAR;
const AddIcon = ICONS.ADD_MEDIUM_THICK;

const defaultStyles = StyleSheet({
  topRow: {
    flexDirection: 'row',
    alignItems: 'flex-end',
    justifyContent: 'space-between',
    display: 'flex',
    height: '34px',
    '@media (max-width: 1100px)': {
      flexDirection: 'column',
      alignItems: 'flex-start',
      height: 'auto',
      gap: '20px'
    }
  }
});

export function PipelineFilters<M extends EntityModel<any>>({
  filterState,
  title,
  serviceName,
  lensesConfig,
  filtersConfig,
  orderBy,
  setOrderBy,
  groupBy,
  setGroupBy,
  onAddNew,
  setColumns,
  fetchedColumns
}: {
  filterState: ReturnType<typeof useCriteria>;
  orderBy: OrderBy;
  setOrderBy: (orderBy: OrderBy) => void;
  groupBy: PipelineGroupBy;
  setGroupBy: (groupBy: PipelineGroupBy) => void;
  setColumns: (columns: PipelineStageData[] | null) => void;
  fetchedColumns: PipelineStageData[] | null;
} & Pick<
  PipelineScreenProps<M>,
  'title' | 'serviceName' | 'lensesConfig' | 'filtersConfig' | 'onAddNew'
>) {
  const s = useStyles(defaultStyles);
  const [
    filteredAppraisalType,
    setFilteredAppraisalType
  ] = useLocalStoredFilter({
    filterKey: 'appraisal_type_id',
    setCriteria: filterState.setCriteria,
    savedFilterId: filterState.savedFilterId,
    displayCriteria: filterState.displayCriteria,
    serviceName
  });

  function handleDateGroupBy() {
    const pipeline_type_id = 'appraisal' as const;
    const columns = groupByColumns(pipeline_type_id, fetchedColumns);

    appraisalsSetListIdsToAlwaysBatch(
      columns.map((c) =>
        getListIdFromColumnId(c.id, 'days_to_expected_close_30_day_bucketed')
      )
    );

    setColumns(columns);
  }

  function handlePipelineStageGroupBy() {
    appraisalsSetListIdsToAlwaysBatch(
      fetchedColumns?.map((c) => getListIdFromColumnId(c.id, 'pipeline_stage'))
    );
    setColumns(fetchedColumns);
  }

  function changeGroupMode(e: { target: { value: PipelineGroupBy } }) {
    Analytics.track({
      event: EVENTS.PIPELINES.GROUP_MODE_CHANGED,
      properties: {
        viewMode: e.target.value
      }
    });
    setGroupBy(e.target.value);
    if (e.target.value === 'pipeline_stage') {
      handlePipelineStageGroupBy();
    } else {
      handleDateGroupBy();
    }
  }

  useEffect(() => {
    if (!fetchedColumns) return;
    if (groupBy === 'pipeline_stage') {
      handlePipelineStageGroupBy();
    } else {
      handleDateGroupBy();
    }
  }, [fetchedColumns]);

  return (
    <Box display={'flex'} flexDirection={'column'} justifyContent={'stretch'}>
      <Box marginTop={PADDINGS.L} {...s('topRow')}>
        <div data-intercom-target='Appraisals Pipeline Filters'>
          <RecordListFilters
            title={title}
            serviceName={serviceName}
            lenses={lensesConfig}
            filtersConfig={filtersConfig}
            {...filterState}
            orderBy={orderBy}
            setOrderBy={setOrderBy}
            truncateDisplayedFilters={true}
          />
        </div>
        <Box
          ml={'auto'}
          flexDirection={'row'}
          alignItems={'center'}
          style={{ gap: PADDINGS.XS }}
        >
          <Requires accessRights={'admin_advanced_settings.update'}>
            <div data-intercom-target='Appraisals Pipeline Edit Stages'>
              <DefaultButton
                onClick={() =>
                  push({
                    config: { path: `/admin/lists/pipelinestages` }
                  })
                }
              >
                Edit Stages
              </DefaultButton>
            </div>
          </Requires>
          <div data-intercom-target='Appraisals Pipeline Group By'>
            <CoreToggle
              alwaysShowLabel
              buttons={[
                {
                  id: 'pipeline_stage',
                  text: 'group by stage',
                  Icon: ICONS.ADMIN
                },
                {
                  id: 'days_to_expected_close_30_day_bucketed',
                  text: 'group by date',
                  Icon: () => (
                    // @ts-ignore
                    <Calendar
                      style={{
                        width: '21px',
                        height: 'auto',
                        marginRight: '3px'
                      }}
                    />
                  )
                }
              ]}
              value={groupBy}
              onChange={changeGroupMode}
            />
          </div>

          {onAddNew ? (
            <Requires accessRights={'appraisals.create'}>
              <DefaultButton
                blue
                IconLeft={AddIcon}
                onClick={() => onAddNew(groupBy)}
              >
                Add {title.slice(0, -1)}
              </DefaultButton>
            </Requires>
          ) : null}
        </Box>
      </Box>
      <Box
        flexDirection={'row'}
        alignItems={'center'}
        justifyContent={'flex-start'}
        style={{
          borderTop: `2px solid ${COLORS.STATES.IDLE}`,
          gap: PADDINGS.L
        }}
        mt={PADDINGS.M}
        pt={PADDINGS.S}
        pb={PADDINGS.L}
      >
        <FilterAppraisalTypeDropdown
          onChange={setFilteredAppraisalType}
          value={filteredAppraisalType as string}
        />
        <OrderByDropdown value={orderBy} onChange={setOrderBy} />
      </Box>
    </Box>
  );
}
