import React, { PureComponent } from 'react';
import Box from '@rexlabs/box';
import { styled, StyleSheet } from '@rexlabs/styling';
import { PADDINGS } from 'theme';
import { TextInput } from '@rexlabs/text-input';
import { Select } from 'view/components/input/select';
import adminIdentityDocumentTypeModel from 'data/models/entities/admin-identity-document-types';
import { autobind } from 'core-decorators';
import _ from 'lodash';
import { query, withQuery } from '@rexlabs/model-generator';

const defaultStyles = StyleSheet({
  inputs: {
    marginRight: PADDINGS.XS
  },
  textInputContainer: {},
  selectionContainer: {}
});

const getCriteria = () => [
  {
    name: 'is_hidden',
    type: '=',
    value: '0'
  }
];

const idTypesQuery = query`{
  ${adminIdentityDocumentTypeModel} (criteria: ${getCriteria}) {
    id
    name
    characters_required
  }
}`;

@withQuery(idTypesQuery)
@styled(defaultStyles)
@autobind()
class TypeReferenceInput extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      options: [],
      charactersRequiredMap: {},
      humanReadableNameMap: {}
    };
  }

  componentDidMount() {
    const tempCharactersRequiredMap = {};
    const tempHumanReadableNameMap = {};

    if (this.props.adminIdentityDocumentTypes.list.status === 'loaded') {
      _.get(this.props.adminIdentityDocumentTypes, 'list.items', []).forEach(
        (idType) => {
          tempCharactersRequiredMap[idType.id] = idType.characters_required;
          tempHumanReadableNameMap[idType.id] = idType.name;
        }
      );

      this.setState({
        options: _.get(
          this.props.adminIdentityDocumentTypes,
          'list.items',
          []
        ).map((idType) => {
          return {
            label: idType.name,
            value: idType.id
          };
        }),
        charactersRequiredMap: tempCharactersRequiredMap,
        humanReadableNameMap: tempHumanReadableNameMap
      });
    }
  }

  componentWillReceiveProps(nextProps) {
    const tempCharactersRequiredMap = {};
    const tempHumanReadableNameMap = {};

    if (
      this.props.adminIdentityDocumentTypes.list.status === 'loading' &&
      nextProps.adminIdentityDocumentTypes.list.status === 'loaded'
    ) {
      _.get(nextProps.adminIdentityDocumentTypes, 'list.items').forEach(
        (idType) => {
          tempCharactersRequiredMap[idType.id] = idType.characters_required;
          tempHumanReadableNameMap[idType.id] = idType.name;
        }
      );

      this.setState({
        options: _.get(nextProps.adminIdentityDocumentTypes, 'list.items').map(
          (idType) => {
            return {
              label: idType.name,
              value: idType.id
            };
          }
        ),
        charactersRequiredMap: tempCharactersRequiredMap,
        humanReadableNameMap: tempHumanReadableNameMap
      });
    }
  }

  handleTypeChange({ target }) {
    const { value, onChange } = this.props;

    const fakeEvent = {
      target: {
        value: {
          typeValue: target.value,
          referenceValue: _.get(value, 'referenceValue'),
          charactersRequired: this.state.charactersRequiredMap[target.value],
          humanReadableName: this.state.humanReadableNameMap[target.value]
        }
      }
    };
    onChange(fakeEvent);
  }

  handleReferenceChange({ target }) {
    const { value, onChange } = this.props;

    const fakeEvent = {
      target: {
        value: {
          typeValue: _.get(value, 'typeValue'),
          referenceValue: target.value,
          charactersRequired: _.get(value, 'charactersRequired'),
          humanReadableName: _.get(value, 'humanReadableName')
        }
      }
    };
    onChange(fakeEvent);
  }

  render() {
    const { styles: s, value, meta, ...props } = this.props;
    return (
      <Box width='100%' flexDirection={'row'}>
        <Box width='30%' {...s('inputs', 'selectionContainer')}>
          <Select
            onChange={this.handleTypeChange}
            value={_.get(value, 'typeValue')}
            options={this.state.options}
            {...props}
          />
        </Box>
        <Box width='70%' {...s('inputs', 'textInputContainer')}>
          <TextInput
            style={{ width: '100%' }}
            meta={meta}
            onChange={this.handleReferenceChange}
            value={_.get(value, 'referenceValue', '')}
            {...props}
          />
        </Box>
      </Box>
    );
  }
}

export default TypeReferenceInput;
