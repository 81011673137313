import React, { KeyboardEventHandler, useRef, useState } from 'react';
import Box from '@rexlabs/box';
import { StyleSheet, useStyles } from '@rexlabs/styling';
import { COLORS } from 'theme';
import { AiGradientButton } from 'features/ai/components/primitives/ai-gradient-button';
import { AiPopoutContents } from 'features/ai/components/primitives/ai-popout-contents';
import {
  AI_POPOUT_CLOSE_DURATION_SECONDS,
  AiPopout
} from 'features/ai/components/primitives/ai-popout';
import { AiBackButton } from 'features/ai/components/primitives/ai-back-button';

type RefinementPopoutProps = {
  onCustomRefinement: (refinementText: string) => void;
  children: React.ReactNode;
};

type RefinementPopoutContentProps = {
  setIsWriting: React.Dispatch<React.SetStateAction<boolean>>;
  setRefinementText: React.Dispatch<React.SetStateAction<string>>;
  isWriting: boolean;
  refinementText: string;
  closePopout: () => void;
  onCustomRefinement: (refinementText: string) => void;
};
export const suggestedRefinements = [
  'Remove bit about',
  'Put more emphasis on',
  'Add note about',
  "Don't fixate on",
  'Change headline to'
];

const refinementStyles = StyleSheet({
  container: {
    position: 'relative'
  },
  header: {
    fontSize: '12px',
    lineHeight: '14px',
    padding: '7px',
    color: COLORS.DARK_GREY,
    fontWeight: 600,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: '2px'
  },
  item: {
    backgroundColor: COLORS.WHITE,
    padding: '5px 10px',
    border: 0,
    fontSize: '14px',
    lineHeight: '17px',
    webkitAppearance: 'none',
    borderRadius: '4px',
    color: COLORS.DARK_GREY,
    textAlign: 'left',
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: 'rgba(208, 231, 246, 1)'
    }
  },
  writingBox: {
    position: 'absolute',
    flexDirection: 'column',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'white',
    padding: '15px'
  },
  textarea: {
    width: '100%',
    flex: 1,
    resize: 'none',
    border: 'none',
    marginTop: '15px',
    outline: 'none',
    padding: 0,
    fontSize: '14px',
    lineHeight: '17px',
    color: COLORS.DARK_GREY
  }
});

export function RefinementPopout({
  onCustomRefinement,
  children
}: RefinementPopoutProps) {
  const [isWriting, setIsWriting] = useState(false);
  const [refinementText, setRefinementText] = useState('');

  return (
    <AiPopout
      renderContent={({ close }) => (
        <RefinementPopoutContent
          setIsWriting={setIsWriting}
          setRefinementText={setRefinementText}
          isWriting={isWriting}
          closePopout={close}
          refinementText={refinementText}
          onCustomRefinement={onCustomRefinement}
        />
      )}
      onClose={() => {
        setTimeout(() => {
          setIsWriting(false);
        }, AI_POPOUT_CLOSE_DURATION_SECONDS * 1000);
      }}
    >
      {children}
    </AiPopout>
  );
}

function RefinementPopoutContent({
  setIsWriting,
  setRefinementText,
  isWriting,
  closePopout,
  refinementText,
  onCustomRefinement
}: RefinementPopoutContentProps) {
  const s = useStyles(refinementStyles);
  const textAreaRef = useRef<HTMLTextAreaElement>(null);

  function handleKeyDown(e: KeyboardEvent) {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      setRefinementText('');
      setIsWriting(false);
      onCustomRefinement(refinementText);
      closePopout();
    }
  }

  return (
    <AiPopoutContents
      key={isWriting ? 'isWriting' : 'notWriting'}
      items={suggestedRefinements
        .map((r) => ({
          label: r + '...',
          onClick: () => {
            setRefinementText(`${r} `);
            setIsWriting(true);
            setTimeout(() => {
              if (!textAreaRef.current) return;
              textAreaRef.current?.setSelectionRange(
                textAreaRef.current?.value.length,
                textAreaRef.current?.value.length
              );
            }, 0);
          }
        }))
        .concat([
          {
            label: 'Choose my own',
            onClick: () => {
              setIsWriting(true);
            }
          }
        ])}
    >
      <Box
        {...s('writingBox')}
        style={{ display: isWriting ? 'flex' : 'none' }}
      >
        <AiBackButton
          onClick={() => setIsWriting(false)}
          label='Back to suggested prompts'
        />

        <textarea
          {...s('textarea')}
          ref={textAreaRef}
          autoFocus
          onKeyDown={
            (handleKeyDown as unknown) as KeyboardEventHandler<HTMLTextAreaElement>
          }
          onChange={(e) => setRefinementText(e.target?.value)}
        >
          {refinementText}
        </textarea>
        <Box
          flexDirection={'row'}
          justifyContent={'flex-end'}
          style={{ gap: '10px' }}
        >
          <AiGradientButton
            withIcon
            disabled={!refinementText}
            onClick={() => {
              setRefinementText('');
              setIsWriting(false);
              onCustomRefinement(refinementText);
              closePopout();
            }}
          >
            Refine
          </AiGradientButton>
        </Box>
      </Box>
    </AiPopoutContents>
  );
}
