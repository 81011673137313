import { gql } from 'hooks/use-graphql-query';
import {
  User,
  Property,
  Chain,
  CustomFields
} from 'features/custom-reporting/data/fragments';
import {
  Contact,
  ContactEmails,
  ContactPhones,
  MatchProfile
} from 'features/custom-reporting/modules/contacts/contact-data-fragments';
import { Contract } from 'features/custom-reporting/modules/contracts/contract-data-fragments';
import { ContractEvent } from 'features/custom-reporting/modules/contract-conditions/contract-conditions-data-fragments';
import { Listing } from 'features/custom-reporting/modules/listings/listing-data-fragments';

export const contractConditionsReportQueryDefaultVariables = {
  include_subresource_contract: false,
  include_subresource_listing: false,
  include_listing_custom_fields: false,
  listing_custom_field_ids: [],
  include_contract_custom_fields: false,
  contract_custom_field_ids: [],
  include_subresource_property: false,
  include_property_custom_fields: false,
  property_custom_field_ids: [],
  include_subresource_vendor: false,
  include_vendor_custom_fields: false,
  vendor_custom_field_ids: [],
  include_subresource_vendor_solicitor: false,
  include_vendor_solicitor_custom_fields: false,
  vendor_solicitor_custom_field_ids: [],
  include_subresource_vendor_solicitor_contact: false,
  include_vendor_solicitor_contact_custom_fields: false,
  vendor_solicitor_contact_custom_field_ids: [],
  include_subresource_purchasers: false,
  include_purchasers_custom_fields: false,
  purchasers_custom_field_ids: [],
  include_subresource_purchaser_solicitor: false,
  include_purchaser_solicitor_custom_fields: false,
  purchaser_solicitor_custom_field_ids: [],
  include_subresource_purchaser_solicitor_contact: false,
  include_purchaser_solicitor_contact_custom_fields: false,
  purchaser_solicitor_contact_custom_field_ids: []
};

export const ContractConditionsReportQuery = gql`
  ${User}
  ${Contract}
  ${Listing}
  ${Property}
  ${Contact}
  ${ContractEvent}
  ${ContactEmails}
  ${ContactPhones}
  ${MatchProfile}
  ${Chain}
  ${CustomFields}

  fragment ContactForContracts on Contact {
    ...Contact
    phones {
      ...ContactPhones
    }
    emails {
      ...ContactEmails
    }
    system_owner_user {
      ...User
    }
    latest_match_profile {
      ...MatchProfile
    }
  }

  query(
    $include_subresource_contract: Boolean!
    $include_subresource_listing: Boolean!
    $include_listing_custom_fields: Boolean!
    $listing_custom_field_ids: [Int!]!
    $include_contract_custom_fields: Boolean!
    $contract_custom_field_ids: [Int!]!
    $include_subresource_property: Boolean!
    $include_property_custom_fields: Boolean!
    $property_custom_field_ids: [Int!]!
    $include_subresource_vendor: Boolean!
    $include_vendor_custom_fields: Boolean!
    $vendor_custom_field_ids: [Int!]!
    $include_subresource_vendor_solicitor: Boolean!
    $include_vendor_solicitor_custom_fields: Boolean!
    $vendor_solicitor_custom_field_ids: [Int!]!
    $include_subresource_vendor_solicitor_contact: Boolean!
    $include_vendor_solicitor_contact_custom_fields: Boolean!
    $vendor_solicitor_contact_custom_field_ids: [Int!]!
    $include_subresource_purchasers: Boolean!
    $include_purchasers_custom_fields: Boolean!
    $purchasers_custom_field_ids: [Int!]!
    $include_subresource_purchaser_solicitor: Boolean!
    $include_purchaser_solicitor_custom_fields: Boolean!
    $purchaser_solicitor_custom_field_ids: [Int!]!
    $include_subresource_purchaser_solicitor_contact: Boolean!
    $include_purchaser_solicitor_contact_custom_fields: Boolean!
    $purchaser_solicitor_contact_custom_field_ids: [Int!]!
    $criteria: [Criterion!]
    $order_bys: [OrderBy!]
    $limit: Int!
    $cursor: String
  ) {
    rowData: contract_events(
      criteria: $criteria
      use_case: reporting
      order_bys: $order_bys
      limit: $limit
      cursor: $cursor
    ) {
      pagination {
        next_page {
          cursor
        }
      }
      rows {
        ...ContractEvent
        contract @include(if: $include_subresource_contract) {
          ...Contract
          custom_field_values(field_ids: $contract_custom_field_ids)
            @include(if: $include_contract_custom_fields) {
            ...CustomFields
          }
          agent {
            ...User
          }
          chain_instance {
            assignee_user {
              ...User
            }
            chain {
              ...Chain
            }
          }
        }
        listing: contract @include(if: $include_subresource_listing) {
          listing {
            ...Listing
            custom_field_values(field_ids: $listing_custom_field_ids)
              @include(if: $include_listing_custom_fields) {
              ...CustomFields
            }
            listing_agent_1 {
              name
            }
            listing_agent_2 {
              name
            }
            system_owner_user {
              ...User
            }
          }
        }
        property: contract @include(if: $include_subresource_property) {
          listing {
            property {
              ...Property
              custom_field_values(field_ids: $property_custom_field_ids)
                @include(if: $include_property_custom_fields) {
                ...CustomFields
              }
              system_owner_user {
                ...User
              }
            }
          }
        }
        owner: contract @include(if: $include_subresource_vendor) {
          listing {
            contact_relationships(limit: 1, type: "owner") {
              relationship_type {
                id
              }
              contact {
                ...ContactForContracts
                custom_field_values(field_ids: $vendor_custom_field_ids)
                  @include(if: $include_vendor_custom_fields) {
                  ...CustomFields
                }
              }
            }
          }
        }
        vendor_solicitor: contract
          @include(if: $include_subresource_vendor_solicitor) {
          listing {
            legal_solicitor {
              ...ContactForContracts
              custom_field_values(
                field_ids: $vendor_solicitor_custom_field_ids
              ) @include(if: $include_vendor_solicitor_custom_fields) {
                ...CustomFields
              }
            }
          }
        }
        vendor_solicitor_contact: contract
          @include(if: $include_subresource_vendor_solicitor_contact) {
          listing {
            legal_solicitor_contact {
              ...ContactForContracts
              custom_field_values(
                field_ids: $vendor_solicitor_contact_custom_field_ids
              ) @include(if: $include_vendor_solicitor_contact_custom_fields) {
                ...CustomFields
              }
            }
          }
        }
        purchasers: contract @include(if: $include_subresource_purchasers) {
          purchtenant_contacts {
            ...ContactForContracts
            custom_field_values(field_ids: $purchasers_custom_field_ids)
              @include(if: $include_purchasers_custom_fields) {
              ...CustomFields
            }
          }
        }
        purchaser_solicitor: contract
          @include(if: $include_subresource_purchasers) {
          purchtenant_solicitor
            @include(if: $include_subresource_purchaser_solicitor) {
            ...ContactForContracts
            custom_field_values(
              field_ids: $purchaser_solicitor_custom_field_ids
            ) @include(if: $include_purchaser_solicitor_custom_fields) {
              ...CustomFields
            }
          }
        }
        purchaser_solicitor_contact: contract
          @include(if: $include_subresource_purchaser_solicitor_contact) {
          purchtenant_solicitor_contact {
            ...ContactForContracts
            custom_field_values(
              field_ids: $purchaser_solicitor_contact_custom_field_ids
            ) @include(if: $include_purchaser_solicitor_contact_custom_fields) {
              ...CustomFields
            }
          }
        }
      }
    }
  }
`;
