import Box from '@rexlabs/box';
import React, { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { StyleSheet } from '@rexlabs/styling';
import { RadioGroupInput } from 'src/view/components/input/radio-buttons';
import { COLORS } from 'src/theme';
import {
  AgentAllocation,
  CommissionTier,
  CommissionWorksheetItem
} from '../types/commission-worksheet';
import { withModel } from '@rexlabs/model-generator';
import agentCommissionsModel, {
  AgentCommissionsModel,
  UpstreamCommissionSummary
} from 'data/models/entities/agent-commissions';
import { useErrorDialog } from 'hooks/use-error-dialog';
import Spinner from 'shared/components/spinner';
import { getSlidingScaleCommission } from '../utils/get-sliding-scale-commission';
import { AgentFixedCommissions } from 'features/commission-worksheet/components/agent-fixed-commissions';
import { AgentSlidingScaleCommissions } from 'features/commission-worksheet/components/agent-sliding-scale-commissions';
import { Body } from 'components/text/body';

export const radioButtonOverrides = {
  Checkbox: StyleSheet({
    container: {
      width: 'max-content',
      position: 'relative',
      paddingTop: 0,
      paddingBottom: 0,
      // Suffix
      '& span': {
        position: 'absolute',
        right: -26,
        display: 'flex'
      }
    },

    label: {
      '&&': {
        fontWeight: 600,
        fontSize: '12px',
        paddingLeft: 0
      }
    },

    input: {
      '&& ~ .styled-label': {
        margin: '6px 2px 6px 0',
        height: '17px',
        width: '17px',
        border: `2px solid ${COLORS.STATES.IDLE}`,

        '&:focus': {
          // NOTE: this is bad practise from a11y standpoint and only
          // here to match backwards compatibility of styles :/
          outline: 'none'
        },

        '&:before': {
          background: `${COLORS.DARK_GREY}`
        }
      },

      '&:checked ~ .styled-label': {
        borderColor: `${COLORS.STATES.IDLE} !important`
      },

      '&:hover ~ .styled-label': {
        borderColor: `${COLORS.STATES.HOVER} !important`
      }
    }
  })
};

interface AgentSlidingCommissionProps {
  worksheet: CommissionWorksheetItem;
  agentAllocation: AgentAllocation;
  agentCommissions: AgentCommissionsModel;
  distributableCommissions: number;
  onChange: (name: string, value: unknown, silent?: boolean) => void;
}

const AgentSlidingCommission = ({
  worksheet,
  agentCommissions,
  agentAllocation,
  distributableCommissions,
  onChange
}: AgentSlidingCommissionProps) => {
  const errorDialog = useErrorDialog();
  const [
    commissionSummary,
    setCommissionSummary
  ] = useState<UpstreamCommissionSummary | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const { worksheet_version } = worksheet;

  const availableCommission = useMemo(() => {
    const availableCommission =
      Number(distributableCommissions) *
      Number(agentAllocation.gross_comm_alloc_percentage / 100);

    // Automatically update tier calculation when available commission changes
    if (commissionSummary && agentAllocation.sliding_scale_tiers) {
      const agentRole = agentAllocation.agent_role.id;
      const tiers: CommissionTier[] = commissionSummary.sliding_scale_tiers.map(
        (tier, index) =>
          agentAllocation.sliding_scale_tiers?.[index]
            ? agentAllocation.sliding_scale_tiers[index]
            : {
                sliding_structure_tier: tier,
                amount: 0,
                percent: (agentRole === 'list'
                  ? tier.listing_amount_percentage
                  : agentRole === 'sale_or_lease'
                  ? tier.selling_amount_percentage
                  : tier.listing_selling_amount_percentage
                ).toString()
              }
      );
      const tierAllocations = getSlidingScaleCommission(
        availableCommission,
        commissionSummary.summary.total_amount,
        commissionSummary.calculation_base.id,
        tiers
      );

      onChange(
        'sliding_scale_tiers',
        tierAllocations.map((tier) => ({
          sliding_structure_tier: tier.sliding_structure_tier,
          amount: tier.amount,
          percent: tier.percent
        }))
      );
    }

    return availableCommission;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [distributableCommissions, agentAllocation.gross_comm_alloc_percentage]);

  const getCommissionSummary = async (updateTiers: boolean) => {
    setIsLoading(true);

    try {
      const res = await agentCommissions.getUpstreamCommissionSummary({
        worksheet_id: worksheet._id,
        agent_id: agentAllocation.agent.id
      });

      const { result } = res.data;

      // It means user doesn't have sliding scale tiers
      if (!result) {
        setIsLoading(false);
        return;
      }

      setCommissionSummary(result);

      if (updateTiers) {
        onChange('calculation_id', 'sliding');

        // Pre-fill tier calculations
        const agentRole = agentAllocation.agent_role.id;
        const tiers: CommissionTier[] = result.sliding_scale_tiers.map(
          (tier) => ({
            sliding_structure_tier: tier,
            amount: 0,
            // Pre-fill percentage based on the agent role selected in 'Award Allocation'
            percent: (agentRole === 'list'
              ? tier.listing_amount_percentage
              : agentRole === 'sale_or_lease'
              ? tier.selling_amount_percentage
              : tier.listing_selling_amount_percentage
            ).toString()
          })
        );

        const tierAllocations = getSlidingScaleCommission(
          availableCommission,
          result.summary.total_amount,
          result.calculation_base.id,
          tiers
        );
        onChange(
          'sliding_scale_tiers',
          tierAllocations.map((tier) => ({
            sliding_structure_tier: tier.sliding_structure_tier,
            amount: tier.amount,
            percent: tier.percent
          }))
        );
      }

      setIsLoading(false);
    } catch (error) {
      errorDialog.open(error as Error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getCommissionSummary(
      !agentAllocation.net_comm_amount &&
        !agentAllocation.sliding_scale_tiers?.length &&
        availableCommission > 0
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoading) {
    return (
      <Box alignItems='center' justifyContent='center'>
        <Spinner small dark classic />
      </Box>
    );
  }

  return (
    <Box>
      {!!commissionSummary?.sliding_scale_tiers.length && (
        <Box mb={15}>
          {worksheet_version?.id === '3' ? (
            <Body dark small semibold>
              calculation type
            </Body>
          ) : null}
          <RadioGroupInput
            overrides={radioButtonOverrides}
            orientation='horizontal'
            value={agentAllocation.calculation_id}
            name={`calculation-${agentAllocation.agent?.id ?? ''}`}
            options={[
              { value: 'fixed', label: 'fixed calculation' },
              {
                value: 'sliding',
                label: 'sliding calculation'
              }
            ]}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              if (e.target.value === 'fixed') {
                onChange('sliding_scale_tiers', null);
              } else {
                onChange('net_comm_amount', null);
                onChange('net_comm_amount_unit', {
                  id: 'exact_amount',
                  text: 'Exact Amount'
                });
                getCommissionSummary(true);
              }
              onChange('calculation_id', e.target.value);
            }}
          />
        </Box>
      )}
      {agentAllocation.calculation_id === 'fixed' ? (
        <AgentFixedCommissions
          worksheetVersionId={worksheet_version?.id}
          agentAllocation={agentAllocation}
          availableCommission={availableCommission}
          distributableCommissions={distributableCommissions}
          onChange={onChange}
        />
      ) : (
        <AgentSlidingScaleCommissions
          agentAllocation={agentAllocation}
          availableCommission={availableCommission}
          commissionSummary={commissionSummary}
          distributableCommissions={distributableCommissions}
          worksheetVersionId={worksheet_version?.id}
          onChange={onChange}
        />
      )}
    </Box>
  );
};

export default withModel(agentCommissionsModel)(AgentSlidingCommission);
