import { SendMailMergeRecordData } from 'components/record-preview/record-preview-action-bar';
import { ContactItem } from 'data/models/entities/contacts';

export function getContactPreviewPopoutData({
  contact
}: {
  contact?: ContactItem;
}) {
  const contactImage = contact?.contact_image?.url;
  const contactType = contact?.type;
  const recordTypeHeading = `Contact${contactType ? ` — ${contactType}` : ''}`;
  const contactName = contact?.system_search_key || '';

  const contactPhoneNumbers = contact?.related?.contact_phones || [];
  const contactPhoneRecord =
    contactPhoneNumbers.find((phone) => phone.phone_primary) ||
    contactPhoneNumbers[0];

  const contactPhoneNumber = contactPhoneRecord?.phone_number;

  const phoneIsOnTPS = contactPhoneRecord?.wash?.present_on_lists?.includes?.(
    'tps'
  );
  const shouldOverrideTPS = contactPhoneRecord?.wash?.user_override === 'allow';

  const isDoNotContact = !!contact?.is_dnd;

  const contactEmailAddresses = contact?.related?.contact_emails || [];

  const contactEmailAddress =
    contactEmailAddresses.find((emailAddress) => emailAddress.email_primary)
      ?.email_address || contactEmailAddresses[0]?.email_address;

  const buyerInterestLevel = contact?.interest_level;

  const newMailMergeData: SendMailMergeRecordData | undefined = contact && {
    id: contact.id,
    name: contactName,
    service: 'contact'
  };

  const newNoteData = {
    id: '',
    _related: {
      note_contacts: [{ contact }]
    }
  };

  const newAppointmentData = contact && {
    records: [
      {
        id: contact.id,
        label: contactName,
        service: 'Contacts',
        stub: {
          ...contact,
          name: contact.system_search_key,
          email_address: contactEmailAddress,
          phone_number: contactPhoneNumber
        }
      }
    ]
  };

  const feedbackData = contact && {
    related: { feedback_contacts: [{ contact: contact }] }
  };

  return {
    contactImage,
    recordTypeHeading,
    contactName,
    contactPhoneNumber,
    contactEmailAddress,
    isDoNotContact,
    buyerInterestLevel,
    newMailMergeData,
    newNoteData,
    newAppointmentData,
    feedbackData,
    phoneIsOnTPS,
    shouldOverrideTPS
  };
}
